import { css } from "@emotion/react";
import styled from "@emotion/styled";

const Flex = styled("div")`
  display: flex;
`;

const FlexCentered = styled("div")`
  display: flex;
  align-items: center;
`;

const FlexCenteredGap = styled("div")(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
  `
);

const FlexColumn = styled("div")`
  display: flex;
  flex-direction: column;
`;

const FlexColumnGap = styled("div")(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(1)};
  `
);

const FlexColumnCentered = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export {
  Flex,
  FlexCentered,
  FlexCenteredGap,
  FlexColumn,
  FlexColumnGap,
  FlexColumnCentered,
};
