import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Command } from "cmdk";
import React from "react";
import toast from "react-hot-toast";
import { BsTools } from "react-icons/bs";
import { FaGem, FaGoogle, FaTwitter } from "react-icons/fa";
import { RxIconjarLogo } from "react-icons/rx";

import tokensniffer from "../../../assets/icons/tokenLinks/tokensniffer.jpg";
import {
  NetworkExplorerIcons,
  NetworkScanNames,
  NetworkShortName,
} from "../../../constants/Network";
import { chartLinks } from "../../../constants/TokenLinks";
import { useGetBarsQuery } from "../../../generated/graphql";
import { breakpoints } from "../../../styles/Layout";
import { monospace } from "../../../styles/Mixins";
import { NetworkId } from "../../../types/Network";
import { formatDisplayNumber } from "../../../utils/format";
import { createNetworkScanLink } from "../../../utils/network";
import { createTokenChartLink } from "../../../utils/token";
import {
  Flex,
  FlexCentered,
  FlexColumn,
  FlexColumnGap,
} from "../../basic/Flex";
import { TimeChart, TimeChartPoint } from "../../chart/TimeChart";
import { PercentChange } from "../../dataDisplay/PercentChange";
import { TokenIcon } from "../../dataDisplay/TokenIcon";
import { GroupHeadingWithIcon, TokenWithTopPair } from "../Search";

export interface SearchTokenSelectOptions {
  networkId: NetworkId;
  networkShortName: string;
  networkFullName: string;
  address: string;
}

export interface TokenPageProps {
  token: TokenWithTopPair;
  selectedWindow?: string;
}

const TokenPageContainer = styled(FlexColumnGap)(
  ({ theme }) => css`
    color: ${theme.colors.text};
    padding: ${theme.spacing(1)};
  `
);

const Header = styled(FlexCentered)(
  ({ theme }) => css`
    gap: ${theme.spacing(2)};
  `
);

const SymbolAndName = styled(FlexColumn)`
  margin-right: auto;
  line-height: 1.3;
`;

const PriceAndChange = styled(FlexColumn)(
  ({ theme }) => css`
    align-items: flex-end;
    gap: ${theme.spacing(0.5)};
  `
);

const Price = styled("h2")`
  font-weight: 700;
  ${monospace}
`;

const ColumnGroups = styled(Flex)(
  ({ theme }) => css`
    gap: ${theme.spacing(1)};
    [cmdk-group] {
      flex: 1;
      margin-top: 8px;
    }

    @media (max-width: ${breakpoints.sm}px) {
      flex-direction: column;
    }
  `
);

const ItemIcon = styled("img")(
  ({ theme }) => css`
    background: ${theme.colors.primary}22;
    width: 20px;
    height: 20px;
    border-radius: 50%;
  `
);

const StyledCommandItem = styled(Command.Item)`
  svg {
    width: 20px;
    height: 20px;
  }
`;

const CommandItemWithAuxClick: React.FC<{
  value: string;
  link: string | null;
  children: React.ReactNode;
}> = ({ value, link, children }) =>
  !link ? (
    <StyledCommandItem disabled value={value}>
      {children}
    </StyledCommandItem>
  ) : (
    <StyledCommandItem
      value={value}
      onSelect={() => window.open(link)}
      onAuxClick={() => window.open(link, "_blank")}
    >
      {children}
    </StyledCommandItem>
  );

const TokenPage: React.FC<TokenPageProps> = ({ token, selectedWindow }) => {
  const [price, setPrice] = React.useState<number | null>();
  const [priceChange, setPriceChange] = React.useState<number | null>();

  const [formattedChartData, setFormattedChartData] = React.useState<
    TimeChartPoint[] | undefined
  >([]);

  const resolutionFromWindow = React.useMemo(() => {
    return selectedWindow || "15"; // TODO: map window to resolution
  }, [selectedWindow]);

  const toDate = Math.floor(Date.now() / 1000);
  const fromDate = toDate - 60 * 60 * 24;

  const { data: chartData, loading: chartLoading } = useGetBarsQuery({
    variables: {
      symbol: token.id,
      from: fromDate,
      to: toDate,
      resolution: resolutionFromWindow,
    },
    skip: formattedChartData && formattedChartData.length > 0, // Don't re-run query if we already have assets
    onError: (e) => {
      toast.error(e.message);
    },
  });

  React.useEffect(() => {
    if (!chartData) return;

    const oldestPrice = chartData?.getBars?.c[0];
    const newestPrice = chartData?.getBars?.c[chartData?.getBars?.c.length - 1];
    setPrice(newestPrice);
    setPriceChange(
      newestPrice && oldestPrice
        ? (newestPrice - oldestPrice) / oldestPrice
        : null
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFormattedChartData(
      chartData?.getBars?.t.map((time, i) => ({
        x: new Date(time * 1000).toISOString(),
        y: chartData?.getBars?.c[i] || null,
      }))
    );
  }, [chartData]);

  return (
    <TokenPageContainer>
      <Header>
        <TokenIcon
          address={token.address}
          name={token.name || token.address}
          networkId={token.networkId}
          sizeInPx={50}
        />
        <SymbolAndName>
          <h1>{token.symbol}</h1>
          <h3 style={{ opacity: "0.6" }}>{token.name}</h3>
        </SymbolAndName>
        <PriceAndChange>
          <Price>
            {formatDisplayNumber({
              dollars: true,
              value: price,
              zerosTilSubscript: 4,
              sigFigs: 4,
            })}
          </Price>
          <PercentChange value={priceChange} />
        </PriceAndChange>
      </Header>
      <TimeChart
        heightInPx={300}
        chartPoints={formattedChartData}
        chartId={token.id}
        loading={chartLoading}
      />
      <ColumnGroups>
        {/*
          TODO: Have to migrate this endpoint to the public API
         <Command.Group
          heading={
            <GroupHeadingWithIcon>
              <HiShare />
              Socials
            </GroupHeadingWithIcon>
          }
        >
          {pairsLoading ? (
            <Command.Item>Loading...</Command.Item>
          ) : (
            <>
              <Command.Item value="website">Website</Command.Item>
              <Command.Item value="twitter">Twitter</Command.Item>
              <Command.Item value="telegram">Telegram</Command.Item>
              <Command.Item value="discord">Discord</Command.Item>
              <Command.Item value="blog">Blog</Command.Item>
            </>
          )}
        </Command.Group> */}
        <Command.Group
          heading={
            <GroupHeadingWithIcon>
              <BsTools />
              Charts
            </GroupHeadingWithIcon>
          }
        >
          {chartLinks.map((item) => (
            <CommandItemWithAuxClick
              key={item.name}
              value={item.name}
              link={createTokenChartLink(
                item.url,
                token.networkId,
                token.address,
                item.networkNameMap,
                token.topPairAddress,
                token.quoteToken
              )}
            >
              <ItemIcon alt={item.name} src={item.icon} /> {item.name}
            </CommandItemWithAuxClick>
          ))}
        </Command.Group>
        <Command.Group
          heading={
            <GroupHeadingWithIcon>
              <FaGem />
              Scan
            </GroupHeadingWithIcon>
          }
        >
          <CommandItemWithAuxClick
            value="etherscan"
            link={createNetworkScanLink(
              token.networkId,
              token.address,
              "token"
            )}
          >
            <ItemIcon
              alt={NetworkScanNames[token.networkId as NetworkId]}
              src={NetworkExplorerIcons[token.networkId as NetworkId]}
            />{" "}
            {NetworkScanNames[token.networkId as NetworkId]}
          </CommandItemWithAuxClick>
          <CommandItemWithAuxClick
            value="honeypotDetector"
            link={`https://detecthoneypot.com/scan?address=${
              token.address
            }&chain=${NetworkShortName[token.networkId as NetworkId]}`}
          >
            <RxIconjarLogo /> Honeypot Detector
          </CommandItemWithAuxClick>
          <CommandItemWithAuxClick
            value="tokenSniffer"
            link={`https://tokensniffer.com/token/${token.address}`}
          >
            <ItemIcon alt="tokenSniffer" src={tokensniffer} /> TokenSniffer
          </CommandItemWithAuxClick>
        </Command.Group>
        <Command.Group
          heading={
            <GroupHeadingWithIcon>
              <BsTools />
              Other
            </GroupHeadingWithIcon>
          }
        >
          <CommandItemWithAuxClick
            value="searchTwitter"
            link={`https://twitter.com/search?q=%24${token.symbol}`}
          >
            <FaTwitter /> Search on Twitter
          </CommandItemWithAuxClick>
          <CommandItemWithAuxClick
            value="searchGoogle"
            link={`https://google.com/search?q=${token.name}`}
          >
            <FaGoogle /> Search on Google
          </CommandItemWithAuxClick>
        </Command.Group>
      </ColumnGroups>
    </TokenPageContainer>
  );
};

export { TokenPage };
