enum TokenFilterNoWindow {
  Fdv = "fdv",
  Liquidity = "liquidity",
  MarketCap = "marketCap",
  PriceUsd = "priceUSD",
  Change = "change",
  BuyCount = "buyCount",
  High = "high",
  Low = "low",
  SellCount = "sellCount",
  TxnCount = "txnCount",
  UniqueBuys = "uniqueBuys",
  UniqueSells = "uniqueSells",
  UniqueTransactions = "uniqueTransactions",
  Volume = "volume",
}

const TokenFilterLabel: Record<TokenFilterNoWindow, string> = {
  [TokenFilterNoWindow.BuyCount]: "Total Buys",
  [TokenFilterNoWindow.Change]: "Price Change (%)",
  [TokenFilterNoWindow.Fdv]: "Fully Diluted Value ($)",
  [TokenFilterNoWindow.High]: "High Price ($)",
  [TokenFilterNoWindow.Liquidity]: "Liquidity ($)",
  [TokenFilterNoWindow.Low]: "Low Price ($)",
  [TokenFilterNoWindow.MarketCap]: "Market Cap",
  [TokenFilterNoWindow.PriceUsd]: "Price ($)",
  [TokenFilterNoWindow.SellCount]: "Total Sells",
  [TokenFilterNoWindow.TxnCount]: "Txn Count",
  [TokenFilterNoWindow.UniqueBuys]: "Unique Buys",
  [TokenFilterNoWindow.UniqueSells]: "Unique Sells",
  [TokenFilterNoWindow.UniqueTransactions]: "Unique Txns",
  [TokenFilterNoWindow.Volume]: "Volume ($)",
};

const TokenFilterLabelShort: Record<TokenFilterNoWindow, string> = {
  [TokenFilterNoWindow.BuyCount]: "Buys",
  [TokenFilterNoWindow.Change]: "Chng (%)",
  [TokenFilterNoWindow.Fdv]: "FDV ($)",
  [TokenFilterNoWindow.High]: "High ($)",
  [TokenFilterNoWindow.Liquidity]: "Liq.",
  [TokenFilterNoWindow.Low]: "Low ($)",
  [TokenFilterNoWindow.MarketCap]: "Mkt Cap ($)",
  [TokenFilterNoWindow.PriceUsd]: "Price ($)",
  [TokenFilterNoWindow.SellCount]: "Sells",
  [TokenFilterNoWindow.TxnCount]: "Txns",
  [TokenFilterNoWindow.UniqueBuys]: "Unq Buys",
  [TokenFilterNoWindow.UniqueSells]: "Unq Sells",
  [TokenFilterNoWindow.UniqueTransactions]: "Unq Txns",
  [TokenFilterNoWindow.Volume]: "Vol ($)",
};

const TokenFilterHasWindow: Record<TokenFilterNoWindow, boolean> = {
  [TokenFilterNoWindow.BuyCount]: true,
  [TokenFilterNoWindow.Change]: true,
  [TokenFilterNoWindow.Fdv]: false,
  [TokenFilterNoWindow.High]: true,
  [TokenFilterNoWindow.Liquidity]: false,
  [TokenFilterNoWindow.Low]: true,
  [TokenFilterNoWindow.MarketCap]: false,
  [TokenFilterNoWindow.PriceUsd]: false,
  [TokenFilterNoWindow.SellCount]: true,
  [TokenFilterNoWindow.TxnCount]: true,
  [TokenFilterNoWindow.UniqueBuys]: true,
  [TokenFilterNoWindow.UniqueSells]: true,
  [TokenFilterNoWindow.UniqueTransactions]: true,
  [TokenFilterNoWindow.Volume]: true,
};

export {
  TokenFilterNoWindow,
  TokenFilterLabel,
  TokenFilterLabelShort,
  TokenFilterHasWindow,
};
