import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import React, { ComponentProps } from "react";
import { BiCaretDown, BiCaretUp } from "react-icons/bi";
import { BsDiamondFill } from "react-icons/bs";
import { GoPrimitiveDot } from "react-icons/go";

import { ellipsis, monospace } from "../../styles/Mixins";
import { getFormattedPercent } from "../../utils/format";
import { withTransientProps } from "../../utils/styles";

const PercentChangeContainer = styled(
  "div",
  withTransientProps
)<{ $percent: number; $accentColor: string }>(
  ({ $percent, $accentColor }) => css`
    color: ${$accentColor};
    filter: saturate(${0.5 + Math.min(Math.abs($percent) * 0.7, 0.7)});
    font-weight: ${Math.abs($percent) > 1 ? "600" : "unset"};
    ${ellipsis}
    ${monospace}
  `
);

const CaretUp = styled(BiCaretUp)`
  vertical-align: sub;
`;

const CaretDown = styled(BiCaretDown)`
  vertical-align: sub;
`;

const CaretNeutral = styled(BsDiamondFill)`
  font-size: 0.5em;
  vertical-align: middle;
  margin: 0 0.5em 0.25em;
`;

const CaretLoading = styled(GoPrimitiveDot)`
  vertical-align: sub;
`;

const PercentChange: React.FC<
  { value?: number | null; onlyIcon?: boolean } & ComponentProps<"div">
> = ({ value, onlyIcon, ...props }) => {
  const theme = useTheme();
  // Convert to show very small values as 0.
  if (!value || Number(value?.toFixed(2)) === 0)
    return (
      <PercentChangeContainer
        translate="no"
        $percent={value || 0}
        $accentColor={theme.colors.disabled}
        {...props}
      >
        {Number(value?.toFixed(2)) === 0 ? (
          <>
            <CaretNeutral />
            {!onlyIcon && "0.00%"}
          </>
        ) : (
          <>
            <CaretLoading />
            {!onlyIcon && "–.––%"}
          </>
        )}
      </PercentChangeContainer>
    );

  return value >= 0 ? (
    <PercentChangeContainer
      translate="no"
      $percent={value}
      $accentColor={theme.colors.success}
      {...props}
    >
      <CaretUp />
      {!onlyIcon && getFormattedPercent(value * 100)}
    </PercentChangeContainer>
  ) : (
    <PercentChangeContainer
      translate="no"
      $percent={value}
      $accentColor={theme.colors.error}
      {...props}
    >
      <CaretDown />
      {!onlyIcon && getFormattedPercent(value * -100)}
    </PercentChangeContainer>
  );
};

export { PercentChange };
