import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";

const initializeApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    uri: process.env.REACT_APP_GRAPH_URL,
    cache: new InMemoryCache({
      typePolicies: {
        NftAsset: {
          keyFields: ["address", "tokenId"],
        },
      },
    }),
    headers: {
      "x-api-key": process.env.REACT_APP_GRAPH_API_KEY as string,
      "Content-Type": "application/json",
    },
  });
};

export { initializeApolloClient };
