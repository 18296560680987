const getItem = <T>(
  key: string,
  storage: Storage = localStorage
): T | undefined => {
  try {
    const item = storage.getItem(key);
    return item ? JSON.parse(item) : undefined;
  } catch (e) {
    console.error(e);
  }
};

const setItem = <T>(
  key: string,
  value?: T | null,
  storage: Storage = localStorage
): boolean => {
  try {
    storage.setItem(key, JSON.stringify(value));
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const removeItem = (key: string, storage: Storage = localStorage): boolean => {
  try {
    storage.removeItem(key);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const restoreState = <T>({
  key,
  defaults,
  storage = localStorage,
}: {
  key: string;
  defaults: T;
  storage?: Storage;
}): T => {
  try {
    const storedSettings = getItem<T>(key, storage) || {};

    return { ...defaults, ...storedSettings };
  } catch {
    return defaults;
  }
};

export { restoreState, getItem, setItem, removeItem };
