import { css } from "@emotion/react";

const borderRadius = css`
  border-radius: 8px;
`;

const ellipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const iconSizeSm = css`
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
`;

const iconSizeMd = css`
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px;
`;

const iconSizeLg = css`
  height: 32px;
  width: 32px;
  min-height: 32px;
  min-width: 32px;
`;

const monospace = css`
  font-family: "Fira Mono", "Arial Unicode MS", "Arial Unicode", Arial,
    monospace;
  font-weight: 500;
`;

export {
  borderRadius,
  ellipsis,
  iconSizeSm,
  iconSizeMd,
  iconSizeLg,
  monospace,
};
