import React from "react";

import {
  NetworkColors,
  NetworkIcons,
  NetworkNames,
} from "../../constants/Network";
import { NetworkId } from "../../types/Network";
import { AvatarFallback, AvatarImage, AvatarRoot } from "../dataDisplay/Avatar";

export interface NetworkIconProps {
  networkId: NetworkId;
  sizeInPx?: number;
  stacked?: boolean;
}
const NetworkIcon: React.FC<NetworkIconProps> = ({
  networkId,
  sizeInPx = 22,
  stacked,
}) => (
  <AvatarRoot $sizeInPx={sizeInPx} $stacked={stacked}>
    <AvatarImage
      src={NetworkIcons[networkId] || ""}
      alt={NetworkNames[networkId] || ""}
    />
    <AvatarFallback $accentColor={NetworkColors[networkId] || ""}>
      {NetworkNames[networkId][0]}
    </AvatarFallback>
  </AvatarRoot>
);

export { NetworkIcon };
