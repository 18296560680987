import { Command } from "cmdk";
import React from "react";
import toast from "react-hot-toast";
import { BiReset } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";
import { IoMdCheckmark } from "react-icons/io";

import {
  TokenFilterHasWindow,
  TokenFilterLabel,
  TokenFilterLabelShort,
  TokenFilterNoWindow,
} from "../../../constants/TokenFilters";
import {
  FiltersActionType,
  FiltersContext,
} from "../../../context/FiltersContext";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { breakpoints, lessThan } from "../../../styles/Layout";
import { Duration } from "../../../types/Duration";
import { FlexCenteredGap } from "../../basic/Flex";
import { PrimaryButton } from "../../inputs/Button";
import { KeyboardShortcut } from "../../inputs/KeyboardShortcut";
import { GroupHeadingWithIcon } from "../Search";
import {
  MinMaxFilter,
  NetworkFilter,
  WindowMinMaxFilter,
} from "./FilterInputs";
import { RankingFilter } from "./TokenRankingFilter";

export interface TokenFiltersPageProps {
  handleCloseFilters: () => void;
}

const TokenFiltersPage: React.FC<TokenFiltersPageProps> = ({
  handleCloseFilters,
}) => {
  const lessThanMd = useMediaQuery(lessThan(breakpoints.md));

  const {
    state: filtersState,
    dispatch: filtersDispatch,
    filtersActive,
  } = React.useContext(FiltersContext);

  const handleApplyFilters = React.useCallback(() => {
    toast.success("Applied filters");
    handleCloseFilters();
  }, [handleCloseFilters]);

  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        e.preventDefault();

        handleCloseFilters();
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [handleCloseFilters]);

  const handleWindowChange = React.useCallback(
    (attribute: TokenFilterNoWindow, newWindow: Duration) => {
      filtersDispatch({
        type: FiltersActionType.UpdateFilterWindow,
        attribute: attribute,
        window: newWindow,
      });
    },
    [filtersDispatch]
  );

  const handleMinChange = React.useCallback(
    (attribute: TokenFilterNoWindow, newMin: string) => {
      filtersDispatch({
        type: FiltersActionType.UpdateFilterMin,
        attribute: attribute,
        min: newMin,
      });
    },
    [filtersDispatch]
  );

  const handleMaxChange = React.useCallback(
    (attribute: TokenFilterNoWindow, newMax: string) => {
      filtersDispatch({
        type: FiltersActionType.UpdateFilterMax,
        attribute: attribute,
        max: newMax,
      });
    },
    [filtersDispatch]
  );

  const resetTokenFilters = React.useCallback(() => {
    filtersDispatch({
      type: FiltersActionType.ResetAllTokenFilters,
    });
    toast.success("Token filters reset");
    handleCloseFilters();
  }, [filtersDispatch, handleCloseFilters]);

  return (
    <Command.Group
      heading={
        <GroupHeadingWithIcon>
          <FiSettings />
          Token Filters
          <PrimaryButton
            onClick={handleApplyFilters}
            style={{ marginLeft: "auto" }}
          >
            <IoMdCheckmark style={{ height: 16 }} />
            Apply filters
            <KeyboardShortcut shortcut="↵" />
          </PrimaryButton>
        </GroupHeadingWithIcon>
      }
    >
      <>
        <Command.Item value="ranking">
          <FlexCenteredGap
            style={{
              flex: "1",
              justifyContent: "space-between",
            }}
          >
            <div>{lessThanMd ? "Sort" : "Sort by"}</div>
            <RankingFilter />
          </FlexCenteredGap>
        </Command.Item>
        <Command.Item value="networks">
          <FlexCenteredGap
            style={{
              flex: "1",
              justifyContent: "space-between",
            }}
          >
            <div>{lessThanMd ? "Ntwks" : "Networks"}</div>
            <NetworkFilter />
          </FlexCenteredGap>
        </Command.Item>
        {Object.values(TokenFilterNoWindow).map(
          (filter: TokenFilterNoWindow) => {
            const label = lessThanMd
              ? TokenFilterLabelShort[filter]
              : TokenFilterLabel[filter];

            const filterValues = filtersState[filter];
            return (
              <Command.Item key={filter} value={label}>
                <FlexCenteredGap
                  style={{
                    flex: "1",
                    justifyContent: "space-between",
                  }}
                >
                  <div>{label}</div>
                  <div>
                    {TokenFilterHasWindow[filter] ? (
                      <WindowMinMaxFilter
                        min={filterValues.min}
                        max={filterValues.max}
                        window={filterValues.window as Duration}
                        handleWindowChange={(newWindow: Duration) =>
                          handleWindowChange(filter, newWindow)
                        }
                        handleMinChange={(newMin: string) =>
                          handleMinChange(filter, newMin)
                        }
                        handleMaxChange={(newMax: string) =>
                          handleMaxChange(filter, newMax)
                        }
                      />
                    ) : (
                      <MinMaxFilter
                        min={filterValues.min}
                        max={filterValues.max}
                        handleMinChange={(newMin: string) =>
                          handleMinChange(filter, newMin)
                        }
                        handleMaxChange={(newMax: string) =>
                          handleMaxChange(filter, newMax)
                        }
                      />
                    )}
                  </div>
                </FlexCenteredGap>
              </Command.Item>
            );
          }
        )}
        <Command.Item
          value="reset-token-filter"
          onSelect={resetTokenFilters}
          disabled={!filtersActive}
        >
          <BiReset /> Reset token filters to default
        </Command.Item>
      </>
    </Command.Group>
  );
};

export { TokenFiltersPage };
