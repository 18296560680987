import styled from "@emotion/styled";
import { Command } from "cmdk";
import React from "react";

import { NftSearchResponseCollection } from "../../../generated/graphql";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { breakpoints, greaterThan } from "../../../styles/Layout";
import { ellipsis } from "../../../styles/Mixins";
import { getAbbreviatedAddress } from "../../../utils/format";
import { FormattedNumber } from "../../dataDisplay/FormattedNumber";
import { PercentChange } from "../../dataDisplay/PercentChange";
import { NetworkIcon } from "../../networks/NetworkIcon";
import { NftAssetImage } from "../../nft/NftAssetImage";

export interface NftSearchItemProps {
  collection: NftSearchResponseCollection;
  handleSelect: (collection: NftSearchResponseCollection) => void;
  valuePrefix?: string;
}

const Container = styled("div")`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const AssetAndName = styled("div")`
  display: flex;
  min-width: 0;
  gap: 12px;
  flex: 5;
`;

const AssetContainer = styled("div")`
  align-items: center;
  border-radius: 50%;
  display: flex;
  flex: 0 0 auto;
`;

const Identifiers = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  padding-right: 8px;
`;

const Symbol = styled("p")`
  font-weight: 700;
  line-height: 1.3;
  ${ellipsis}
`;

const Name = styled("p")`
  opacity: 0.7;
  font-size: 0.8em;
  line-height: 1.3;
  ${ellipsis}
`;

const NetworkIconContainer = styled("div")`
  display: flex;
  flex: 1.5;
`;

const StackedValueContainer = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 3;
  gap: 2px;
`;

const StackedValue = styled("div")`
  display: flex;
  gap: 4px;
  font-size: 0.9em;
`;

const StackedValueLabel = styled("div")`
  opacity: 0.6;
  width: 40px;
`;

const RecentNftCollection: React.FC<{
  collection: NftSearchResponseCollection;
  handleSelect: (collection: NftSearchResponseCollection) => void;
}> = ({ collection, handleSelect }) => (
  <NftSearchItem
    key={`recent-${collection?.id}`}
    collection={collection}
    handleSelect={handleSelect}
    valuePrefix="r-"
  />
);

const NftSearchItem: React.FC<NftSearchItemProps> = ({
  collection,
  handleSelect,
  valuePrefix = "",
}) => {
  const greaterThanMd = useMediaQuery(greaterThan(breakpoints.md));
  const { address, average, floor, name, symbol, volume, volumeChange } =
    collection;

  return (
    <Command.Item
      value={`${valuePrefix}${collection.id}`}
      onSelect={() => handleSelect(collection)}
    >
      <Container>
        <AssetAndName>
          <AssetContainer>
            <NftAssetImage src={collection.imageUrl || ""} sizeInPx={35} />
          </AssetContainer>
          <Identifiers>
            <Symbol>{symbol || getAbbreviatedAddress(address)}</Symbol>
            <Name>{name || getAbbreviatedAddress(address)}</Name>
          </Identifiers>
        </AssetAndName>
        {greaterThanMd && collection.networkId ? (
          <NetworkIconContainer>
            <NetworkIcon networkId={collection.networkId} />
          </NetworkIconContainer>
        ) : null}
        {greaterThanMd && (
          <StackedValueContainer>
            <StackedValue>
              <StackedValueLabel>Avg:</StackedValueLabel>
              <FormattedNumber
                style={{ fontWeight: "bold" }}
                dollars
                value={average}
              />
            </StackedValue>
            <StackedValue>
              <StackedValueLabel>Floor:</StackedValueLabel>
              <FormattedNumber
                style={{ fontWeight: "bold" }}
                dollars
                value={floor}
              />
            </StackedValue>
          </StackedValueContainer>
        )}
        <StackedValueContainer>
          <StackedValue>
            <StackedValueLabel>Vol:</StackedValueLabel>
            <FormattedNumber
              style={{ fontWeight: "bold" }}
              dollars
              abbreviate
              value={volume}
            />
          </StackedValue>
          <StackedValue>
            <StackedValueLabel>Chg:</StackedValueLabel>
            <PercentChange value={volumeChange} />
          </StackedValue>
        </StackedValueContainer>
      </Container>
    </Command.Item>
  );
};

export { NftSearchItem, RecentNftCollection };
