import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Command } from "cmdk";
import React, { useMemo } from "react";

import { Exchange, TokenFilterResult } from "../../../generated/graphql";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { breakpoints, greaterThan } from "../../../styles/Layout";
import { ellipsis } from "../../../styles/Mixins";
import { Duration } from "../../../types/Duration";
import { withTransientProps } from "../../../utils/styles";
import { FormattedNumber } from "../../dataDisplay/FormattedNumber";
import { PercentChange } from "../../dataDisplay/PercentChange";
import { TokenIcon } from "../../dataDisplay/TokenIcon";
import { ExchangeIconStack } from "../../exchanges/ExchangeIconStack";
import { NetworkIcon } from "../../networks/NetworkIcon";
import { TokenWithTopPair } from "../Search";

export interface TokenSearchItemProps {
  token: TokenWithTopPair;
  exchanges?: Exchange[];
  liquidity?: string | null;
  handleSelect: (token: TokenWithTopPair) => void;
  volume?: string | null;
  marketCap?: string | null;
  transactionCount?: number | null;
  price?: string | null;
  priceChange?: string | null;
  valuePrefix?: string;
  window: Duration;
}

const Container = styled("div")`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const IconName = styled("div")`
  display: flex;
  min-width: 0;
  gap: 12px;
  flex: 5;
`;

const IconContainer = styled("div")`
  align-items: center;
  border-radius: 50%;
  display: flex;
  flex: 0 0 auto;
`;

const Identifiers = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  padding-right: 8px;
`;

const Symbol = styled("p")`
  font-weight: 700;
  line-height: 1.3;
  ${ellipsis}
`;

const Name = styled("p")`
  opacity: 0.7;
  font-size: 0.8em;
  line-height: 1.3;
  ${ellipsis}
`;

const NetworkIconContainer = styled("div")`
  display: flex;
  flex: 1.5;
`;

const ExchangeIconStackContainer = styled("div")`
  display: flex;
  flex: 3;
`;

const PriceAndChange = styled("div")`
  flex: 3;
`;

const StackedStats = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 4;
  gap: 2px;
`;

const Stat = styled(
  "div",
  withTransientProps
)<{ $isLow?: boolean }>(
  ({ $isLow }) => css`
    font-weight: ${$isLow ? "initial" : "bold"};
    opacity: ${$isLow ? "0.6" : "1"};
    display: flex;
    gap: 4px;
    font-size: 0.9em;
  `
);

const StatLabel = styled("div")`
  opacity: 0.6;
  width: 35px;
`;

const TokenSearchItemResult: React.FC<{
  result: TokenFilterResult;
  handleSelect: (token: TokenWithTopPair) => void;
  window: Duration;
}> = ({ result, handleSelect, window }) => {
  const volume = useMemo(() => {
    switch (window) {
      case Duration.Hour1:
        return result.volume1;
      case Duration.Hour4:
        return result.volume4;
      case Duration.Hour12:
        return result.volume12;
      case Duration.Day1:
        return result.volume24;
    }
  }, [result, window]);

  const txnCount = useMemo(() => {
    switch (window) {
      case Duration.Hour1:
        return result.txnCount1;
      case Duration.Hour4:
        return result.txnCount4;
      case Duration.Hour12:
        return result.txnCount12;
      case Duration.Day1:
        return result.txnCount24;
    }
  }, [result, window]);

  const priceChange = useMemo(() => {
    switch (window) {
      case Duration.Hour1:
        return result.change1;
      case Duration.Hour4:
        return result.change4;
      case Duration.Hour12:
        return result.change12;
      case Duration.Day1:
        return result.change24;
    }
  }, [result, window]);

  return (
    <TokenSearchItem
      key={result.token?.id}
      token={result.token as TokenWithTopPair}
      handleSelect={handleSelect}
      liquidity={result.liquidity}
      marketCap={result.marketCap}
      transactionCount={txnCount}
      exchanges={result.exchanges as Exchange[]}
      price={result.priceUSD}
      priceChange={priceChange}
      volume={volume}
      window={window}
    />
  );
};

const RecentToken: React.FC<{
  token: TokenWithTopPair;
  handleSelect: (token: TokenWithTopPair) => void;
  window: Duration;
}> = ({ token, handleSelect, window }) => (
  <TokenSearchItem
    key={`recent-${token?.id}`}
    token={token as TokenWithTopPair}
    handleSelect={handleSelect}
    valuePrefix="r-"
    window={window}
  />
);

const TokenSearchItem: React.FC<TokenSearchItemProps> = ({
  exchanges,
  liquidity,
  token,
  handleSelect,
  volume,
  marketCap,
  transactionCount,
  price,
  priceChange,
  valuePrefix = "",
}) => {
  const greaterThanMd = useMediaQuery(greaterThan(breakpoints.md));

  const { address, name, networkId, symbol } = token;

  return (
    <Command.Item
      value={`${valuePrefix}${token.id}`}
      onSelect={() => handleSelect(token)}
    >
      <Container>
        <IconName>
          <IconContainer>
            <TokenIcon
              address={address}
              networkId={networkId}
              name={name || ""}
              sizeInPx={30}
            />
          </IconContainer>
          <Identifiers>
            <Symbol>{symbol}</Symbol>
            <Name>{name}</Name>
          </Identifiers>
        </IconName>
        {greaterThanMd && networkId ? (
          <NetworkIconContainer>
            <NetworkIcon networkId={networkId} />
          </NetworkIconContainer>
        ) : null}
        {greaterThanMd ? (
          <ExchangeIconStackContainer>
            {exchanges && exchanges?.length > 0 && (
              <ExchangeIconStack exchanges={exchanges} />
            )}
          </ExchangeIconStackContainer>
        ) : null}
        <PriceAndChange>
          <FormattedNumber
            dollars
            value={price}
            style={{ fontWeight: "700" }}
          />
          <PercentChange
            value={Number(priceChange)}
            style={{ fontSize: "0.9em" }}
          />
        </PriceAndChange>
        {greaterThanMd && (
          <>
            <StackedStats>
              <Stat $isLow={Number(volume) < 10000}>
                <StatLabel>Vol:</StatLabel>
                <FormattedNumber
                  dollars
                  abbreviate
                  value={volume}
                  zerosTilSubscript={4}
                />
              </Stat>
              <Stat $isLow={Number(liquidity) < 10000}>
                <StatLabel>Liq:</StatLabel>
                <FormattedNumber
                  dollars
                  abbreviate
                  value={liquidity}
                  zerosTilSubscript={4}
                  sigFigs={4}
                />
              </Stat>
            </StackedStats>
            <StackedStats>
              <Stat $isLow={Number(transactionCount) < 100}>
                <StatLabel>Txs:</StatLabel>
                <FormattedNumber
                  abbreviate
                  asInteger
                  value={transactionCount}
                  zerosTilSubscript={4}
                />
              </Stat>

              <Stat $isLow={Number(marketCap) < 100000}>
                <StatLabel>Mkt:</StatLabel>
                <FormattedNumber
                  dollars
                  abbreviate
                  value={marketCap}
                  zerosTilSubscript={4}
                  sigFigs={4}
                />
              </Stat>
            </StackedStats>
          </>
        )}
      </Container>
    </Command.Item>
  );
};

export { TokenSearchItem, TokenSearchItemResult, RecentToken };
