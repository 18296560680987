import { isEqual } from "lodash";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from "react";

import { EnabledNetworks } from "../constants/Network";
import { TokenFilterNoWindow } from "../constants/TokenFilters";
import { RankingDirection } from "../generated/graphql";
import { Duration } from "../types/Duration";
import { NetworkId } from "../types/Network";
import { restoreState, setItem } from "../utils/storage";

enum FiltersActionType {
  HideNetwork = "HIDE_NETWORK",
  HideAllButOneNetwork = "HIDE_ALL_BUT_ONE_NETWORK",
  ShowNetwork = "SHOW_NETWORK",
  ShowAllNetworks = "SHOW_ALL_NETWORKS",
  UpdateMainSearchWindow = "UPDATE_WINDOW",
  UpdateTokenRankingBy = "UPDATE_TOKEN_RANKING_BY",
  UpdateTokenRankingWindow = "UPDATE_TOKEN_RANKING_WINDOW",
  UpdateTokenRankingDirection = "UPDATE_TOKEN_RANKING_DIRECTION",
  UpdateCategory = "UPDATE_CATEGORY",
  UpdateFilter = "UPDATE_FILTER",
  UpdateFilterMin = "UPDATE_FILTER_MIN",
  UpdateFilterMax = "UPDATE_FILTER_MAX",
  UpdateFilterWindow = "UPDATE_FILTER_WINDOW",
  ToggleFilterNetworksModal = "TOGGLE_FILTER_NETWORKS_MODAL",
  ResetAllTokenFilters = "RESET_ALL_TOKEN_FILTERS",
}

export enum SearchCategory {
  Nft = "NFT",
  Token = "TOKEN",
}

type FilterAction =
  | { type: FiltersActionType.HideNetwork; network: NetworkId }
  | { type: FiltersActionType.HideAllButOneNetwork }
  | { type: FiltersActionType.ShowNetwork; network: NetworkId }
  | { type: FiltersActionType.ShowAllNetworks }
  | { type: FiltersActionType.UpdateMainSearchWindow; window: Duration }
  | {
      type: FiltersActionType.UpdateTokenRankingBy;
      attribute: TokenFilterNoWindow;
    }
  | {
      type: FiltersActionType.UpdateTokenRankingWindow;
      window: Duration;
    }
  | {
      type: FiltersActionType.UpdateTokenRankingDirection;
      direction: RankingDirection;
    }
  | { type: FiltersActionType.UpdateCategory; category: SearchCategory }
  | { type: FiltersActionType.ToggleFilterNetworksModal; open: boolean }
  | {
      type: FiltersActionType.UpdateFilterMin;
      attribute: TokenFilterNoWindow;
      min: string;
    }
  | {
      type: FiltersActionType.UpdateFilterMax;
      attribute: TokenFilterNoWindow;
      max: string;
    }
  | {
      type: FiltersActionType.UpdateFilterWindow;
      attribute: TokenFilterNoWindow;
      window: Duration;
    }
  | {
      type: FiltersActionType.ResetAllTokenFilters;
    };

export interface MinMaxWindow {
  min: string;
  max: string;
  window: Duration;
}

interface FiltersState {
  activeCategory: SearchCategory;
  mainSearchWindow: Duration;
  hiddenNetworks: NetworkId[]; // TODO: should this differ for tokens / NFTs?
  visibleNetworks: NetworkId[];
  visibleNetworksModalOpen: boolean;

  // Token tokenRanking
  tokenRankingBy: TokenFilterNoWindow;
  tokenRankingWindow: Duration;
  tokenRankingDirection: RankingDirection;

  // Token filters
  fdv: MinMaxWindow;
  liquidity: MinMaxWindow;
  marketCap: MinMaxWindow;
  priceUSD: MinMaxWindow;
  change: MinMaxWindow;
  high: MinMaxWindow;
  low: MinMaxWindow;
  buyCount: MinMaxWindow;
  sellCount: MinMaxWindow;
  txnCount: MinMaxWindow;
  uniqueBuys: MinMaxWindow;
  uniqueSells: MinMaxWindow;
  uniqueTransactions: MinMaxWindow;
  volume: MinMaxWindow;
}

type FiltersContext = {
  state: FiltersState;
  dispatch: React.Dispatch<FilterAction>;
  isHidden: (networkId: NetworkId) => boolean;
  filtersActive: boolean;
  numFiltersActive: number;
};

const FILTERS_STORAGE_KEY = "filters";

const DEFAULT_NUMBER_FILTER = {
  min: "",
  max: "",
  window: Duration.Day1,
};

const DEFAULT_STATE: FiltersState = {
  activeCategory: SearchCategory.Token,
  tokenRankingBy: TokenFilterNoWindow.Volume,
  tokenRankingWindow: Duration.Day1,
  tokenRankingDirection: RankingDirection.Desc,
  hiddenNetworks: [], // Notion of *hidden* networks so new ones can be added
  mainSearchWindow: Duration.Day1,
  visibleNetworks: [],
  visibleNetworksModalOpen: false,

  // Token filters
  fdv: DEFAULT_NUMBER_FILTER,
  liquidity: DEFAULT_NUMBER_FILTER,
  marketCap: DEFAULT_NUMBER_FILTER,
  priceUSD: DEFAULT_NUMBER_FILTER,
  change: DEFAULT_NUMBER_FILTER,
  high: DEFAULT_NUMBER_FILTER,
  low: DEFAULT_NUMBER_FILTER,
  buyCount: DEFAULT_NUMBER_FILTER,
  sellCount: DEFAULT_NUMBER_FILTER,
  txnCount: DEFAULT_NUMBER_FILTER,
  uniqueBuys: DEFAULT_NUMBER_FILTER,
  uniqueSells: DEFAULT_NUMBER_FILTER,
  uniqueTransactions: DEFAULT_NUMBER_FILTER,
  volume: DEFAULT_NUMBER_FILTER,
};

const reducer: React.Reducer<FiltersState, FilterAction> = (state, action) => {
  let newHiddenNetworks: NetworkId[];
  switch (action.type) {
    case FiltersActionType.HideNetwork:
      if (state.hiddenNetworks.includes(action.network)) return state;
      newHiddenNetworks = state.hiddenNetworks.concat(action.network);
      return {
        ...state,
        hiddenNetworks: newHiddenNetworks,
        visibleNetworks: EnabledNetworks.filter(
          (nw) => !newHiddenNetworks.includes(nw)
        ),
      };
    case FiltersActionType.HideAllButOneNetwork:
      // Don't allow hiding of all networks, so keep the top one
      return {
        ...state,
        hiddenNetworks: EnabledNetworks.slice(1),
        visibleNetworks: [EnabledNetworks[0]],
      };
    case FiltersActionType.ShowNetwork:
      if (!state.hiddenNetworks.includes(action.network)) return state;
      newHiddenNetworks = state.hiddenNetworks.filter(
        (nw) => nw !== action.network
      );
      return {
        ...state,
        hiddenNetworks: newHiddenNetworks,
        visibleNetworks: EnabledNetworks.filter(
          (nw) => !newHiddenNetworks.includes(nw)
        ),
      };
    case FiltersActionType.ShowAllNetworks:
      return {
        ...state,
        hiddenNetworks: [],
        visibleNetworks: DEFAULT_STATE.visibleNetworks,
      };
    case FiltersActionType.UpdateTokenRankingDirection:
      return {
        ...state,
        tokenRankingDirection: action.direction,
      };
    case FiltersActionType.UpdateTokenRankingBy:
      return {
        ...state,
        tokenRankingBy: action.attribute,
      };
    case FiltersActionType.UpdateTokenRankingWindow:
      return {
        ...state,
        tokenRankingWindow: action.window,
      };
    case FiltersActionType.UpdateCategory:
      return {
        ...state,
        activeCategory: action.category,
      };
    case FiltersActionType.ToggleFilterNetworksModal:
      return {
        ...state,
        visibleNetworksModalOpen: action.open,
      };
    case FiltersActionType.UpdateFilterMin:
      return {
        ...state,
        [action.attribute]: {
          ...state[action.attribute],
          min: action.min,
        },
      };
    case FiltersActionType.UpdateFilterMax:
      return {
        ...state,
        [action.attribute]: {
          ...state[action.attribute],
          max: action.max,
        },
      };
    case FiltersActionType.UpdateFilterWindow:
      return {
        ...state,
        [action.attribute]: {
          ...state[action.attribute],
          window: action.window,
        },
      };
    case FiltersActionType.UpdateMainSearchWindow:
      return {
        ...state,
        mainSearchWindow: action.window,
      };
    case FiltersActionType.ResetAllTokenFilters:
      const resetFilters = Object.values(TokenFilterNoWindow).map((filter) => {
        state[filter] = DEFAULT_NUMBER_FILTER;
      });
      return {
        ...state,
        ...resetFilters,
        tokenRankingBy: DEFAULT_STATE.tokenRankingBy,
        tokenRankingWindow: DEFAULT_STATE.tokenRankingWindow,
        tokenRankingDirection: DEFAULT_STATE.tokenRankingDirection,
        hiddenNetworks: [],
        visibleNetworks: DEFAULT_STATE.visibleNetworks,
      };

    default:
      return DEFAULT_STATE;
  }
};

const FiltersContext = createContext<FiltersContext>({} as FiltersContext);

const FiltersContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(
    reducer,
    restoreState<FiltersState>({
      key: FILTERS_STORAGE_KEY,
      defaults: DEFAULT_STATE,
    })
  );

  const isHidden = useCallback(
    (networkId: NetworkId) =>
      networkId && state.hiddenNetworks.includes(networkId),
    [state.hiddenNetworks]
  );

  if (!state.visibleNetworks.length) {
    state.visibleNetworks = EnabledNetworks.filter(
      (nw) => !state.hiddenNetworks.includes(nw)
    );
  }

  const isMinMaxWindowActive = useCallback((filter: MinMaxWindow) => {
    return !isEqual([filter.min, filter.max], ["", ""]);
  }, []);

  const numFiltersActive = useMemo(
    () =>
      state.activeCategory === SearchCategory.Token
        ? [
            !isEqual(state.visibleNetworks, EnabledNetworks),
            // Token tokenRanking (counts as 1)
            ...[
              !isEqual(state.tokenRankingBy, DEFAULT_STATE.tokenRankingBy) ||
                !isEqual(
                  state.tokenRankingWindow,
                  DEFAULT_STATE.tokenRankingWindow
                ) ||
                !isEqual(
                  state.tokenRankingDirection,
                  DEFAULT_STATE.tokenRankingDirection
                ),
            ],
            // Token filters
            ...[
              state.fdv,
              state.liquidity,
              state.marketCap,
              state.priceUSD,
              state.change,
              state.high,
              state.low,
              state.buyCount,
              state.sellCount,
              state.txnCount,
              state.uniqueBuys,
              state.uniqueSells,
              state.uniqueTransactions,
              state.volume,
            ]
              .map((filter) => isMinMaxWindowActive(filter))
              .filter(Boolean),
          ].filter(Boolean).length
        : 0,
    [isMinMaxWindowActive, state]
  );
  const filtersActive = useMemo(() => numFiltersActive > 0, [numFiltersActive]);

  const filtersContext = {
    state,
    dispatch,
    isHidden,
    filtersActive,
    numFiltersActive,
  };

  useEffect(() => {
    setItem(FILTERS_STORAGE_KEY, state);
  }, [state]);

  return (
    <FiltersContext.Provider value={filtersContext}>
      {children}
    </FiltersContext.Provider>
  );
};

export { FiltersContext, FiltersContextProvider, FiltersActionType };
