import { NetworkExplorerUrls } from "../constants/Network";
import { NetworkId } from "../types/Network";

const createNetworkScanLink = (
  networkId: NetworkId,
  input: string,
  type: "tx" | "address" | "token"
): string => {
  return NetworkExplorerUrls[networkId]
    .replace("${input}", input)
    .replace("${type}", type)
    .replace("${typeSubTokenForAddress}", type === "token" ? "address" : type);
};

export { createNetworkScanLink };
