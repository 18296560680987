import { css } from "@emotion/react/macro";
import styled from "@emotion/styled";
import React from "react";

export interface KeyboardShortcutProps {
  shortcut: string;
}

const KeyGroup = styled("div")(
  ({ theme }) => css`
    display: flex;
    gap: ${theme.spacing(0.25)};
  `
);

const KeyItem = styled("div")(
  ({ theme }) => css`
    background-color: ${theme.colors.gray.gray5};
    color: ${theme.colors.gray.gray11};
    height: 20px;
    width: 20px;
    border-radius: 4px;
    padding: 0 4px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-of-type {
      margin-left: 8px;
    }
  `
);

const KeyboardShortcut: React.FC<KeyboardShortcutProps> = ({ shortcut }) => (
  <KeyGroup>
    {shortcut.split(" ").map((key) => {
      return <KeyItem key={key}>{key}</KeyItem>;
    })}
  </KeyGroup>
);

export { KeyboardShortcut };
