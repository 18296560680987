import styled from "@emotion/styled";
import React from "react";

import { Exchange } from "../../generated/graphql";
import { ExchangeIcon } from "./ExchangeIcon";

export interface ExchangeIconStackProps {
  exchanges: Exchange[];
}

const ExchangeIconStackContainer = styled("div")`
  display: flex;
  align-items: center;
`;

const ExchangeIconStack: React.FC<ExchangeIconStackProps> = ({ exchanges }) => {
  const names = exchanges.map((ex) => ex.name);

  // Merge different versions of the same exchange into one icon
  const mergedExchanges = exchanges.filter(
    ({ name }, i) => !names.includes(name, i + 1)
  );

  return (
    <ExchangeIconStackContainer>
      {mergedExchanges.map((exchange) => (
        <ExchangeIcon key={exchange.id} exchange={exchange} stacked />
      ))}
    </ExchangeIconStackContainer>
  );
};

export { ExchangeIconStack };
