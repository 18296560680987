import arbiscan from "../assets/icons/explorers/arbiscan.png";
import auroraExplorer from "../assets/icons/explorers/auroraExplorer.png";
import astarscan from "../assets/icons/explorers/blockscout.png";
import cantoScout from "../assets/icons/explorers/blockscout.png";
import dogechainScout from "../assets/icons/explorers/blockscout.png";
import echelonScout from "../assets/icons/explorers/blockscout.png";
import energiScout from "../assets/icons/explorers/blockscout.png";
import milkomedaScout from "../assets/icons/explorers/blockscout.png";
import novaScout from "../assets/icons/explorers/blockscout.png";
import syscoinScout from "../assets/icons/explorers/blockscout.png";
import bobaExplorer from "../assets/icons/explorers/bobaExplorer.png";
import bscscan from "../assets/icons/explorers/bscscan.png";
import callistoExplorer from "../assets/icons/explorers/callistoExplorer.png";
import celoExplorer from "../assets/icons/explorers/celoExplorer.png";
import confluxscan from "../assets/icons/explorers/confluxscan.png";
import cronosExplorer from "../assets/icons/explorers/cronosExplorer.png";
import avalanchedfkExplorer from "../assets/icons/explorers/dfkExplorer.png";
import elastosExplorer from "../assets/icons/explorers/elastosExplorer.png";
import energyExplorer from "../assets/icons/explorers/energyExplorer.png";
import etherscan from "../assets/icons/explorers/etherscan.png";
import evmosscan from "../assets/icons/explorers/evmosscan.png";
import ftmscan from "../assets/icons/explorers/ftmscan.png";
import fuseExplorer from "../assets/icons/explorers/fuseExplorer.png";
import harmonyExplorer from "../assets/icons/explorers/harmonyExplorer.png";
import hecoExplorer from "../assets/icons/explorers/hecoInfo.png";
import hscExplorer from "../assets/icons/explorers/hscExplorer.png";
import iotexscout from "../assets/icons/explorers/iotexscout.png";
import kardiaExplorer from "../assets/icons/explorers/kardiaExplorer.png";
import kccExplorer from "../assets/icons/explorers/kccExplorer.png";
import klaytnScope from "../assets/icons/explorers/klaytnscope.png";
import meterscan from "../assets/icons/explorers/meterscan.png";
import metisExplorer from "../assets/icons/explorers/metisExplorer.png";
import moonbeamExplorer from "../assets/icons/explorers/moonbeamExplorer.png";
import moonriverExplorer from "../assets/icons/explorers/moonriverExplorer.png";
import oasisExplorer from "../assets/icons/explorers/oasisExplorer.png";
import oklink from "../assets/icons/explorers/oklink.png";
import optimisticExplorer from "../assets/icons/explorers/optimisticExplorer.png";
import polisExplorer from "../assets/icons/explorers/polisExplorer.png";
import polygonscan from "../assets/icons/explorers/polygonscan.png";
import smartscan from "../assets/icons/explorers/smartscan.png";
import snowtrace from "../assets/icons/explorers/snowtrace.png";
import solscan from "../assets/icons/explorers/solscan.png";
import shidenscan from "../assets/icons/explorers/subscan.png";
import teloscan from "../assets/icons/explorers/teloscan.png";
import velasExplorer from "../assets/icons/explorers/velasExplorer.png";
import wanscan from "../assets/icons/explorers/wanscan.png";
import xdaiExplorer from "../assets/icons/explorers/xdaiExplorer.png";
import zyxscan from "../assets/icons/explorers/zyxscan.png";
import arbitrum from "../assets/icons/networks/arbitrum.png";
import astar from "../assets/icons/networks/astar.png";
import aurora from "../assets/icons/networks/aurora.png";
import avalanche from "../assets/icons/networks/avalanche.png";
import avalanchedfk from "../assets/icons/networks/avalanchedfk.png";
import binance from "../assets/icons/networks/binance.png";
import boba from "../assets/icons/networks/boba.png";
import callisto from "../assets/icons/networks/callisto.png";
import canto from "../assets/icons/networks/canto.png";
import celo from "../assets/icons/networks/celo.png";
import conflux from "../assets/icons/networks/conflux.png";
import cronos from "../assets/icons/networks/cronos.png";
import dogechain from "../assets/icons/networks/dogechain.png";
import echelon from "../assets/icons/networks/echelon.png";
import elastos from "../assets/icons/networks/elastos.png";
import energi from "../assets/icons/networks/energi.png";
import energyweb from "../assets/icons/networks/energyweb.png";
import ethereum from "../assets/icons/networks/ethereum.png";
import evmos from "../assets/icons/networks/evmos.png";
import fantom from "../assets/icons/networks/fantom.png";
import fuse from "../assets/icons/networks/fuse.png";
import harmony from "../assets/icons/networks/harmony.png";
import heco from "../assets/icons/networks/heco.png";
import hsc from "../assets/icons/networks/hsc.png";
import iotex from "../assets/icons/networks/iotex.png";
import kardia from "../assets/icons/networks/kardia.png";
import kcc from "../assets/icons/networks/kcc.png";
import klaytn from "../assets/icons/networks/klaytn.png";
import meter from "../assets/icons/networks/meter.png";
import metis from "../assets/icons/networks/metis.png";
import milkomeda from "../assets/icons/networks/milkomeda.png";
import moonbeam from "../assets/icons/networks/moonbeam.png";
import moonriver from "../assets/icons/networks/moonriver.png";
import nova from "../assets/icons/networks/nova.png";
import oasis from "../assets/icons/networks/oasis.png";
import oec from "../assets/icons/networks/oec.png";
import optimism from "../assets/icons/networks/optimism.png";
import polis from "../assets/icons/networks/polis.png";
import polygon from "../assets/icons/networks/polygon.png";
import shiden from "../assets/icons/networks/shiden.png";
import smartbch from "../assets/icons/networks/smartbch.png";
import solana from "../assets/icons/networks/solana.png";
import syscoin from "../assets/icons/networks/syscoin.png";
import telos from "../assets/icons/networks/telos.png";
import velas from "../assets/icons/networks/velas.png";
import wanchain from "../assets/icons/networks/wanchain.png";
import xdai from "../assets/icons/networks/xdai.png";
import zyx from "../assets/icons/networks/zyx.png";
import {
  NetworkId,
  NetworkIdShortNamesType,
  NetworkShortNameIdsType,
} from "../types/Network";

const NetworkExplorerIcons: Record<NetworkId, string> = {
  [NetworkId.ARBITRUM]: arbiscan,
  [NetworkId.ASTAR]: astarscan,
  [NetworkId.AURORA]: auroraExplorer,
  [NetworkId.AVA]: snowtrace,
  [NetworkId.AVALANCHEDFK]: avalanchedfkExplorer,
  [NetworkId.BOBA]: bobaExplorer,
  [NetworkId.BSC]: bscscan,
  [NetworkId.CALLISTO]: callistoExplorer,
  [NetworkId.CANTO]: cantoScout,
  [NetworkId.CELO]: celoExplorer,
  [NetworkId.CONFLUX]: confluxscan,
  [NetworkId.CRONOS]: cronosExplorer,
  [NetworkId.DOGECHAIN]: dogechainScout,
  [NetworkId.ECHELON]: echelonScout,
  [NetworkId.ELASTOS]: elastosExplorer,
  [NetworkId.ENERGI]: energiScout,
  [NetworkId.ENERGYWEB]: energyExplorer,
  [NetworkId.ETH]: etherscan,
  [NetworkId.EVMOS]: evmosscan,
  [NetworkId.FTM]: ftmscan,
  [NetworkId.FUSE]: fuseExplorer,
  [NetworkId.GOERLI]: etherscan,
  [NetworkId.HARMONY]: harmonyExplorer,
  [NetworkId.HECO]: hecoExplorer,
  [NetworkId.HSC]: hscExplorer,
  [NetworkId.IOTEX]: iotexscout,
  [NetworkId.KARDIA]: kardiaExplorer,
  [NetworkId.KCC]: kccExplorer,
  [NetworkId.KLAYTN]: klaytnScope,
  [NetworkId.MATIC]: polygonscan,
  [NetworkId.METER]: meterscan,
  [NetworkId.METIS]: metisExplorer,
  [NetworkId.MILKOMEDA]: milkomedaScout,
  [NetworkId.MOONBEAM]: moonbeamExplorer,
  [NetworkId.MOONRIVER]: moonriverExplorer,
  [NetworkId.NOVA]: novaScout,
  [NetworkId.OASIS]: oasisExplorer,
  [NetworkId.OEC]: oklink,
  [NetworkId.OPTIMISM]: optimisticExplorer,
  [NetworkId.POLIS]: polisExplorer,
  [NetworkId.SHIDEN]: shidenscan,
  [NetworkId.SMARTBCH]: smartscan,
  [NetworkId.SOLANA]: solscan,
  [NetworkId.SYSCOIN]: syscoinScout,
  [NetworkId.TELOS]: teloscan,
  [NetworkId.VELAS]: velasExplorer,
  [NetworkId.WANCHAIN]: wanscan,
  [NetworkId.XDAI]: xdaiExplorer,
  [NetworkId.ZYX]: zyxscan,
} as const;

const NetworkIcons: Record<NetworkId, string> = {
  [NetworkId.ARBITRUM]: arbitrum,
  [NetworkId.ASTAR]: astar,
  [NetworkId.AURORA]: aurora,
  [NetworkId.AVA]: avalanche,
  [NetworkId.AVALANCHEDFK]: avalanchedfk,
  [NetworkId.BOBA]: boba,
  [NetworkId.BSC]: binance,
  [NetworkId.CALLISTO]: callisto,
  [NetworkId.CANTO]: canto,
  [NetworkId.CELO]: celo,
  [NetworkId.CONFLUX]: conflux,
  [NetworkId.CRONOS]: cronos,
  [NetworkId.DOGECHAIN]: dogechain,
  [NetworkId.ECHELON]: echelon,
  [NetworkId.ELASTOS]: elastos,
  [NetworkId.ENERGI]: energi,
  [NetworkId.ENERGYWEB]: energyweb,
  [NetworkId.ETH]: ethereum,
  [NetworkId.EVMOS]: evmos,
  [NetworkId.FTM]: fantom,
  [NetworkId.FUSE]: fuse,
  [NetworkId.GOERLI]: ethereum,
  [NetworkId.HARMONY]: harmony,
  [NetworkId.HECO]: heco,
  [NetworkId.HSC]: hsc,
  [NetworkId.IOTEX]: iotex,
  [NetworkId.KARDIA]: kardia,
  [NetworkId.KCC]: kcc,
  [NetworkId.KLAYTN]: klaytn,
  [NetworkId.MATIC]: polygon,
  [NetworkId.METER]: meter,
  [NetworkId.METIS]: metis,
  [NetworkId.MILKOMEDA]: milkomeda,
  [NetworkId.MOONBEAM]: moonbeam,
  [NetworkId.MOONRIVER]: moonriver,
  [NetworkId.NOVA]: nova,
  [NetworkId.OASIS]: oasis,
  [NetworkId.OEC]: oec,
  [NetworkId.OPTIMISM]: optimism,
  [NetworkId.POLIS]: polis,
  [NetworkId.SHIDEN]: shiden,
  [NetworkId.SMARTBCH]: smartbch,
  [NetworkId.SOLANA]: solana,
  [NetworkId.SYSCOIN]: syscoin,
  [NetworkId.TELOS]: telos,
  [NetworkId.VELAS]: velas,
  [NetworkId.WANCHAIN]: wanchain,
  [NetworkId.XDAI]: xdai,
  [NetworkId.ZYX]: zyx,
} as const;

const NetworkColors: Record<NetworkId, string> = {
  [NetworkId.ARBITRUM]: "#6cb9ec",
  [NetworkId.ASTAR]: "#7239c1",
  [NetworkId.AURORA]: "#78d64b",
  [NetworkId.AVA]: "#ef5953",
  [NetworkId.AVALANCHEDFK]: "#77afb1",
  [NetworkId.BOBA]: "#97ac42",
  [NetworkId.BSC]: "#f0b90b",
  [NetworkId.CALLISTO]: "#34c88a",
  [NetworkId.CANTO]: "#06fc99",
  [NetworkId.CELO]: "#35d07f",
  [NetworkId.CONFLUX]: "#484e93",
  [NetworkId.CRONOS]: "#1199fa",
  [NetworkId.DOGECHAIN]: "#b0944b",
  [NetworkId.ECHELON]: "#7c1790",
  [NetworkId.ELASTOS]: "#8530f1",
  [NetworkId.ENERGI]: "#00e676",
  [NetworkId.ENERGYWEB]: "#a566ff",
  [NetworkId.ETH]: "#627eea",
  [NetworkId.EVMOS]: "#ed4e33",
  [NetworkId.FTM]: "#0826f2",
  [NetworkId.FUSE]: "#c3f9ae",
  [NetworkId.GOERLI]: "#627eea",
  [NetworkId.HARMONY]: "#00aee9",
  [NetworkId.HECO]: "#90c923",
  [NetworkId.HSC]: "#00c0ab",
  [NetworkId.IOTEX]: "#44ffb2",
  [NetworkId.KARDIA]: "#ad182a",
  [NetworkId.KCC]: "#31d7a0",
  [NetworkId.KLAYTN]: "#aa0032",
  [NetworkId.MATIC]: "#8248e5",
  [NetworkId.METER]: "#0287d0",
  [NetworkId.METIS]: "#2adacc",
  [NetworkId.MILKOMEDA]: "#ef7d00",
  [NetworkId.MOONBEAM]: "#53cbc8",
  [NetworkId.MOONRIVER]: "#f5c942",
  [NetworkId.NOVA]: "#dd7d25",
  [NetworkId.OASIS]: "#1f7ef2",
  [NetworkId.OEC]: "#2d60e0",
  [NetworkId.OPTIMISM]: "#ff0420",
  [NetworkId.POLIS]: "#0f32a6",
  [NetworkId.SHIDEN]: "#5928b1",
  [NetworkId.SMARTBCH]: "#74dd54",
  [NetworkId.SOLANA]: "#40b9c8",
  [NetworkId.SYSCOIN]: "#1f5eff",
  [NetworkId.TELOS]: "#3d4f8b",
  [NetworkId.VELAS]: "#0037c1",
  [NetworkId.WANCHAIN]: "#009ee2",
  [NetworkId.XDAI]: "#48A9A6",
  [NetworkId.ZYX]: "#f3d131",
} as const;

const NetworkScanNames: Record<NetworkId, string> = {
  [NetworkId.ARBITRUM]: "Arbiscan",
  [NetworkId.ASTAR]: "AstarScan",
  [NetworkId.AURORA]: "Aurora Explorer",
  [NetworkId.AVA]: "SnowTrace",
  [NetworkId.AVALANCHEDFK]: "DFK Explorer",
  [NetworkId.BOBA]: "Boba Explorer",
  [NetworkId.BSC]: "Bscscan",
  [NetworkId.CALLISTO]: "Callisto Explorer",
  [NetworkId.CANTO]: "Canto Explorer",
  [NetworkId.CELO]: "Celo Explorer",
  [NetworkId.CONFLUX]: "ConfluxScan",
  [NetworkId.CRONOS]: "Cronos Explorer",
  [NetworkId.DOGECHAIN]: "Dogechain Explorer",
  [NetworkId.ECHELON]: "EchelonScout",
  [NetworkId.ELASTOS]: "Elastos Explorer",
  [NetworkId.ENERGI]: "EnergiScout",
  [NetworkId.ENERGYWEB]: "Energy Web Explorer",
  [NetworkId.ETH]: "Etherscan",
  [NetworkId.EVMOS]: "EvmosScan",
  [NetworkId.FTM]: "FTMScan",
  [NetworkId.FUSE]: "Fuse Explorer",
  [NetworkId.GOERLI]: "Etherscan",
  [NetworkId.HARMONY]: "Harmony Explorer",
  [NetworkId.HECO]: "HecoInfo",
  [NetworkId.HSC]: "Hooscan",
  [NetworkId.IOTEX]: "IoTeXScout",
  [NetworkId.KARDIA]: "Kardiachain Explorer",
  [NetworkId.KCC]: "KCC Explorer",
  [NetworkId.KLAYTN]: "KlaytnScout",
  [NetworkId.MATIC]: "Polygonscan",
  [NetworkId.METER]: "MeterScan",
  [NetworkId.METIS]: "Metis Explorer",
  [NetworkId.MILKOMEDA]: "MilkkomedaScout",
  [NetworkId.MOONBEAM]: "Moonbeam Explorer",
  [NetworkId.MOONRIVER]: "Moonscan",
  [NetworkId.NOVA]: "Arbitrum Nova Chain Explorer",
  [NetworkId.OASIS]: "Oasis Explorer",
  [NetworkId.OEC]: "OKLink",
  [NetworkId.OPTIMISM]: "Optimistic Ethereum Explorer",
  [NetworkId.POLIS]: "Polis Explorer",
  [NetworkId.SHIDEN]: "ShidenScan",
  [NetworkId.SMARTBCH]: "SmartScan",
  [NetworkId.SOLANA]: "Solscan",
  [NetworkId.SYSCOIN]: "SyscoinScout",
  [NetworkId.TELOS]: "Teloscan",
  [NetworkId.VELAS]: "Velas Explorer",
  [NetworkId.WANCHAIN]: "Wanscan",
  [NetworkId.XDAI]: "xDai Explorer",
  [NetworkId.ZYX]: "ZYXScan",
} as const;

const NetworkShortName: Record<NetworkId, string> = {
  [NetworkId.ARBITRUM]: "arb",
  [NetworkId.ASTAR]: "astar",
  [NetworkId.AURORA]: "aur",
  [NetworkId.AVA]: "avax",
  [NetworkId.AVALANCHEDFK]: "dfk",
  [NetworkId.BOBA]: "boba",
  [NetworkId.BSC]: "bsc",
  [NetworkId.CALLISTO]: "clo",
  [NetworkId.CANTO]: "canto",
  [NetworkId.CELO]: "celo",
  [NetworkId.CONFLUX]: "cfx",
  [NetworkId.CRONOS]: "cro",
  [NetworkId.DOGECHAIN]: "doge",
  [NetworkId.ECHELON]: "ech",
  [NetworkId.ELASTOS]: "ela",
  [NetworkId.ENERGI]: "nrg",
  [NetworkId.ENERGYWEB]: "energy",
  [NetworkId.ETH]: "eth",
  [NetworkId.EVMOS]: "evmos",
  [NetworkId.FTM]: "ftm",
  [NetworkId.FUSE]: "fuse",
  [NetworkId.GOERLI]: "goerli",
  [NetworkId.HARMONY]: "one",
  [NetworkId.HECO]: "ht",
  [NetworkId.HSC]: "hoo",
  [NetworkId.IOTEX]: "iotx",
  [NetworkId.KARDIA]: "kai",
  [NetworkId.KCC]: "kcs",
  [NetworkId.KLAYTN]: "klay",
  [NetworkId.MATIC]: "matic",
  [NetworkId.METER]: "meter",
  [NetworkId.METIS]: "metis",
  [NetworkId.MILKOMEDA]: "milk",
  [NetworkId.MOONBEAM]: "glmr",
  [NetworkId.MOONRIVER]: "movr",
  [NetworkId.NOVA]: "nova",
  [NetworkId.OASIS]: "oasis",
  [NetworkId.OEC]: "okt",
  [NetworkId.OPTIMISM]: "opti",
  [NetworkId.POLIS]: "polis",
  [NetworkId.SHIDEN]: "sdn",
  [NetworkId.SMARTBCH]: "bch",
  [NetworkId.SOLANA]: "sol",
  [NetworkId.SYSCOIN]: "sys",
  [NetworkId.TELOS]: "tlos",
  [NetworkId.VELAS]: "vlx",
  [NetworkId.WANCHAIN]: "wan",
  [NetworkId.XDAI]: "xdai",
  [NetworkId.ZYX]: "zyx",
} as const;

const NetworkNames: Record<NetworkId, string> = {
  [NetworkId.ARBITRUM]: "Arbitrum",
  [NetworkId.ASTAR]: "Astar",
  [NetworkId.AURORA]: "Aurora",
  [NetworkId.AVA]: "Avalanche",
  [NetworkId.AVALANCHEDFK]: "Avalanche DFK",
  [NetworkId.BOBA]: "Boba",
  [NetworkId.BSC]: "Binance Smart Chain",
  [NetworkId.CALLISTO]: "Callisto",
  [NetworkId.CANTO]: "Canto",
  [NetworkId.CELO]: "Celo",
  [NetworkId.CONFLUX]: "Conflux",
  [NetworkId.CRONOS]: "Cronos",
  [NetworkId.DOGECHAIN]: "Dogechain",
  [NetworkId.ECHELON]: "Echelon",
  [NetworkId.ELASTOS]: "Elastos",
  [NetworkId.ENERGI]: "Energi",
  [NetworkId.ENERGYWEB]: "Energy Web",
  [NetworkId.ETH]: "Ethereum",
  [NetworkId.EVMOS]: "Evmos",
  [NetworkId.FTM]: "Fantom",
  [NetworkId.FUSE]: "Fuse",
  [NetworkId.GOERLI]: "Goerli",
  [NetworkId.HARMONY]: "Harmony",
  [NetworkId.HECO]: "Heco",
  [NetworkId.HSC]: "Hoo Smart Chain",
  [NetworkId.IOTEX]: "IoTeX",
  [NetworkId.KARDIA]: "KardiaChain",
  [NetworkId.KCC]: "KuCoin Community Chain",
  [NetworkId.KLAYTN]: "Klaytn",
  [NetworkId.MATIC]: "Polygon",
  [NetworkId.METER]: "Meter",
  [NetworkId.METIS]: "Metis",
  [NetworkId.MILKOMEDA]: "Milkomeda",
  [NetworkId.MOONBEAM]: "Moonbeam",
  [NetworkId.MOONRIVER]: "Moonriver",
  [NetworkId.NOVA]: "Arbitrum Nova",
  [NetworkId.OASIS]: "Oasis Emerald",
  [NetworkId.OEC]: "OEC",
  [NetworkId.OPTIMISM]: "Optimism",
  [NetworkId.POLIS]: "Polis",
  [NetworkId.SHIDEN]: "Shiden",
  [NetworkId.SMARTBCH]: "Smartbch",
  [NetworkId.SOLANA]: "Solana",
  [NetworkId.SYSCOIN]: "Syscoin",
  [NetworkId.TELOS]: "Telos",
  [NetworkId.VELAS]: "Velas",
  [NetworkId.WANCHAIN]: "Wanchain",
  [NetworkId.XDAI]: "xDai",
  [NetworkId.ZYX]: "ZYX",
} as const;

const NetworkCoinScanName: Record<NetworkId, string | null> = {
  [NetworkId.ARBITRUM]: "arb",
  [NetworkId.ASTAR]: "astar",
  [NetworkId.AURORA]: "aur",
  [NetworkId.AVA]: "avax",
  [NetworkId.AVALANCHEDFK]: "dfk",
  [NetworkId.BOBA]: "boba",
  [NetworkId.BSC]: "bsc",
  [NetworkId.CALLISTO]: "clo",
  [NetworkId.CANTO]: "canto",
  [NetworkId.CELO]: "celo",
  [NetworkId.CONFLUX]: "cfx",
  [NetworkId.CRONOS]: "cro",
  [NetworkId.DOGECHAIN]: null,
  [NetworkId.ECHELON]: null,
  [NetworkId.ELASTOS]: "ela",
  [NetworkId.ENERGI]: null,
  [NetworkId.ENERGYWEB]: "energy",
  [NetworkId.ETH]: "eth",
  [NetworkId.EVMOS]: "evmos",
  [NetworkId.FTM]: "ftm",
  [NetworkId.FUSE]: "fuse",
  [NetworkId.GOERLI]: null,
  [NetworkId.HARMONY]: "one",
  [NetworkId.HECO]: "ht",
  [NetworkId.HSC]: null,
  [NetworkId.IOTEX]: "iotex",
  [NetworkId.KARDIA]: "kai",
  [NetworkId.KCC]: "kcs",
  [NetworkId.KLAYTN]: "klay",
  [NetworkId.MATIC]: "matic",
  [NetworkId.METER]: null,
  [NetworkId.METIS]: "metis",
  [NetworkId.MILKOMEDA]: "milk",
  [NetworkId.MOONBEAM]: "glmr",
  [NetworkId.MOONRIVER]: "movr",
  [NetworkId.NOVA]: "nova",
  [NetworkId.OASIS]: "oasis",
  [NetworkId.OEC]: "okt",
  [NetworkId.OPTIMISM]: "opti",
  [NetworkId.POLIS]: null,
  [NetworkId.SHIDEN]: "sdn",
  [NetworkId.SMARTBCH]: "bch",
  [NetworkId.SOLANA]: null,
  [NetworkId.SYSCOIN]: "sys",
  [NetworkId.TELOS]: "tlos",
  [NetworkId.VELAS]: "vlx",
  [NetworkId.WANCHAIN]: "wan",
  [NetworkId.XDAI]: "xdai",
  [NetworkId.ZYX]: null,
} as const;

const NetworkDexScreenerName: Record<NetworkId, string | null> = {
  [NetworkId.ARBITRUM]: "arbitrum",
  [NetworkId.ASTAR]: "astar",
  [NetworkId.AURORA]: "aurora",
  [NetworkId.AVA]: "avalanche",
  [NetworkId.AVALANCHEDFK]: "avalanchedfk",
  [NetworkId.BOBA]: "boba",
  [NetworkId.BSC]: "bsc",
  [NetworkId.CALLISTO]: null,
  [NetworkId.CANTO]: "canto",
  [NetworkId.CELO]: "celo",
  [NetworkId.CONFLUX]: "conflux",
  [NetworkId.CRONOS]: "cronos",
  [NetworkId.DOGECHAIN]: "dogechain",
  [NetworkId.ECHELON]: null,
  [NetworkId.ELASTOS]: "elastos",
  [NetworkId.ENERGI]: "energi",
  [NetworkId.ENERGYWEB]: null,
  [NetworkId.ETH]: "ethereum",
  [NetworkId.EVMOS]: "evmos",
  [NetworkId.FTM]: "fantom",
  [NetworkId.FUSE]: "fuse",
  [NetworkId.GOERLI]: "goerli",
  [NetworkId.HARMONY]: "harmony",
  [NetworkId.HECO]: "heco",
  [NetworkId.HSC]: null,
  [NetworkId.IOTEX]: "iotex",
  [NetworkId.KARDIA]: "kardiachain",
  [NetworkId.KCC]: "kcc",
  [NetworkId.KLAYTN]: "klaytn",
  [NetworkId.MATIC]: "polygon",
  [NetworkId.METER]: "meter",
  [NetworkId.METIS]: "metis",
  [NetworkId.MILKOMEDA]: "milkomedacardano",
  [NetworkId.MOONBEAM]: "moonbeam",
  [NetworkId.MOONRIVER]: "moonriver",
  [NetworkId.NOVA]: "arbitrumnova",
  [NetworkId.OASIS]: "oasisemerald",
  [NetworkId.OEC]: "okc",
  [NetworkId.OPTIMISM]: "optimism",
  [NetworkId.POLIS]: null,
  [NetworkId.SHIDEN]: null,
  [NetworkId.SMARTBCH]: "smartbch",
  [NetworkId.SOLANA]: "solana",
  [NetworkId.SYSCOIN]: "syscoin",
  [NetworkId.TELOS]: "telos",
  [NetworkId.VELAS]: "velas",
  [NetworkId.WANCHAIN]: "wanchain",
  [NetworkId.XDAI]: "gnosischain",
  [NetworkId.ZYX]: "zksync",
} as const;

const NetworkDexToolsName: Record<NetworkId, string | null> = {
  [NetworkId.ARBITRUM]: "arbitrum",
  [NetworkId.ASTAR]: "astar",
  [NetworkId.AURORA]: "aurora",
  [NetworkId.AVA]: "avalanche",
  [NetworkId.AVALANCHEDFK]: "dfk",
  [NetworkId.BOBA]: "boba",
  [NetworkId.BSC]: "bnb",
  [NetworkId.CALLISTO]: null,
  [NetworkId.CANTO]: "canto",
  [NetworkId.CELO]: "celo",
  [NetworkId.CONFLUX]: "conflux",
  [NetworkId.CRONOS]: "cronos",
  [NetworkId.DOGECHAIN]: "dogechain",
  [NetworkId.ECHELON]: "echelon",
  [NetworkId.ELASTOS]: "elastos",
  [NetworkId.ENERGI]: "energi",
  [NetworkId.ENERGYWEB]: null,
  [NetworkId.ETH]: "ether",
  [NetworkId.EVMOS]: "evmos",
  [NetworkId.FTM]: "fantom",
  [NetworkId.FUSE]: "fuse",
  [NetworkId.GOERLI]: "ethergoerli",
  [NetworkId.HARMONY]: "harmony",
  [NetworkId.HECO]: "heco",
  [NetworkId.HSC]: "hoo",
  [NetworkId.IOTEX]: "iotex",
  [NetworkId.KARDIA]: "kardia",
  [NetworkId.KCC]: "kucoin",
  [NetworkId.KLAYTN]: null,
  [NetworkId.MATIC]: "polygon",
  [NetworkId.METER]: "meter",
  [NetworkId.METIS]: "metis",
  [NetworkId.MILKOMEDA]: "milkomeda",
  [NetworkId.MOONBEAM]: "moonbeam",
  [NetworkId.MOONRIVER]: "moonriver",
  [NetworkId.NOVA]: "arbitrumnova",
  [NetworkId.OASIS]: "oasis",
  [NetworkId.OEC]: "okc",
  [NetworkId.OPTIMISM]: "optimism",
  [NetworkId.POLIS]: null,
  [NetworkId.SHIDEN]: "shiden",
  [NetworkId.SMARTBCH]: "smartbch",
  [NetworkId.SOLANA]: "solana",
  [NetworkId.SYSCOIN]: "syscoin",
  [NetworkId.TELOS]: "telos",
  [NetworkId.VELAS]: "velas",
  [NetworkId.WANCHAIN]: "wan",
  [NetworkId.XDAI]: "gnosis",
  [NetworkId.ZYX]: "zksync",
} as const;

const NetworkGeckoTerminalName: Record<NetworkId, string | null> = {
  [NetworkId.ARBITRUM]: "arbitrum",
  [NetworkId.ASTAR]: "astr",
  [NetworkId.AURORA]: null,
  [NetworkId.AVA]: "avax",
  [NetworkId.AVALANCHEDFK]: "dfk",
  [NetworkId.BOBA]: "boba",
  [NetworkId.BSC]: "bsc",
  [NetworkId.CALLISTO]: "callisto",
  [NetworkId.CANTO]: "canto",
  [NetworkId.CELO]: "celo",
  [NetworkId.CONFLUX]: null,
  [NetworkId.CRONOS]: "cro",
  [NetworkId.DOGECHAIN]: "dogechain",
  [NetworkId.ECHELON]: null,
  [NetworkId.ELASTOS]: "ela",
  [NetworkId.ENERGI]: "nrg",
  [NetworkId.ENERGYWEB]: null,
  [NetworkId.ETH]: "eth",
  [NetworkId.EVMOS]: "evmos",
  [NetworkId.FTM]: "ftm",
  [NetworkId.FUSE]: "fuse",
  [NetworkId.GOERLI]: "goerli-testnet",
  [NetworkId.HARMONY]: "one",
  [NetworkId.HECO]: "heco",
  [NetworkId.HSC]: "hsc",
  [NetworkId.IOTEX]: "iotx",
  [NetworkId.KARDIA]: null,
  [NetworkId.KCC]: "kcc",
  [NetworkId.KLAYTN]: "klaytn",
  [NetworkId.MATIC]: "polygon_pos",
  [NetworkId.METER]: "mtr",
  [NetworkId.METIS]: "metis",
  [NetworkId.MILKOMEDA]: "milkada",
  [NetworkId.MOONBEAM]: "glmr",
  [NetworkId.MOONRIVER]: "movr",
  [NetworkId.NOVA]: "arbitrum_nova",
  [NetworkId.OASIS]: null,
  [NetworkId.OEC]: null,
  [NetworkId.OPTIMISM]: "optimism",
  [NetworkId.POLIS]: null,
  [NetworkId.SHIDEN]: "sdn",
  [NetworkId.SMARTBCH]: null,
  [NetworkId.SOLANA]: "solana",
  [NetworkId.SYSCOIN]: "sys",
  [NetworkId.TELOS]: null,
  [NetworkId.VELAS]: "velas",
  [NetworkId.WANCHAIN]: "wan",
  [NetworkId.XDAI]: null,
  [NetworkId.ZYX]: "zksync",
} as const;

const NetworkExplorerUrls: Record<NetworkId, string> = {
  [NetworkId.ARBITRUM]: "https://arbiscan.io/${type}/${input}",
  [NetworkId.ASTAR]: "https://blockscout.com/astar/${type}/${input}",
  [NetworkId.AURORA]: "https://aurorascan.dev/${type}/${input}",
  [NetworkId.AVA]: "https://snowtrace.io/${type}/${input}",
  [NetworkId.AVALANCHEDFK]:
    "https://subnets.avax.network/defi-kingdoms/dfk-chain/explorer/${type}/${input}",
  [NetworkId.BOBA]:
    "https://blockexplorer.boba.network/${typeSubTokenForAddress}/${input}",
  [NetworkId.BSC]: "https://bscscan.com/${type}/${input}",
  [NetworkId.CALLISTO]:
    "https://explorer.callisto.network/${typeSubTokenForAddress}/${input}",
  [NetworkId.CANTO]: "https://evm.explorer.canto.io/${type}/${input}",
  [NetworkId.CELO]: "https://explorer.celo.org/${type}/${input}",
  [NetworkId.CONFLUX]: "https://evm.confluxscan.io/${type}/${input}",
  [NetworkId.CRONOS]: "https://cronoscan.com/${type}/${input}",
  [NetworkId.DOGECHAIN]: "https://explorer.dogechain.dog/${type}/${input}",
  [NetworkId.ECHELON]: "https://scout.ech.network/${type}/${input}",
  [NetworkId.ELASTOS]: "https://eth.elastos.io/${type}/${input}",
  [NetworkId.ENERGI]: "https://explorer.energi.network/${type}/${input}",
  [NetworkId.ENERGYWEB]: "https://explorer.energyweb.org/${type}/${input}",
  [NetworkId.ETH]: "https://etherscan.io/${type}/${input}",
  [NetworkId.EVMOS]: "https://evm.evmos.org/${type}/${input}",
  [NetworkId.FTM]: "https://ftmscan.com/${type}/${input}",
  [NetworkId.FUSE]: "https://explorer.fuse.io/${type}/${input}",
  [NetworkId.GOERLI]: "https://goerli.etherscan.io/${type}/${input}",
  [NetworkId.HARMONY]: "https://explorer.harmony.one/${type}/${input}",
  [NetworkId.HECO]: "https://hecoinfo.com/${type}/${input}",
  [NetworkId.HSC]: "https://hooscan.com/${type}/${input}",
  [NetworkId.IOTEX]: "https://iotexscout.io/${type}/${input}",
  [NetworkId.KARDIA]: "https://legacy-explorer.kardiachain.io/${type}/${input}",
  [NetworkId.KCC]: "https://explorer.kcc.io/en/${type}/${input}",
  [NetworkId.KLAYTN]: "https://scope.klaytn.com/${type}/${input}",
  [NetworkId.MATIC]: "https://polygonscan.com/${type}/${input}",
  [NetworkId.METER]: "https://scan.meter.io/${type}/${input}",
  [NetworkId.METIS]: "https://andromeda-explorer.metis.io/${type}/${input}",
  [NetworkId.MILKOMEDA]:
    "https://explorer-mainnet-cardano-evm.c1.milkomeda.com/${type}/${input}",
  [NetworkId.MOONBEAM]:
    "https://blockscout.moonbeam.network/${typeSubTokenForAddress}/${input}",
  [NetworkId.MOONRIVER]: "https://moonriver.moonscan.io/${type}/${input}",
  [NetworkId.NOVA]: "https://nova-explorer.arbitrum.io/${type}/${input}",
  [NetworkId.OASIS]: "https://explorer.emerald.oasis.dev/${type}/${input}",
  [NetworkId.OEC]:
    "https://www.oklink.com/en/oec/${typeSubTokenForAddress}/${input}",
  [NetworkId.OPTIMISM]: "https://optimistic.etherscan.io/${type}/${input}",
  [NetworkId.POLIS]: "https://explorer.polis.tech/${type}/${input}",
  [NetworkId.SHIDEN]: "https://blockscout.com/shiden/${type}/${input}",
  [NetworkId.SMARTBCH]:
    "https://www.smartscan.cash/${typeSubTokenForAddress}/${input}",
  [NetworkId.SOLANA]: "https://solscan.io/${type}/${input}",
  [NetworkId.SYSCOIN]: "https://explorer.syscoin.org/${type}/${input}",
  [NetworkId.TELOS]:
    "https://www.teloscan.io/${typeSubTokenForAddress}/${input}",
  [NetworkId.VELAS]: "https://evmexplorer.velas.com/${type}/${input}",
  [NetworkId.WANCHAIN]: "https://www.wanscan.org/${type}/${input}",
  [NetworkId.XDAI]: "https://blockscout.com/xdai/mainnet/${type}/${input}",
  [NetworkId.ZYX]: "https://zyxscan.com/${type}/${input}",
} as const;

const NetworkChecksumExplorerUrls: Record<NetworkId, boolean> = {
  [NetworkId.ARBITRUM]: true,
  [NetworkId.ASTAR]: true,
  [NetworkId.AURORA]: true,
  [NetworkId.AVA]: true,
  [NetworkId.AVALANCHEDFK]: true,
  [NetworkId.BOBA]: true,
  [NetworkId.BSC]: true,
  [NetworkId.CALLISTO]: true,
  [NetworkId.CANTO]: true,
  [NetworkId.CELO]: true,
  [NetworkId.CONFLUX]: true,
  [NetworkId.CRONOS]: true,
  [NetworkId.DOGECHAIN]: true,
  [NetworkId.ECHELON]: true,
  [NetworkId.ELASTOS]: true,
  [NetworkId.ENERGI]: true,
  [NetworkId.ENERGYWEB]: true,
  [NetworkId.ETH]: true,
  [NetworkId.EVMOS]: true,
  [NetworkId.FTM]: true,
  [NetworkId.FUSE]: true,
  [NetworkId.GOERLI]: true,
  [NetworkId.HARMONY]: true,
  [NetworkId.HECO]: true,
  [NetworkId.HSC]: true,
  [NetworkId.IOTEX]: true,
  [NetworkId.KARDIA]: true,
  [NetworkId.KCC]: true,
  [NetworkId.KLAYTN]: false,
  [NetworkId.MATIC]: true,
  [NetworkId.METER]: false,
  [NetworkId.METIS]: true,
  [NetworkId.MILKOMEDA]: true,
  [NetworkId.MOONBEAM]: true,
  [NetworkId.MOONRIVER]: true,
  [NetworkId.NOVA]: true,
  [NetworkId.OASIS]: true,
  [NetworkId.OEC]: true,
  [NetworkId.OPTIMISM]: true,
  [NetworkId.POLIS]: true,
  [NetworkId.SHIDEN]: true,
  [NetworkId.SMARTBCH]: true,
  [NetworkId.SOLANA]: false,
  [NetworkId.SYSCOIN]: true,
  [NetworkId.TELOS]: true,
  [NetworkId.VELAS]: true,
  [NetworkId.WANCHAIN]: true,
  [NetworkId.XDAI]: true,
  [NetworkId.ZYX]: true,
} as const;

// IMPORTANT: Networks will be displayed throughout the UI in this order.
// Ordered (fairly arbitrarily) by our deemed level of importance/hype.
const NetworkMagicOrder: NetworkId[] = [
  NetworkId.ETH,
  NetworkId.SOLANA,
  NetworkId.BSC,
  NetworkId.AVA,
  NetworkId.FTM,
  NetworkId.MATIC,
  NetworkId.HARMONY,
  NetworkId.ARBITRUM,
  NetworkId.OPTIMISM,
  NetworkId.DOGECHAIN,
  NetworkId.CANTO,
  NetworkId.CRONOS,
  NetworkId.CELO,
  NetworkId.AURORA,
  NetworkId.NOVA,
  NetworkId.MOONBEAM,
  NetworkId.MOONRIVER,
  NetworkId.EVMOS,
  NetworkId.HECO,
  NetworkId.METIS,
  NetworkId.XDAI,
  NetworkId.ASTAR,
  NetworkId.OASIS,
  NetworkId.AVALANCHEDFK,
  NetworkId.BOBA,
  NetworkId.ENERGI,
  NetworkId.IOTEX,
  NetworkId.OEC,
  NetworkId.WANCHAIN,
  NetworkId.KCC,
  NetworkId.VELAS,
  NetworkId.SMARTBCH,
  NetworkId.KARDIA,
  NetworkId.FUSE,
  NetworkId.ELASTOS,
  NetworkId.MILKOMEDA,
  NetworkId.CONFLUX,
  NetworkId.ECHELON,
  NetworkId.POLIS,
  NetworkId.ZYX,
  NetworkId.HSC,
  NetworkId.SYSCOIN,
  NetworkId.KLAYTN,
  NetworkId.SHIDEN,
  NetworkId.TELOS,
  NetworkId.ENERGYWEB,
  NetworkId.CALLISTO,
  NetworkId.METER,
];

const NetworkEnabled: Record<NetworkId, boolean> = {
  [NetworkId.ARBITRUM]: true,
  [NetworkId.ASTAR]: true,
  [NetworkId.AURORA]: true,
  [NetworkId.AVA]: true,
  [NetworkId.AVALANCHEDFK]: true,
  [NetworkId.BOBA]: true,
  [NetworkId.BSC]: true,
  [NetworkId.CALLISTO]: true,
  [NetworkId.CANTO]: true,
  [NetworkId.CELO]: true,
  [NetworkId.CONFLUX]: true,
  [NetworkId.CRONOS]: true,
  [NetworkId.DOGECHAIN]: false,
  [NetworkId.ECHELON]: true,
  [NetworkId.ELASTOS]: true,
  [NetworkId.ENERGI]: false,
  [NetworkId.ENERGYWEB]: true,
  [NetworkId.ETH]: true,
  [NetworkId.EVMOS]: true,
  [NetworkId.FTM]: true,
  [NetworkId.FUSE]: true,
  [NetworkId.GOERLI]: false,
  [NetworkId.HARMONY]: true,
  [NetworkId.HECO]: true,
  [NetworkId.HSC]: true,
  [NetworkId.IOTEX]: true,
  [NetworkId.KARDIA]: true,
  [NetworkId.KCC]: true,
  [NetworkId.KLAYTN]: true,
  [NetworkId.MATIC]: true,
  [NetworkId.METER]: false,
  [NetworkId.METIS]: true,
  [NetworkId.MILKOMEDA]: true,
  [NetworkId.MOONBEAM]: true,
  [NetworkId.MOONRIVER]: true,
  [NetworkId.NOVA]: true,
  [NetworkId.OASIS]: true,
  [NetworkId.OEC]: true,
  [NetworkId.OPTIMISM]: true,
  [NetworkId.POLIS]: true,
  [NetworkId.SHIDEN]: true,
  [NetworkId.SMARTBCH]: true,
  [NetworkId.SOLANA]: false,
  [NetworkId.SYSCOIN]: true,
  [NetworkId.TELOS]: true,
  [NetworkId.VELAS]: true,
  [NetworkId.WANCHAIN]: true,
  [NetworkId.XDAI]: true,
  [NetworkId.ZYX]: true,
} as const;

const NetworkIdShortNames = Object.entries(
  NetworkId
).reduce<NetworkIdShortNamesType>((acc, [k]) => {
  const networkId = parseInt(k) as NetworkId;
  acc[networkId] = NetworkShortName[networkId];
  return acc;
}, {} as NetworkIdShortNamesType);

const NetworkShortNameIds = Object.entries(
  NetworkShortName
).reduce<NetworkShortNameIdsType>((acc, [v]) => {
  const networkId = parseInt(v) as NetworkId;
  acc[NetworkShortName[networkId]] = networkId;
  return acc;
}, {} as NetworkShortNameIdsType);

const EnabledNetworks = NetworkMagicOrder.filter((m) => NetworkEnabled[m]);

export {
  EnabledNetworks,
  NetworkChecksumExplorerUrls,
  NetworkColors,
  NetworkExplorerIcons,
  NetworkExplorerUrls,
  NetworkIcons,
  NetworkIdShortNames,
  NetworkNames,
  NetworkScanNames,
  NetworkShortName,
  NetworkShortNameIds,
  NetworkCoinScanName,
  NetworkDexScreenerName,
  NetworkDexToolsName,
  NetworkGeckoTerminalName,
};
