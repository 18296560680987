import React from "react";

import { Exchange } from "../../generated/graphql";
import { AvatarFallback, AvatarImage, AvatarRoot } from "../dataDisplay/Avatar";

export interface ExchangeIconProps {
  exchange: Exchange;
  sizeInPx?: number;
  stacked?: boolean;
}

const ExchangeIcon: React.FC<ExchangeIconProps> = ({
  exchange,
  sizeInPx = 22,
  stacked,
}) => (
  <AvatarRoot $sizeInPx={sizeInPx} $stacked={stacked}>
    <AvatarImage src={exchange.iconUrl || ""} alt={exchange.name || ""} />
    <AvatarFallback $accentColor={exchange.color || ""}>
      {exchange?.name?.[0]}
    </AvatarFallback>
  </AvatarRoot>
);

export { ExchangeIcon };
