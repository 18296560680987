// TODO move this whole this to client-lib? nathan will hate me

import BigNumber from "bignumber.js";

export interface FormatDisplayNumberProps {
  abbreviate?: boolean;
  asInteger?: boolean;
  delimitingSpaces?: boolean;
  dollars?: boolean;
  sigFigs?: number;
  value?: string | number | null;
  zerosTilSubscript?: number;
}

export interface AbbreviateNumberProps {
  sigFigs?: number;
  value: number | BigNumber.Value;
}

const subscriptNums = [
  "₀",
  "₁",
  "₂",
  "₃",
  "₄",
  "₅",
  "₆",
  "₇",
  "₈",
  "₉",
  "₁₀",
  "₁₁",
  "₁₂",
  "₁₃",
  "₁₄",
  "₁₅",
  "₁₆",
  "₁₇",
  "₁₈",
  "₁₉",
];

const sixthSpace = "\u2006"; // 1/6 an em
const thirdSpace = "\u2004"; // 1/3 an em

/*
 * Find the number of zeros after the decimal place but before
 * the significant figures begin.
 */
const getLeadingZeros = (value: string): number => {
  const afterDecimal = value.split(".")[1];
  const numZeros = afterDecimal.match(/^0.0*/);
  return numZeros ? numZeros[0].length : 0;
};

/*
 * Replace leading zeros with a subscript number.
 */
const addSubscriptZeros = (value: string): string => {
  const numLeadingZeros = getLeadingZeros(value);

  return `0.0${sixthSpace}${
    numLeadingZeros >= 20 ? "₂₀₊" : subscriptNums[numLeadingZeros]
  }${sixthSpace + value.replace(/^0.0+/, "")}`;
};

/*
 * Add a thin space after every third digit after the comma.
 */
const addDelimitingSpaces = (value: string): string => {
  const splitValue = value.split(".");
  // Matches sets of zeros in 3s + any numbers afterwards
  const parts = splitValue[1]?.match(/0{3}|[0-9]+/g);
  return parts ? `${splitValue[0]}.${parts.join(thirdSpace)}` : value;
};

/*
 * Turn 1500300 into "1.500M", etc.
 */
const abbreviateNumber = ({
  value,
  sigFigs = 4,
}: AbbreviateNumberProps): string => {
  const bnValue = new BigNumber(value);
  let formattedValue;
  let symbol = "";

  if (value > 999000000000000) {
    formattedValue = new BigNumber(999);
    symbol = `${sixthSpace}T`;
  } else if (value >= 1000000000000) {
    formattedValue = bnValue.div(1000000000000);
    symbol = `${sixthSpace}T`;
  } else if (value >= 1000000000) {
    formattedValue = bnValue.div(1000000000);
    symbol = `${sixthSpace}B`;
  } else if (value >= 1000000) {
    formattedValue = bnValue.div(1000000);
    symbol = `${sixthSpace}M`;
  } else if (value >= 1000) {
    formattedValue = bnValue.div(1000);
    symbol = `${sixthSpace}K`;
  } else {
    formattedValue = bnValue;
  }

  return (
    formattedValue.toNumber().toLocaleString("en-US", {
      minimumSignificantDigits: sigFigs - 2,
      maximumSignificantDigits: sigFigs,
    }) + symbol
  );
};

const formatDisplayNumber = ({
  abbreviate,
  asInteger,
  delimitingSpaces,
  dollars,
  sigFigs = 4,
  value,
  zerosTilSubscript = 4,
}: FormatDisplayNumberProps): string => {
  if (value === null || value === undefined)
    return abbreviate ? "——" : "——————";

  const bnValue = new BigNumber(value);

  // Force abbreviate for very large nums (over one billion)
  const forceAbbreviate = bnValue.isGreaterThanOrEqualTo(1000000000);
  // Display '>' prefix for very very large nums (over 999T)
  const over999T = bnValue.isGreaterThan(999000000000000);

  let formattedValue;
  let minSignificantDigits = sigFigs;
  let maxSignificantDigits = sigFigs;

  if (asInteger) {
    // Still make use of comma delimiting & abbreviation,
    // but round to the nearest integer.
    if (bnValue.isGreaterThanOrEqualTo(Math.pow(10, sigFigs))) {
      formattedValue = abbreviateNumber({ value: bnValue, sigFigs: sigFigs });
    } else {
      formattedValue = Math.round(bnValue.toNumber())
        .toLocaleString("en-US", {
          minimumSignificantDigits: minSignificantDigits,
          maximumSignificantDigits: maxSignificantDigits,
        })
        .split(".")?.[0];
    }
  } else if (
    (abbreviate && bnValue.isGreaterThanOrEqualTo(10000)) ||
    forceAbbreviate
  ) {
    // Abbreviate to B/M/K with lower sig figs
    // Ex: $983,384 => $983K
    formattedValue = abbreviateNumber({ value: bnValue, sigFigs: sigFigs });
  } else {
    // Add more sig figs if we are showing the full, unabbreviated number.
    // Normally, showing 1,234,567 to 4 sig figs would show as 1,235,000.
    if (bnValue.isGreaterThanOrEqualTo(Math.pow(10, sigFigs))) {
      const numbersBeforeZero = String(Math.round(bnValue.toNumber())).length;
      minSignificantDigits = numbersBeforeZero;
      maxSignificantDigits = numbersBeforeZero;
    }

    // To avoid showing only one digit for cents (ex: $123,385.7)
    if (
      dollars &&
      bnValue.isGreaterThanOrEqualTo(10 ** (maxSignificantDigits - 2)) &&
      bnValue.isLessThan(10 ** (maxSignificantDigits - 1))
    ) {
      minSignificantDigits += 1;
      maxSignificantDigits += 1;

      // To avoid showing three digits after the decimal place (ex: $123.385)
      // This is an issue because it can be confused for a thousand separator
    } else if (
      bnValue.isGreaterThanOrEqualTo(10 ** (maxSignificantDigits - 4)) &&
      bnValue.isLessThan(10 ** (maxSignificantDigits - 3))
    ) {
      minSignificantDigits -= 1;
      maxSignificantDigits -= 1;
    }

    // Adds commas to thousands & adjust to desired sig figs.
    formattedValue = bnValue.toNumber().toLocaleString("en-US", {
      minimumSignificantDigits: minSignificantDigits,
      maximumSignificantDigits: maxSignificantDigits,
    });

    // Check if the number is small enough to add a subscript num (ex: 0.0₃483).
    if (
      zerosTilSubscript &&
      bnValue.isPositive() &&
      bnValue.isLessThan(0.1 / Math.pow(10, zerosTilSubscript - 1)) &&
      !bnValue.isZero()
    ) {
      formattedValue = addSubscriptZeros(formattedValue);
    } else if (
      delimitingSpaces &&
      bnValue.isLessThan(0.001) // Prevent spaces for nums w/ trailing zeros: 1.000 00
    ) {
      formattedValue = addDelimitingSpaces(formattedValue);
    }
  }

  if (bnValue.eq(0)) formattedValue = "0";

  return (over999T ? ">" : "") + (dollars ? "$" : "") + formattedValue;
};

/*
 * Default to show first 6 and last 4 digits of address
 * e.g. 0x7C28…feEc
 */
const getAbbreviatedAddress = (
  address: string,
  start = 0,
  stop = 6,
  end = -4
): string => {
  if (!address) return "";
  return `${address.slice(start, stop)}…${address.slice(end)}`;
};

const getFormattedPercent = (value: number): string => {
  return `${formatDisplayNumber({
    value: value,
    abbreviate: true,
    zerosTilSubscript: 3,
    sigFigs: 3,
  })}%`;
};

export { formatDisplayNumber, getAbbreviatedAddress, getFormattedPercent };
