import { css } from "@emotion/react";
import styled from "@emotion/styled";
import * as RadixDialog from "@radix-ui/react-dialog";
import React from "react";
import { IoIosClose } from "react-icons/io";

import { breakpoints } from "../../styles/Layout";
import { borderRadius } from "../../styles/Mixins";
import { TextButton } from "../inputs/Button";

const maxDialogWidth = 1000;
export interface DialogProps {
  title?: string;
  description?: string;
  open?: boolean;
  handleOpenChange?: (open: boolean) => void;
  children?: React.ReactNode;
}

const DialogOverlay = styled(RadixDialog.Overlay)(
  ({ theme }) => css`
    background-color: ${theme.colors.black.blackA9};
    position: fixed;
    inset: 0;
  `
);

const DialogContent = styled(RadixDialog.Content)(
  ({ theme }) => css`
    background-color: ${theme.colors.gray.gray1};
    left: 50%;
    max-height: 85vh;
    max-width: ${maxDialogWidth}px;
    overflow-y: auto;
    padding: ${theme.spacing(4)};
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;

    ${borderRadius}
    &:focus {
      outline: none;
    }

    @media (max-width: ${breakpoints.md}px) {
      padding: ${theme.spacing(2)};
      width: 100vw;
    }
  `
);

const DialogTitle = styled(RadixDialog.Title)(
  ({ theme }) => css`
    margin: 0;
    font-weight: 500;
    color: ${theme.colors.gray.gray12};
    font-size: 17px;
  `
);

const CloseButton = styled(RadixDialog.Close)(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.spacing(2)};
    right: ${theme.spacing(2)};
    font-size: 22px;
    &:hover {
      background-color: ${theme.colors.gray.gray4};
    }
  `
);

const DialogDescription = styled(RadixDialog.Description)(
  ({ theme }) => css`
    margin: 10px 0 20px;
    color: ${theme.colors.secondary};
    font-size: 15px;
    line-height: 1.5;
  `
);

const Dialog: React.FC<DialogProps> = ({
  title,
  description,
  open,
  handleOpenChange,
  children,
}) => {
  return (
    <RadixDialog.Root open={open} onOpenChange={handleOpenChange}>
      <RadixDialog.Portal>
        <DialogOverlay />
        <DialogContent>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
          {children}
          <CloseButton asChild>
            <TextButton aria-label="Close">
              <IoIosClose />
            </TextButton>
          </CloseButton>
        </DialogContent>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
};

export { Dialog };
