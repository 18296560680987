import { css } from "@emotion/react";
import styled from "@emotion/styled";
import * as AvatarPrimitive from "@radix-ui/react-avatar";
import React from "react";

import { borderRadius } from "../../styles/Mixins";
import { withTransientProps } from "../../utils/styles";

export interface NftAssetImageProps {
  src?: string;
  sizeInPx?: number;
}

const PIXELATE_UNDER_PX_THRESHOLD = 100;

const AvatarRoot = styled(
  AvatarPrimitive.Root,
  withTransientProps
)<{ $sizeInPx: number; $pixelated: boolean }>(
  ({ theme, $sizeInPx, $pixelated }) => css`
    background-color: ${theme.colors.secondary}11;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    overflow: hidden;
    user-select: none;
    width: ${$sizeInPx}px;
    height: ${$sizeInPx}px;
    image-rendering: ${$pixelated ? "pixelated" : "unset"};
    object-fit: contain;
    ${borderRadius}
  `
);

const AvatarImage = styled(AvatarPrimitive.Image)`
  aspect-ratio: 1 / 1;
  border-radius: inherit;
  object-fit: cover;
  width: 100%;
`;

const AvatarFallback = styled(AvatarPrimitive.Fallback)`
  align-items: center;
  display: flex;
  font-size: 15px;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  line-height: 1;
  width: 100%;
`;

const NftAssetImage: React.FC<NftAssetImageProps> = ({
  src,
  sizeInPx = 20,
}) => {
  const image = new Image();
  let pixelated = false;

  if (src) {
    image.src = src;
    pixelated = image.naturalWidth < PIXELATE_UNDER_PX_THRESHOLD;
  }

  return (
    <AvatarRoot $sizeInPx={sizeInPx} $pixelated={pixelated}>
      <AvatarImage src={src} />
      <AvatarFallback>{name?.[0]}</AvatarFallback>
    </AvatarRoot>
  );
};

export { NftAssetImage };
