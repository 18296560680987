import styled from "@emotion/styled";
import React from "react";

import { ellipsis, monospace } from "../../styles/Mixins";
import { formatDisplayNumber } from "../../utils/format";

export interface FormatDisplayNumberProps {
  abbreviate?: boolean;
  asInteger?: boolean;
  delimitingSpaces?: boolean;
  dollars?: boolean;
  sigFigs?: number;
  value?: string | number | null;
  zerosTilSubscript?: number;
}

const Number = styled("p")`
  ${monospace}
  ${ellipsis}
`;

const FormattedNumber: React.FC<
  React.HTMLAttributes<HTMLParagraphElement> & FormatDisplayNumberProps
> = ({
  abbreviate,
  asInteger,
  delimitingSpaces,
  dollars,
  sigFigs,
  value,
  zerosTilSubscript,
  ...rest
}) => {
  return (
    <Number {...rest}>
      {formatDisplayNumber({
        abbreviate: abbreviate,
        asInteger: asInteger,
        delimitingSpaces: delimitingSpaces,
        dollars: dollars,
        sigFigs: sigFigs,
        value: value,
        zerosTilSubscript,
      })}
    </Number>
  );
};

export { FormattedNumber };
