import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { borderRadius } from "../../styles/Mixins";

const TextButton = styled("button")(
  ({ theme }) => css`
    align-items: center;
    cursor: pointer;
    color: ${theme.colors.gray.gray12};
    display: flex;
    gap: ${theme.spacing(0.75)};
    font-size: ${theme.fontSize.body2};
    justify-content: center;
    min-height: 34px;
    min-width: 34px;
    padding: ${theme.spacing(0.75)} ${theme.spacing(1)};
    user-select: none;
    transition: all 0.1s;
    ${borderRadius}
  `
);

const Button = styled(TextButton)(
  ({ theme }) => css`
    background-color: ${theme.colors.secondary}44;
    color: ${theme.colors.secondaryAlt};
    &:hover {
      background-color: ${theme.colors.secondary}55;
    }
    &:active {
      background-color: ${theme.colors.secondary}77;
    }
  `
);

const PrimaryButton = styled(TextButton)(
  ({ theme }) => css`
    background-color: ${theme.colors.primary}44;
    color: ${theme.colors.gray.gray12};
    &:hover {
      background-color: ${theme.colors.primary}55;
    }
    &:active {
      background-color: ${theme.colors.primary}77;
    }
  `
);

const DisabledButton = styled(TextButton)(
  ({ theme }) => css`
    background-color: ${theme.colors.secondary}22;
    color: ${theme.colors.secondaryAlt}66;
    cursor: default;
  `
);

export { Button, PrimaryButton, TextButton, DisabledButton };
