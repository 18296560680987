import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Command } from "cmdk";
import React from "react";
import toast from "react-hot-toast";
import { BiMinus, BiPlus } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";

import {
  HistoryActionType,
  HistoryContext,
} from "../../../context/HistoryContext";
import { SettingsContext } from "../../../context/SettingsContext";
import { borderRadius, monospace } from "../../../styles/Mixins";
import { FlexCentered, FlexCenteredGap } from "../../basic/Flex";
import { Button } from "../../inputs/Button";
import { GroupHeadingWithIcon } from "../Search";

const SettingItem = styled("div")`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

const SettingNumber = styled(FlexCentered)(
  ({ theme }) => css`
    background: ${theme.colors.black.blackA8};
    padding: ${theme.spacing(0.5)} ${theme.spacing(1.5)};
    font-size: ${theme.fontSize.subtitle2};
    ${monospace}
    ${borderRadius}
  `
);

const SettingsPage: React.FC = () => {
  const {
    settingsState: { numRecentlyViewedTokens },
    setSettingsState,
  } = React.useContext(SettingsContext);
  const { dispatch: historyDispatch } = React.useContext(HistoryContext);

  const lowerRecentlyViewed = React.useCallback(() => {
    if (numRecentlyViewedTokens <= 0) return;
    setSettingsState((state) => {
      return {
        ...state,
        numRecentlyViewedTokens: numRecentlyViewedTokens - 1,
      };
    });
  }, [numRecentlyViewedTokens, setSettingsState]);

  const increaseRecentlyViewed = React.useCallback(() => {
    setSettingsState((state) => {
      return {
        ...state,
        numRecentlyViewedTokens: numRecentlyViewedTokens + 1,
      };
    });
  }, [numRecentlyViewedTokens, setSettingsState]);

  return (
    <Command.Group
      heading={
        <GroupHeadingWithIcon>
          <FiSettings />
          Settings
        </GroupHeadingWithIcon>
      }
    >
      <Command.Item value="numRecentlyViewedTokens">
        <SettingItem>
          Recently viewed tokens / NFTs
          <FlexCenteredGap>
            <Button
              onClick={lowerRecentlyViewed}
              disabled={numRecentlyViewedTokens === 0}
              style={{ opacity: numRecentlyViewedTokens === 0 ? 0.3 : 1 }}
            >
              <BiMinus />
            </Button>
            <SettingNumber>{numRecentlyViewedTokens}</SettingNumber>
            <Button onClick={increaseRecentlyViewed}>
              <BiPlus />
            </Button>
          </FlexCenteredGap>
        </SettingItem>
      </Command.Item>
      <Command.Item
        value="clearRecentlyViewed"
        onSelect={() => {
          toast.success("Cleared recently viewed");
          historyDispatch({
            type: HistoryActionType.ResetRecent,
          });
        }}
      >
        Clear recently viewed tokens / NFTs
      </Command.Item>
    </Command.Group>
  );
};

export { SettingsPage };
