import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { linearGradientDef } from "@nivo/core";
import { ResponsiveLine } from "@nivo/line";
import React from "react";

import { borderRadius, monospace } from "../../styles/Mixins";
import { nivoDarkTheme, nivoLightTheme } from "../../styles/Theme";
import { formatDisplayNumber } from "../../utils/format";
import { withTransientProps } from "../../utils/styles";
import { LoadingIndicator } from "../loading/LoadingIndicator";

export type TimeChartPoint = {
  x: string;
  y: number | null;
};

export interface TimeChartProps {
  chartId: string;
  chartPoints?: TimeChartPoint[];
  heightInPx: number;
  loading: boolean;
}

export interface TimeChartTooltipProps {
  amount: string | number;
  dateTime: string | number;
}

const ChartLoadingContainer = styled(
  "div",
  withTransientProps
)<{ $borderColor: string }>(
  ({ $borderColor }) => css`
    align-items: center;
    animation: ChartLoadingContainer-fadeIn 0.5s ease-in-out;
    border-bottom: 0.5px ${$borderColor} solid;
    border-left: 0.5px ${$borderColor} solid;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    margin: 16px 16px 32px 80px;
    position: absolute;
    right: 0;
    top: 0;

    @keyframes ChartLoadingContainer-fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  `
);

const ChartContainer = styled(
  "div",
  withTransientProps
)<{ $heightInPx: number }>(
  ({ $heightInPx }) => css`
    display: flex;
    height: ${$heightInPx}px;
    position: relative;
  `
);

const ChartTooltipContainer = styled("div")(
  ({ theme }) => css`
    align-items: center;
    background-color: var(--gray2);
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing(1)} ${theme.spacing(0.75)};
    ${monospace}
    ${borderRadius}
  `
);

const TimeChartTooltip: React.FC<TimeChartTooltipProps> = ({
  amount,
  dateTime,
}) => {
  const formattedValue = formatDisplayNumber({
    dollars: true,
    value: amount,
    sigFigs: 6,
  });

  return (
    <ChartTooltipContainer>
      {formattedValue}
      <div style={{ opacity: 0.7, fontSize: "0.8em" }}>{dateTime}</div>
    </ChartTooltipContainer>
  );
};

const TimeChart: React.FC<TimeChartProps> = ({
  chartId,
  chartPoints,
  heightInPx,
  loading,
}) => {
  const theme = useTheme();

  return (
    <ChartContainer $heightInPx={heightInPx}>
      {loading ? (
        <ChartLoadingContainer
          $borderColor={
            theme.mode === "dark"
              ? nivoDarkTheme.axis.domain.line.stroke
              : nivoLightTheme.axis.domain.line.stroke
          }
        >
          <LoadingIndicator />
        </ChartLoadingContainer>
      ) : (
        <ResponsiveLine
          margin={{ top: 16, right: 16, bottom: 32, left: 80 }} // same margin as ChartLoadingContainer
          animate
          sliceTooltip={({ slice }) => {
            return (
              <TimeChartTooltip
                amount={slice.points[0].data.yFormatted}
                dateTime={slice.points[0].data.xFormatted}
              />
            );
          }}
          data={[
            {
              id: chartId,
              data: chartPoints || [],
            },
          ]}
          xScale={{
            type: "time",
            format: "%Y-%m-%dT%H:%M:%S.%LZ",
            useUTC: false,
            precision: "minute",
          }}
          xFormat="time:%Y-%m-%d %H:%M"
          yScale={{
            type: "linear",
            stacked: false,
            min: "auto",
            nice: true,
          }}
          axisLeft={{
            format: (value) =>
              formatDisplayNumber({
                dollars: true,
                value: value,
              }),
            tickValues: Math.ceil(heightInPx / 50),
          }}
          axisBottom={{
            format: "%H:%M",
            tickValues: Math.ceil(heightInPx / 100),
          }}
          curve="monotoneX"
          pointSize={6}
          colors={[theme.colors.primary]}
          theme={theme.mode === "dark" ? nivoDarkTheme : nivoLightTheme}
          enableSlices="x"
          motionConfig="stiff"
          defs={[
            linearGradientDef("timeChartGradient", [
              { offset: 0, color: "inherit", opacity: 0.5 },
              { offset: 100, color: "inherit", opacity: 0 },
            ]),
          ]}
          fill={[{ match: "*", id: "timeChartGradient" }]}
          enableArea={true}
          areaBaselineValue={
            chartPoints &&
            Math.min(
              ...(chartPoints
                .filter((p) => p && p.y !== null && p.y !== undefined)
                .map((p) => p.y) as number[])
            )
          }
        />
      )}
    </ChartContainer>
  );
};

export { TimeChart };
