import React, { createContext, useEffect, useReducer } from "react";

import { TokenWithTopPair } from "../components/search/Search";
import { NftSearchResponseCollection } from "../generated/graphql";
import { restoreState, setItem } from "../utils/storage";

enum HistoryActionType {
  AddRecentToken = "ADD_RECENT_TOKEN",
  AddRecentNftCollection = "ADD_RECENT_NFT_COLLECTION",
  RemoveRecentToken = "REMOVE_RECENT_TOKEN",
  RemoveRecentNftCollection = "REMOVE_RECENT_NFT_COLLECTION",
  ResetRecent = "RESET_RECENT",
}

type HistoryAction =
  | { type: HistoryActionType.AddRecentToken; token: TokenWithTopPair }
  | {
      type: HistoryActionType.AddRecentNftCollection;
      collection: NftSearchResponseCollection;
    }
  | { type: HistoryActionType.RemoveRecentToken; token: TokenWithTopPair }
  | {
      type: HistoryActionType.RemoveRecentNftCollection;
      collection: NftSearchResponseCollection;
    }
  | { type: HistoryActionType.ResetRecent };

interface HistoryState {
  recentTokenPairs: TokenWithTopPair[];
  recentNftCollections: NftSearchResponseCollection[];
}

type HistoryContext = {
  state: HistoryState;
  dispatch: React.Dispatch<HistoryAction>;
};

const HISTORY_STORAGE_KEY = "history";

// Where we just don't store them anymore
const MAX_RECENT_TOKENS = 50;
const MAX_RECENT_NFTS = 50;

const DEFAULT_STATE: HistoryState = {
  recentTokenPairs: [],
  recentNftCollections: [],
};

const reducer: React.Reducer<HistoryState, HistoryAction> = (state, action) => {
  switch (action.type) {
    case HistoryActionType.AddRecentToken:
      const newRecentTokenPairs = state.recentTokenPairs.filter(
        (token) => token.id !== action.token.id
      );
      newRecentTokenPairs.unshift(action.token);
      return {
        ...state,
        recentTokenPairs: newRecentTokenPairs.slice(0, MAX_RECENT_TOKENS),
      };
    case HistoryActionType.AddRecentNftCollection:
      const newRecentNftCollections = state.recentNftCollections.filter(
        (collection) => collection.id !== action.collection.id
      );
      newRecentNftCollections.unshift(action.collection);
      return {
        ...state,
        recentNftCollections: newRecentNftCollections.slice(0, MAX_RECENT_NFTS),
      };
    case HistoryActionType.RemoveRecentToken:
      return {
        ...state,
        recentTokenPairs: state.recentTokenPairs.filter(
          (token) => token.id !== action.token.id
        ),
      };
    case HistoryActionType.RemoveRecentNftCollection:
      return {
        ...state,
        recentNftCollections: state.recentNftCollections.filter(
          (collection) => collection.id !== action.collection.id
        ),
      };
    case HistoryActionType.ResetRecent:
      return {
        ...state,
        recentTokenPairs: [],
        recentNftCollections: [],
      };
    default:
      return DEFAULT_STATE;
  }
};

const HistoryContext = createContext<HistoryContext>({} as HistoryContext);

const HistoryContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(
    reducer,
    restoreState<HistoryState>({
      key: HISTORY_STORAGE_KEY,
      defaults: DEFAULT_STATE,
    })
  );

  const historyContext = {
    state,
    dispatch,
  };

  useEffect(() => {
    setItem(HISTORY_STORAGE_KEY, {
      recentTokenPairs: state.recentTokenPairs,
      recentNftCollections: state.recentNftCollections,
    });
  }, [state.recentTokenPairs, state.recentNftCollections]);

  return (
    <HistoryContext.Provider value={historyContext}>
      {children}
    </HistoryContext.Provider>
  );
};

export { HistoryContext, HistoryContextProvider, HistoryActionType };
