enum Duration {
  Sec1 = "sec1",
  Sec5 = "sec5",
  Sec15 = "sec15",
  Min1 = "min1",
  Min5 = "min5",
  Min15 = "min15",
  Min30 = "min30",
  Hour1 = "hour1",
  Hour4 = "hour4",
  Hour12 = "hour12",
  Day1 = "day1",
  Day30 = "day30",
  Day90 = "day90",
  Week1 = "week1",
}

export { Duration };
