import React from "react";
import toast from "react-hot-toast";
import { FaSortAmountDown, FaSortAmountDownAlt } from "react-icons/fa";

import {
  TokenFilterHasWindow,
  TokenFilterLabel,
  TokenFilterLabelShort,
  TokenFilterNoWindow,
} from "../../../constants/TokenFilters";
import {
  FiltersActionType,
  FiltersContext,
} from "../../../context/FiltersContext";
import { RankingDirection } from "../../../generated/graphql";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { breakpoints, lessThan } from "../../../styles/Layout";
import { Duration } from "../../../types/Duration";
import { FlexCenteredGap } from "../../basic/Flex";
import { DurationSelect } from "../../durationSelect/DurationSelect";
import { Select, SelectOption } from "../../inputs/Select";
import { ToggleGroupItem, ToggleGroupRoot } from "../../inputs/ToggleGroup";
import { ResetFilterButton } from "./FilterInputs";

const DEFAULT_RANKING_BY = TokenFilterNoWindow.Volume;
const DEFAULT_RANKING_WINDOW = Duration.Day1;
const DEFAULT_RANKING_DIRECTION = RankingDirection.Desc;

const RankingFilter: React.FC = () => {
  const lessThanMd = useMediaQuery(lessThan(breakpoints.md));

  const { state: filtersState, dispatch: filtersDispatch } =
    React.useContext(FiltersContext);

  const handleChangeRankingBy = React.useCallback(
    (newAttribute: TokenFilterNoWindow) => {
      filtersDispatch({
        type: FiltersActionType.UpdateTokenRankingBy,
        attribute: newAttribute as TokenFilterNoWindow,
      });
    },
    [filtersDispatch]
  );

  const handleChangeRankingWindow = React.useCallback(
    (newWindow: Duration) => {
      filtersDispatch({
        type: FiltersActionType.UpdateTokenRankingWindow,
        window: newWindow,
      });
    },
    [filtersDispatch]
  );

  const handleChangeRankingDirection = React.useCallback(
    (newDirection: RankingDirection) => {
      filtersDispatch({
        type: FiltersActionType.UpdateTokenRankingDirection,
        direction: newDirection,
      });
    },
    [filtersDispatch]
  );

  const handleSetToDefault = () => {
    filtersDispatch({
      type: FiltersActionType.UpdateTokenRankingBy,
      attribute: DEFAULT_RANKING_BY,
    });
    filtersDispatch({
      type: FiltersActionType.UpdateTokenRankingWindow,
      window: DEFAULT_RANKING_WINDOW,
    });
    filtersDispatch({
      type: FiltersActionType.UpdateTokenRankingDirection,
      direction: DEFAULT_RANKING_DIRECTION,
    });
    toast.success(`Set token ranking to default`, {
      id: "filter-update",
    });
  };

  return (
    <FlexCenteredGap>
      <Select
        value={filtersState.tokenRankingBy}
        onChange={handleChangeRankingBy}
        {...{
          name: "token-ranking-by",
          style: { width: lessThanMd ? "100px" : "160px" },
        }}
      >
        {Object.values(TokenFilterNoWindow).map((filter) => {
          return (
            <SelectOption
              key={filter}
              value={filter}
              text={
                lessThanMd
                  ? TokenFilterLabelShort[filter]
                  : TokenFilterLabel[filter]
              }
            />
          );
        })}
      </Select>
      {TokenFilterHasWindow[filtersState.tokenRankingBy] && (
        <DurationSelect
          value={filtersState.tokenRankingWindow}
          handleChange={handleChangeRankingWindow}
          style={{ width: "100%", maxWidth: "60px" }}
        />
      )}

      <ToggleGroupRoot
        type="single"
        value={filtersState.tokenRankingDirection}
        onValueChange={(newValue) => {
          if (!newValue) return;
          handleChangeRankingDirection(newValue as RankingDirection);
        }}
      >
        <ToggleGroupItem value={RankingDirection.Desc}>
          <FaSortAmountDown /> {!lessThanMd && "Desc"}
        </ToggleGroupItem>
        <ToggleGroupItem value={RankingDirection.Asc}>
          <FaSortAmountDownAlt /> {!lessThanMd && "Asc"}
        </ToggleGroupItem>
      </ToggleGroupRoot>
      <ResetFilterButton
        isDefault={
          filtersState.tokenRankingBy === DEFAULT_RANKING_BY &&
          filtersState.tokenRankingWindow === DEFAULT_RANKING_WINDOW &&
          filtersState.tokenRankingDirection === DEFAULT_RANKING_DIRECTION
        }
        setToDefault={handleSetToDefault}
      />
    </FlexCenteredGap>
  );
};

export { RankingFilter };
