import { Command } from "cmdk";
import React from "react";
import toast from "react-hot-toast";
import { BiReset } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";
import { IoMdCheckmark } from "react-icons/io";

import { PrimaryButton } from "../../inputs/Button";
import { KeyboardShortcut } from "../../inputs/KeyboardShortcut";
import { GroupHeadingWithIcon } from "../Search";
// import { WindowMinMaxFilter } from "./FilterInputs";

export interface NftFiltersPageProps {
  handleCloseFilters: () => void;
}

// const filters = {
//   change: {
//     label: "Price Change (%)",
//     inputs: WindowMinMaxFilter,
//   },
//   ceiling: {
//     label: "Ceiling (USD $)",
//     inputs: WindowMinMaxFilter,
//   },
//   avgTrade: {
//     label: "Average Trade (USD $)",
//     inputs: WindowMinMaxFilter,
//   },
//   salesFloor: {
//     label: "Sales Floor (USD $)",
//     inputs: WindowMinMaxFilter,
//   },
//   listingFloor: {
//     label: "Listing Floor (USD $)",
//     inputs: WindowMinMaxFilter,
//   },
//   buyCount: {
//     label: "Buy Count",
//     inputs: WindowMinMaxFilter,
//   },
//   sellCount: {
//     label: "Sell Count",
//     inputs: WindowMinMaxFilter,
//   },
//   txnCount: {
//     label: "Transaction Count",
//     inputs: WindowMinMaxFilter,
//   },
//   volume: {
//     label: "Volume",
//     inputs: WindowMinMaxFilter,
//   },
// };

const NftFiltersPage: React.FC<NftFiltersPageProps> = ({
  handleCloseFilters,
}) => {
  const handleApplyFilters = React.useCallback(() => {
    toast.success("Applied filters");
    handleCloseFilters();
  }, [handleCloseFilters]);

  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        e.preventDefault();

        handleCloseFilters();
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [handleCloseFilters]);

  return (
    <Command.Group
      heading={
        <GroupHeadingWithIcon>
          <FiSettings />
          NFT Filters
          <PrimaryButton
            onClick={handleApplyFilters}
            style={{ marginLeft: "auto" }}
          >
            <IoMdCheckmark style={{ height: 16 }} />
            Apply filters
            <KeyboardShortcut shortcut="↵" />
          </PrimaryButton>
        </GroupHeadingWithIcon>
      }
    >
      <>
        <Command.Item value="coming-soon">Coming soon</Command.Item>
        {/* {Object.entries(filters).map(([filterKey, filterValues]) => (
          <Command.Item key={filterKey} value={filterValues.label}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flex: "1",
                alignItems: "center",
              }}
            >
              <div>{filterValues.label}</div>
              <div>
                {React.createElement(filterValues.inputs, {
                  name: filterKey,
                })}
              </div>
            </div>
          </Command.Item>
        ))} */}
        <Command.Item
          value="reset-nft-filter"
          onSelect={() => toast.success("Reset NFT filters to default")}
          disabled
        >
          <BiReset /> Reset NFT filters to default
        </Command.Item>
      </>
    </Command.Group>
  );
};

export { NftFiltersPage };
