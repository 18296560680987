import { css } from "@emotion/react/macro";
import styled from "@emotion/styled";
import { Command } from "cmdk";

const StyledGroup = styled(Command.Group)(({}) => css``);

const StyledCommand = styled(Command)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: ${theme.mode === "dark"
      ? theme.colors.black.blackA8
      : theme.colors.white.whiteA8};
    border-radius: 12px;
    font-family: var(--font-sans);
    box-shadow: var(--cmdk-shadow);
    border: 1px solid var(--gray6);
    position: relative;
    overflow: hidden;

    [cmdk-item] {
      &[aria-selected="true"] {
        background: ${theme.colors.secondary}44;
        color: ${theme.colors.secondaryAlt};
      }
    }
  `
);

const StyledCommandList = styled(Command.List)(
  ({ theme }) => css`
    scrollbar-color: ${theme.colors.secondary}88 ${theme.colors.gray.gray3};
    scrollbar-width: thin;

    // Until Chrome supports the above standardized scrollbar attributes
    ::-webkit-scrollbar {
      height: 6px;
      width: 6px;
      background: inherit;
    }

    ::-webkit-scrollbar-thumb {
      background: ${theme.colors.secondary}88;

      -webkit-border-radius: 1ex;
      -webkit-box-shadow: 0px 1px 2px ${theme.colors.gray.gray3};
    }

    ::-webkit-scrollbar-corner {
      background: inherit;
    }
  `
);

export { StyledGroup, StyledCommand, StyledCommandList };
