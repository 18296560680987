import coinscan from "../assets/icons/tokenLinks/coinscan.png";
import defined from "../assets/icons/tokenLinks/defined.png";
import dexscreener from "../assets/icons/tokenLinks/dexscreener.png";
import dextools from "../assets/icons/tokenLinks/dextools.png";
import geckoterminal from "../assets/icons/tokenLinks/geckoterminal.png";
import { TokenLink } from "../types/TokenLink";
import {
  NetworkCoinScanName,
  NetworkDexScreenerName,
  NetworkDexToolsName,
  NetworkGeckoTerminalName,
  NetworkShortName,
} from "./Network";

const chartLinks: TokenLink[] = [
  {
    name: "Defined",
    url: "https://www.defined.fi/${networkShortName}/${pairAddress}?quoteToken=${quoteToken}",
    color: "#58eec3",
    icon: defined,
    networkNameMap: NetworkShortName,
  },
  {
    name: "CoinScan",
    url: "https://www.coinscan.com/tokens/${networkShortName}/${tokenAddress}",
    color: "#f5b100",
    icon: coinscan,
    networkNameMap: NetworkCoinScanName,
  },
  {
    name: "DEX Screener",
    url: "https://dexscreener.com/${networkShortName}/${tokenAddress}",
    color: "#6f83de",
    icon: dexscreener,
    networkNameMap: NetworkDexScreenerName,
  },
  {
    name: "DEXTools",
    url: "https://www.dextools.io/app/en/${networkShortName}/pair-explorer/${tokenAddress}",
    color: "#05b3d2",
    icon: dextools,
    networkNameMap: NetworkDexToolsName,
  },
  {
    name: "GeckoTerminal",
    url: "https://www.geckoterminal.com/${networkShortName}/pools/${tokenAddress}",
    color: "#320670",
    icon: geckoterminal,
    networkNameMap: NetworkGeckoTerminalName,
  },
];

export { chartLinks };
