import React, { createContext, useEffect, useState } from "react";

import { restoreState, setItem } from "../utils/storage";

export interface SettingsState {
  numRecentlyViewedTokens: number;
  showTrendingTokens: boolean;
  darkMode: boolean;
}

type SettingsContext = {
  settingsState: SettingsState;
  setSettingsState: React.Dispatch<React.SetStateAction<SettingsState>>;
};

const SETTINGS_STORAGE_KEY = "settings";

const SEARCH_DEFAULT_STATE: SettingsState = {
  numRecentlyViewedTokens: 3,
  showTrendingTokens: true,
  darkMode: true,
};

const SettingsContext = createContext<SettingsContext>({} as SettingsContext);

const SettingsContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [settingsState, setSettingsState] = useState<SettingsState>(
    restoreState<SettingsState>({
      key: SETTINGS_STORAGE_KEY,
      defaults: SEARCH_DEFAULT_STATE,
    })
  );

  const settingsContext = {
    settingsState,
    setSettingsState,
  };

  useEffect(() => {
    setItem(SETTINGS_STORAGE_KEY, settingsState);
  }, [settingsState]);

  return (
    <SettingsContext.Provider value={settingsContext}>
      {children}
    </SettingsContext.Provider>
  );
};

export { SettingsContext, SettingsContextProvider, SEARCH_DEFAULT_STATE };
