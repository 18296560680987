import "./Search.scss";

import { useTheme } from "@emotion/react/macro";
import styled from "@emotion/styled";
import { Command } from "cmdk";
import React from "react";
import toast from "react-hot-toast";
import { BsImages } from "react-icons/bs";
import { FaRegGem } from "react-icons/fa";
import { RiEyeLine } from "react-icons/ri";

import {
  DurationToFilterSuffix,
  DurationToNftAnalyticWindow,
} from "../../constants/Duration";
import {
  TokenFilterHasWindow,
  TokenFilterNoWindow,
} from "../../constants/TokenFilters";
import { FiltersContext, SearchCategory } from "../../context/FiltersContext";
import {
  HistoryActionType,
  HistoryContext,
} from "../../context/HistoryContext";
import { SettingsContext } from "../../context/SettingsContext";
import {
  NftSearchResponseCollection,
  NumberFilter,
  Token,
  TokenFilterResult,
  TokenRankingAttribute,
  useFilterTokensLazyQuery,
  useSearchNftsLazyQuery,
} from "../../generated/graphql";
import { useDebounce } from "../../hooks/useDebounce";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { breakpoints, lessThan } from "../../styles/Layout";
import { FlexCenteredGap } from "../basic/Flex";
import { FilterBar } from "./filters/FilterBar";
import { NftFiltersPage } from "./filters/NftFiltersPage";
import { TokenFiltersPage } from "./filters/TokenFiltersPage";
import {
  NftCollectionPage,
  SearchNftSelectOptions,
} from "./nft/NftCollectionPage";
import { NftSearchItem, RecentNftCollection } from "./nft/NftSearchItem";
import { SearchInput } from "./SearchInput";
import { SettingsPage } from "./settings/SettingsPage";
import { StyledCommand, StyledCommandList, StyledGroup } from "./StyledCommand";
import { TokenPage } from "./token/TokenPage";
import { RecentToken, TokenSearchItemResult } from "./token/TokenSearchItem";

export interface SearchProps {
  open?: boolean;
  setOpen?: (open: boolean) => void;
  onNftCollectionSelect?: (
    nftCollectionSelectOptions: SearchNftSelectOptions
  ) => void;
  onNftAssetSelect?: (nftAssetSelectOptions: SearchNftSelectOptions) => void;
}

export type TokenWithTopPair = Token & {
  topPairAddress?: string | undefined;
  quoteToken?: string | undefined;
};

export enum SearchPage {
  Nft = "NFT",
  Token = "Token",
  TokenFilters = "TokenFilters",
  NftFilters = "NftFilters",
  Setting = "Setting",
}

const searchPageAttributes: Record<
  SearchPage,
  {
    searchPlaceholder: string;
    filteringInPlace?: boolean;
    disableBackspace?: boolean;
  }
> = {
  [SearchPage.Nft]: {
    searchPlaceholder: "Search for NFT IDs, names or traits",
  },
  [SearchPage.Token]: {
    searchPlaceholder: "Search for a service",
    filteringInPlace: true,
  },
  [SearchPage.TokenFilters]: {
    searchPlaceholder: "Search filters",
    filteringInPlace: true,
    disableBackspace: true,
  },
  [SearchPage.NftFilters]: {
    searchPlaceholder: "Search filters",
    filteringInPlace: true,
    disableBackspace: true,
  },
  [SearchPage.Setting]: {
    searchPlaceholder: "Search for a setting",
    filteringInPlace: true,
  },
};

const GroupHeadingWithIcon = styled(FlexCenteredGap)`
  flex: 1;
  svg {
    height: 20px;
    width: 20px;
  }
`;

const Search: React.FC<SearchProps> = ({
  open = true,
  setOpen,
  onNftCollectionSelect,
  onNftAssetSelect,
}) => {
  const theme = useTheme();
  const lessThanMd = useMediaQuery(lessThan(breakpoints.md));

  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const listRef = React.useRef(null);

  const [stashedValue, setStashedValue] = React.useState("");
  const [value, setValue] = React.useState("");
  const [searchText, setSearchText] = React.useState("");
  const debouncedSearchText = useDebounce(searchText.trim().toLowerCase(), 300);

  const [page, setPage] = React.useState<SearchPage | null>();
  const [searchTextForPages, setSearchTextForPages] = React.useState("");
  const debouncedSearchTextForPages = useDebounce(
    searchTextForPages.trim(),
    300
  );
  const [valueForPages, setValueForPages] = React.useState("");

  const [selectedToken, setSelectedToken] =
    React.useState<TokenWithTopPair | null>(null);
  const [selectedCollection, setSelectedCollection] =
    React.useState<NftSearchResponseCollection | null>(null);

  const [searchInGridMode, setSearchInGridMode] = React.useState(false);

  const {
    settingsState: { numRecentlyViewedTokens },
  } = React.useContext(SettingsContext);
  const {
    state: { recentTokenPairs: recentTokens, recentNftCollections },
    dispatch: historyDispatch,
  } = React.useContext(HistoryContext);
  const { state: filtersState } = React.useContext(FiltersContext);
  const [filterTokenResults, setFilterTokenResults] = React.useState<
    TokenFilterResult[] | null
  >(null);
  const [nftSearchResults, setNftSearchResults] = React.useState<
    NftSearchResponseCollection[] | null
  >(null);
  const [loading, setLoading] = React.useState(false);
  const searchResultLimit = 16; // Arbitrary so it has a good default on our monitors

  const filteredFilterTokenResults = React.useMemo(
    () => filterTokenResults?.filter((result) => !result.token?.isScam) ?? [],
    [filterTokenResults]
  );

  const rankingAttributeWithWindow = React.useMemo(() => {
    return TokenFilterHasWindow[filtersState.tokenRankingBy]
      ? filtersState.tokenRankingBy +
          DurationToFilterSuffix[filtersState.tokenRankingWindow]
      : filtersState.tokenRankingBy;
  }, [
    filtersState.tokenRankingBy,
    filtersState.tokenRankingWindow,
  ]) as TokenRankingAttribute;

  const tokenFiltersForQuery = React.useMemo(
    () =>
      Object.values(TokenFilterNoWindow).reduce((acc, filter) => {
        const filterName = (
          TokenFilterHasWindow[filter]
            ? filter + DurationToFilterSuffix[filtersState[filter].window]
            : filter
        ) as TokenRankingAttribute;

        // Handle percentage-based filters
        if ([TokenFilterNoWindow.Change].includes(filter)) {
          acc[filterName] = {
            gte: filtersState[filter].min
              ? Number(filtersState[filter].min) / 100
              : undefined,
            lte: filtersState[filter].max
              ? Number(filtersState[filter].max) / 100
              : undefined,
          };
        } else {
          acc[filterName] = {
            gte: filtersState[filter].min
              ? Number(filtersState[filter].min)
              : undefined,
            lte: filtersState[filter].max
              ? Number(filtersState[filter].max)
              : undefined,
          };
        }

        return acc;
      }, {} as Record<TokenRankingAttribute, NumberFilter>),
    [filtersState]
  );

  const [filterTokens] = useFilterTokensLazyQuery({
    onCompleted: (data) => {
      if (!data?.filterTokens?.results) return;

      setFilterTokenResults(data.filterTokens.results as TokenFilterResult[]);
      setLoading(false);
    },
  });

  const [searchNfts] = useSearchNftsLazyQuery({
    onCompleted: (data) => {
      if (!data?.searchNfts?.items) return;

      setNftSearchResults(
        data.searchNfts.items as NftSearchResponseCollection[]
      );
      setLoading(false);
    },
  });

  React.useEffect(() => {
    if (page || filtersState.activeCategory !== SearchCategory.Token) return;

    setLoading(true);

    filterTokens({
      variables: {
        filters: {
          ...tokenFiltersForQuery,
          network: filtersState.visibleNetworks,
        },
        phrase: debouncedSearchText,
        offset: 0,
        rankings: [
          {
            attribute: rankingAttributeWithWindow,
            direction: filtersState.tokenRankingDirection,
          },
        ],
        limit: searchResultLimit,
      },
    });
  }, [
    debouncedSearchText,
    filterTokens,
    filtersState.activeCategory,
    filtersState.tokenRankingDirection,
    filtersState.visibleNetworks,
    page,
    rankingAttributeWithWindow,
    tokenFiltersForQuery,
  ]);

  React.useEffect(() => {
    if (page || filtersState.activeCategory !== SearchCategory.Nft) return;
    setLoading(true);

    searchNfts({
      variables: {
        networkFilter: filtersState.visibleNetworks,
        search: debouncedSearchText,
        limit: searchResultLimit,
        window: DurationToNftAnalyticWindow[filtersState.mainSearchWindow],
      },
    });
  }, [
    debouncedSearchText,
    filtersState.activeCategory,
    filtersState.mainSearchWindow,
    filtersState.visibleNetworks,
    page,
    searchNfts,
  ]);

  // Toggle the menu when a shortcut is pressed
  React.useEffect(() => {
    if (!setOpen) return;

    const down = (e: {
      key: string;
      metaKey: boolean;
      preventDefault: () => void;
    }) => {
      if (e.key === "/" || (e.metaKey && e.key === "k")) {
        if (!open) {
          e.preventDefault();
          setOpen(true);
        } else {
          e.preventDefault();
          inputRef?.current?.focus();
        }
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [open, setOpen]);

  React.useEffect(() => {
    if (lessThanMd) return;

    inputRef?.current?.focus();
  }, [filtersState.activeCategory, lessThanMd, page]);

  const backToMainPage = React.useCallback(() => {
    setPage(null);
    setSearchInGridMode(false);
  }, []);

  const viewToken = React.useCallback(
    (token: TokenWithTopPair) => {
      setSelectedToken(token);
      historyDispatch({
        type: HistoryActionType.AddRecentToken,
        token: token,
      });
      setPage(SearchPage.Token);
    },
    [historyDispatch]
  );

  const viewCollection = React.useCallback(
    (collection: NftSearchResponseCollection) => {
      setSelectedCollection(collection);
      historyDispatch({
        type: HistoryActionType.AddRecentNftCollection,
        collection: collection,
      });
      setPage(SearchPage.Nft);
      setSearchInGridMode(true);
    },
    [historyDispatch]
  );

  const clearSearchText = React.useCallback(() => {
    if (searchTextForPages) {
      setSearchTextForPages("");
    } else if (!page && searchText) {
      setSearchText("");
    }
    inputRef?.current?.focus();
  }, [page, searchText, searchTextForPages]);

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (searchInGridMode && ["ArrowUp", "ArrowDown"].includes(e.key)) {
        e.preventDefault();
      } else if (e.key === "Escape") {
        if ((!page && searchText) || searchTextForPages) {
          e.preventDefault();
          e.stopPropagation();
          clearSearchText();
        } else if (page) {
          e.preventDefault();
          e.stopPropagation();
          backToMainPage();
        }
      } else if (e.key === "Backspace") {
        if (
          page &&
          !searchPageAttributes[page].disableBackspace &&
          !searchTextForPages
        ) {
          e.preventDefault();
          backToMainPage();
          setSearchTextForPages("");
        }
      } else if (e.shiftKey && e.metaKey && e.key === "s") {
        e.preventDefault();
        setPage(SearchPage.Setting);
      } else if (e.shiftKey && e.metaKey && e.key === "f") {
        e.preventDefault();
        setPage(SearchPage.TokenFilters);
      } else if (e.shiftKey && e.metaKey && e.key === "g") {
        e.preventDefault();
        setPage(SearchPage.NftFilters);
      } else if (e.metaKey && e.key === "Enter") {
        e.preventDefault();
        toast.success(`Opened ${value} in new tab`);
      }
    },
    [
      backToMainPage,
      clearSearchText,
      page,
      searchInGridMode,
      searchText,
      searchTextForPages,
      value,
    ]
  );

  // When returning from a page, restore the position of the selected row.
  // TODO: Works fine until search occurs & results are filtered.
  React.useEffect(() => {
    if (page) {
      setStashedValue(value);
    } else if (stashedValue) {
      setValue(stashedValue);
      setStashedValue("");
    }
  }, [value, page, stashedValue]);

  return (
    <div className={`raycast ${theme.mode}`}>
      <StyledCommand
        loop
        value={page ? valueForPages : value}
        onValueChange={(v) => (page ? setValueForPages(v) : setValue(v))}
        onKeyDown={handleKeyDown}
        shouldFilter={
          (page && searchPageAttributes[page].filteringInPlace) ?? false
        }
      >
        <div className="cmdk-raycast-top-shine" />
        <SearchInput
          backToMainPage={backToMainPage}
          clearSearchText={clearSearchText}
          inputRef={inputRef}
          page={page}
          searchText={searchText}
          searchTextForPages={searchTextForPages}
          setSearchText={setSearchText}
          setSearchTextForPages={setSearchTextForPages}
        />
        <hr className="cmdk-raycast-loader" />
        {!page && (
          <FilterBar
            handleTokenFiltersButtonClick={() =>
              setPage(SearchPage.TokenFilters)
            }
            handleNftFiltersButtonClick={() => setPage(SearchPage.NftFilters)}
            handleSettingsButtonClick={() => setPage(SearchPage.Setting)}
          />
        )}
        <StyledCommandList ref={listRef}>
          {!page && (
            <>
              {filtersState.activeCategory === SearchCategory.Token && (
                <>
                  {!searchText &&
                  numRecentlyViewedTokens > 0 &&
                  recentTokens.length ? (
                    <StyledGroup
                      heading={
                        <GroupHeadingWithIcon>
                          <RiEyeLine />
                          Recently viewed
                        </GroupHeadingWithIcon>
                      }
                    >
                      {recentTokens
                        ?.slice(0, numRecentlyViewedTokens)
                        .map((token) => (
                          <RecentToken
                            key={token.id}
                            token={token as TokenWithTopPair}
                            handleSelect={viewToken}
                            window={filtersState.mainSearchWindow}
                          />
                        ))}
                    </StyledGroup>
                  ) : null}
                  {
                    <StyledGroup
                      style={{
                        opacity: loading ? 0.6 : 1,
                        transition: "0.2s all",
                      }}
                      heading={
                        <GroupHeadingWithIcon>
                          <FaRegGem />
                          {searchText ? "Token results" : "Tokens"}
                        </GroupHeadingWithIcon>
                      }
                    >
                      {filteredFilterTokenResults?.map((result) => {
                        const tokenWithTopPair = {
                          ...result.token,
                          topPairAddress: result.pair?.address,
                          quoteToken: result.quoteToken
                            ? result.quoteToken
                            : undefined,
                        };
                        return (
                          <TokenSearchItemResult
                            key={result.token?.id}
                            result={result}
                            handleSelect={() =>
                              viewToken(tokenWithTopPair as TokenWithTopPair)
                            }
                            window={filtersState.mainSearchWindow}
                          />
                        );
                      })}
                    </StyledGroup>
                  }
                </>
              )}

              {filtersState.activeCategory === SearchCategory.Nft && (
                <>
                  {!searchText &&
                  numRecentlyViewedTokens > 0 &&
                  recentNftCollections.length ? (
                    <StyledGroup
                      heading={
                        <GroupHeadingWithIcon>
                          <RiEyeLine />
                          Recently viewed
                        </GroupHeadingWithIcon>
                      }
                    >
                      {recentNftCollections
                        ?.slice(0, numRecentlyViewedTokens)
                        .map((collection) => (
                          <RecentNftCollection
                            key={collection.id}
                            collection={
                              collection as NftSearchResponseCollection
                            }
                            handleSelect={viewCollection}
                          />
                        ))}
                    </StyledGroup>
                  ) : null}
                </>
              )}
            </>
          )}

          {!page && (
            <>
              {filtersState.activeCategory === SearchCategory.Nft && (
                <StyledGroup
                  style={{
                    opacity: loading ? 0.6 : 1,
                    transition: "0.2s all",
                  }}
                  heading={
                    <GroupHeadingWithIcon>
                      <BsImages />
                      {searchText
                        ? "NFT collection results"
                        : "NFT collections"}
                    </GroupHeadingWithIcon>
                  }
                >
                  {nftSearchResults?.map((collection) => (
                    <NftSearchItem
                      key={collection.id}
                      collection={collection as NftSearchResponseCollection}
                      handleSelect={viewCollection}
                    />
                  ))}
                </StyledGroup>
              )}
            </>
          )}

          <Command.Empty>No results found.</Command.Empty>

          {/* Pages */}

          {page === SearchPage.Token && selectedToken && (
            <StyledGroup>
              <TokenPage token={selectedToken} />
            </StyledGroup>
          )}

          {page === SearchPage.Nft && selectedCollection && (
            <StyledGroup>
              <NftCollectionPage
                collection={selectedCollection}
                searchText={debouncedSearchTextForPages}
                setValue={setValueForPages}
                value={valueForPages}
                onNftCollectionSelect={onNftCollectionSelect}
                onNftAssetSelect={onNftAssetSelect}
              />
            </StyledGroup>
          )}

          {page === SearchPage.TokenFilters && (
            <TokenFiltersPage
              handleCloseFilters={() => {
                clearSearchText();
                backToMainPage();
              }}
            />
          )}

          {page === SearchPage.NftFilters && (
            <NftFiltersPage
              handleCloseFilters={() => {
                clearSearchText();
                backToMainPage();
              }}
            />
          )}

          {page === SearchPage.Setting && <SettingsPage />}
        </StyledCommandList>
      </StyledCommand>
    </div>
  );
};

export { Search, GroupHeadingWithIcon, searchPageAttributes };
