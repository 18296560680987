import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import { BsCoin, BsImages } from "react-icons/bs";
import { FiFilter, FiSettings } from "react-icons/fi";

import { DurationFormattedShort } from "../../../constants/Duration";
import {
  FiltersActionType,
  FiltersContext,
  SearchCategory,
} from "../../../context/FiltersContext";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { breakpoints, lessThan } from "../../../styles/Layout";
import { iconSizeSm } from "../../../styles/Mixins";
import { Duration } from "../../../types/Duration";
import { FlexCenteredGap } from "../../basic/Flex";
import { DurationSelect } from "../../durationSelect/DurationSelect";
import { Button, PrimaryButton } from "../../inputs/Button";
import { ToggleGroupItem, ToggleGroupRoot } from "../../inputs/ToggleGroup";

export interface FilterBarProps {
  handleTokenFiltersButtonClick: () => void;
  handleNftFiltersButtonClick: () => void;
  handleSettingsButtonClick: () => void;
}

const windows = [
  Duration.Hour1,
  Duration.Hour4,
  Duration.Hour12,
  Duration.Day1,
];

const FilterBarContainer = styled("div")(
  ({ theme }) => css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(1)};
    min-width: max-content;
    padding: ${theme.spacing(0.5)} ${theme.spacing(1.5)} ${theme.spacing(1)};
    width: 100%;
  `
);

const NumFiltersActiveIndicator = styled("div")(
  ({ theme }) => css`
    background-color: ${theme.colors.primary}44;
    color: ${theme.colors.gray.gray12};
    border-radius: 100%;
    ${iconSizeSm}
  `
);

const FilterBar: React.FC<FilterBarProps> = ({
  handleTokenFiltersButtonClick,
  handleNftFiltersButtonClick,
  handleSettingsButtonClick,
}) => {
  const lessThanMd = useMediaQuery(lessThan(breakpoints.md));
  const {
    state: { activeCategory, mainSearchWindow },
    dispatch: filtersDispatch,
    filtersActive,
    numFiltersActive,
  } = React.useContext(FiltersContext);

  const handleToggleSearchType = React.useCallback(
    (newValue: SearchCategory) => {
      if (!newValue.length) return;
      filtersDispatch({
        type: FiltersActionType.UpdateCategory,
        category: newValue,
      });
    },
    [filtersDispatch]
  );

  const handleToggleWindow = React.useCallback(
    (newWindow: Duration) => {
      if (!newWindow) return;
      filtersDispatch({
        type: FiltersActionType.UpdateMainSearchWindow,
        window: newWindow,
      });
    },
    [filtersDispatch]
  );

  const ButtonType = filtersActive ? PrimaryButton : Button;

  return (
    <FilterBarContainer>
      <ToggleGroupRoot
        type="single"
        value={activeCategory}
        onValueChange={handleToggleSearchType}
      >
        <ToggleGroupItem value={SearchCategory.Token}>
          <BsCoin />
          Tokens
        </ToggleGroupItem>
        <ToggleGroupItem value={SearchCategory.Nft}>
          <BsImages />
          NFTs
        </ToggleGroupItem>
      </ToggleGroupRoot>
      {lessThanMd ? (
        <DurationSelect
          handleChange={handleToggleWindow}
          value={mainSearchWindow}
        />
      ) : (
        <ToggleGroupRoot
          type="single"
          value={mainSearchWindow}
          onValueChange={handleToggleWindow}
        >
          {windows.map((wn) => (
            <ToggleGroupItem key={wn} value={wn}>
              {DurationFormattedShort[wn]}
            </ToggleGroupItem>
          ))}
        </ToggleGroupRoot>
      )}
      <FlexCenteredGap style={{ marginLeft: "auto" }}>
        <ButtonType
          onClick={
            activeCategory === SearchCategory.Token
              ? handleTokenFiltersButtonClick
              : handleNftFiltersButtonClick
          }
        >
          <FiFilter />
          {!lessThanMd &&
            `${
              activeCategory === SearchCategory.Token ? "Token" : "NFT"
            } Filters`}
          {numFiltersActive > 0 && (
            <NumFiltersActiveIndicator>
              {numFiltersActive}
            </NumFiltersActiveIndicator>
          )}
        </ButtonType>
        <Button onClick={handleSettingsButtonClick}>
          <FiSettings />
        </Button>
      </FlexCenteredGap>
    </FilterBarContainer>
  );
};

export { FilterBar };
