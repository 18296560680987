import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import definedLogo from "../assets/defined/definedLogo.svg";
import definedLogoAlt from "../assets/defined/definedLogoAlt.svg";
import { FlexCentered, FlexColumnCentered } from "../components/basic/Flex";
import { Button } from "../components/inputs/Button";
import { Search } from "../components/search/Search";
import { SettingsContext } from "../context/SettingsContext";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { breakpoints, lessThan } from "../styles/Layout";
import { borderRadius } from "../styles/Mixins";

const maxHomePageWidth = 1200;

const HomePageContainer = styled(FlexColumnCentered)(
  ({ theme }) => css`
    background: radial-gradient(
        ellipse at top,
        ${theme.mode === "dark" ? "#151443" : "#ffeff6"},
        transparent
      ),
      radial-gradient(
        ellipse at bottom,
        ${theme.mode === "dark" ? "#190437" : "#b8abd5"},
        ${theme.mode === "dark" ? "#030316" : "#e5e9ff"}
      );
    color: ${theme.colors.text};
    justify-content: center;
    height: 100vh;
    padding: ${theme.spacing(3)};

    @media (max-width: ${breakpoints.md}px) {
      padding: ${theme.spacing(1)};
      height: 100dvh;
    }
  `
);

const HomePageInner = styled(FlexCentered)(
  ({ theme }) => css`
    align-items: center;
    gap: ${theme.spacing(3)};
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    max-width: ${maxHomePageWidth}px;
    height: 100%;
    @media (max-width: ${breakpoints.md}px) {
      gap: ${theme.spacing(1)};
    }
  `
);

const Header = styled("div")(
  ({ theme }) => css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(2)};
    justify-content: space-between;
    width: 100%;
    max-width: 1400px;
    @media (max-width: ${breakpoints.md}px) {
      padding: 0 ${theme.spacing(0.5)};
    }
  `
);

const LogoContainer = styled("div")`
  align-items: flex-end;
  display: flex;
`;

const Logo = styled.div`
  display: flex;
  width: 140px;

  img {
    width: 100%;
  }
  @media (max-width: ${breakpoints.md}px) {
    width: 120px;
  }
`;

const SearchContainer = styled(FlexColumnCentered)`
  flex: 1;
  width: 100%;
  min-height: 0;
`;

const ApiCta = styled("a")(
  ({ theme }) => css`
    align-items: center;
    margin-left: auto;
    display: flex;
    padding: ${theme.spacing(0.75)} ${theme.spacing(1)};
    min-height: 34px;
    gap: ${theme.spacing(1)};
    opacity: 0.8;
    text-align: center;
    transition: all 0.2s ease;

    :hover {
      background: #8a80f922;
      opacity: 1;
    }
    ${borderRadius}
  `
);

const HomePage: React.FC = () => {
  const lessThanMd = useMediaQuery(lessThan(breakpoints.md));

  const {
    settingsState: { darkMode },
    setSettingsState,
  } = React.useContext(SettingsContext);

  const setDarkMode = React.useCallback(
    (newDarkMode: boolean) => {
      setSettingsState((state) => ({
        ...state,
        darkMode: newDarkMode,
      }));
    },
    [setSettingsState]
  );

  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.shiftKey && e.metaKey && e.key === "d") {
        e.preventDefault();
        setDarkMode(!darkMode);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [darkMode, setDarkMode]);

  return (
    <HomePageContainer>
      <HomePageInner>
        <Header>
          <LogoContainer>
            <Logo>
              <img src={darkMode ? definedLogo : definedLogoAlt} alt="logo" />
            </Logo>
          </LogoContainer>
          {!lessThanMd ? (
            <ApiCta
              href="https://docs.defined.fi"
              target="_blank"
              rel="noopener noreferrer"
            >
              Build your app with the Defined API ⚡
            </ApiCta>
          ) : null}
          <Button onClick={() => setDarkMode(!darkMode)}>
            {darkMode ? "🌛" : "🌞"}
          </Button>
        </Header>
        <SearchContainer>
          <Search />
        </SearchContainer>
      </HomePageInner>
    </HomePageContainer>
  );
};

export { HomePage };
