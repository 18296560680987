import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import { withTransientProps } from "../../utils/styles";

interface LoadingIndicatorProps {
  singleLine?: boolean;
}

const CenteredContainer = styled("div")`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const LoadingIndicatorContainer = styled(
  "div",
  withTransientProps
)<{ $singleLine?: boolean }>(
  ({ $singleLine }) => css`
    height: ${$singleLine ? 10 : 60}px;
    width: 60px;
  `
);

const LoadingDot = styled("div")(
  ({ theme }) => css`
    width: 6px;
    height: 6px;
    background: ${theme.colors.secondary};
    border-radius: 100%;
    float: left;
    margin-bottom: 12px;
    animation: LoadingIndicator-scale 2s ease infinite;
    &:not(:nth-of-type(4n + 4)) {
      margin-right: 12px;
    }
    //row 1
    &:nth-of-type(1) {
      animation-delay: 0;
    }
    //row 2
    &:nth-of-type(2),
    &:nth-of-type(5) {
      animation-delay: 0.1s;
    }
    //row 3
    &:nth-of-type(3),
    &:nth-of-type(6),
    &:nth-of-type(9) {
      animation-delay: 0.2s;
    }
    //row 4
    &:nth-of-type(4),
    &:nth-of-type(7),
    &:nth-of-type(10),
    &:nth-of-type(13) {
      animation-delay: 0.3s;
    }
    //row 5
    &:nth-of-type(8),
    &:nth-of-type(11),
    &:nth-of-type(14) {
      animation-delay: 0.4s;
    }
    //row 6
    &:nth-of-type(12),
    &:nth-of-type(15) {
      animation-delay: 0.5s;
    }
    //row 7
    &:nth-of-type(16) {
      animation-delay: 0.6s;
    }
    @keyframes LoadingIndicator-scale {
      40% {
        transform: scale(1.5) translate(-2px, -2px);
        background: ${theme.colors.primary};
      }
      80% {
        transform: scale(1);
      }
      100% {
        transform: scale(1);
      }
    }
  `
);

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ singleLine }) => {
  return (
    <CenteredContainer>
      <LoadingIndicatorContainer $singleLine={singleLine}>
        {[...Array(singleLine ? 4 : 16)].map((x, i) => (
          <LoadingDot key={i} />
        ))}
      </LoadingIndicatorContainer>
    </CenteredContainer>
  );
};

export { LoadingIndicator };
