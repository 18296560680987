import { NetworkNames } from "../constants/Network";
import { NetworkId } from "../types/Network";

const createTokenChartLink = (
  templatedUrl: string,
  networkId: NetworkId,
  tokenAddress: string,
  networkNameMap: Record<NetworkId, string | null>,
  pairAddress?: string,
  quoteToken?: string
): string | null => {
  const networkShortName = networkNameMap[networkId];
  const networkSlug = NetworkNames[networkId].replace(/\W/g, "").toLowerCase();

  if (!networkShortName) return null;

  return templatedUrl
    .replace("${networkShortName}", networkShortName)
    .replace("${networkSlug}", networkSlug)
    .replace("${tokenAddress}", tokenAddress)
    .replace("${pairAddress}", pairAddress ?? tokenAddress)
    .replace("quoteToken=${quoteToken}", () => {
      if (quoteToken) return `quoteToken=${quoteToken}`;
      return "";
    });
};

export { createTokenChartLink };
