import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Command } from "cmdk";
import React from "react";
import toast from "react-hot-toast";

import { NetworkNames, NetworkShortName } from "../../../constants/Network";
import {
  NftAsset,
  NftSearchResponseCollection,
  useGetNftAssetsQuery,
} from "../../../generated/graphql";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { breakpoints, lessThan } from "../../../styles/Layout";
import { ellipsis } from "../../../styles/Mixins";
import { NetworkId } from "../../../types/Network";
import { getAbbreviatedAddress } from "../../../utils/format";
import { withTransientProps } from "../../../utils/styles";
import { FormattedNumber } from "../../dataDisplay/FormattedNumber";
import { PercentChange } from "../../dataDisplay/PercentChange";
import { TextButton } from "../../inputs/Button";
import { LoadingIndicator } from "../../loading/LoadingIndicator";
import { NftAssetImage } from "../../nft/NftAssetImage";

export interface SearchNftSelectOptions {
  networkId: NetworkId;
  networkShortName: string;
  networkFullName: string;
  collectionAddress: string;
  tokenId?: string;
}

export interface NftCollectionPageProps {
  collection: NftSearchResponseCollection;
  searchText?: string;
  value: string;
  setValue: (value: string) => void;
  onNftCollectionSelect?: (
    nftCollectionSelectOptions: SearchNftSelectOptions
  ) => void;
  onNftAssetSelect?: (nftAssetSelectOptions: SearchNftSelectOptions) => void;
}

const assetLimit = 20;
const assetSizePx = 165;

const CollectionPageContainer = styled("div")(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(1)};
    color: ${theme.colors.text};
  `
);

const Header = styled("div")(
  ({ theme }) => css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(2)};
    padding: ${theme.spacing(0.5)} ${theme.spacing(2)};

    @media (max-width: ${breakpoints.md}px) {
      flex-direction: column;
    }
  `
);

const PrimaryInfoContainer = styled("div")(
  ({ theme }) => css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(2)};
    flex: 1;
    min-width: 0;
    max-width: 100%;
  `
);

const FeaturedImageContainer = styled("div")`
  flex: 0 0 auto;
`;

const SymbolAndName = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
`;

const Symbol = styled("h1")`
  ${ellipsis}
`;

const Name = styled("h3")`
  opacity: 0.6;
  ${ellipsis}
`;

const CollectionCta = styled(TextButton)(
  ({ theme }) => css`
    align-self: center;
    width: fit-content;
    margin: ${theme.spacing(-1)};
    padding: ${theme.spacing(1.5)};
    opacity: 0.6;
    :hover {
      background: ${theme.colors.secondary}44;
      opacity: 1;
    }
  `
);

const StatsContainer = styled("div")(
  ({ theme }) => css`
    display: flex;
    gap: ${theme.spacing(3)};
    flex-direction: row;
    align-items: center;
    @media (max-width: ${breakpoints.sm}px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  `
);

const Stat = styled("div")(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${theme.spacing(0.5)};
  `
);

const Label = styled("div")(
  ({ theme }) => css`
    opacity: 0.6;
    font-size: ${theme.fontSize.body2};
  `
);

const CommandGroupGrid = styled(
  Command.Group,
  withTransientProps
)<{ $itemsPerRow: number }>(
  ({ theme, $itemsPerRow }) => css`
    [cmdk-group-items] {
      display: grid;
      grid-template-columns: repeat(${$itemsPerRow}, 1fr);
      grid-gap: 4px;
    }
    [cmdk-item] {
      height: ${assetSizePx + 45}px;
      min-width: 0;
      padding: ${theme.spacing(1)};
      @media (max-width: ${breakpoints.sm}px) {
        height: ${assetSizePx + 25}px;
      }
    }
  `
);

const CommandItemGrid = styled(Command.Item)`
  display: flex;
  flex-direction: column;
`;

const TokenId = styled("div")`
  text-align: center;
  width: 100%;
  ${ellipsis}
`;

const NftCollectionPage: React.FC<NftCollectionPageProps> = ({
  collection,
  searchText,
  value,
  setValue,
  onNftCollectionSelect,
  onNftAssetSelect,
}) => {
  const [nftAssets, setNftAssets] = React.useState<NftAsset[]>([]);
  const lessThanLg = useMediaQuery(lessThan(breakpoints.lg));
  const lessThanMd = useMediaQuery(lessThan(breakpoints.md));
  const lessThanSm = useMediaQuery(lessThan(breakpoints.sm));
  const assetsPerRow = lessThanSm ? 2 : lessThanMd ? 3 : lessThanLg ? 4 : 5;

  const { data, loading } = useGetNftAssetsQuery({
    variables: {
      address: collection.address,
      networkId: collection.networkId,
      limit: assetLimit,
      tokenIds: searchText ? [searchText] : undefined,
    },
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const nftAssetsFound = React.useMemo(
    () => nftAssets?.length > 0 && nftAssets[0] !== null,
    [nftAssets]
  );

  React.useEffect(() => {
    if (!data?.getNftAssets?.items) return;

    setNftAssets(data?.getNftAssets?.items as NftAsset[]);
  }, [data]);

  React.useEffect(() => {
    if (!nftAssetsFound) return;
    setValue(nftAssets[0].tokenId);
  }, [nftAssets, nftAssetsFound, setValue]);

  React.useEffect(() => {
    if (nftAssets.length === 0 || value === undefined) return;

    // Navigate through assets in a grid with arrow keys
    const down = (e: { key: string; preventDefault: () => void }) => {
      if (["ArrowRight", "ArrowLeft", "ArrowUp", "ArrowDown"].includes(e.key)) {
        let nextNftAsset: NftAsset | undefined;
        const indexOfSelectedAsset = nftAssets.findIndex(
          (nft: NftAsset) => nft.tokenId === value
        ) as number;

        if (e.key === "ArrowRight") {
          nextNftAsset = nftAssets[indexOfSelectedAsset + 1];
        } else if (e.key === "ArrowLeft") {
          nextNftAsset = nftAssets[indexOfSelectedAsset - 1];
        } else if (e.key === "ArrowUp") {
          nextNftAsset = nftAssets[indexOfSelectedAsset - assetsPerRow];
        } else if (e.key === "ArrowDown") {
          nextNftAsset = nftAssets[indexOfSelectedAsset + assetsPerRow];
        }

        if (nextNftAsset) {
          setValue(nextNftAsset.tokenId);
        }
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [nftAssets, assetsPerRow, setValue, value]);

  const handleNftCollectionSelect = () => {
    onNftCollectionSelect
      ? onNftCollectionSelect({
          networkId: collection.networkId,
          networkShortName: NetworkShortName[collection.networkId as NetworkId],
          networkFullName: NetworkNames[collection.networkId as NetworkId],
          collectionAddress: collection.address,
        })
      : (location.href = `https://www.defined.fi/nft/${
          NetworkShortName[collection.networkId as NetworkId]
        }/${collection.address}`);
  };

  const handleNftAssetSelect = (asset: NftAsset) => {
    onNftAssetSelect
      ? onNftAssetSelect({
          networkId: collection.networkId,
          networkShortName: NetworkShortName[collection.networkId as NetworkId],
          networkFullName: NetworkNames[collection.networkId as NetworkId],
          collectionAddress: collection.address,
          tokenId: asset.tokenId,
        })
      : (location.href = `https://www.defined.fi/nft/${
          NetworkShortName[collection.networkId as NetworkId]
        }/${collection.address}${
          asset.tokenId ? `?tokenId=${asset.tokenId}` : ""
        }`);
  };

  return (
    <CollectionPageContainer>
      <Header>
        <PrimaryInfoContainer>
          <FeaturedImageContainer>
            <NftAssetImage src={collection.imageUrl || ""} sizeInPx={80} />
          </FeaturedImageContainer>
          <SymbolAndName>
            <Symbol>{collection.symbol}</Symbol>
            <Name>
              {collection.name || getAbbreviatedAddress(collection.address)}
            </Name>
          </SymbolAndName>
        </PrimaryInfoContainer>
        <StatsContainer>
          <Stat>
            <Label>Average</Label>
            <FormattedNumber dollars value={collection.average} />
          </Stat>
          <Stat>
            <Label>Floor</Label>
            <FormattedNumber dollars value={collection.floor} />
          </Stat>
          <Stat>
            <Label>Volume</Label>
            <FormattedNumber dollars abbreviate value={collection.volume} />
          </Stat>
          <Stat>
            <Label>Change</Label>
            <PercentChange value={collection.volumeChange * 100} />
          </Stat>
        </StatsContainer>
      </Header>
      <CollectionCta onClick={handleNftCollectionSelect}>
        View Collection ➔
      </CollectionCta>
      {loading && (
        <Command.Item>
          <LoadingIndicator singleLine />
        </Command.Item>
      )}
      <CommandGroupGrid $itemsPerRow={assetsPerRow}>
        {!nftAssetsFound && <Command.Empty>No results found.</Command.Empty>}
        <>
          {nftAssetsFound &&
            !loading &&
            nftAssets?.map((asset, i) => (
              <CommandItemGrid
                key={i}
                value={asset.tokenId}
                onSelect={() => handleNftAssetSelect(asset)}
              >
                <NftAssetImage
                  src={asset.media?.thumbSm}
                  sizeInPx={lessThanSm ? assetSizePx - 20 : assetSizePx}
                />
                <TokenId>#{asset.tokenId}</TokenId>
              </CommandItemGrid>
            ))}
        </>
      </CommandGroupGrid>
    </CollectionPageContainer>
  );
};

export { NftCollectionPage };
