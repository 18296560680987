import { css } from "@emotion/react";
import styled from "@emotion/styled";
import * as AvatarPrimitive from "@radix-ui/react-avatar";

import { Exchange } from "../../generated/graphql";
import { withTransientProps } from "../../utils/styles";

export interface ExchangeIconProps {
  exchange: Exchange;
  sizeInPx?: number;
  stacked?: boolean;
}

const AvatarRoot = styled(
  AvatarPrimitive.Root,
  withTransientProps
)<{ $sizeInPx: number; $stacked?: boolean }>(
  ({ theme, $sizeInPx, $stacked }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    overflow: hidden;
    user-select: none;
    border: 1px solid ${theme.colors.gray.gray8};
    width: ${$sizeInPx}px;
    height: ${$sizeInPx}px;
    margin-left: ${$stacked ? (-1 * $sizeInPx) / 4 + "px" : "0"};
    border-radius: 100%;
  `
);

const AvatarImage = styled(AvatarPrimitive.Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
`;

const AvatarFallback = styled(
  AvatarPrimitive.Fallback,
  withTransientProps
)<{ $accentColor: string }>(
  ({ $accentColor }) => css`
    align-items: center;
    background-color: ${$accentColor};
    display: flex;
    font-size: 15px;
    font-weight: 500;
    height: 100%;
    justify-content: center;
    line-height: 1;
    width: 100%;
  `
);

export { AvatarRoot, AvatarImage, AvatarFallback };
