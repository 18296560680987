import { NetworkShortName } from "../constants/Network";

enum NetworkId {
  ARBITRUM = 42161,
  ASTAR = 592,
  AURORA = 1313161554,
  AVA = 43114,
  AVALANCHEDFK = 53935,
  BOBA = 288,
  BSC = 56,
  CANTO = 7700,
  CALLISTO = 820,
  CELO = 42220,
  CONFLUX = 1030,
  CRONOS = 25,
  DOGECHAIN = 2000,
  ECHELON = 3000,
  ELASTOS = 20,
  ENERGI = 39797,
  ENERGYWEB = 246,
  ETH = 1,
  EVMOS = 9001,
  FTM = 250,
  FUSE = 122,
  GOERLI = 5,
  HARMONY = 1666600000,
  HECO = 128,
  HSC = 70,
  IOTEX = 4689,
  KARDIA = 24,
  KCC = 321,
  KLAYTN = 8217,
  MATIC = 137,
  METER = 82,
  METIS = 1088,
  MILKOMEDA = 2001,
  MOONBEAM = 1284,
  MOONRIVER = 1285,
  NOVA = 42170,
  OASIS = 42262,
  OEC = 66,
  OPTIMISM = 10,
  POLIS = 333999,
  SHIDEN = 336,
  SMARTBCH = 10000,
  SOLANA = 69420,
  SYSCOIN = 57,
  TELOS = 40,
  VELAS = 106,
  WANCHAIN = 888,
  XDAI = 100,
  ZYX = 55,
}

export type NetworkIdShortNamesType = Record<
  NetworkId,
  typeof NetworkShortName[NetworkId]
>;

export type NetworkShortNameIdsType = Record<
  typeof NetworkShortName[NetworkId],
  NetworkId
>;

export { NetworkId };
