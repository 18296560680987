import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSJSON: any;
};

/**   undocumented */
export type AddEventsInput = {
  address: Scalars['String'];
  events: Array<InputMaybe<EventInputUnion>>;
  id: Scalars['String'];
  networkId: Scalars['Int'];
  quoteToken?: InputMaybe<QuoteToken>;
};

/**   Response returned by `onCreateEvents`. */
export type AddEventsOutput = {
  __typename?: 'AddEventsOutput';
  /**   The contract address of the pair. */
  address: Scalars['String'];
  /**   A list of transactions for the token. */
  events: Array<Maybe<Event>>;
  /**   The ID of the event (`address`:`networkId`). For example, `0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2:1`. */
  id: Scalars['String'];
  /**   The network ID that the token is deployed on. */
  networkId: Scalars['Int'];
  /**   The token of interest within the pair. Can be `token0` or `token1`. */
  quoteToken?: Maybe<QuoteToken>;
};

/**   undocumented */
export type AddNftEventsInput = {
  address: Scalars['String'];
  events: Array<NftEventInput>;
  id: Scalars['String'];
  networkId: Scalars['Int'];
};

/**   Response returned by `onCreateNftEvents`. */
export type AddNftEventsOutput = {
  __typename?: 'AddNftEventsOutput';
  /**   The contract address of the NFT collection. */
  address: Scalars['String'];
  /**   A list of NFT transactions streaming real-time. */
  events: Array<Maybe<NftEvent>>;
  /**   The id of the collection (`address`:`networkId`). */
  id: Scalars['String'];
  /**   The network ID the collection is deployed on. */
  networkId: Scalars['Int'];
};

/**   undocumented */
export type AddNftPoolEventsInput = {
  collectionAddress: Scalars['String'];
  events: Array<NftPoolEventInput>;
  exchangeAddress: Scalars['String'];
  id: Scalars['String'];
  networkId: Scalars['Int'];
  poolAddress: Scalars['String'];
};

/**   Response returned by `onCreateNftPoolEvents`. */
export type AddNftPoolEventsOutput = {
  __typename?: 'AddNftPoolEventsOutput';
  /**   The contract address of the NFT collection. */
  collectionAddress: Scalars['String'];
  /**   A list of transactions for the NFT pool. */
  events: Array<Maybe<NftPoolEvent>>;
  /**   The contract address of the NFT AMM marketplace. */
  exchangeAddress: Scalars['String'];
  /**   The ID of the NFT pool (`poolAddress`:`networkId`). For example, `0xdbea289dcc10eed8431e78753414a3d81b8e7201:1`. */
  id: Scalars['String'];
  /**   The network ID the NFT collection is deployed on. */
  networkId: Scalars['Int'];
  /**   The contract address of the NFT pool. */
  poolAddress: Scalars['String'];
};

/**   The recurrence of the webhook. Can be `INDEFINITE` or `ONCE`. */
export enum AlertRecurrence {
  Indefinite = 'INDEFINITE',
  Once = 'ONCE'
}

/**   Bar chart data to track token price changes over time. */
export type BarsResponse = {
  __typename?: 'BarsResponse';
  /**   The closing price. */
  c: Array<Maybe<Scalars['Float']>>;
  /**   The high price. */
  h: Array<Maybe<Scalars['Float']>>;
  /**   The low price. */
  l: Array<Maybe<Scalars['Float']>>;
  /**   The opening price. */
  o: Array<Maybe<Scalars['Float']>>;
  /**   The status code for the bar. `200` responses are `ok`. */
  s: Scalars['String'];
  /**   The timestamp for the bar. */
  t: Array<Scalars['Int']>;
  /**   The volume. */
  v: Array<Maybe<Scalars['Int']>>;
  /**   The volume with higher precision. */
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**   The volume in the network base token */
  volumeNativeToken?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/**   The mathematical formula that defines how the prices of NFTs change after each buy or sell within a pool. */
export enum BondingCurveType {
  Exponential = 'EXPONENTIAL',
  Gda = 'GDA',
  Linear = 'LINEAR',
  Xyk = 'XYK'
}

/**   Event data for a token burn event. */
export type BurnEventData = {
  __typename?: 'BurnEventData';
  /**   The amount of `token0` removed from the pair. */
  amount0?: Maybe<Scalars['String']>;
  /**   The amount of `token0` removed from the pair, adjusted by the number of decimals in the token. For example, if `amount0` is in WEI, `amount0Shifted` will be in ETH. */
  amount0Shifted?: Maybe<Scalars['String']>;
  /**   The amount of `token1` removed from the pair. */
  amount1?: Maybe<Scalars['String']>;
  /**   The amount of `token1` removed from the pair, adjusted by the number of decimals in the token. For example, USDC `amount1Shifted` will be by 6 decimals. */
  amount1Shifted?: Maybe<Scalars['String']>;
  /**   The lower tick boundary of the position. Only applicable for UniswapV3 events. */
  tickLower?: Maybe<Scalars['String']>;
  /**   The upper tick boundary of the position. Only applicable for UniswapV3 events. */
  tickUpper?: Maybe<Scalars['String']>;
  /**   The type of token event, `Burn`. */
  type: EventType;
};

/**   undocumented */
export type BurnEventDataInput = {
  amount0?: InputMaybe<Scalars['String']>;
  amount0Shifted?: InputMaybe<Scalars['String']>;
  amount1?: InputMaybe<Scalars['String']>;
  amount1Shifted?: InputMaybe<Scalars['String']>;
  tickLower?: InputMaybe<Scalars['String']>;
  tickUpper?: InputMaybe<Scalars['String']>;
  type: EventType;
};

/**   undocumented */
export type BurnEventInput = {
  address: Scalars['String'];
  baseTokenPrice?: InputMaybe<Scalars['String']>;
  blockHash: Scalars['String'];
  blockNumber: Scalars['Int'];
  data?: InputMaybe<BurnEventDataInput>;
  eventDisplayType?: InputMaybe<EventDisplayType>;
  id: Scalars['String'];
  liquidityToken?: InputMaybe<Scalars['String']>;
  logIndex: Scalars['Int'];
  maker?: InputMaybe<Scalars['String']>;
  networkId: Scalars['Int'];
  quoteToken?: InputMaybe<QuoteToken>;
  timestamp: Scalars['Int'];
  token0PoolValueUsd?: InputMaybe<Scalars['String']>;
  token0SwapValueUsd?: InputMaybe<Scalars['String']>;
  token0ValueBase?: InputMaybe<Scalars['String']>;
  token1PoolValueUsd?: InputMaybe<Scalars['String']>;
  token1SwapValueUsd?: InputMaybe<Scalars['String']>;
  token1ValueBase?: InputMaybe<Scalars['String']>;
  transactionHash: Scalars['String'];
  transactionIndex: Scalars['Int'];
};

/**   undocumented */
export type CallSortkeyInput = {
  blockNumber: Scalars['Int'];
  traceIndex?: InputMaybe<Scalars['Int']>;
  transactionIndex?: InputMaybe<Scalars['Int']>;
};

export type ChartInput = {
  /**   The input required to fetch a pairt chart */
  pair?: InputMaybe<PairChartInput>;
};

export enum ChartTheme {
  Dark = 'DARK',
  Light = 'LIGHT'
}

/**   The chart url */
export type ChartUrl = {
  __typename?: 'ChartUrl';
  url: Scalars['String'];
};

/**   The response type for a chart url query */
export type ChartUrlsResponse = {
  __typename?: 'ChartUrlsResponse';
  pair: ChartUrl;
};

/**   Comparison operators. */
export type ComparisonOperator = {
  __typename?: 'ComparisonOperator';
  /**   Equal to. */
  eq?: Maybe<Scalars['String']>;
  /**   Greater than. */
  gt?: Maybe<Scalars['String']>;
  /**   Greater than or equal to. */
  gte?: Maybe<Scalars['String']>;
  /**   Less than. */
  lt?: Maybe<Scalars['String']>;
  /**   Less than or equal to. */
  lte?: Maybe<Scalars['String']>;
};

/**   Input for comparison operators. */
export type ComparisonOperatorInput = {
  /**   Equal */
  eq?: InputMaybe<Scalars['String']>;
  /**   Greater than. */
  gt?: InputMaybe<Scalars['String']>;
  /**   Greater than or equal. */
  gte?: InputMaybe<Scalars['String']>;
  /**   Less than. */
  lt?: InputMaybe<Scalars['String']>;
  /**   Less than or equal. */
  lte?: InputMaybe<Scalars['String']>;
};

/**   Input for creating a decoded call webhook. */
export type CreateDecodedCallWebhookArgs = {
  /**   The recurrence of the webhook. Can be `INDEFINITE` or `ONCE`. */
  alertRecurrence: AlertRecurrence;
  /**   The url to which the webhook message should be sent. */
  callbackUrl: Scalars['String'];
  /**   The conditions which must be met in order for the webhook to send a message. */
  conditions: DecodedCallWebhookConditionInput;
  /**   A webhook group ID (max 64 characters). Can be used to group webhooks so that their messages are kept in order as a group rather than by individual webhook. */
  groupId?: InputMaybe<Scalars['String']>;
  /**   The name of the webhook (max 128 characters). */
  name: Scalars['String'];
  /**   The settings for retrying failed webhook messages. */
  retrySettings?: InputMaybe<RetrySettingsInput>;
  /**   A string value to hash along with `deduplicationId` using SHA-256. Included in the webhook message for added security. */
  securityToken: Scalars['String'];
};

/**   Input for creating decoded call webhooks. */
export type CreateDecodedCallWebhooksInput = {
  /**   A list of decoded call webhooks to create. */
  webhooks: Array<CreateDecodedCallWebhookArgs>;
};

/**   Input for creating a decoded log webhook. */
export type CreateDecodedLogWebhookArgs = {
  /**   The recurrence of the webhook. Can be `INDEFINITE` or `ONCE`. */
  alertRecurrence: AlertRecurrence;
  /**   The url to which the webhook message should be sent. */
  callbackUrl: Scalars['String'];
  /**   The conditions which must be met in order for the webhook to send a message. */
  conditions: DecodedLogWebhookConditionInput;
  /**   A webhook group ID (max 64 characters). Can be used to group webhooks so that their messages are kept in order as a group rather than by individual webhook. */
  groupId?: InputMaybe<Scalars['String']>;
  /**   The name of the webhook (max 128 characters). */
  name: Scalars['String'];
  /**   The settings for retrying failed webhook messages. */
  retrySettings?: InputMaybe<RetrySettingsInput>;
  /**   A string value to hash along with `deduplicationId` using SHA-256. Included in the webhook message for added security. */
  securityToken: Scalars['String'];
};

/**   Input for creating decoded log webhooks. */
export type CreateDecodedLogWebhooksInput = {
  /**   A list of decoded log webhooks to create. */
  webhooks: Array<CreateDecodedLogWebhookArgs>;
};

/**   Input for creating an NFT event webhook. */
export type CreateNftEventWebhookArgs = {
  /**   The recurrence of the webhook. Can be `INDEFINITE` or `ONCE`. */
  alertRecurrence: AlertRecurrence;
  /**   The url to which the webhook message should be sent. */
  callbackUrl: Scalars['String'];
  /**   The conditions which must be met in order for the webhook to send a message. */
  conditions: NftEventWebhookConditionInput;
  /**   A webhook group ID (max 64 characters). Can be used to group webhooks so that their messages are kept in order as a group rather than by individual webhook. */
  groupId?: InputMaybe<Scalars['String']>;
  /**   The name of the webhook (max 128 characters). */
  name: Scalars['String'];
  /**   The settings for retrying failed webhook messages. */
  retrySettings?: InputMaybe<RetrySettingsInput>;
  /**   A string value to hash along with `deduplicationId` using SHA-256. Included in the webhook message for added security. */
  securityToken: Scalars['String'];
};

/**   Input for creating NFT event webhooks. */
export type CreateNftEventWebhooksInput = {
  /**   A list of NFT event webhooks to create. */
  webhooks: Array<CreateNftEventWebhookArgs>;
};

/**   Input for creating a price webhook. */
export type CreatePriceWebhookArgs = {
  /**   The recurrence of the webhook. Can be `INDEFINITE` or `ONCE`. */
  alertRecurrence: AlertRecurrence;
  /**   The url to which the webhook message should be sent. */
  callbackUrl: Scalars['String'];
  /**   The conditions which must be met in order for the webhook to send a message. */
  conditions: PriceEventWebhookConditionInput;
  /**   A webhook group ID (max 64 characters). Can be used to group webhooks so that their messages are kept in order as a group rather than by individual webhook. */
  groupId?: InputMaybe<Scalars['String']>;
  /**   The name of the webhook (max 128 characters). */
  name: Scalars['String'];
  /**   The settings for retrying failed webhook messages. */
  retrySettings?: InputMaybe<RetrySettingsInput>;
  /**   A string value to hash along with `deduplicationId` using SHA-256. Included in the webhook message for added security. */
  securityToken: Scalars['String'];
};

/**   Input for creating price webhooks. */
export type CreatePriceWebhooksInput = {
  /**   A list of price webhooks to create. */
  webhooks: Array<CreatePriceWebhookArgs>;
};

/**   Input for creating a raw call trace webhook. */
export type CreateRawCallTraceWebhookArgs = {
  /**   The recurrence of the webhook. Can be `INDEFINITE` or `ONCE`. */
  alertRecurrence: AlertRecurrence;
  /**   The url to which the webhook message should be sent. */
  callbackUrl: Scalars['String'];
  /**   The conditions which must be met in order for the webhook to send a message. */
  conditions: RawCallTraceWebhookConditionInput;
  /**   A webhook group ID (max 64 characters). Can be used to group webhooks so that their messages are kept in order as a group rather than by individual webhook. */
  groupId?: InputMaybe<Scalars['String']>;
  /**   The name of the webhook (max 128 characters). */
  name: Scalars['String'];
  /**   The settings for retrying failed webhook messages. */
  retrySettings?: InputMaybe<RetrySettingsInput>;
  /**   A string value to hash along with `deduplicationId` using SHA-256. Included in the webhook message for added security. */
  securityToken: Scalars['String'];
};

/**   Input for creating raw call trace webhooks. */
export type CreateRawCallTraceWebhooksInput = {
  /**   A list of raw call trace webhooks to create. */
  webhooks: Array<CreateRawCallTraceWebhookArgs>;
};

/**   Input for creating a token pair event webhook. */
export type CreateTokenPairEventWebhookArgs = {
  /**   The recurrence of the webhook. Can be `INDEFINITE` or `ONCE`. */
  alertRecurrence: AlertRecurrence;
  /**   The url to which the webhook message should be sent. */
  callbackUrl: Scalars['String'];
  /**   The conditions which must be met in order for the webhook to send a message. */
  conditions: TokenPairEventWebhookConditionInput;
  /**   A webhook group ID (max 64 characters). Can be used to group webhooks so that their messages are kept in order as a group rather than by individual webhook. */
  groupId?: InputMaybe<Scalars['String']>;
  /**   The name of the webhook (max 128 characters). */
  name: Scalars['String'];
  /**   The settings for retrying failed webhook messages. */
  retrySettings?: InputMaybe<RetrySettingsInput>;
  /**   A string value to hash along with `deduplicationId` using SHA-256. Included in the webhook message for added security. */
  securityToken: Scalars['String'];
};

/**   Input for creating token pair event webhooks. */
export type CreateTokenPairEventWebhooksInput = {
  /**   A list of token pair event webhooks to create. */
  webhooks: Array<CreateTokenPairEventWebhookArgs>;
};

/**   Input for creating webhooks. */
export type CreateWebhooksInput = {
  /**   Input for creating decoded call webhooks. */
  decodedCallWebhooksInput?: InputMaybe<CreateDecodedCallWebhooksInput>;
  /**   Input for creating decoded log webhooks. */
  decodedLogWebhooksInput?: InputMaybe<CreateDecodedLogWebhooksInput>;
  /**   Input for creating NFT event webhooks. */
  nftEventWebhooksInput?: InputMaybe<CreateNftEventWebhooksInput>;
  /**   Input for creating price webhooks. */
  priceWebhooksInput?: InputMaybe<CreatePriceWebhooksInput>;
  /**   Input for creating raw call trace webhooks. */
  rawCallTraceWebhooksInput?: InputMaybe<CreateRawCallTraceWebhooksInput>;
  /**   Input for creating token pair event webhooks. */
  tokenPairEventWebhooksInput?: InputMaybe<CreateTokenPairEventWebhooksInput>;
};

/**   Result returned by `createWebhooks`. */
export type CreateWebhooksOutput = {
  __typename?: 'CreateWebhooksOutput';
  /**   The list of decoded call webhooks that were created. */
  decodedCallWebhooks: Array<Maybe<Webhook>>;
  /**   The list of decoded log webhooks that were created. */
  decodedLogWebhooks: Array<Maybe<Webhook>>;
  /**   The list of NFT event webhooks that were created. */
  nftEventWebhooks: Array<Maybe<Webhook>>;
  /**   The list of price webhooks that were created. */
  priceWebhooks: Array<Maybe<Webhook>>;
  /**   The list of raw call trace webhooks that were created. */
  rawCallTraceWebhooks: Array<Maybe<Webhook>>;
  /**   The list of token pair event webhooks that were created. */
  tokenPairEventWebhooks: Array<Maybe<Webhook>>;
};

/**   Price data for a bar at a specific resolution. */
export type CurrencyBarData = {
  __typename?: 'CurrencyBarData';
  /**   The timestamp for the bar. */
  t: Scalars['Int'];
  /**   Bar chart data in the network's base token. */
  token: IndividualBarData;
  /**   Bar chart data in USD. */
  usd: IndividualBarData;
};

/**   undocumented */
export type CurrencyBarInput = {
  t: Scalars['Int'];
  token: IndividualBarInput;
  usd: IndividualBarInput;
};

/**   undocumented */
export type DecodedCall = {
  __typename?: 'DecodedCall';
  blockNumber: Scalars['Int'];
  blockTimestamp: Scalars['Int'];
  contractHashKey: Scalars['String'];
  contractName: Scalars['String'];
  decodingId: Scalars['String'];
  from: Scalars['String'];
  hashKey: Scalars['String'];
  input: Scalars['AWSJSON'];
  methodId: Scalars['String'];
  methodName: Scalars['String'];
  networkId: Scalars['Int'];
  output: Scalars['AWSJSON'];
  projectName: Scalars['String'];
  sortKey: Scalars['String'];
  success: Scalars['Boolean'];
  to: Scalars['String'];
  traceAddress: Scalars['String'];
  traceIndex: Scalars['Int'];
  traceType: Scalars['Int'];
  transactionHash: Scalars['String'];
  transactionIndex: Scalars['Int'];
};

/**   Webhook conditions for a decoded call event. */
export type DecodedCallWebhookCondition = {
  __typename?: 'DecodedCallWebhookCondition';
  /**   The contract address of the decoding the webhook is listening for. Either `methodName` or `address` must be supplied. */
  address?: Maybe<StringEqualsCondition>;
  /**   The `ContractDecoding` ID the webhook is listening for. */
  decodingId: StringEqualsCondition;
  /**   The `from` address the webhook is listening for. */
  from?: Maybe<StringEqualsCondition>;
  /**   Stringified JSON of the `input` property the webhook is listening for. */
  input?: Maybe<Scalars['AWSJSON']>;
  /**   The `methodId` property the webhook is listening for. */
  methodId?: Maybe<StringEqualsCondition>;
  /**   The name of the method the webhook is listening for. Either `methodName` or `address` must be supplied. */
  methodName?: Maybe<StringEqualsCondition>;
  /**   Stringified JSON of the `output` property the webhook is listening for. */
  output?: Maybe<Scalars['AWSJSON']>;
  /**   The `to` address the webhook is listening for. */
  to?: Maybe<StringEqualsCondition>;
};

/**   Input conditions for a decoded call webhook. */
export type DecodedCallWebhookConditionInput = {
  /**   The contract address of the decoding to listen for. Either `methodName` or `address` must be supplied. */
  address?: InputMaybe<StringEqualsConditionInput>;
  /**   The `ContractDecoding` ID to listen for. */
  decodingId: StringEqualsConditionInput;
  /**   The `from` address to listen for. */
  from?: InputMaybe<StringEqualsConditionInput>;
  /**   Stringified JSON of the `input` property to listen for. */
  input?: InputMaybe<Scalars['AWSJSON']>;
  /**   The `methodId` property to listen for. */
  methodId?: InputMaybe<StringEqualsConditionInput>;
  /**   The name of the method to listen for. Either `methodName` or `address` must be supplied. */
  methodName?: InputMaybe<StringEqualsConditionInput>;
  /**   Stringified JSON of the `output` property to listen for. */
  output?: InputMaybe<Scalars['AWSJSON']>;
  /**   The `to` address to listen for. */
  to?: InputMaybe<StringEqualsConditionInput>;
};

/**   undocumented */
export type DecodedCallsConnection = {
  __typename?: 'DecodedCallsConnection';
  cursor?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<DecodedCall>>>;
};

/**   undocumented */
export type DecodedLog = {
  __typename?: 'DecodedLog';
  address: Scalars['String'];
  blockNumber: Scalars['Int'];
  blockTimestamp: Scalars['Int'];
  contractHashKey: Scalars['String'];
  contractName: Scalars['String'];
  decodingId: Scalars['String'];
  event: Scalars['AWSJSON'];
  eventName: Scalars['String'];
  hashKey: Scalars['String'];
  logIndex: Scalars['Int'];
  networkId: Scalars['Int'];
  projectName: Scalars['String'];
  sortKey: Scalars['String'];
  transactionHash: Scalars['String'];
  transactionIndex: Scalars['Int'];
};

/**   Webhook conditions for a decoded log event. */
export type DecodedLogWebhookCondition = {
  __typename?: 'DecodedLogWebhookCondition';
  /**   The contract address of the decoding the webhook is listening for. Either `methodName` or `address` must be supplied. */
  address?: Maybe<StringEqualsCondition>;
  /**   The `ContractDecoding` ID the webhook is listening for. */
  decodingId: StringEqualsCondition;
  /**   Stringified JSON of the `event` property the webhook is listening for. */
  event?: Maybe<Scalars['AWSJSON']>;
  /**   The name of the event the webhook is listening for. Either `methodName` or `address` must be supplied. */
  eventName?: Maybe<StringEqualsCondition>;
};

/**   Input conditions for a decoded log webhook. */
export type DecodedLogWebhookConditionInput = {
  /**   The contract address of the decoding to listen for. Either `methodName` or `address` must be supplied. */
  address?: InputMaybe<StringEqualsConditionInput>;
  /**   The `ContractDecoding` ID to listen for. */
  decodingId: StringEqualsConditionInput;
  /**   Stringified JSON of the `event` property to listen for. */
  event?: InputMaybe<Scalars['AWSJSON']>;
  /**   The name of the method to listen for. Either `methodName` or `address` must be supplied. */
  eventName?: InputMaybe<StringEqualsConditionInput>;
};

/**   undocumented */
export type DecodedLogsConnection = {
  __typename?: 'DecodedLogsConnection';
  cursor?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<DecodedLog>>>;
};

/**   undocumented */
export type Decoding = {
  __typename?: 'Decoding';
  abi: Scalars['AWSJSON'];
  contractAddress?: Maybe<Scalars['String']>;
  contractName: Scalars['String'];
  eventNames: Array<Scalars['String']>;
  id: Scalars['String'];
  methodNames: Array<Scalars['String']>;
  methods: Array<Scalars['String']>;
  networkId: Scalars['Int'];
  projectName: Scalars['String'];
  startBlockNumber?: Maybe<Scalars['Int']>;
  topics: Array<Scalars['String']>;
};

/**   undocumented */
export type DecodingsConnection = {
  __typename?: 'DecodingsConnection';
  cursor?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<Decoding>>>;
};

/**   Input for deleting webhooks. */
export type DeleteWebhooksInput = {
  /**   A list of webhook IDs to delete. */
  webhookIds: Array<Scalars['String']>;
};

/**   Result returned by `deleteWebhooks`. */
export type DeleteWebhooksOutput = {
  __typename?: 'DeleteWebhooksOutput';
  /**   The list of webhook IDs that were deleted. */
  deletedIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/**   Detailed stats for an NFT collection. */
export type DetailedNftStats = {
  __typename?: 'DetailedNftStats';
  /**   The contract address of the NFT collection. */
  collectionAddress: Scalars['String'];
  /**   The marketplace address or `all`. Can be used to get marketplace-specific metrics. */
  grouping?: Maybe<Scalars['String']>;
  /**   The network ID the NFT collection is deployed on. */
  networkId: Scalars['Int'];
  /**   The breakdown of stats over a 24 hour window. */
  stats_day1?: Maybe<WindowedDetailedNftStats>;
  /**   The breakdown of stats over a 30 day window. */
  stats_day30?: Maybe<WindowedDetailedNftStats>;
  /**   The breakdown of stats over an hour window. */
  stats_hour1?: Maybe<WindowedDetailedNftStats>;
  /**   The breakdown of stats over a 4 hour window. */
  stats_hour4?: Maybe<WindowedDetailedNftStats>;
  /**   The breakdown of stats over a 12 hour window. */
  stats_hour12?: Maybe<WindowedDetailedNftStats>;
  /**   The breakdown of stats over a 7 day window. */
  stats_week1?: Maybe<WindowedDetailedNftStats>;
};

/**   The start/end timestamp for a given bucket within the window. */
export type DetailedNftStatsBucketTimestamp = {
  __typename?: 'DetailedNftStatsBucketTimestamp';
  /**   The unix timestamp for the end of the window. */
  end: Scalars['Int'];
  /**   The unix timestamp for the start of the window. */
  start: Scalars['Int'];
};

/**   The duration used to request detailed NFT stats. */
export enum DetailedNftStatsDuration {
  Day1 = 'day1',
  Day30 = 'day30',
  Hour1 = 'hour1',
  Hour4 = 'hour4',
  Hour12 = 'hour12',
  Week1 = 'week1'
}

/**   Number metrics for detailed NFT stats. */
export type DetailedNftStatsNumberMetrics = {
  __typename?: 'DetailedNftStatsNumberMetrics';
  /**   The list of aggregated values for each bucket. */
  buckets: Array<Maybe<Scalars['Int']>>;
  /**   The percent change between the `currentValue` and `previousValue`. */
  change?: Maybe<Scalars['Float']>;
  /**   The total value for the most recent duration. */
  currentValue?: Maybe<Scalars['Int']>;
  /**   The total value for the previous duration. */
  previousValue?: Maybe<Scalars['Int']>;
};

/**   String metrics for detailed NFT stats. */
export type DetailedNftStatsStringMetrics = {
  __typename?: 'DetailedNftStatsStringMetrics';
  /**   The list of aggregated values for each bucket. */
  buckets: Array<Maybe<Scalars['String']>>;
  /**   The percent change between the `currentValue` and `previousValue`. */
  change?: Maybe<Scalars['Float']>;
  /**   The total value for the most recent duration. */
  currentValue?: Maybe<Scalars['String']>;
  /**   The total value for the previous duration. */
  previousValue?: Maybe<Scalars['String']>;
};

/**   Detailed stats for a token within a pair. */
export type DetailedPairStats = {
  __typename?: 'DetailedPairStats';
  /**   The network ID the pair is deployed on. */
  networkId: Scalars['Int'];
  /**   The contract address of the pair. */
  pairAddress: Scalars['String'];
  /**   The breakdown of stats over a 24 hour window. */
  stats_day1?: Maybe<WindowedDetailedPairStats>;
  /**   The breakdown of stats over a 30 day window. */
  stats_day30?: Maybe<WindowedDetailedPairStats>;
  /**   The breakdown of stats over an hour window. */
  stats_hour1?: Maybe<WindowedDetailedPairStats>;
  /**   The breakdown of stats over a 4 hour window. */
  stats_hour4?: Maybe<WindowedDetailedPairStats>;
  /**   The breakdown of stats over a 12 hour window. */
  stats_hour12?: Maybe<WindowedDetailedPairStats>;
  /**   The breakdown of stats over a 5 minute window. */
  stats_min5?: Maybe<WindowedDetailedPairStats>;
  /**   The breakdown of stats over a 15 minute window. */
  stats_min15?: Maybe<WindowedDetailedPairStats>;
  /**   The breakdown of stats over a 7 day window. */
  stats_week1?: Maybe<WindowedDetailedPairStats>;
  /**   The token of interest used to calculate token-specific stats. */
  tokenOfInterest?: Maybe<TokenOfInterest>;
};

/**   The start/end timestamp for a given bucket within the window. */
export type DetailedPairStatsBucketTimestamp = {
  __typename?: 'DetailedPairStatsBucketTimestamp';
  /**   The unix timestamp for the start of the bucket. */
  end: Scalars['Int'];
  /**   The unix timestamp for the start of the bucket. */
  start: Scalars['Int'];
};

/**   The duration used to request detailed pair stats. */
export enum DetailedPairStatsDuration {
  Day1 = 'day1',
  Day30 = 'day30',
  Hour1 = 'hour1',
  Hour4 = 'hour4',
  Hour12 = 'hour12',
  Min5 = 'min5',
  Min15 = 'min15',
  Week1 = 'week1'
}

/**   Number metrics for detailed pair stats. */
export type DetailedPairStatsNumberMetrics = {
  __typename?: 'DetailedPairStatsNumberMetrics';
  /**   The list of aggregated values for each bucket. */
  buckets: Array<Maybe<Scalars['Int']>>;
  /**   The percent change between the `currentValue` and `previousValue`. */
  change?: Maybe<Scalars['Float']>;
  /**   The total value for the most recent duration. */
  currentValue?: Maybe<Scalars['Int']>;
  /**   The total value for the previous duration. */
  previousValue?: Maybe<Scalars['Int']>;
};

/**   String metrics for detailed pair stats. */
export type DetailedPairStatsStringMetrics = {
  __typename?: 'DetailedPairStatsStringMetrics';
  /**   The list of aggregated values for each bucket. */
  buckets: Array<Maybe<Scalars['String']>>;
  /**   The percent change between the `currentValue` and `previousValue`. */
  change?: Maybe<Scalars['Float']>;
  /**   The total value for the most recent duration. */
  currentValue?: Maybe<Scalars['String']>;
  /**   The total value for the previous duration. */
  previousValue?: Maybe<Scalars['String']>;
};

/**   undocumented */
export type DetailedStats = {
  __typename?: 'DetailedStats';
  pairId: Scalars['String'];
  stats_day1?: Maybe<WindowedDetailedStats>;
  stats_hour1?: Maybe<WindowedDetailedStats>;
  stats_hour4?: Maybe<WindowedDetailedStats>;
  stats_hour12?: Maybe<WindowedDetailedStats>;
  stats_min5?: Maybe<WindowedDetailedStats>;
  tokenOfInterest: TokenOfInterest;
};

/**   undocumented */
export type DetailedStatsBucketTimestamp = {
  __typename?: 'DetailedStatsBucketTimestamp';
  end: Scalars['Int'];
  start: Scalars['Int'];
};

/**   undocumented */
export type DetailedStatsNumberMetrics = {
  __typename?: 'DetailedStatsNumberMetrics';
  buckets: Array<Maybe<Scalars['Int']>>;
  change: Scalars['Float'];
  currentValue: Scalars['Int'];
  previousValue: Scalars['Int'];
};

/**   undocumented */
export type DetailedStatsStringMetrics = {
  __typename?: 'DetailedStatsStringMetrics';
  buckets: Array<Maybe<Scalars['String']>>;
  change: Scalars['Float'];
  currentValue: Scalars['String'];
  previousValue: Scalars['String'];
};

/**   undocumented */
export enum DetailedStatsWindowSize {
  Day1 = 'day1',
  Hour1 = 'hour1',
  Hour4 = 'hour4',
  Hour12 = 'hour12',
  Min5 = 'min5'
}

/**   Metadata for a token. */
export type EnhancedToken = {
  __typename?: 'EnhancedToken';
  /**   The contract address of the token. */
  address: Scalars['String'];
  /**   The token ID on CoinMarketCap. */
  cmcId?: Maybe<Scalars['Int']>;
  /**   The precision to which the token can be divided. For example, the smallest unit for USDC is 0.000001 (6 decimals). */
  decimals: Scalars['Int'];
  /**   The ID of the token (`address`:`networkId`). */
  id: Scalars['String'];
  /**   Whether the token has been flagged as a scam. */
  isScam?: Maybe<Scalars['Boolean']>;
  /**   External social links for the token. */
  links?: Maybe<SocialLinks>;
  /**   The token name. For example, `ApeCoin`. */
  name?: Maybe<Scalars['String']>;
  /**   The network ID the token is deployed on. */
  networkId: Scalars['Int'];
  /**   The amount of this token in the pair. */
  pooled?: Maybe<Scalars['String']>;
  /**   The token symbol. For example, `APE`. */
  symbol?: Maybe<Scalars['String']>;
  /**   The total supply of the token. */
  totalSupply?: Maybe<Scalars['String']>;
};

/**   A token transaction. */
export type Event = {
  __typename?: 'Event';
  /**   The contract address of the token's top pair. */
  address: Scalars['String'];
  /**   The price of the network's base token. */
  baseTokenPrice?: Maybe<Scalars['String']>;
  /**   The hash of the block where the transaction occurred. */
  blockHash: Scalars['String'];
  /**   The block number for the transaction. */
  blockNumber: Scalars['Int'];
  /**   The event-specific data for the transaction. Can be `BurnEventData` or `MintEventData` or `SwapEventData`. */
  data?: Maybe<EventData>;
  /**   A more specific breakdown of `eventType`. Splits `Swap` into `Buy` or `Sell`. */
  eventDisplayType?: Maybe<EventDisplayType>;
  /**   The type of transaction event. Can be `Burn`, `Mint`, `Swap`, `Sync`, `Collect`, or `CollectProtocol`. */
  eventType: EventType;
  /**   The ID of the event (`address`:`networkId`). For example, `0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2:1`. */
  id: Scalars['String'];
  /**   The contract address of the token with higher liquidity in the token's top pair. */
  liquidityToken?: Maybe<Scalars['String']>;
  /**   The index of the log in the block. */
  logIndex: Scalars['Int'];
  /**   The wallet address that performed the transaction. */
  maker?: Maybe<Scalars['String']>;
  /**   The network ID that the token is deployed on. */
  networkId: Scalars['Int'];
  /**   The token of interest within the token's top pair. Can be `token0` or `token1`. */
  quoteToken?: Maybe<QuoteToken>;
  /**   The unix timestamp for when the transaction occurred. */
  timestamp: Scalars['Int'];
  /**   The updated price of `token0` in USD, calculated after the transaction. */
  token0PoolValueUsd?: Maybe<Scalars['String']>;
  /**   The price of `token0` paid/received in USD, including any fees. */
  token0SwapValueUsd?: Maybe<Scalars['String']>;
  /**   The price of `token0` paid/received in the network's base token, including fees. */
  token0ValueBase?: Maybe<Scalars['String']>;
  /**   The updated price of `token1` in USD, calculated after the transaction. */
  token1PoolValueUsd?: Maybe<Scalars['String']>;
  /**   The price of `token1` paid/received in USD, including any fees. */
  token1SwapValueUsd?: Maybe<Scalars['String']>;
  /**   The price of `token1` paid/received in the network's base token, including fees. */
  token1ValueBase?: Maybe<Scalars['String']>;
  /**   The unique hash for the transaction. */
  transactionHash: Scalars['String'];
  /**   The index of the transaction within the block. */
  transactionIndex: Scalars['Int'];
};

/**   Response returned by `getTokenEvents`. */
export type EventConnection = {
  __typename?: 'EventConnection';
  /**   A cursor for use in pagination. */
  cursor?: Maybe<Scalars['String']>;
  /**   undocumented */
  hydration?: Maybe<HydrationResponse>;
  /**   A list of transactions for a token's top pair. */
  items?: Maybe<Array<Maybe<Event>>>;
};

/**   Event-specific data for a token transaction. */
export type EventData = BurnEventData | MintEventData | SwapEventData;

/**   A more specific breakdown of `EventType`. Splits `Swap` into `Buy` and `Sell`. */
export enum EventDisplayType {
  Burn = 'Burn',
  Buy = 'Buy',
  Collect = 'Collect',
  CollectProtocol = 'CollectProtocol',
  Mint = 'Mint',
  Sell = 'Sell',
  Sync = 'Sync'
}

/**   undocumented */
export type EventInputUnion = {
  burnEventInput?: InputMaybe<BurnEventInput>;
  mintEventInput?: InputMaybe<MintEventInput>;
  swapEventInput?: InputMaybe<SwapEventInput>;
};

/**   Input type of `EventQueryTimestamp`. */
export type EventQueryTimestampInput = {
  /**   The unix timestamp for the start of the requested range. */
  from: Scalars['Int'];
  /**   The unix timestamp for the end of the requested range. */
  to: Scalars['Int'];
};

/**   The event type for a token transaction. */
export enum EventType {
  Burn = 'Burn',
  Collect = 'Collect',
  CollectProtocol = 'CollectProtocol',
  Mint = 'Mint',
  Swap = 'Swap',
  Sync = 'Sync'
}

/**   Input type of `EventsQuery`. */
export type EventsQueryInput = {
  /**   The pair contract address to filter by. */
  address: Scalars['String'];
  /**   The list of event display types to filter by. */
  eventDisplayType?: InputMaybe<Array<InputMaybe<EventDisplayType>>>;
  /**   The specific event type to filter by. */
  eventType?: InputMaybe<EventType>;
  /**   The specific wallet address to filter by. */
  maker?: InputMaybe<Scalars['String']>;
  /**   The network ID to filter by. */
  networkId: Scalars['Int'];
  /**   The time range to filter by. */
  timestamp?: InputMaybe<EventQueryTimestampInput>;
};

/**   undocumented */
export type Exchange = {
  __typename?: 'Exchange';
  address: Scalars['String'];
  color?: Maybe<Scalars['String']>;
  exchangeVersion?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  networkId: Scalars['Int'];
  tradeUrl?: Maybe<Scalars['String']>;
};

/**   Response returned by `filterExchanges`. */
export type ExchangeFilterConnection = {
  __typename?: 'ExchangeFilterConnection';
  /**   The number of exchanges returned. */
  count?: Maybe<Scalars['Int']>;
  /**   Where in the list the server started when returning items. */
  offset?: Maybe<Scalars['Int']>;
  /**   The list of exchanges matching the filter parameters. */
  results?: Maybe<Array<Maybe<ExchangeFilterResult>>>;
};

/**   An exchange matching a set of filter parameters. */
export type ExchangeFilterResult = {
  __typename?: 'ExchangeFilterResult';
  /**   The total unique daily active users. */
  dailyActiveUsers?: Maybe<Scalars['Int']>;
  /**   Exchange metadata. */
  exchange?: Maybe<FilterExchange>;
  /**   The total unique monthly active users (30 days). */
  monthlyActiveUsers?: Maybe<Scalars['Int']>;
  /**   The number of transactions on the exchange in the past hour. */
  txnCount1?: Maybe<Scalars['String']>;
  /**   The number of transactions on the exchange in the past 4 hours. */
  txnCount4?: Maybe<Scalars['String']>;
  /**   The number of transactions on the exchange in the past 12 hours. */
  txnCount12?: Maybe<Scalars['String']>;
  /**   The number of transactions on the exchange in the past 24 hours. */
  txnCount24?: Maybe<Scalars['String']>;
  /**   The trade volume in the network's base token in the past hour. */
  volumeNBT1?: Maybe<Scalars['String']>;
  /**   The trade volume in the network's base token in the past 4 hours. */
  volumeNBT4?: Maybe<Scalars['String']>;
  /**   The trade volume in the network's base token in the past 12 hours. */
  volumeNBT12?: Maybe<Scalars['String']>;
  /**   The trade volume in the network's base token in the past 24 hours. */
  volumeNBT24?: Maybe<Scalars['String']>;
  /**   The trade volume in USD in the past hour. */
  volumeUSD1?: Maybe<Scalars['String']>;
  /**   The trade volume in USD in the past 4 hours. */
  volumeUSD4?: Maybe<Scalars['String']>;
  /**   The trade volume in USD in the past 12 hours. */
  volumeUSD12?: Maybe<Scalars['String']>;
  /**   The trade volume in USD in the past 24 hours. */
  volumeUSD24?: Maybe<Scalars['String']>;
};

/**   Input type of `ExchangeFilters`. */
export type ExchangeFilters = {
  /**   The list of exchange contract addresses to filter by. */
  address?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /**   The total unique daily active users. */
  dailyActiveUsers?: InputMaybe<NumberFilter>;
  /**   The total unique monthly active users (30 days). */
  monthlyActiveUsers?: InputMaybe<NumberFilter>;
  /**   The list of network IDs to filter by. */
  network?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /**   The number of transactions on the exchange in the past hour. */
  txnCount1?: InputMaybe<StringFilter>;
  /**   The number of transactions on the exchange in the past 4 hours. */
  txnCount4?: InputMaybe<StringFilter>;
  /**   The number of transactions on the exchange in the past 12 hours. */
  txnCount12?: InputMaybe<StringFilter>;
  /**   The number of transactions on the exchange in the past 24 hours. */
  txnCount24?: InputMaybe<StringFilter>;
  /**   The trade volume in the network's base token in the past hour. */
  volumeNBT1?: InputMaybe<StringFilter>;
  /**   The trade volume in the network's base token in the past 4 hours. */
  volumeNBT4?: InputMaybe<StringFilter>;
  /**   The trade volume in the network's base token in the past 12 hours. */
  volumeNBT12?: InputMaybe<StringFilter>;
  /**   The trade volume in the network's base token in the past 24 hours. */
  volumeNBT24?: InputMaybe<StringFilter>;
  /**   The trade volume in USD in the past hour. */
  volumeUSD1?: InputMaybe<StringFilter>;
  /**   The trade volume in USD in the past 4 hours. */
  volumeUSD4?: InputMaybe<StringFilter>;
  /**   The trade volume in USD in the past 12 hours. */
  volumeUSD12?: InputMaybe<StringFilter>;
  /**   The trade volume in USD in the past 24 hours. */
  volumeUSD24?: InputMaybe<StringFilter>;
};

/**   Input type of `ExchangeRanking`. */
export type ExchangeRanking = {
  /**   The attribute to rank exchanges by. */
  attribute?: InputMaybe<ExchangeRankingAttribute>;
  /**   The direction to apply to the ranking attribute. */
  direction?: InputMaybe<RankingDirection>;
};

/**   The attribute used to rank exchanges. */
export enum ExchangeRankingAttribute {
  DailyActiveUsers = 'dailyActiveUsers',
  MonthlyActiveUsers = 'monthlyActiveUsers',
  TxnCount1 = 'txnCount1',
  TxnCount4 = 'txnCount4',
  TxnCount12 = 'txnCount12',
  TxnCount24 = 'txnCount24',
  VolumeNbt1 = 'volumeNBT1',
  VolumeNbt4 = 'volumeNBT4',
  VolumeNbt12 = 'volumeNBT12',
  VolumeNbt24 = 'volumeNBT24',
  VolumeUsd1 = 'volumeUSD1',
  VolumeUsd4 = 'volumeUSD4',
  VolumeUsd12 = 'volumeUSD12',
  VolumeUsd24 = 'volumeUSD24'
}

/**   Metadata for an exchange. */
export type FilterExchange = {
  __typename?: 'FilterExchange';
  /**   The address for the exchange factory contract. */
  address: Scalars['String'];
  /**   The version of the exchange. For example, `3` for UniswapV3. */
  exchangeVersion?: Maybe<Scalars['String']>;
  /**   The URL for the exchange's icon. */
  iconUrl?: Maybe<Scalars['String']>;
  /**   The ID of the exchange (`address`:`networkId`). */
  id: Scalars['String'];
  /**   The name of the exchange. */
  name?: Maybe<Scalars['String']>;
  /**   The network ID the factory is deployed on. */
  networkId: Scalars['Int'];
  /**   The URL for the exchange's trading platform. */
  tradeUrl?: Maybe<Scalars['String']>;
};

/**   undocumented */
export type GetDecodedCallsInput = {
  contractName: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<CallSortkeyInput>;
  limit?: InputMaybe<Scalars['Int']>;
  methodName?: InputMaybe<Scalars['String']>;
  networkId: Scalars['Int'];
  projectName: Scalars['String'];
  start?: InputMaybe<CallSortkeyInput>;
};

/**   undocumented */
export type GetDecodedLogsInput = {
  contractName: Scalars['String'];
  /**   The cursor to use for pagination. */
  cursor?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<SortkeyInput>;
  eventName?: InputMaybe<Scalars['String']>;
  /**   The maximum number of decoded logs to return. */
  limit?: InputMaybe<Scalars['Int']>;
  networkId: Scalars['Int'];
  projectName: Scalars['String'];
  start?: InputMaybe<SortkeyInput>;
};

/**   Response returned by `getNftPoolCollectionsByExchange`. */
export type GetNftPoolCollectionsResponse = {
  __typename?: 'GetNftPoolCollectionsResponse';
  /**   A cursor for use in pagination. */
  cursor?: Maybe<Scalars['String']>;
  /**   A list of NFT collections. */
  items?: Maybe<Array<Maybe<NftPoolCollectionResponse>>>;
};

/**   Response returned by `getNftPoolsByCollectionAndExchange` and `getNftPoolsByOwner`. */
export type GetNftPoolsResponse = {
  __typename?: 'GetNftPoolsResponse';
  /**   A cursor for use in pagination. */
  cursor?: Maybe<Scalars['String']>;
  /**   A list of NFT pools. */
  items: Array<Maybe<NftPoolResponse>>;
};

/**   Input type of `getTokenPrices`. */
export type GetPriceInput = {
  /**   The contract address of the token. */
  address: Scalars['String'];
  /**   The maximum number of deviations from the token's Liquidity-Weighted Mean Price. This is used to mitigate low liquidity pairs producing prices that are not representative of reality. Default is `1`. */
  maxDeviations?: InputMaybe<Scalars['Float']>;
  /**   The network ID the token is deployed on. */
  networkId: Scalars['Int'];
  /**   The unix timestamp for the price. */
  timestamp?: InputMaybe<Scalars['Int']>;
};

/**   Response returned by `getWebhooks`. */
export type GetWebhooksResponse = {
  __typename?: 'GetWebhooksResponse';
  /**   A cursor for use in pagination. */
  cursor?: Maybe<Scalars['String']>;
  /**   A list of webhooks belonging to a user. */
  items?: Maybe<Array<Maybe<Webhook>>>;
};

/**   The pool variant. */
export enum GraphQlNftPoolVariant {
  Erc20 = 'ERC20',
  Native = 'NATIVE'
}

/**   undocumented */
export type HydrationResponse = {
  __typename?: 'HydrationResponse';
  hydrationId: Scalars['String'];
  queryExecutionId?: Maybe<Scalars['String']>;
  queryExecutionState?: Maybe<Scalars['String']>;
  status: HydrationStatus;
};

/**   undocumented */
export enum HydrationStatus {
  Error = 'Error',
  Finished = 'Finished',
  Indexing = 'Indexing'
}

/**   Bar chart data. */
export type IndividualBarData = {
  __typename?: 'IndividualBarData';
  /**   The closing price. */
  c: Scalars['Float'];
  /**   The high price. */
  h: Scalars['Float'];
  /**   The low price. */
  l: Scalars['Float'];
  /**   The opening price. */
  o: Scalars['Float'];
  /**   The timestamp for the bar. */
  t: Scalars['Int'];
  /**   The volume. */
  v?: Maybe<Scalars['Int']>;
  /**   The volume with higher precision. */
  volume: Scalars['String'];
};

/**   undocumented */
export type IndividualBarInput = {
  c: Scalars['Float'];
  h: Scalars['Float'];
  l: Scalars['Float'];
  o: Scalars['Float'];
  t: Scalars['Int'];
  v?: InputMaybe<Scalars['Int']>;
  volume: Scalars['String'];
};

/**   Integer equals condition. */
export type IntEqualsCondition = {
  __typename?: 'IntEqualsCondition';
  /**   The integer to equal. */
  eq: Scalars['Int'];
};

/**   Input for integer equals condition. */
export type IntEqualsConditionInput = {
  /**   The integer to equal. */
  eq: Scalars['Int'];
};

/**   The status for a network supported on Defined. */
export type MetadataResponse = {
  __typename?: 'MetadataResponse';
  /**   The last processed block on the network. */
  lastProcessedBlock?: Maybe<Scalars['Int']>;
  /**   The unix timestamp for the last processed block on the network. */
  lastProcessedTimestamp?: Maybe<Scalars['Int']>;
  /**   The network ID. */
  networkId: Scalars['Int'];
  /**   The name of the network. */
  networkName: Scalars['String'];
};

/**   Event data for a token mint event. */
export type MintEventData = {
  __typename?: 'MintEventData';
  /**   The amount of `token0` added to the pair. */
  amount0?: Maybe<Scalars['String']>;
  /**   The amount of `token0` added to the pair, adjusted by the number of decimals in the token. For example, if `amount0` is in WEI, `amount0Shifted` will be in ETH. */
  amount0Shifted?: Maybe<Scalars['String']>;
  /**   The amount of `token1` added to the pair. */
  amount1?: Maybe<Scalars['String']>;
  /**   The amount of `token1` added to the pair, adjusted by the number of decimals in the token. For example, USDC `amount1Shifted` will be by 6 decimals. */
  amount1Shifted?: Maybe<Scalars['String']>;
  /**   The lower tick boundary of the position. Only applicable for UniswapV3 events. */
  tickLower?: Maybe<Scalars['String']>;
  /**   The upper tick boundary of the position. Only applicable for UniswapV3 events. */
  tickUpper?: Maybe<Scalars['String']>;
  /**   The type of token event, `Mint`. */
  type: EventType;
};

/**   undocumented */
export type MintEventDataInput = {
  amount0?: InputMaybe<Scalars['String']>;
  amount0Shifted?: InputMaybe<Scalars['String']>;
  amount1?: InputMaybe<Scalars['String']>;
  amount1Shifted?: InputMaybe<Scalars['String']>;
  tickLower?: InputMaybe<Scalars['String']>;
  tickUpper?: InputMaybe<Scalars['String']>;
  type: EventType;
};

/**   undocumented */
export type MintEventInput = {
  address: Scalars['String'];
  baseTokenPrice?: InputMaybe<Scalars['String']>;
  blockHash: Scalars['String'];
  blockNumber: Scalars['Int'];
  data?: InputMaybe<MintEventDataInput>;
  eventDisplayType?: InputMaybe<EventDisplayType>;
  id: Scalars['String'];
  liquidityToken?: InputMaybe<Scalars['String']>;
  logIndex: Scalars['Int'];
  maker?: InputMaybe<Scalars['String']>;
  networkId: Scalars['Int'];
  quoteToken?: InputMaybe<QuoteToken>;
  timestamp: Scalars['Int'];
  token0PoolValueUsd?: InputMaybe<Scalars['String']>;
  token0SwapValueUsd?: InputMaybe<Scalars['String']>;
  token0ValueBase?: InputMaybe<Scalars['String']>;
  token1PoolValueUsd?: InputMaybe<Scalars['String']>;
  token1SwapValueUsd?: InputMaybe<Scalars['String']>;
  token1ValueBase?: InputMaybe<Scalars['String']>;
  transactionHash: Scalars['String'];
  transactionIndex: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /**   undocumented */
  addEvents: AddEventsOutput;
  /**   undocumented */
  addEventsLiquid: AddEventsOutput;
  /**   undocumented */
  addNftEvents: AddNftEventsOutput;
  /**   undocumented */
  addNftPoolEvents: AddNftPoolEventsOutput;
  /**
   *   Create price, decoded log, decoded call, raw call trace, token/pair event, and NFT event webhooks.
   *  input: Input for creating webhooks.
   */
  createWebhooks: CreateWebhooksOutput;
  /**
   *   Delete multiple webhooks.
   *  input: Input for deleting webhooks.
   */
  deleteWebhooks?: Maybe<DeleteWebhooksOutput>;
  /**   undocumented */
  updateAggregateBatch: UpdateAggregateBatchResponse;
  /**   undocumented */
  updateAggregateBatchLiquid: UpdateAggregateBatchResponse;
  /**   undocumented */
  updatePrice: Price;
};


export type MutationAddEventsArgs = {
  input: AddEventsInput;
};


export type MutationAddEventsLiquidArgs = {
  input: AddEventsInput;
};


export type MutationAddNftEventsArgs = {
  input: AddNftEventsInput;
};


export type MutationAddNftPoolEventsArgs = {
  input: AddNftPoolEventsInput;
};


export type MutationCreateWebhooksArgs = {
  input: CreateWebhooksInput;
};


export type MutationDeleteWebhooksArgs = {
  input: DeleteWebhooksInput;
};


export type MutationUpdateAggregateBatchArgs = {
  input: UpdateAggregateBatchInput;
};


export type MutationUpdateAggregateBatchLiquidArgs = {
  input: UpdateAggregateBatchInput;
};


export type MutationUpdatePriceArgs = {
  input: UpdatePriceInput;
};

/**   A network supported on Defined. */
export type Network = {
  __typename?: 'Network';
  /**   The network ID. For example, `42161` for `arbitrum`. */
  id: Scalars['Int'];
  /**   The name of the network. For example, `arbitrum`. */
  name: Scalars['String'];
};

/**   Event data for creating a new NFT pool. */
export type NewPoolEventData = {
  __typename?: 'NewPoolEventData';
  /**   The wallet address that will receive the tokens or NFT sent to the pair during swaps. */
  assetRecipientAddress: Scalars['String'];
  /**   The contract address of the bonding curve. */
  bondingCurveAddress: Scalars['String'];
  /**   The bonding curve type that defines how the prices of NFTs change after each buy or sell within a pool. */
  bondingCurveType: BondingCurveType;
  /**   The initial price at which the pool is willing to buy an NFT in the pool's liquidity token. */
  buyPriceT: Scalars['String'];
  /**   The contract address of the NFT collection. */
  collectionAddress: Scalars['String'];
  /**   The unix timestamp for the time the pool was created. */
  createdAt: Scalars['Int'];
  /**   The initial delta used in the bonding curve. */
  delta: Scalars['String'];
  /**   The pool fee amount in the pool's liquidity token. */
  feeAmountT: Scalars['String'];
  /**   The ratio of the transaction token to the network's base token. */
  nbtRatio: Scalars['String'];
  /**   The network ID the NFT collection is deployed on. */
  networkId: Scalars['Int'];
  /**   The number of NFTs in the contract after the block has processed. */
  nftTokenBalance: Scalars['String'];
  /**   The wallet address of the pool owner. */
  ownerAddress: Scalars['String'];
  /**   The contract address of the NFT pool. */
  poolAddress: Scalars['String'];
  /**   The initial price at which the pool is willing to sell an NFT in the pool's liquidity token. */
  sellPriceT: Scalars['String'];
  /**   The initial spot price in the pool's liquidity token. */
  startPriceT: Scalars['String'];
  /**   The contract address of the liquidity token of the pool (usually WETH). */
  tokenAddress: Scalars['String'];
  /**   The amount of token in the contract after the block has processed in the pool's liquidity token. */
  tokenBalanceT: Scalars['String'];
  /**   The type of NFT pool event, `NEW_POOL`. */
  type: NftPoolEventType;
  /**   The ratio of the transaction token to USD. */
  usdRatio: Scalars['String'];
};

/**   undocumented */
export type NewPoolEventDataInput = {
  assetRecipientAddress: Scalars['String'];
  bondingCurveAddress: Scalars['String'];
  bondingCurveType: BondingCurveType;
  buyPriceT: Scalars['String'];
  collectionAddress: Scalars['String'];
  createdAt: Scalars['Int'];
  delta: Scalars['String'];
  feeAmountT: Scalars['String'];
  nbtRatio: Scalars['String'];
  networkId: Scalars['Int'];
  nftTokenBalance: Scalars['String'];
  ownerAddress: Scalars['String'];
  poolAddress: Scalars['String'];
  sellPriceT: Scalars['String'];
  startPriceT: Scalars['String'];
  tokenAddress: Scalars['String'];
  tokenBalanceT: Scalars['String'];
  type: NftPoolEventType;
  usdRatio: Scalars['String'];
};

/**   Event data for creating a new NFT pool. */
export type NewPoolEventDataV2 = {
  __typename?: 'NewPoolEventDataV2';
  /**   The wallet address that will receive the tokens or NFT sent to the pair during swaps. */
  assetRecipientAddress: Scalars['String'];
  /**   The contract address of the bonding curve. */
  bondingCurveAddress: Scalars['String'];
  /**   The bonding curve type that defines how the prices of NFTs change after each buy or sell within a pool. */
  bondingCurveType: BondingCurveType;
  /**   The initial price at which the pool is willing to buy an NFT in the pool's liquidity token. */
  buyPriceT: Scalars['String'];
  /**   The contract address of the NFT collection. */
  collectionAddress: Scalars['String'];
  /**   The unix timestamp for the time the pool was created. */
  createdAt: Scalars['Int'];
  /**   The initial delta used in the bonding curve. */
  delta: Scalars['String'];
  /**   The pool fee amount in the pool's liquidity token. */
  feeAmountT: Scalars['String'];
  /**   The ratio of the transaction token to the network's base token. */
  nbtRatio: Scalars['String'];
  /**   The network ID the NFT collection is deployed on. */
  networkId: Scalars['Int'];
  /**   The list of NFT token IDs initially deposited. */
  nftTokenIds: Array<Maybe<Scalars['String']>>;
  /**   The amount of each NFT token initially deposited. */
  nftTokenQuantities: Array<Maybe<Scalars['String']>>;
  /**   The wallet address of the pool owner. */
  ownerAddress: Scalars['String'];
  /**   The contract address of the NFT pool. */
  poolAddress: Scalars['String'];
  /**   The type of NFT in the pool. */
  poolNftType: PoolNftType;
  /**   The property checker contract address for the pool. */
  propertyChecker?: Maybe<Scalars['String']>;
  /**   The list of royalties for the pool. Only applicable for `SUDOSWAP_V2` pools. */
  royalties?: Maybe<Array<Maybe<NftPoolRoyalty>>>;
  /**   The initial price at which the pool is willing to sell an NFT in the pool's liquidity token. */
  sellPriceT: Scalars['String'];
  /**   The initial spot price in the pool's liquidity token. */
  startPriceT: Scalars['String'];
  /**   The contract address of the liquidity token of the pool (usually WETH). */
  tokenAddress: Scalars['String'];
  /**   The amount of token in the contract after the block has processed in the pool's liquidity token. */
  tokenBalanceT: Scalars['String'];
  /**   The type of NFT pool event, `NEW_POOL`. */
  type: NftPoolEventType;
  /**   The ratio of the transaction token to USD. */
  usdRatio: Scalars['String'];
};

/**   undocumented */
export type NewPoolEventDataV2Input = {
  assetRecipientAddress: Scalars['String'];
  bondingCurveAddress: Scalars['String'];
  bondingCurveType: BondingCurveType;
  buyPriceT: Scalars['String'];
  collectionAddress: Scalars['String'];
  createdAt: Scalars['Int'];
  delta: Scalars['String'];
  feeAmountT: Scalars['String'];
  nbtRatio: Scalars['String'];
  networkId: Scalars['Int'];
  nftTokenIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nftTokenQuantities?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ownerAddress: Scalars['String'];
  poolAddress: Scalars['String'];
  poolNftType: PoolNftType;
  propertyChecker?: InputMaybe<Scalars['String']>;
  royalties?: InputMaybe<Array<InputMaybe<NftPoolRoyaltyInput>>>;
  sellPriceT: Scalars['String'];
  startPriceT: Scalars['String'];
  tokenAddress: Scalars['String'];
  tokenBalanceT: Scalars['String'];
  type: NftPoolEventType;
  usdRatio: Scalars['String'];
};

/**   An NFT asset. */
export type NftAsset = {
  __typename?: 'NftAsset';
  /**   The contract address of the NFT collection. */
  address: Scalars['String'];
  /**   The attributes for the NFT asset. */
  attributes?: Maybe<Array<NftAssetAttribute>>;
  /**   The description of the NFT asset. */
  description?: Maybe<Scalars['String']>;
  /**   The ID of the NFT asset (`address`:`tokenId`). */
  id: Scalars['String'];
  /**   The NFT asset media. */
  media?: Maybe<NftAssetMedia>;
  /**   The name of the NFT asset. */
  name?: Maybe<Scalars['String']>;
  /**   The network ID the NFT collection is deployed on. */
  networkId: Scalars['Int'];
  /**   The source image URI linked by smart contract metadata. */
  originalImage?: Maybe<Scalars['String']>;
  /**   undocumented */
  quantity?: Maybe<Scalars['String']>;
  /**   The token ID of the NFT asset. */
  tokenId: Scalars['String'];
  /**   The URI provided by the smart contract. Typically JSON that contains metadata. */
  uri?: Maybe<Scalars['String']>;
};

/**   Attributes for an NFT asset. */
export type NftAssetAttribute = {
  __typename?: 'NftAssetAttribute';
  /**   Suggested class name to use for css styling. An optional attribute of ERC-1155 assets. */
  class?: Maybe<Scalars['String']>;
  /**   Suggested CSS styling. An optional attribute of ERC-1155 assets. */
  css?: Maybe<Scalars['String']>;
  /**   The attribute display type. Can be `Trait`, `Stat`, `Ranking`, `BoostNumber`, `BoostPercentage` or `Date`. */
  displayType: NftAssetAttributeDisplayType;
  /**   The max value, if applicable. */
  maxValue?: Maybe<Scalars['String']>;
  /**   The name of the attribute. */
  name: Scalars['String'];
  /**   The value of the attribute. */
  value: Scalars['String'];
  /**   The type for the `value` field. Can be `String`, `Number` or `Array`. */
  valueType: NftAssetAttributeType;
};

/**   The display type for the NFT asset attribute. */
export enum NftAssetAttributeDisplayType {
  BoostNumber = 'BoostNumber',
  BoostPercentage = 'BoostPercentage',
  Date = 'Date',
  Ranking = 'Ranking',
  Stat = 'Stat',
  Trait = 'Trait'
}

/**   The type for the NFT asset attribute `value` field. */
export enum NftAssetAttributeType {
  Array = 'Array',
  Number = 'Number',
  String = 'String'
}

/**   NFT asset media. */
export type NftAssetMedia = {
  __typename?: 'NftAssetMedia';
  /**   The URL for a full size image of the NFT asset. */
  image: Scalars['String'];
  /**   The URL for large generated thumbnail of the NFT asset. */
  thumbLg: Scalars['String'];
  /**   The URL for small generated thumbnail of the NFT asset. */
  thumbSm: Scalars['String'];
};

/**   Response returned by `getNftAssets`. */
export type NftAssetsConnection = {
  __typename?: 'NftAssetsConnection';
  /**   A cursor for use in pagination. */
  cursor?: Maybe<Scalars['String']>;
  /**   A list of NFT assets. */
  items?: Maybe<Array<Maybe<NftAsset>>>;
};

/**   Price stats for an NFT collection over a time frame. Either in USD or the network's base token. */
export type NftCollectionCurrencyStats = {
  __typename?: 'NftCollectionCurrencyStats';
  /**   The average sale price in the time frame. */
  average?: Maybe<NftStatsStringMetrics>;
  /**   The closing price for the time frame. */
  close?: Maybe<NftStatsStringMetrics>;
  /**   The closing floor listing price for the time frame. */
  closeListingFloor?: Maybe<NftStatsStringMetrics>;
  /**   The highest listing price in the time frame. */
  highestListingFloor?: Maybe<NftStatsStringMetrics>;
  /**   The highest sale price in the time frame. */
  highestSale?: Maybe<NftStatsStringMetrics>;
  /**   The lowest listing price for the time frame. */
  listingFloor?: Maybe<NftStatsStringMetrics>;
  /**   The lowest sale price in the time frame. */
  lowestSale?: Maybe<NftStatsStringMetrics>;
  /**   The opening price for the time frame. */
  open?: Maybe<NftStatsStringMetrics>;
  /**   The opening floor listing price for the time frame. */
  openListingFloor?: Maybe<NftStatsStringMetrics>;
  /**   The volume over the time frame. */
  volume?: Maybe<NftStatsStringMetrics>;
};

/**   Response returned by `filterNftCollections`. */
export type NftCollectionFilterConnection = {
  __typename?: 'NftCollectionFilterConnection';
  /**   The number of NFT collections returned. */
  count?: Maybe<Scalars['Int']>;
  /**   Where in the list the server started when returning items. */
  offset?: Maybe<Scalars['Int']>;
  /**   The list of NFT collections matching the filter parameters. */
  results?: Maybe<Array<Maybe<NftCollectionFilterResult>>>;
};

/**   An NFT collection matching a set of filter parameters. */
export type NftCollectionFilterResult = {
  __typename?: 'NftCollectionFilterResult';
  /**   The contract address of the NFT collection. */
  address?: Maybe<Scalars['String']>;
  /**   The token standard. Can be a variation of `ERC-721` or `ERC-1155`. */
  ercType?: Maybe<Scalars['String']>;
  /**   The marketplace address or `all`. Can be used to get marketplace-specific metrics. */
  grouping?: Maybe<Scalars['String']>;
  /**   The ID of the NFT collection (`address`:`networkId`). */
  id?: Maybe<Scalars['String']>;
  /**   The image URL for the collection or one of the assets within the collection. */
  imageUrl?: Maybe<Scalars['String']>;
  /**   The unix timestamp for the last event. */
  lastEventTimestamp?: Maybe<Scalars['Int']>;
  /**   The name of the NFT collection. */
  name?: Maybe<Scalars['String']>;
  /**   The network ID the NFT collection is deployed on. */
  networkId?: Maybe<Scalars['Int']>;
  /**   Stats for the past hour. */
  stats1h?: Maybe<NftStatsWindowWithChange>;
  /**   Stats for the past 4 hours. */
  stats4h?: Maybe<NftStatsWindowWithChange>;
  /**   Stats for the past 12 hours. */
  stats12h?: Maybe<NftStatsWindowWithChange>;
  /**   Stats for the past 24 hours. */
  stats24h?: Maybe<NftStatsWindowWithChange>;
  /**   The symbol of the NFT collection. */
  symbol?: Maybe<Scalars['String']>;
  /**   The unix timestamp indicating the last time the data was updated. Updates daily. */
  timestamp?: Maybe<Scalars['Int']>;
  /**   The total supply of the NFT collection. */
  totalSupply?: Maybe<Scalars['String']>;
};

/**   Input filters for `filterNftCollections`. */
export type NftCollectionFilters = {
  /**   The list of token standards to filter by. */
  ercType?: InputMaybe<Array<InputMaybe<NftContractErcType>>>;
  /**   The unix timestamp for the last event. */
  lastEventTimestamp?: InputMaybe<NumberFilter>;
  /**   The list of network IDs to filter by. */
  network?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /**   Stats for the past hour. */
  stats1h?: InputMaybe<NftStatsWindowFilter>;
  /**   Stats for the past 4 hours. */
  stats4h?: InputMaybe<NftStatsWindowFilter>;
  /**   Stats for the past 12 hours. */
  stats12h?: InputMaybe<NftStatsWindowFilter>;
  /**   Stats for the past 24 hours. */
  stats24h?: InputMaybe<NftStatsWindowFilter>;
  /**   The total supply of the NFT collection. */
  totalSupply?: InputMaybe<NumberFilter>;
};

/**   Response returned by `getNftCollectionMetadata`. */
export type NftCollectionMetadataResponse = {
  __typename?: 'NftCollectionMetadataResponse';
  /**   Metadata for the NFT collection. */
  contract: NftContract;
  /**   The ID of the NFT collection (`address`:`networkId`). */
  id: Scalars['String'];
  /**
   *   The media for one of the assets within the NFT collection.
   * @deprecated use `image` from the `NftContract` instead.
   */
  media?: Maybe<NftAssetMedia>;
  /**   A list of stats for the NFT collection across different time frames. */
  stats?: Maybe<Array<Maybe<NftCollectionWindowStats>>>;
};

/**   Numerical stats for an NFT collection over a time frame. */
export type NftCollectionNonCurrencyStats = {
  __typename?: 'NftCollectionNonCurrencyStats';
  /**   The number of mints over the time frame. */
  mints?: Maybe<NftStatsNumberMetrics>;
  /**   The number of sales over the time frame. */
  sales?: Maybe<NftStatsNumberMetrics>;
  /**   The number of tokens sold over the time frame. */
  tokensSold?: Maybe<NftStatsStringMetrics>;
  /**   The number of transfers over the time frame. */
  transfers?: Maybe<NftStatsNumberMetrics>;
  /**   The number of unique buyers over the time frame. */
  uniqueBuyers?: Maybe<NftStatsNumberMetrics>;
  /**   The number of unique minters over the time frame. */
  uniqueMinters?: Maybe<NftStatsNumberMetrics>;
  /**   The number of unique wallets (buyers or sellers) over the time frame. */
  uniqueSalesWallets?: Maybe<NftStatsNumberMetrics>;
  /**   The number of unique sellers over the time frame. */
  uniqueSellers?: Maybe<NftStatsNumberMetrics>;
};

/**   Price stats for an NFT collection over a time frame. Either in USD or the network's base token. */
export type NftCollectionPriceStats = {
  __typename?: 'NftCollectionPriceStats';
  /**   The average sale price. */
  average: Scalars['String'];
  /**   The change in average price between the previous and current time frame. */
  averageChange?: Maybe<Scalars['Float']>;
  /**   The highest sale price. */
  ceiling: Scalars['String'];
  /**   The change in ceiling price between the previous and current time frame. */
  ceilingChange?: Maybe<Scalars['Float']>;
  /**   The lowest sale price. */
  floor: Scalars['String'];
  /**   The change in floor price between the previous and current time frame. */
  floorChange?: Maybe<Scalars['Float']>;
  /**   The lowest listing price. */
  listingFloor?: Maybe<Scalars['String']>;
  /**   The change in floor listing price between the previous and current time frame. */
  listingFloorChange?: Maybe<Scalars['Float']>;
  /**   The trade volume. */
  volume: Scalars['String'];
  /**   The change in volume between the previous and current time frame. */
  volumeChange?: Maybe<Scalars['Float']>;
};

/**   Input type of `NftCollectionRanking`. */
export type NftCollectionRanking = {
  /**   The attribute to rank NFT collections by. */
  attribute?: InputMaybe<NftCollectionRankingAttribute>;
  /**   The direction to apply to the ranking attribute. */
  direction?: InputMaybe<RankingDirection>;
};

/**   The attribute used to rank NFT collections. */
export enum NftCollectionRankingAttribute {
  LastEventTimestamp = 'lastEventTimestamp',
  Stats1hNetworkBaseTokenAverageChange = 'stats1hNetworkBaseTokenAverageChange',
  Stats1hNetworkBaseTokenAverageCurrent = 'stats1hNetworkBaseTokenAverageCurrent',
  Stats1hNetworkBaseTokenAveragePrevious = 'stats1hNetworkBaseTokenAveragePrevious',
  Stats1hNetworkBaseTokenCloseChange = 'stats1hNetworkBaseTokenCloseChange',
  Stats1hNetworkBaseTokenCloseCurrent = 'stats1hNetworkBaseTokenCloseCurrent',
  Stats1hNetworkBaseTokenCloseListingFloorChange = 'stats1hNetworkBaseTokenCloseListingFloorChange',
  Stats1hNetworkBaseTokenCloseListingFloorCurrent = 'stats1hNetworkBaseTokenCloseListingFloorCurrent',
  Stats1hNetworkBaseTokenCloseListingFloorPrevious = 'stats1hNetworkBaseTokenCloseListingFloorPrevious',
  Stats1hNetworkBaseTokenClosePrevious = 'stats1hNetworkBaseTokenClosePrevious',
  Stats1hNetworkBaseTokenHighestListingFloorChange = 'stats1hNetworkBaseTokenHighestListingFloorChange',
  Stats1hNetworkBaseTokenHighestListingFloorCurrent = 'stats1hNetworkBaseTokenHighestListingFloorCurrent',
  Stats1hNetworkBaseTokenHighestListingFloorPrevious = 'stats1hNetworkBaseTokenHighestListingFloorPrevious',
  Stats1hNetworkBaseTokenHighestSaleChange = 'stats1hNetworkBaseTokenHighestSaleChange',
  Stats1hNetworkBaseTokenHighestSaleCurrent = 'stats1hNetworkBaseTokenHighestSaleCurrent',
  Stats1hNetworkBaseTokenHighestSalePrevious = 'stats1hNetworkBaseTokenHighestSalePrevious',
  Stats1hNetworkBaseTokenListingFloorChange = 'stats1hNetworkBaseTokenListingFloorChange',
  Stats1hNetworkBaseTokenListingFloorCurrent = 'stats1hNetworkBaseTokenListingFloorCurrent',
  Stats1hNetworkBaseTokenListingFloorPrevious = 'stats1hNetworkBaseTokenListingFloorPrevious',
  Stats1hNetworkBaseTokenLowestSaleChange = 'stats1hNetworkBaseTokenLowestSaleChange',
  Stats1hNetworkBaseTokenLowestSaleCurrent = 'stats1hNetworkBaseTokenLowestSaleCurrent',
  Stats1hNetworkBaseTokenLowestSalePrevious = 'stats1hNetworkBaseTokenLowestSalePrevious',
  Stats1hNetworkBaseTokenOpenChange = 'stats1hNetworkBaseTokenOpenChange',
  Stats1hNetworkBaseTokenOpenCurrent = 'stats1hNetworkBaseTokenOpenCurrent',
  Stats1hNetworkBaseTokenOpenListingFloorChange = 'stats1hNetworkBaseTokenOpenListingFloorChange',
  Stats1hNetworkBaseTokenOpenListingFloorCurrent = 'stats1hNetworkBaseTokenOpenListingFloorCurrent',
  Stats1hNetworkBaseTokenOpenListingFloorPrevious = 'stats1hNetworkBaseTokenOpenListingFloorPrevious',
  Stats1hNetworkBaseTokenOpenPrevious = 'stats1hNetworkBaseTokenOpenPrevious',
  Stats1hNetworkBaseTokenVolumeChange = 'stats1hNetworkBaseTokenVolumeChange',
  Stats1hNetworkBaseTokenVolumeCurrent = 'stats1hNetworkBaseTokenVolumeCurrent',
  Stats1hNetworkBaseTokenVolumePrevious = 'stats1hNetworkBaseTokenVolumePrevious',
  Stats1hNonCurrencyMintsChange = 'stats1hNonCurrencyMintsChange',
  Stats1hNonCurrencyMintsCurrent = 'stats1hNonCurrencyMintsCurrent',
  Stats1hNonCurrencyMintsPrevious = 'stats1hNonCurrencyMintsPrevious',
  Stats1hNonCurrencySalesChange = 'stats1hNonCurrencySalesChange',
  Stats1hNonCurrencySalesCurrent = 'stats1hNonCurrencySalesCurrent',
  Stats1hNonCurrencySalesPrevious = 'stats1hNonCurrencySalesPrevious',
  Stats1hNonCurrencyTokensSoldChange = 'stats1hNonCurrencyTokensSoldChange',
  Stats1hNonCurrencyTokensSoldCurrent = 'stats1hNonCurrencyTokensSoldCurrent',
  Stats1hNonCurrencyTokensSoldPrevious = 'stats1hNonCurrencyTokensSoldPrevious',
  Stats1hNonCurrencyTransfersChange = 'stats1hNonCurrencyTransfersChange',
  Stats1hNonCurrencyTransfersCurrent = 'stats1hNonCurrencyTransfersCurrent',
  Stats1hNonCurrencyTransfersPrevious = 'stats1hNonCurrencyTransfersPrevious',
  Stats1hNonCurrencyUniqueBuyersChange = 'stats1hNonCurrencyUniqueBuyersChange',
  Stats1hNonCurrencyUniqueBuyersCurrent = 'stats1hNonCurrencyUniqueBuyersCurrent',
  Stats1hNonCurrencyUniqueBuyersPrevious = 'stats1hNonCurrencyUniqueBuyersPrevious',
  Stats1hNonCurrencyUniqueMintersChange = 'stats1hNonCurrencyUniqueMintersChange',
  Stats1hNonCurrencyUniqueMintersCurrent = 'stats1hNonCurrencyUniqueMintersCurrent',
  Stats1hNonCurrencyUniqueMintersPrevious = 'stats1hNonCurrencyUniqueMintersPrevious',
  Stats1hNonCurrencyUniqueSalesWalletsChange = 'stats1hNonCurrencyUniqueSalesWalletsChange',
  Stats1hNonCurrencyUniqueSalesWalletsCurrent = 'stats1hNonCurrencyUniqueSalesWalletsCurrent',
  Stats1hNonCurrencyUniqueSalesWalletsPrevious = 'stats1hNonCurrencyUniqueSalesWalletsPrevious',
  Stats1hNonCurrencyUniqueSellersChange = 'stats1hNonCurrencyUniqueSellersChange',
  Stats1hNonCurrencyUniqueSellersCurrent = 'stats1hNonCurrencyUniqueSellersCurrent',
  Stats1hNonCurrencyUniqueSellersPrevious = 'stats1hNonCurrencyUniqueSellersPrevious',
  Stats1hUsdAverageChange = 'stats1hUsdAverageChange',
  Stats1hUsdAverageCurrent = 'stats1hUsdAverageCurrent',
  Stats1hUsdAveragePrevious = 'stats1hUsdAveragePrevious',
  Stats1hUsdCloseChange = 'stats1hUsdCloseChange',
  Stats1hUsdCloseCurrent = 'stats1hUsdCloseCurrent',
  Stats1hUsdCloseListingFloorChange = 'stats1hUsdCloseListingFloorChange',
  Stats1hUsdCloseListingFloorCurrent = 'stats1hUsdCloseListingFloorCurrent',
  Stats1hUsdCloseListingFloorPrevious = 'stats1hUsdCloseListingFloorPrevious',
  Stats1hUsdClosePrevious = 'stats1hUsdClosePrevious',
  Stats1hUsdHighestListingFloorChange = 'stats1hUsdHighestListingFloorChange',
  Stats1hUsdHighestListingFloorCurrent = 'stats1hUsdHighestListingFloorCurrent',
  Stats1hUsdHighestListingFloorPrevious = 'stats1hUsdHighestListingFloorPrevious',
  Stats1hUsdHighestSaleChange = 'stats1hUsdHighestSaleChange',
  Stats1hUsdHighestSaleCurrent = 'stats1hUsdHighestSaleCurrent',
  Stats1hUsdHighestSalePrevious = 'stats1hUsdHighestSalePrevious',
  Stats1hUsdListingFloorChange = 'stats1hUsdListingFloorChange',
  Stats1hUsdListingFloorCurrent = 'stats1hUsdListingFloorCurrent',
  Stats1hUsdListingFloorPrevious = 'stats1hUsdListingFloorPrevious',
  Stats1hUsdLowestSaleChange = 'stats1hUsdLowestSaleChange',
  Stats1hUsdLowestSaleCurrent = 'stats1hUsdLowestSaleCurrent',
  Stats1hUsdLowestSalePrevious = 'stats1hUsdLowestSalePrevious',
  Stats1hUsdOpenChange = 'stats1hUsdOpenChange',
  Stats1hUsdOpenCurrent = 'stats1hUsdOpenCurrent',
  Stats1hUsdOpenListingFloorChange = 'stats1hUsdOpenListingFloorChange',
  Stats1hUsdOpenListingFloorCurrent = 'stats1hUsdOpenListingFloorCurrent',
  Stats1hUsdOpenListingFloorPrevious = 'stats1hUsdOpenListingFloorPrevious',
  Stats1hUsdOpenPrevious = 'stats1hUsdOpenPrevious',
  Stats1hUsdVolumeChange = 'stats1hUsdVolumeChange',
  Stats1hUsdVolumeCurrent = 'stats1hUsdVolumeCurrent',
  Stats1hUsdVolumePrevious = 'stats1hUsdVolumePrevious',
  Stats4hNetworkBaseTokenAverageChange = 'stats4hNetworkBaseTokenAverageChange',
  Stats4hNetworkBaseTokenAverageCurrent = 'stats4hNetworkBaseTokenAverageCurrent',
  Stats4hNetworkBaseTokenAveragePrevious = 'stats4hNetworkBaseTokenAveragePrevious',
  Stats4hNetworkBaseTokenCloseChange = 'stats4hNetworkBaseTokenCloseChange',
  Stats4hNetworkBaseTokenCloseCurrent = 'stats4hNetworkBaseTokenCloseCurrent',
  Stats4hNetworkBaseTokenCloseListingFloorChange = 'stats4hNetworkBaseTokenCloseListingFloorChange',
  Stats4hNetworkBaseTokenCloseListingFloorCurrent = 'stats4hNetworkBaseTokenCloseListingFloorCurrent',
  Stats4hNetworkBaseTokenCloseListingFloorPrevious = 'stats4hNetworkBaseTokenCloseListingFloorPrevious',
  Stats4hNetworkBaseTokenClosePrevious = 'stats4hNetworkBaseTokenClosePrevious',
  Stats4hNetworkBaseTokenHighestListingFloorChange = 'stats4hNetworkBaseTokenHighestListingFloorChange',
  Stats4hNetworkBaseTokenHighestListingFloorCurrent = 'stats4hNetworkBaseTokenHighestListingFloorCurrent',
  Stats4hNetworkBaseTokenHighestListingFloorPrevious = 'stats4hNetworkBaseTokenHighestListingFloorPrevious',
  Stats4hNetworkBaseTokenHighestSaleChange = 'stats4hNetworkBaseTokenHighestSaleChange',
  Stats4hNetworkBaseTokenHighestSaleCurrent = 'stats4hNetworkBaseTokenHighestSaleCurrent',
  Stats4hNetworkBaseTokenHighestSalePrevious = 'stats4hNetworkBaseTokenHighestSalePrevious',
  Stats4hNetworkBaseTokenListingFloorChange = 'stats4hNetworkBaseTokenListingFloorChange',
  Stats4hNetworkBaseTokenListingFloorCurrent = 'stats4hNetworkBaseTokenListingFloorCurrent',
  Stats4hNetworkBaseTokenListingFloorPrevious = 'stats4hNetworkBaseTokenListingFloorPrevious',
  Stats4hNetworkBaseTokenLowestSaleChange = 'stats4hNetworkBaseTokenLowestSaleChange',
  Stats4hNetworkBaseTokenLowestSaleCurrent = 'stats4hNetworkBaseTokenLowestSaleCurrent',
  Stats4hNetworkBaseTokenLowestSalePrevious = 'stats4hNetworkBaseTokenLowestSalePrevious',
  Stats4hNetworkBaseTokenOpenChange = 'stats4hNetworkBaseTokenOpenChange',
  Stats4hNetworkBaseTokenOpenCurrent = 'stats4hNetworkBaseTokenOpenCurrent',
  Stats4hNetworkBaseTokenOpenListingFloorChange = 'stats4hNetworkBaseTokenOpenListingFloorChange',
  Stats4hNetworkBaseTokenOpenListingFloorCurrent = 'stats4hNetworkBaseTokenOpenListingFloorCurrent',
  Stats4hNetworkBaseTokenOpenListingFloorPrevious = 'stats4hNetworkBaseTokenOpenListingFloorPrevious',
  Stats4hNetworkBaseTokenOpenPrevious = 'stats4hNetworkBaseTokenOpenPrevious',
  Stats4hNetworkBaseTokenVolumeChange = 'stats4hNetworkBaseTokenVolumeChange',
  Stats4hNetworkBaseTokenVolumeCurrent = 'stats4hNetworkBaseTokenVolumeCurrent',
  Stats4hNetworkBaseTokenVolumePrevious = 'stats4hNetworkBaseTokenVolumePrevious',
  Stats4hNonCurrencyMintsChange = 'stats4hNonCurrencyMintsChange',
  Stats4hNonCurrencyMintsCurrent = 'stats4hNonCurrencyMintsCurrent',
  Stats4hNonCurrencyMintsPrevious = 'stats4hNonCurrencyMintsPrevious',
  Stats4hNonCurrencySalesChange = 'stats4hNonCurrencySalesChange',
  Stats4hNonCurrencySalesCurrent = 'stats4hNonCurrencySalesCurrent',
  Stats4hNonCurrencySalesPrevious = 'stats4hNonCurrencySalesPrevious',
  Stats4hNonCurrencyTokensSoldChange = 'stats4hNonCurrencyTokensSoldChange',
  Stats4hNonCurrencyTokensSoldCurrent = 'stats4hNonCurrencyTokensSoldCurrent',
  Stats4hNonCurrencyTokensSoldPrevious = 'stats4hNonCurrencyTokensSoldPrevious',
  Stats4hNonCurrencyTransfersChange = 'stats4hNonCurrencyTransfersChange',
  Stats4hNonCurrencyTransfersCurrent = 'stats4hNonCurrencyTransfersCurrent',
  Stats4hNonCurrencyTransfersPrevious = 'stats4hNonCurrencyTransfersPrevious',
  Stats4hNonCurrencyUniqueBuyersChange = 'stats4hNonCurrencyUniqueBuyersChange',
  Stats4hNonCurrencyUniqueBuyersCurrent = 'stats4hNonCurrencyUniqueBuyersCurrent',
  Stats4hNonCurrencyUniqueBuyersPrevious = 'stats4hNonCurrencyUniqueBuyersPrevious',
  Stats4hNonCurrencyUniqueMintersChange = 'stats4hNonCurrencyUniqueMintersChange',
  Stats4hNonCurrencyUniqueMintersCurrent = 'stats4hNonCurrencyUniqueMintersCurrent',
  Stats4hNonCurrencyUniqueMintersPrevious = 'stats4hNonCurrencyUniqueMintersPrevious',
  Stats4hNonCurrencyUniqueSalesWalletsChange = 'stats4hNonCurrencyUniqueSalesWalletsChange',
  Stats4hNonCurrencyUniqueSalesWalletsCurrent = 'stats4hNonCurrencyUniqueSalesWalletsCurrent',
  Stats4hNonCurrencyUniqueSalesWalletsPrevious = 'stats4hNonCurrencyUniqueSalesWalletsPrevious',
  Stats4hNonCurrencyUniqueSellersChange = 'stats4hNonCurrencyUniqueSellersChange',
  Stats4hNonCurrencyUniqueSellersCurrent = 'stats4hNonCurrencyUniqueSellersCurrent',
  Stats4hNonCurrencyUniqueSellersPrevious = 'stats4hNonCurrencyUniqueSellersPrevious',
  Stats4hUsdAverageChange = 'stats4hUsdAverageChange',
  Stats4hUsdAverageCurrent = 'stats4hUsdAverageCurrent',
  Stats4hUsdAveragePrevious = 'stats4hUsdAveragePrevious',
  Stats4hUsdCloseChange = 'stats4hUsdCloseChange',
  Stats4hUsdCloseCurrent = 'stats4hUsdCloseCurrent',
  Stats4hUsdCloseListingFloorChange = 'stats4hUsdCloseListingFloorChange',
  Stats4hUsdCloseListingFloorCurrent = 'stats4hUsdCloseListingFloorCurrent',
  Stats4hUsdCloseListingFloorPrevious = 'stats4hUsdCloseListingFloorPrevious',
  Stats4hUsdClosePrevious = 'stats4hUsdClosePrevious',
  Stats4hUsdHighestListingFloorChange = 'stats4hUsdHighestListingFloorChange',
  Stats4hUsdHighestListingFloorCurrent = 'stats4hUsdHighestListingFloorCurrent',
  Stats4hUsdHighestListingFloorPrevious = 'stats4hUsdHighestListingFloorPrevious',
  Stats4hUsdHighestSaleChange = 'stats4hUsdHighestSaleChange',
  Stats4hUsdHighestSaleCurrent = 'stats4hUsdHighestSaleCurrent',
  Stats4hUsdHighestSalePrevious = 'stats4hUsdHighestSalePrevious',
  Stats4hUsdListingFloorChange = 'stats4hUsdListingFloorChange',
  Stats4hUsdListingFloorCurrent = 'stats4hUsdListingFloorCurrent',
  Stats4hUsdListingFloorPrevious = 'stats4hUsdListingFloorPrevious',
  Stats4hUsdLowestSaleChange = 'stats4hUsdLowestSaleChange',
  Stats4hUsdLowestSaleCurrent = 'stats4hUsdLowestSaleCurrent',
  Stats4hUsdLowestSalePrevious = 'stats4hUsdLowestSalePrevious',
  Stats4hUsdOpenChange = 'stats4hUsdOpenChange',
  Stats4hUsdOpenCurrent = 'stats4hUsdOpenCurrent',
  Stats4hUsdOpenListingFloorChange = 'stats4hUsdOpenListingFloorChange',
  Stats4hUsdOpenListingFloorCurrent = 'stats4hUsdOpenListingFloorCurrent',
  Stats4hUsdOpenListingFloorPrevious = 'stats4hUsdOpenListingFloorPrevious',
  Stats4hUsdOpenPrevious = 'stats4hUsdOpenPrevious',
  Stats4hUsdVolumeChange = 'stats4hUsdVolumeChange',
  Stats4hUsdVolumeCurrent = 'stats4hUsdVolumeCurrent',
  Stats4hUsdVolumePrevious = 'stats4hUsdVolumePrevious',
  Stats12hNetworkBaseTokenAverageChange = 'stats12hNetworkBaseTokenAverageChange',
  Stats12hNetworkBaseTokenAverageCurrent = 'stats12hNetworkBaseTokenAverageCurrent',
  Stats12hNetworkBaseTokenAveragePrevious = 'stats12hNetworkBaseTokenAveragePrevious',
  Stats12hNetworkBaseTokenCloseChange = 'stats12hNetworkBaseTokenCloseChange',
  Stats12hNetworkBaseTokenCloseCurrent = 'stats12hNetworkBaseTokenCloseCurrent',
  Stats12hNetworkBaseTokenCloseListingFloorChange = 'stats12hNetworkBaseTokenCloseListingFloorChange',
  Stats12hNetworkBaseTokenCloseListingFloorCurrent = 'stats12hNetworkBaseTokenCloseListingFloorCurrent',
  Stats12hNetworkBaseTokenCloseListingFloorPrevious = 'stats12hNetworkBaseTokenCloseListingFloorPrevious',
  Stats12hNetworkBaseTokenClosePrevious = 'stats12hNetworkBaseTokenClosePrevious',
  Stats12hNetworkBaseTokenHighestListingFloorChange = 'stats12hNetworkBaseTokenHighestListingFloorChange',
  Stats12hNetworkBaseTokenHighestListingFloorCurrent = 'stats12hNetworkBaseTokenHighestListingFloorCurrent',
  Stats12hNetworkBaseTokenHighestListingFloorPrevious = 'stats12hNetworkBaseTokenHighestListingFloorPrevious',
  Stats12hNetworkBaseTokenHighestSaleChange = 'stats12hNetworkBaseTokenHighestSaleChange',
  Stats12hNetworkBaseTokenHighestSaleCurrent = 'stats12hNetworkBaseTokenHighestSaleCurrent',
  Stats12hNetworkBaseTokenHighestSalePrevious = 'stats12hNetworkBaseTokenHighestSalePrevious',
  Stats12hNetworkBaseTokenListingFloorChange = 'stats12hNetworkBaseTokenListingFloorChange',
  Stats12hNetworkBaseTokenListingFloorCurrent = 'stats12hNetworkBaseTokenListingFloorCurrent',
  Stats12hNetworkBaseTokenListingFloorPrevious = 'stats12hNetworkBaseTokenListingFloorPrevious',
  Stats12hNetworkBaseTokenLowestSaleChange = 'stats12hNetworkBaseTokenLowestSaleChange',
  Stats12hNetworkBaseTokenLowestSaleCurrent = 'stats12hNetworkBaseTokenLowestSaleCurrent',
  Stats12hNetworkBaseTokenLowestSalePrevious = 'stats12hNetworkBaseTokenLowestSalePrevious',
  Stats12hNetworkBaseTokenOpenChange = 'stats12hNetworkBaseTokenOpenChange',
  Stats12hNetworkBaseTokenOpenCurrent = 'stats12hNetworkBaseTokenOpenCurrent',
  Stats12hNetworkBaseTokenOpenListingFloorChange = 'stats12hNetworkBaseTokenOpenListingFloorChange',
  Stats12hNetworkBaseTokenOpenListingFloorCurrent = 'stats12hNetworkBaseTokenOpenListingFloorCurrent',
  Stats12hNetworkBaseTokenOpenListingFloorPrevious = 'stats12hNetworkBaseTokenOpenListingFloorPrevious',
  Stats12hNetworkBaseTokenOpenPrevious = 'stats12hNetworkBaseTokenOpenPrevious',
  Stats12hNetworkBaseTokenVolumeChange = 'stats12hNetworkBaseTokenVolumeChange',
  Stats12hNetworkBaseTokenVolumeCurrent = 'stats12hNetworkBaseTokenVolumeCurrent',
  Stats12hNetworkBaseTokenVolumePrevious = 'stats12hNetworkBaseTokenVolumePrevious',
  Stats12hNonCurrencyMintsChange = 'stats12hNonCurrencyMintsChange',
  Stats12hNonCurrencyMintsCurrent = 'stats12hNonCurrencyMintsCurrent',
  Stats12hNonCurrencyMintsPrevious = 'stats12hNonCurrencyMintsPrevious',
  Stats12hNonCurrencySalesChange = 'stats12hNonCurrencySalesChange',
  Stats12hNonCurrencySalesCurrent = 'stats12hNonCurrencySalesCurrent',
  Stats12hNonCurrencySalesPrevious = 'stats12hNonCurrencySalesPrevious',
  Stats12hNonCurrencyTokensSoldChange = 'stats12hNonCurrencyTokensSoldChange',
  Stats12hNonCurrencyTokensSoldCurrent = 'stats12hNonCurrencyTokensSoldCurrent',
  Stats12hNonCurrencyTokensSoldPrevious = 'stats12hNonCurrencyTokensSoldPrevious',
  Stats12hNonCurrencyTransfersChange = 'stats12hNonCurrencyTransfersChange',
  Stats12hNonCurrencyTransfersCurrent = 'stats12hNonCurrencyTransfersCurrent',
  Stats12hNonCurrencyTransfersPrevious = 'stats12hNonCurrencyTransfersPrevious',
  Stats12hNonCurrencyUniqueBuyersChange = 'stats12hNonCurrencyUniqueBuyersChange',
  Stats12hNonCurrencyUniqueBuyersCurrent = 'stats12hNonCurrencyUniqueBuyersCurrent',
  Stats12hNonCurrencyUniqueBuyersPrevious = 'stats12hNonCurrencyUniqueBuyersPrevious',
  Stats12hNonCurrencyUniqueMintersChange = 'stats12hNonCurrencyUniqueMintersChange',
  Stats12hNonCurrencyUniqueMintersCurrent = 'stats12hNonCurrencyUniqueMintersCurrent',
  Stats12hNonCurrencyUniqueMintersPrevious = 'stats12hNonCurrencyUniqueMintersPrevious',
  Stats12hNonCurrencyUniqueSalesWalletsChange = 'stats12hNonCurrencyUniqueSalesWalletsChange',
  Stats12hNonCurrencyUniqueSalesWalletsCurrent = 'stats12hNonCurrencyUniqueSalesWalletsCurrent',
  Stats12hNonCurrencyUniqueSalesWalletsPrevious = 'stats12hNonCurrencyUniqueSalesWalletsPrevious',
  Stats12hNonCurrencyUniqueSellersChange = 'stats12hNonCurrencyUniqueSellersChange',
  Stats12hNonCurrencyUniqueSellersCurrent = 'stats12hNonCurrencyUniqueSellersCurrent',
  Stats12hNonCurrencyUniqueSellersPrevious = 'stats12hNonCurrencyUniqueSellersPrevious',
  Stats12hUsdAverageChange = 'stats12hUsdAverageChange',
  Stats12hUsdAverageCurrent = 'stats12hUsdAverageCurrent',
  Stats12hUsdAveragePrevious = 'stats12hUsdAveragePrevious',
  Stats12hUsdCloseChange = 'stats12hUsdCloseChange',
  Stats12hUsdCloseCurrent = 'stats12hUsdCloseCurrent',
  Stats12hUsdCloseListingFloorChange = 'stats12hUsdCloseListingFloorChange',
  Stats12hUsdCloseListingFloorCurrent = 'stats12hUsdCloseListingFloorCurrent',
  Stats12hUsdCloseListingFloorPrevious = 'stats12hUsdCloseListingFloorPrevious',
  Stats12hUsdClosePrevious = 'stats12hUsdClosePrevious',
  Stats12hUsdHighestListingFloorChange = 'stats12hUsdHighestListingFloorChange',
  Stats12hUsdHighestListingFloorCurrent = 'stats12hUsdHighestListingFloorCurrent',
  Stats12hUsdHighestListingFloorPrevious = 'stats12hUsdHighestListingFloorPrevious',
  Stats12hUsdHighestSaleChange = 'stats12hUsdHighestSaleChange',
  Stats12hUsdHighestSaleCurrent = 'stats12hUsdHighestSaleCurrent',
  Stats12hUsdHighestSalePrevious = 'stats12hUsdHighestSalePrevious',
  Stats12hUsdListingFloorChange = 'stats12hUsdListingFloorChange',
  Stats12hUsdListingFloorCurrent = 'stats12hUsdListingFloorCurrent',
  Stats12hUsdListingFloorPrevious = 'stats12hUsdListingFloorPrevious',
  Stats12hUsdLowestSaleChange = 'stats12hUsdLowestSaleChange',
  Stats12hUsdLowestSaleCurrent = 'stats12hUsdLowestSaleCurrent',
  Stats12hUsdLowestSalePrevious = 'stats12hUsdLowestSalePrevious',
  Stats12hUsdOpenChange = 'stats12hUsdOpenChange',
  Stats12hUsdOpenCurrent = 'stats12hUsdOpenCurrent',
  Stats12hUsdOpenListingFloorChange = 'stats12hUsdOpenListingFloorChange',
  Stats12hUsdOpenListingFloorCurrent = 'stats12hUsdOpenListingFloorCurrent',
  Stats12hUsdOpenListingFloorPrevious = 'stats12hUsdOpenListingFloorPrevious',
  Stats12hUsdOpenPrevious = 'stats12hUsdOpenPrevious',
  Stats12hUsdVolumeChange = 'stats12hUsdVolumeChange',
  Stats12hUsdVolumeCurrent = 'stats12hUsdVolumeCurrent',
  Stats12hUsdVolumePrevious = 'stats12hUsdVolumePrevious',
  Stats24hNetworkBaseTokenAverageChange = 'stats24hNetworkBaseTokenAverageChange',
  Stats24hNetworkBaseTokenAverageCurrent = 'stats24hNetworkBaseTokenAverageCurrent',
  Stats24hNetworkBaseTokenAveragePrevious = 'stats24hNetworkBaseTokenAveragePrevious',
  Stats24hNetworkBaseTokenCloseChange = 'stats24hNetworkBaseTokenCloseChange',
  Stats24hNetworkBaseTokenCloseCurrent = 'stats24hNetworkBaseTokenCloseCurrent',
  Stats24hNetworkBaseTokenCloseListingFloorChange = 'stats24hNetworkBaseTokenCloseListingFloorChange',
  Stats24hNetworkBaseTokenCloseListingFloorCurrent = 'stats24hNetworkBaseTokenCloseListingFloorCurrent',
  Stats24hNetworkBaseTokenCloseListingFloorPrevious = 'stats24hNetworkBaseTokenCloseListingFloorPrevious',
  Stats24hNetworkBaseTokenClosePrevious = 'stats24hNetworkBaseTokenClosePrevious',
  Stats24hNetworkBaseTokenHighestListingFloorChange = 'stats24hNetworkBaseTokenHighestListingFloorChange',
  Stats24hNetworkBaseTokenHighestListingFloorCurrent = 'stats24hNetworkBaseTokenHighestListingFloorCurrent',
  Stats24hNetworkBaseTokenHighestListingFloorPrevious = 'stats24hNetworkBaseTokenHighestListingFloorPrevious',
  Stats24hNetworkBaseTokenHighestSaleChange = 'stats24hNetworkBaseTokenHighestSaleChange',
  Stats24hNetworkBaseTokenHighestSaleCurrent = 'stats24hNetworkBaseTokenHighestSaleCurrent',
  Stats24hNetworkBaseTokenHighestSalePrevious = 'stats24hNetworkBaseTokenHighestSalePrevious',
  Stats24hNetworkBaseTokenListingFloorChange = 'stats24hNetworkBaseTokenListingFloorChange',
  Stats24hNetworkBaseTokenListingFloorCurrent = 'stats24hNetworkBaseTokenListingFloorCurrent',
  Stats24hNetworkBaseTokenListingFloorPrevious = 'stats24hNetworkBaseTokenListingFloorPrevious',
  Stats24hNetworkBaseTokenLowestSaleChange = 'stats24hNetworkBaseTokenLowestSaleChange',
  Stats24hNetworkBaseTokenLowestSaleCurrent = 'stats24hNetworkBaseTokenLowestSaleCurrent',
  Stats24hNetworkBaseTokenLowestSalePrevious = 'stats24hNetworkBaseTokenLowestSalePrevious',
  Stats24hNetworkBaseTokenOpenChange = 'stats24hNetworkBaseTokenOpenChange',
  Stats24hNetworkBaseTokenOpenCurrent = 'stats24hNetworkBaseTokenOpenCurrent',
  Stats24hNetworkBaseTokenOpenListingFloorChange = 'stats24hNetworkBaseTokenOpenListingFloorChange',
  Stats24hNetworkBaseTokenOpenListingFloorCurrent = 'stats24hNetworkBaseTokenOpenListingFloorCurrent',
  Stats24hNetworkBaseTokenOpenListingFloorPrevious = 'stats24hNetworkBaseTokenOpenListingFloorPrevious',
  Stats24hNetworkBaseTokenOpenPrevious = 'stats24hNetworkBaseTokenOpenPrevious',
  Stats24hNetworkBaseTokenVolumeChange = 'stats24hNetworkBaseTokenVolumeChange',
  Stats24hNetworkBaseTokenVolumeCurrent = 'stats24hNetworkBaseTokenVolumeCurrent',
  Stats24hNetworkBaseTokenVolumePrevious = 'stats24hNetworkBaseTokenVolumePrevious',
  Stats24hNonCurrencyMintsChange = 'stats24hNonCurrencyMintsChange',
  Stats24hNonCurrencyMintsCurrent = 'stats24hNonCurrencyMintsCurrent',
  Stats24hNonCurrencyMintsPrevious = 'stats24hNonCurrencyMintsPrevious',
  Stats24hNonCurrencySalesChange = 'stats24hNonCurrencySalesChange',
  Stats24hNonCurrencySalesCurrent = 'stats24hNonCurrencySalesCurrent',
  Stats24hNonCurrencySalesPrevious = 'stats24hNonCurrencySalesPrevious',
  Stats24hNonCurrencyTokensSoldChange = 'stats24hNonCurrencyTokensSoldChange',
  Stats24hNonCurrencyTokensSoldCurrent = 'stats24hNonCurrencyTokensSoldCurrent',
  Stats24hNonCurrencyTokensSoldPrevious = 'stats24hNonCurrencyTokensSoldPrevious',
  Stats24hNonCurrencyTransfersChange = 'stats24hNonCurrencyTransfersChange',
  Stats24hNonCurrencyTransfersCurrent = 'stats24hNonCurrencyTransfersCurrent',
  Stats24hNonCurrencyTransfersPrevious = 'stats24hNonCurrencyTransfersPrevious',
  Stats24hNonCurrencyUniqueBuyersChange = 'stats24hNonCurrencyUniqueBuyersChange',
  Stats24hNonCurrencyUniqueBuyersCurrent = 'stats24hNonCurrencyUniqueBuyersCurrent',
  Stats24hNonCurrencyUniqueBuyersPrevious = 'stats24hNonCurrencyUniqueBuyersPrevious',
  Stats24hNonCurrencyUniqueMintersChange = 'stats24hNonCurrencyUniqueMintersChange',
  Stats24hNonCurrencyUniqueMintersCurrent = 'stats24hNonCurrencyUniqueMintersCurrent',
  Stats24hNonCurrencyUniqueMintersPrevious = 'stats24hNonCurrencyUniqueMintersPrevious',
  Stats24hNonCurrencyUniqueSalesWalletsChange = 'stats24hNonCurrencyUniqueSalesWalletsChange',
  Stats24hNonCurrencyUniqueSalesWalletsCurrent = 'stats24hNonCurrencyUniqueSalesWalletsCurrent',
  Stats24hNonCurrencyUniqueSalesWalletsPrevious = 'stats24hNonCurrencyUniqueSalesWalletsPrevious',
  Stats24hNonCurrencyUniqueSellersChange = 'stats24hNonCurrencyUniqueSellersChange',
  Stats24hNonCurrencyUniqueSellersCurrent = 'stats24hNonCurrencyUniqueSellersCurrent',
  Stats24hNonCurrencyUniqueSellersPrevious = 'stats24hNonCurrencyUniqueSellersPrevious',
  Stats24hUsdAverageChange = 'stats24hUsdAverageChange',
  Stats24hUsdAverageCurrent = 'stats24hUsdAverageCurrent',
  Stats24hUsdAveragePrevious = 'stats24hUsdAveragePrevious',
  Stats24hUsdCloseChange = 'stats24hUsdCloseChange',
  Stats24hUsdCloseCurrent = 'stats24hUsdCloseCurrent',
  Stats24hUsdCloseListingFloorChange = 'stats24hUsdCloseListingFloorChange',
  Stats24hUsdCloseListingFloorCurrent = 'stats24hUsdCloseListingFloorCurrent',
  Stats24hUsdCloseListingFloorPrevious = 'stats24hUsdCloseListingFloorPrevious',
  Stats24hUsdClosePrevious = 'stats24hUsdClosePrevious',
  Stats24hUsdHighestListingFloorChange = 'stats24hUsdHighestListingFloorChange',
  Stats24hUsdHighestListingFloorCurrent = 'stats24hUsdHighestListingFloorCurrent',
  Stats24hUsdHighestListingFloorPrevious = 'stats24hUsdHighestListingFloorPrevious',
  Stats24hUsdHighestSaleChange = 'stats24hUsdHighestSaleChange',
  Stats24hUsdHighestSaleCurrent = 'stats24hUsdHighestSaleCurrent',
  Stats24hUsdHighestSalePrevious = 'stats24hUsdHighestSalePrevious',
  Stats24hUsdListingFloorChange = 'stats24hUsdListingFloorChange',
  Stats24hUsdListingFloorCurrent = 'stats24hUsdListingFloorCurrent',
  Stats24hUsdListingFloorPrevious = 'stats24hUsdListingFloorPrevious',
  Stats24hUsdLowestSaleChange = 'stats24hUsdLowestSaleChange',
  Stats24hUsdLowestSaleCurrent = 'stats24hUsdLowestSaleCurrent',
  Stats24hUsdLowestSalePrevious = 'stats24hUsdLowestSalePrevious',
  Stats24hUsdOpenChange = 'stats24hUsdOpenChange',
  Stats24hUsdOpenCurrent = 'stats24hUsdOpenCurrent',
  Stats24hUsdOpenListingFloorChange = 'stats24hUsdOpenListingFloorChange',
  Stats24hUsdOpenListingFloorCurrent = 'stats24hUsdOpenListingFloorCurrent',
  Stats24hUsdOpenListingFloorPrevious = 'stats24hUsdOpenListingFloorPrevious',
  Stats24hUsdOpenPrevious = 'stats24hUsdOpenPrevious',
  Stats24hUsdVolumeChange = 'stats24hUsdVolumeChange',
  Stats24hUsdVolumeCurrent = 'stats24hUsdVolumeCurrent',
  Stats24hUsdVolumePrevious = 'stats24hUsdVolumePrevious',
  TotalSupply = 'totalSupply'
}

/**   Stats for an NFT collection for a time frame. */
export type NftCollectionWindowStats = {
  __typename?: 'NftCollectionWindowStats';
  /**   The price stats for the NFT collection in the network's base token. */
  networkBaseTokenPriceStats: NftCollectionPriceStats;
  /**   The trade count over the `window`. */
  tradeCount: Scalars['String'];
  /**   The change in trade count between the previous and current `window`. */
  tradeCountChange: Scalars['Float'];
  /**   The price stats for the NFT collection in USD. */
  usdPriceStats: NftCollectionPriceStats;
  /**   The time frame used to calculate the stats. */
  window: Scalars['String'];
};

/**   Metadata for an NFT collection. */
export type NftContract = {
  __typename?: 'NftContract';
  /**   The contract address of the NFT collection. */
  address: Scalars['String'];
  /**   The description of the NFT collection. */
  description?: Maybe<Scalars['String']>;
  /**   The token standard. Can be a variation of `ERC-721` or `ERC-1155`. */
  ercType: Scalars['String'];
  /**   The ID of the NFT collection (`address`:`networkId`). */
  id: Scalars['String'];
  /**   An image associated with the collection. */
  image?: Maybe<Scalars['String']>;
  /**   The name of the NFT collection. */
  name?: Maybe<Scalars['String']>;
  /**   The network ID the NFT collection is deployed on. */
  networkId: Scalars['Int'];
  /**   The symbol for the NFT collection. */
  symbol?: Maybe<Scalars['String']>;
  /**   The total supply of the NFT collection. */
  totalSupply?: Maybe<Scalars['String']>;
};

/**   Token standards. */
export enum NftContractErcType {
  Erc721 = 'ERC721',
  Erc721Enumerable = 'ERC721Enumerable',
  Erc721Metadata = 'ERC721Metadata',
  Erc1155 = 'ERC1155',
  Erc1155Metadata = 'ERC1155Metadata',
  Unsupported = 'Unsupported'
}

/**   An NFT collection transaction. */
export type NftEvent = {
  __typename?: 'NftEvent';
  /**   The contract address of the marketplace aggregator that routed the transaction. */
  aggregatorAddress?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  baseTokenAddress: Scalars['String'];
  /** @deprecated No longer supported */
  baseTokenPrice?: Maybe<Scalars['String']>;
  /**   The block number for the transaction. */
  blockNumber: Scalars['Int'];
  /**   The contract address of the NFT collection. */
  contractAddress: Scalars['String'];
  /**   undocumented */
  data: NftEventData;
  /**   The event type of the transaction. */
  eventType: Scalars['String'];
  /**   The NFT marketplace address of the transaction. */
  exchangeAddress: Scalars['String'];
  /**   The name of the marketplace that processed the transaction. */
  fillSource?: Maybe<Scalars['String']>;
  /**   The ID of the NFT event (`contractAddress`:`tokenId`:`networkId`). */
  id: Scalars['String'];
  /** @deprecated No longer supported */
  individualBaseTokenPrice?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  individualNetworkBaseTokenPrice?: Maybe<Scalars['String']>;
  /** @deprecated Some events may lack this value - use the nullable individualTradePrice. individualPrice will return null values as an empty string. */
  individualPrice?: Maybe<Scalars['String']>;
  /**   The price of each individual NFT in the network's base token. */
  individualPriceNetworkBaseToken?: Maybe<Scalars['String']>;
  /**   The price of each individual NFT in USD. */
  individualPriceUsd?: Maybe<Scalars['String']>;
  /** @deprecated Some events may lack this value - use the nullable individualTradePrice. individualTokenPrice will return null values as an empty string. */
  individualTokenPrice?: Maybe<Scalars['String']>;
  /**   The price of each individual NFT in the purchasing token. */
  individualTradePrice?: Maybe<Scalars['String']>;
  /**   The index of the log in the block. */
  logIndex: Scalars['Int'];
  /**   The wallet address of the buyer. */
  maker: Scalars['String'];
  /** @deprecated No longer supported */
  networkBaseTokenPrice?: Maybe<Scalars['String']>;
  /**   The network ID the NFT collection is deployed on. */
  networkId: Scalars['Int'];
  /**   The number of assets involved in the transaction. */
  numberOfTokens?: Maybe<Scalars['String']>;
  /**   The contract address of the purchasing token. */
  paymentTokenAddress: Scalars['String'];
  /**   The contract address of the NFT pool, if applicable. */
  poolAddress?: Maybe<Scalars['String']>;
  /**   The reason for the price error, if applicable. Can be `NO_TOKEN_DATA`, `NO_TOKEN_PRICE`, or `LOW_LIQUIDITY_PAIR`. */
  priceError?: Maybe<Scalars['String']>;
  /**   The sortKey for the event (`blockNumber`#`transactionIndex`#`logIndex` (+ #`marketplaceEventLogIndex` if applicable), zero padded). For example, `0000000016414564#00000224#00000413#00000414`. */
  sortKey: Scalars['String'];
  /**   The wallet address of the seller. */
  taker: Scalars['String'];
  /**   The unix timestamp for the transaction. */
  timestamp: Scalars['Int'];
  /**   The token ID of the NFT asset involved in the transaction. */
  tokenId: Scalars['String'];
  /** @deprecated Some events may lack this value - use the nullable totalTradePrice. tokenPrice will return null values as an empty string. */
  tokenPrice: Scalars['String'];
  /** @deprecated Some events may lack this value - use the nullable totalTradePrice. totalPrice will return null values as an empty string. */
  totalPrice: Scalars['String'];
  /**   The total trade price for the transaction in the network's base token. (The transaction can include more than 1 token). */
  totalPriceNetworkBaseToken?: Maybe<Scalars['String']>;
  /**   The total trade price for the transaction in USD. (The transaction can include more than 1 token). */
  totalPriceUsd?: Maybe<Scalars['String']>;
  /**   The total trade price for the transaction in the purchasing token. (The transaction can include more than 1 token). */
  totalTradePrice?: Maybe<Scalars['String']>;
  /**   The unique hash for the transaction. */
  transactionHash: Scalars['String'];
  /**   The index of the transaction within the block. */
  transactionIndex: Scalars['Int'];
};

/**   undocumented */
export type NftEventData = {
  __typename?: 'NftEventData';
  buyHash?: Maybe<Scalars['String']>;
  maker?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  sellHash?: Maybe<Scalars['String']>;
  taker?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

/**   undocumented */
export type NftEventDataInput = {
  buyHash?: InputMaybe<Scalars['String']>;
  maker?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['String']>;
  sellHash?: InputMaybe<Scalars['String']>;
  taker?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

/**   NFT marketplaces for a webhook to listen on. */
export type NftEventFillSourceCondition = {
  __typename?: 'NftEventFillSourceCondition';
  /**   The list of NFT marketplaces. */
  oneOf: Array<WebhookNftEventFillSource>;
};

/**   Input for NFT event fill source condition. */
export type NftEventFillSourceConditionInput = {
  /**   The list of NFT marketplace to equal. */
  oneOf: Array<WebhookNftEventFillSource>;
};

/**   undocumented */
export type NftEventInput = {
  aggregatorAddress?: InputMaybe<Scalars['String']>;
  baseTokenAddress: Scalars['String'];
  baseTokenPrice?: InputMaybe<Scalars['String']>;
  blockNumber: Scalars['Int'];
  contractAddress: Scalars['String'];
  data: NftEventDataInput;
  eventType: Scalars['String'];
  exchangeAddress: Scalars['String'];
  fillSource?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  individualBaseTokenPrice?: InputMaybe<Scalars['String']>;
  individualNetworkBaseTokenPrice?: InputMaybe<Scalars['String']>;
  individualPrice?: InputMaybe<Scalars['String']>;
  individualPriceNetworkBaseToken?: InputMaybe<Scalars['String']>;
  individualPriceUsd?: InputMaybe<Scalars['String']>;
  individualTokenPrice?: InputMaybe<Scalars['String']>;
  individualTradePrice?: InputMaybe<Scalars['String']>;
  logIndex: Scalars['Int'];
  maker: Scalars['String'];
  networkBaseTokenPrice?: InputMaybe<Scalars['String']>;
  networkId: Scalars['Int'];
  numberOfTokens?: InputMaybe<Scalars['String']>;
  paymentTokenAddress: Scalars['String'];
  poolAddress?: InputMaybe<Scalars['String']>;
  priceError?: InputMaybe<Scalars['String']>;
  sortKey: Scalars['String'];
  taker: Scalars['String'];
  timestamp: Scalars['Int'];
  tokenId: Scalars['String'];
  tokenPrice: Scalars['String'];
  totalPrice: Scalars['String'];
  totalPriceNetworkBaseToken?: InputMaybe<Scalars['String']>;
  totalPriceUsd?: InputMaybe<Scalars['String']>;
  totalTradePrice?: InputMaybe<Scalars['String']>;
  transactionHash: Scalars['String'];
  transactionIndex: Scalars['Int'];
};

/**   An NFT event type for a webhook to listen for. */
export type NftEventTypeCondition = {
  __typename?: 'NftEventTypeCondition';
  /**   The NFT event type. */
  eq: WebhookNftEventType;
};

/**   Input for NFT event type. */
export type NftEventTypeConditionInput = {
  /**   The NFT event type to equal. */
  eq: WebhookNftEventType;
};

/**   Webhook conditions for an NFT event. */
export type NftEventWebhookCondition = {
  __typename?: 'NftEventWebhookCondition';
  /**   The NFT collection contract address the webhook is listening for. */
  contractAddress?: Maybe<StringEqualsCondition>;
  /**   The NFT event type the webhook is listening for. */
  eventType?: Maybe<NftEventTypeCondition>;
  /**   The exchange contract address the webhook is listening for. */
  exchangeAddress?: Maybe<StringEqualsCondition>;
  /**   The NFT marketplaces the webhook is listening on. */
  fillSource?: Maybe<NftEventFillSourceCondition>;
  /**   The base token price the webhook is listening for. */
  individualBaseTokenPrice?: Maybe<ComparisonOperator>;
  /**   The maker wallet address the webhook is listening for. */
  maker?: Maybe<StringEqualsCondition>;
  /**   The list of network IDs the webhook is listening on. */
  networkId?: Maybe<OneOfNumberCondition>;
  /**   The token contract address the webhook is listening for. */
  tokenAddress?: Maybe<StringEqualsCondition>;
  /**   The token ID the webhook is listening for. */
  tokenId?: Maybe<StringEqualsCondition>;
};

/**   Input conditions for an NFT event webhook. */
export type NftEventWebhookConditionInput = {
  /**   The NFT collection contract address to listen for. */
  contractAddress?: InputMaybe<StringEqualsConditionInput>;
  /**   The NFT event type to listen for. */
  eventType?: InputMaybe<NftEventTypeConditionInput>;
  /**   The exchange contract address to listen for. */
  exchangeAddress?: InputMaybe<StringEqualsConditionInput>;
  /**   The NFT marketplaces to listen for. */
  fillSource?: InputMaybe<NftEventFillSourceConditionInput>;
  /**   The maker wallet address to listen for. */
  maker?: InputMaybe<StringEqualsConditionInput>;
  /**   A list of network IDs to listen on. */
  networkId?: InputMaybe<OneOfNumberConditionInput>;
  /**   The token ID to listen for. */
  tokenId?: InputMaybe<StringEqualsConditionInput>;
};

/**   Response returned by `getNftEvents`. */
export type NftEventsConnection = {
  __typename?: 'NftEventsConnection';
  /**   A cursor for use in pagination. */
  cursor?: Maybe<Scalars['String']>;
  /**   A list of transactions for an NFT collection. */
  items?: Maybe<Array<Maybe<NftEvent>>>;
};

/**   Event data for updating the asset recipient of a pool. */
export type NftPoolAssetRecipientUpdateEventData = {
  __typename?: 'NftPoolAssetRecipientUpdateEventData';
  /**   The updated wallet address that will receive the tokens or NFT sent to the pair during swaps. */
  newAssetRecipient: Scalars['String'];
  /**   The type of NFT pool event, `ASSET_RECIPIENT_CHANGE`. */
  type: NftPoolEventType;
};

/**   undocumented */
export type NftPoolAssetRecipientUpdateEventDataInput = {
  newAssetRecipient: Scalars['String'];
  type: NftPoolEventType;
};

/**   Response returned by `filterNftPoolCollections`. */
export type NftPoolCollectionFilterConnection = {
  __typename?: 'NftPoolCollectionFilterConnection';
  /**   The number of NFT collections returned. */
  count?: Maybe<Scalars['Int']>;
  /**   Where in the list the server started when returning items. */
  page?: Maybe<Scalars['Int']>;
  /**   The list of NFT collections matching the filter parameters. */
  results?: Maybe<Array<Maybe<NftPoolCollectionFilterResult>>>;
};

/**   undocumented */
export type NftPoolCollectionFilterConnectionV2 = {
  __typename?: 'NftPoolCollectionFilterConnectionV2';
  count?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<NftPoolCollectionFilterResultV2>>>;
};

/**   An NFT pool collection. */
export type NftPoolCollectionFilterResult = {
  __typename?: 'NftPoolCollectionFilterResult';
  /**   The total liquidity of the collection in the network's base token. */
  balanceNBT?: Maybe<Scalars['String']>;
  /**   The total liquidity of the collection in USD. */
  balanceUSD?: Maybe<Scalars['String']>;
  /**   The contract address of the NFT collection. */
  collectionAddress?: Maybe<Scalars['String']>;
  /**   The token standard. Can be a variation of `ERC-721` or `ERC-1155`. */
  ercType?: Maybe<Scalars['String']>;
  /**   The contract address of the NFT AMM marketplace. */
  exchangeAddress?: Maybe<Scalars['String']>;
  /**   The total sell volume of the collection in the network's base token over the past 24 hours. */
  expenseNBT24?: Maybe<Scalars['String']>;
  /**   The total sell volume of the collection in the network's base token over the collection's lifetime. */
  expenseNBTAll?: Maybe<Scalars['String']>;
  /**   The total sell volume of the collection in USD over the past 24 hours. */
  expenseUSD24?: Maybe<Scalars['String']>;
  /**   The total sell volume of the collection in USD over the collection's lifetime. */
  expenseUSDAll?: Maybe<Scalars['String']>;
  /**   The lowest price at which any of the collection's pools are willing to sell an NFT in the network's base token. */
  floorNBT?: Maybe<Scalars['String']>;
  /**   The lowest price at which any of the collection's pools are willing to sell an NFT in USD. */
  floorUSD?: Maybe<Scalars['String']>;
  /**   The highest sale price within the collection in the network's base token in the past 24 hours. */
  highPriceNBT24?: Maybe<Scalars['String']>;
  /**   The highest sale price within the collection in the network's base token in the collection's lifetime. */
  highPriceNBTAll?: Maybe<Scalars['String']>;
  /**   The highest sale price within the collection in USD in the past 24 hours. */
  highPriceUSD24?: Maybe<Scalars['String']>;
  /**   The highest sale price within the collection in USD in the collection's lifetime. */
  highPriceUSDAll?: Maybe<Scalars['String']>;
  /**   The ID of the NFT collection (`collectionAddress`:`exchangeAddress`:`networkId`). */
  id: Scalars['String'];
  /**   The image URL for the collection or one of the assets within the collection. */
  imageUrl?: Maybe<Scalars['String']>;
  /**   The lowest sale price within the collection in the network's base token in the past 24 hours. */
  lowPriceNBT24?: Maybe<Scalars['String']>;
  /**   The lowest sale price within the collection in the network's base token in the collection's lifetime. */
  lowPriceNBTAll?: Maybe<Scalars['String']>;
  /**   The lowest sale price within the collection in USD in the past 24 hours. */
  lowPriceUSD24?: Maybe<Scalars['String']>;
  /**   The lowest sale price within the collection in USD in the collection's lifetime. */
  lowPriceUSDAll?: Maybe<Scalars['String']>;
  /**   The name of the NFT collection. */
  name?: Maybe<Scalars['String']>;
  /**   The network ID the NFT collection is deployed on. */
  networkId?: Maybe<Scalars['Int']>;
  /**
   *   The number of NFTs in all of the collection's pools.
   * @deprecated nftBalance is changing from Int to String - use nftBalanceV2 instead.
   */
  nftBalance?: Maybe<Scalars['Int']>;
  /**   The number of NFTs in all of the collection's pools. */
  nftBalanceV2?: Maybe<Scalars['String']>;
  /**
   *   The number of NFTs bought and sold in any of the collection's pools over the past 24 hours.
   * @deprecated nftVolume24 is changing from Int to String - use nftVolume24V2 instead.
   */
  nftVolume24?: Maybe<Scalars['Int']>;
  /**   The number of NFTs bought and sold in any of the collection's pools over the past 24 hours. */
  nftVolume24V2?: Maybe<Scalars['String']>;
  /**
   *   The number of NFTs bought or sold in any of the collection's pools over the collection's lifetime.
   * @deprecated nftVolumeAll is changing from Int to String - use nftVolumeAllV2 instead.
   */
  nftVolumeAll?: Maybe<Scalars['Int']>;
  /**   The number of NFTs bought or sold in any of the collection's pools over the collection's lifetime. */
  nftVolumeAllV2?: Maybe<Scalars['String']>;
  /**
   *   The number of NFTs bought in any of the collection's pools over the past 24 hours.
   * @deprecated nftsBought24 is changing from Int to String - use nftsBought24V2 instead.
   */
  nftsBought24?: Maybe<Scalars['Int']>;
  /**   The number of NFTs bought in any of the collection's pools over the past 24 hours. */
  nftsBought24V2?: Maybe<Scalars['String']>;
  /**
   *   The number of NFTs bought in any of the collection's pools over the collection's lifetime.
   * @deprecated nftsBoughtAll is changing from Int to String - use nftsBoughtAllV2 instead.
   */
  nftsBoughtAll?: Maybe<Scalars['Int']>;
  /**   The number of NFTs bought in any of the collection's pools over the collection's lifetime. */
  nftsBoughtAllV2?: Maybe<Scalars['String']>;
  /**
   *   The number of NFTs sold in any of the collection's pools over the past 24 hours.
   * @deprecated nftsSold24 is changing from Int to String - use nftsSold24V2 instead.
   */
  nftsSold24?: Maybe<Scalars['Int']>;
  /**   The number of NFTs sold in any of the collection's pools over the past 24 hours. */
  nftsSold24V2?: Maybe<Scalars['String']>;
  /**
   *   The number of NFTs sold in any of the collection's pools over the collection's lifetime.
   * @deprecated nftsSoldAll is changing from Int to String - use nftsSoldAllV2 instead.
   */
  nftsSoldAll?: Maybe<Scalars['Int']>;
  /**   The number of NFTs sold in any of the collection's pools over the collection's lifetime. */
  nftsSoldAllV2?: Maybe<Scalars['String']>;
  /**   The highest price at which any of the collection's pools are willing to buy an NFT in the network's base token. */
  offerNBT?: Maybe<Scalars['String']>;
  /**   The highest price at which any of the collection's pools are willing to buy an NFT in USD. */
  offerUSD?: Maybe<Scalars['String']>;
  /**   The sum of pool fees generated by the collection in the network's base token over the past 24 hours. */
  poolFeesNBT24?: Maybe<Scalars['String']>;
  /**   The sum of pool fees generated by the collection in the network's base token over the collection's lifetime. */
  poolFeesNBTAll?: Maybe<Scalars['String']>;
  /**   The sum of pool fees generated by the collection in USD over the past 24 hours. */
  poolFeesUSD24?: Maybe<Scalars['String']>;
  /**   The sum of pool fees generated by the collection in USD over the collection's lifetime. */
  poolFeesUSDAll?: Maybe<Scalars['String']>;
  /**   The sum of protocol fees generated by the collection in the network's base token over the past 24 hours. */
  protocolFeesNBT24?: Maybe<Scalars['String']>;
  /**   The sum of protocol fees generated by the collection in the network's base token over the collection's lifetime. */
  protocolFeesNBTAll?: Maybe<Scalars['String']>;
  /**   The sum of protocol fees generated by the collection in USD over the past 24 hours. */
  protocolFeesUSD24?: Maybe<Scalars['String']>;
  /**   The sum of protocol fees generated by the collection in USD over the collection's lifetime. */
  protocolFeesUSDAll?: Maybe<Scalars['String']>;
  /**   The total buy volume of the collection in the network's base token over the past 24 hours. */
  revenueNBT24?: Maybe<Scalars['String']>;
  /**   The total buy volume of the collection in the network's base token over the collection's lifetime. */
  revenueNBTAll?: Maybe<Scalars['String']>;
  /**   The total buy volume of the collection in USD over the past 24 hours. */
  revenueUSD24?: Maybe<Scalars['String']>;
  /**   The total buy volume of the collection in USD over the collection's lifetime. */
  revenueUSDAll?: Maybe<Scalars['String']>;
  /**   The symbol for the NFT collection. */
  symbol?: Maybe<Scalars['String']>;
  /**   The unix timestamp indicating the last time the data was updated. Updates every 2 hours. */
  timestamp?: Maybe<Scalars['Int']>;
  /**
   *   The total supply of the collection.
   * @deprecated totalSupply is changing from Int to String - use totalSupplyV2 instead.
   */
  totalSupply?: Maybe<Scalars['Int']>;
  totalSupplyV2?: Maybe<Scalars['String']>;
  /**   The total volume of the collection in the network's base token over the past 24 hours. */
  volumeNBT24?: Maybe<Scalars['String']>;
  /**   The total volume of the collection in the network's base token over the collection's lifetime. */
  volumeNBTAll?: Maybe<Scalars['String']>;
  /**   The total volume of the collection in USD over the past 24 hours. */
  volumeUSD24?: Maybe<Scalars['String']>;
  /**   The total volume of the collection in USD over the collection's lifetime. */
  volumeUSDAll?: Maybe<Scalars['String']>;
};

/**   undocumented */
export type NftPoolCollectionFilterResultV2 = {
  __typename?: 'NftPoolCollectionFilterResultV2';
  balanceNBT?: Maybe<Scalars['String']>;
  balanceUSD?: Maybe<Scalars['String']>;
  collectionAddress?: Maybe<Scalars['String']>;
  ercType?: Maybe<Scalars['String']>;
  exchangeAddress?: Maybe<Scalars['String']>;
  expenseNBT24?: Maybe<Scalars['String']>;
  expenseNBTAll?: Maybe<Scalars['String']>;
  expenseUSD24?: Maybe<Scalars['String']>;
  expenseUSDAll?: Maybe<Scalars['String']>;
  floorNBT?: Maybe<Scalars['String']>;
  floorUSD?: Maybe<Scalars['String']>;
  highPriceNBT24?: Maybe<Scalars['String']>;
  highPriceNBTAll?: Maybe<Scalars['String']>;
  highPriceUSD24?: Maybe<Scalars['String']>;
  highPriceUSDAll?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  lowPriceNBT24?: Maybe<Scalars['String']>;
  lowPriceNBTAll?: Maybe<Scalars['String']>;
  lowPriceUSD24?: Maybe<Scalars['String']>;
  lowPriceUSDAll?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  networkId?: Maybe<Scalars['Int']>;
  nftBalance?: Maybe<Scalars['String']>;
  nftVolume24?: Maybe<Scalars['String']>;
  nftVolumeAll?: Maybe<Scalars['String']>;
  nftsBought24?: Maybe<Scalars['String']>;
  nftsBoughtAll?: Maybe<Scalars['String']>;
  nftsSold24?: Maybe<Scalars['String']>;
  nftsSoldAll?: Maybe<Scalars['String']>;
  offerNBT?: Maybe<Scalars['String']>;
  offerUSD?: Maybe<Scalars['String']>;
  poolFeesNBT24?: Maybe<Scalars['String']>;
  poolFeesNBTAll?: Maybe<Scalars['String']>;
  poolFeesUSD24?: Maybe<Scalars['String']>;
  poolFeesUSDAll?: Maybe<Scalars['String']>;
  protocolFeesNBT24?: Maybe<Scalars['String']>;
  protocolFeesNBTAll?: Maybe<Scalars['String']>;
  protocolFeesUSD24?: Maybe<Scalars['String']>;
  protocolFeesUSDAll?: Maybe<Scalars['String']>;
  revenueNBT24?: Maybe<Scalars['String']>;
  revenueNBTAll?: Maybe<Scalars['String']>;
  revenueUSD24?: Maybe<Scalars['String']>;
  revenueUSDAll?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Int']>;
  totalSupply?: Maybe<Scalars['String']>;
  volumeNBT24?: Maybe<Scalars['String']>;
  volumeNBTAll?: Maybe<Scalars['String']>;
  volumeUSD24?: Maybe<Scalars['String']>;
  volumeUSDAll?: Maybe<Scalars['String']>;
};

/**   Input type of `NftPoolCollectionFilters`. */
export type NftPoolCollectionFilters = {
  /**   The total liquidity of the collection in the network's base token. */
  balanceNBT?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The total liquidity of the collection in USD. */
  balanceUSD?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The list of token standards to filter by. Can be a variation of `ERC-721` or `ERC-1155`. */
  ercType?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /**   The list of NFT AMM marketplace addresses to filter by. */
  exchange?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /**   The total sell volume of the collection in the network's base token over the past 24 hours. */
  expenseNBT24?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The total sell volume of the collection in the network's base token over the collection's lifetime. */
  expenseNBTAll?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The total sell volume of the collection in USD over the past 24 hours. */
  expenseUSD24?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The total sell volume of the collection in USD over the collection's lifetime. */
  expenseUSDAll?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The lowest price at which any of the collection's pools are willing to sell an NFT in the network's base token. */
  floorNBT?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The lowest price at which any of the collection's pools are willing to sell an NFT in USD. */
  floorUSD?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The highest sale price within the collection in the network's base token in the past 24 hours. */
  highPriceNBT24?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The highest sale price within the collection in the network's base token in the collection's lifetime. */
  highPriceNBTAll?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The highest sale price within the collection in USD in the past 24 hours. */
  highPriceUSD24?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The highest sale price within the collection in USD in the collection's lifetime. */
  highPriceUSDAll?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The lowest sale price within the collection in the network's base token in the past 24 hours. */
  lowPriceNBT24?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The lowest sale price within the collection in the network's base token in the collection's lifetime. */
  lowPriceNBTAll?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The lowest sale price within the collection in USD in the past 24 hours. */
  lowPriceUSD24?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The lowest sale price within the collection in USD in the collection's lifetime. */
  lowPriceUSDAll?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The list of network IDs to filter by. */
  network?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /**   The number of NFTs in all of the collection's pools. */
  nftBalance?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The number of NFTs bought in any of the collection's pools over the past 24 hours. */
  nftVolume24?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The number of NFTs bought or sold in any of the collection's pools over the collection's lifetime. */
  nftVolumeAll?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The number of NFTs bought in any of the collection's pools over the past 24 hours. */
  nftsBought24?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The number of NFTs bought in any of the collection's pools over the collection's lifetime. */
  nftsBoughtAll?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The number of NFTs sold in any of the collection's pools over the past 24 hours. */
  nftsSold24?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The number of NFTs sold in any of the collection's pools over the collection's lifetime. */
  nftsSoldAll?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The highest price at which any of the collection's pools are willing to buy an NFT in the network's base token. */
  offerNBT?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The highest price at which any of the collection's pools are willing to buy an NFT in USD. */
  offerUSD?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The sum of pool fees generated by the collection in the network's base token over the past 24 hours. */
  poolFeesNBT24?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The sum of pool fees generated by the collection in the network's base token over the collection's lifetime. */
  poolFeesNBTAll?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The sum of pool fees generated by the collection in USD over the past 24 hours. */
  poolFeesUSD24?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The sum of pool fees generated by the collection in USD over the collection's lifetime. */
  poolFeesUSDAll?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The sum of protocol fees generated by the collection in the network's base token over the past 24 hours. */
  protocolFeesNBT24?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The sum of protocol fees generated by the collection in the network's base token over the collection's lifetime. */
  protocolFeesNBTAll?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The sum of protocol fees generated by the collection in USD over the past 24 hours. */
  protocolFeesUSD24?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The sum of protocol fees generated by the collection in USD over the collection's lifetime. */
  protocolFeesUSDAll?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The total buy volume of the collection in the network's base token over the past 24 hours. */
  revenueNBT24?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The total buy volume of the collection in the network's base token over the collection's lifetime. */
  revenueNBTAll?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The total buy volume of the collection in USD over the past 24 hours. */
  revenueUSD24?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The total buy volume of the collection in USD over the collection's lifetime. */
  revenueUSDAll?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The total supply of the collection. */
  totalSupply?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The total volume of the collection in the network's base token over the past 24 hours. */
  volumeNBT24?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The total volume of the collection in the network's base token over the collection's lifetime. */
  volumeNBTAll?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The total volume of the collection in USD over the past 24 hours. */
  volumeUSD24?: InputMaybe<NftPoolCollectionNumberFilter>;
  /**   The total volume of the collection in USD over the collection's lifetime. */
  volumeUSDAll?: InputMaybe<NftPoolCollectionNumberFilter>;
};

/**   Input type of `NftPoolCollectionNumberFilter`. */
export type NftPoolCollectionNumberFilter = {
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
};

/**   Input type of `NftPoolCollectionRanking`. */
export type NftPoolCollectionRanking = {
  /**   The attribute to rank NFT collections by. */
  attribute?: InputMaybe<NftPoolCollectionRankingAttribute>;
  /**   The direction to apply to the ranking attribute. */
  direction?: InputMaybe<RankingDirection>;
};

/**   The attribute used to rank NFT collections. */
export enum NftPoolCollectionRankingAttribute {
  BalanceNbt = 'balanceNBT',
  BalanceUsd = 'balanceUSD',
  ExpenseNbt24 = 'expenseNBT24',
  ExpenseNbtAll = 'expenseNBTAll',
  ExpenseUsd24 = 'expenseUSD24',
  ExpenseUsdAll = 'expenseUSDAll',
  FloorNbt = 'floorNBT',
  FloorUsd = 'floorUSD',
  HighPriceNbt24 = 'highPriceNBT24',
  HighPriceNbtAll = 'highPriceNBTAll',
  HighPriceUsd24 = 'highPriceUSD24',
  HighPriceUsdAll = 'highPriceUSDAll',
  LowPriceNbt24 = 'lowPriceNBT24',
  LowPriceNbtAll = 'lowPriceNBTAll',
  LowPriceUsd24 = 'lowPriceUSD24',
  LowPriceUsdAll = 'lowPriceUSDAll',
  NftBalance = 'nftBalance',
  NftVolume24 = 'nftVolume24',
  NftVolumeAll = 'nftVolumeAll',
  NftsBought24 = 'nftsBought24',
  NftsBoughtAll = 'nftsBoughtAll',
  NftsSold24 = 'nftsSold24',
  NftsSoldAll = 'nftsSoldAll',
  OfferNbt = 'offerNBT',
  OfferUsd = 'offerUSD',
  PoolFeesNbt24 = 'poolFeesNBT24',
  PoolFeesNbtAll = 'poolFeesNBTAll',
  PoolFeesUsd24 = 'poolFeesUSD24',
  PoolFeesUsdAll = 'poolFeesUSDAll',
  ProtocolFeesNbt24 = 'protocolFeesNBT24',
  ProtocolFeesNbtAll = 'protocolFeesNBTAll',
  ProtocolFeesUsd24 = 'protocolFeesUSD24',
  ProtocolFeesUsdAll = 'protocolFeesUSDAll',
  RevenueNbt24 = 'revenueNBT24',
  RevenueNbtAll = 'revenueNBTAll',
  RevenueUsd24 = 'revenueUSD24',
  RevenueUsdAll = 'revenueUSDAll',
  TotalSupply = 'totalSupply',
  VolumeNbt24 = 'volumeNBT24',
  VolumeNbtAll = 'volumeNBTAll',
  VolumeUsd24 = 'volumeUSD24',
  VolumeUsdAll = 'volumeUSDAll'
}

/**   An NFT collection in an NFT pool. */
export type NftPoolCollectionResponse = {
  __typename?: 'NftPoolCollectionResponse';
  /**   The total liquidity of the collection in the network's base token. */
  balanceNBT: Scalars['String'];
  /**   The contract address of the NFT collection. */
  collectionAddress: Scalars['String'];
  /**   The ID of the NFT collection (`collectionAddress`:`networkId`). */
  collectionId: Scalars['String'];
  /**   The contract address of the NFT AMM marketplace. */
  exchangeAddress: Scalars['String'];
  /**   The ID of the exchange (`exchangeAddress`:`networkId`). */
  exchangeId: Scalars['String'];
  /**   The lowest price at which any of the NFT collection's pools are willing to sell an NFT in the network's base token. */
  floorNBT?: Maybe<Scalars['String']>;
  /**   An image associated with the NFT collection. */
  image?: Maybe<Scalars['String']>;
  /**
   *   The media for one of the assets within the NFT collection.
   * @deprecated Use `image` from `NftContract` instead.
   */
  media?: Maybe<NftAssetMedia>;
  /**   The name of the NFT collection. */
  name?: Maybe<Scalars['String']>;
  /**   The network ID the NFT collection is deployed on. */
  networkId: Scalars['Int'];
  /**
   *   The current number of NFTs in all the NFT collection's pools.
   * @deprecated nftBalance is changing from Int to String - use nftBalanceV2 instead.
   */
  nftBalance: Scalars['Int'];
  /**   The current number of NFTs in all the NFT collection's pools. */
  nftBalanceV2: Scalars['String'];
  /**
   *   The total number of NFTs bought and sold over the collection's lifetime.
   * @deprecated nftVolumeAllTime is changing from Int to String - use nftVolumeAllTimeV2 instead.
   */
  nftVolumeAllTime?: Maybe<Scalars['Int']>;
  /**   The total number of NFTs bought and sold over the collection's lifetime. */
  nftVolumeAllTimeV2?: Maybe<Scalars['String']>;
  /**   The highest price at which any of the NFT collection's pools are willing to buy an NFT in the network's base token. */
  offerNBT?: Maybe<Scalars['String']>;
  /**   The total volume of the collection in the network's base token over the collection's lifetime. */
  volumeAllTimeNBT?: Maybe<Scalars['String']>;
};

/**   The NFT pool contract version. */
export enum NftPoolContractVersion {
  SudoswapV1 = 'SUDOSWAP_V1',
  SudoswapV2 = 'SUDOSWAP_V2'
}

/**   Event data for updating the delta of a pool. */
export type NftPoolDeltaUpdateEventData = {
  __typename?: 'NftPoolDeltaUpdateEventData';
  /**   The updated delta used in the bonding curve. */
  newDelta: Scalars['String'];
  /**   The type of NFT pool event, `DELTA_UPDATE`. */
  type: NftPoolEventType;
};

/**   undocumented */
export type NftPoolDeltaUpdateEventDataInput = {
  newDelta: Scalars['String'];
  type: NftPoolEventType;
};

/**   An NFT pool transaction. */
export type NftPoolEvent = {
  __typename?: 'NftPoolEvent';
  /**   The hash of the block where the transaction occurred. */
  blockHash: Scalars['String'];
  /**   The block number for the transaction. */
  blockNumber: Scalars['Int'];
  /**   The contract address of the NFT collection. */
  collectionAddress: Scalars['String'];
  /**   The ID of the NFT collection (`collectionAddress`:`networkId`). */
  collectionId: Scalars['String'];
  /**   The event-specific data for the transaction. */
  data: NftPoolEventData;
  /**   The event type of the transaction. */
  eventType: NftPoolEventType;
  /**   The contract address of the NFT AMM marketplace. */
  exchangeAddress: Scalars['String'];
  /**   The ID of the NFT pool (`poolAddress`:`networkId`). For example, `0xdbea289dcc10eed8431e78753414a3d81b8e7201:1`. */
  id: Scalars['String'];
  /**   The index of the log in the block. */
  logIndex: Scalars['Int'];
  /**   The wallet address that transacted. */
  maker: Scalars['String'];
  /**   The network ID the NFT collection is deployed on. */
  networkId: Scalars['Int'];
  /**   The contract address of the NFT pool. */
  poolAddress: Scalars['String'];
  /**   The type of liquidity pool. */
  poolType: NftPoolType;
  /**   The unix timestamp for the transaction. */
  timestamp: Scalars['Int'];
  /**   The contract address of the liquidity token of the pool (usually WETH). */
  tokenAddress: Scalars['String'];
  /**   The unique hash for the transaction. */
  transactionHash: Scalars['String'];
  /**   The index of the transaction within the block. */
  transactionIndex: Scalars['Int'];
};

/**   Event-specific data for an NFT pool transaction. */
export type NftPoolEventData = NewPoolEventData | NewPoolEventDataV2 | NftPoolAssetRecipientUpdateEventData | NftPoolDeltaUpdateEventData | NftPoolFeeUpdateEventData | NftPoolNftDepositEventData | NftPoolNftDepositEventDataV2 | NftPoolNftWithdrawalEventData | NftPoolNftWithdrawalEventDataV2 | NftPoolSpotPriceUpdateEventData | NftPoolSpotPriceUpdateEventDataV2 | NftPoolTokenDepositEventData | NftPoolTokenDepositEventDataV2 | NftPoolTokenWithdrawalEventData | NftPoolTokenWithdrawalEventDataV2 | SwapNftInPoolEventData | SwapNftInPoolEventDataV2 | SwapNftOutPoolEventData | SwapNftOutPoolEventDataV2;

/**   undocumented */
export type NftPoolEventInput = {
  blockHash: Scalars['String'];
  blockNumber: Scalars['Int'];
  collectionAddress: Scalars['String'];
  collectionId: Scalars['String'];
  data: NftPoolEventInputUnion;
  eventType: NftPoolEventType;
  exchangeAddress: Scalars['String'];
  id: Scalars['String'];
  logIndex: Scalars['Int'];
  maker: Scalars['String'];
  networkId: Scalars['Int'];
  poolAddress: Scalars['String'];
  poolType: NftPoolType;
  timestamp: Scalars['Int'];
  tokenAddress: Scalars['String'];
  transactionHash: Scalars['String'];
  transactionIndex: Scalars['Int'];
  version?: InputMaybe<NftPoolContractVersion>;
};

/**   undocumented */
export type NftPoolEventInputUnion = {
  newPoolEventDataInput?: InputMaybe<NewPoolEventDataInput>;
  newPoolEventDataV2Input?: InputMaybe<NewPoolEventDataV2Input>;
  nftPoolAssetRecipientUpdateEventDataInput?: InputMaybe<NftPoolAssetRecipientUpdateEventDataInput>;
  nftPoolDeltaUpdateEventDataInput?: InputMaybe<NftPoolDeltaUpdateEventDataInput>;
  nftPoolFeeUpdateEventDataInput?: InputMaybe<NftPoolFeeUpdateEventDataInput>;
  nftPoolNftDepositEventDataInput?: InputMaybe<NftPoolNftDepositEventDataInput>;
  nftPoolNftDepositEventDataV2Input?: InputMaybe<NftPoolNftDepositEventDataV2Input>;
  nftPoolNftWithdrawalEventDataInput?: InputMaybe<NftPoolNftWithdrawalEventDataInput>;
  nftPoolNftWithdrawalEventDataV2Input?: InputMaybe<NftPoolNftWithdrawalEventDataV2Input>;
  nftPoolSpotPriceUpdateEventDataInput?: InputMaybe<NftPoolSpotPriceUpdateEventDataInput>;
  nftPoolSpotPriceUpdateEventDataV2Input?: InputMaybe<NftPoolSpotPriceUpdateEventDataV2Input>;
  nftPoolTokenDepositEventDataInput?: InputMaybe<NftPoolTokenDepositEventDataInput>;
  nftPoolTokenDepositEventDataV2Input?: InputMaybe<NftPoolTokenDepositEventDataV2Input>;
  nftPoolTokenWithdrawalEventDataInput?: InputMaybe<NftPoolTokenWithdrawalEventDataInput>;
  nftPoolTokenWithdrawalEventDataV2Input?: InputMaybe<NftPoolTokenWithdrawalEventDataV2Input>;
  swapNftInPoolEventDataInput?: InputMaybe<SwapNftInPoolEventDataInput>;
  swapNftInPoolEventDataV2Input?: InputMaybe<SwapNftInPoolEventDataV2Input>;
  swapNftOutPoolEventDataInput?: InputMaybe<SwapNftOutPoolEventDataInput>;
  swapNftOutPoolEventDataV2Input?: InputMaybe<SwapNftOutPoolEventDataV2Input>;
};

/**   Metadata for an NFT transfer. */
export type NftPoolEventNftTransfer = {
  __typename?: 'NftPoolEventNftTransfer';
  /**   The value of the NFT at the time of transfer. */
  amountT: Scalars['String'];
  /**   The NFT token ID involved in the transfer. */
  nftTokenId: Scalars['String'];
};

/**   undocumented */
export type NftPoolEventNftTransferInput = {
  amountT: Scalars['String'];
  nftTokenId: Scalars['String'];
};

/**   Metadata for an NFT transfer. */
export type NftPoolEventNftTransferV2 = {
  __typename?: 'NftPoolEventNftTransferV2';
  /**   The value of the token at the time of transfer. */
  amountT: Scalars['String'];
  /**   The NFT token ID involved in the transfer. */
  nftTokenId: Scalars['String'];
  /**   The number of tokens involved in the transfer. */
  nftTokenQuantity: Scalars['String'];
};

export type NftPoolEventNftTransferV2Input = {
  amountT: Scalars['String'];
  nftQuantity: Scalars['String'];
  nftTokenId: Scalars['String'];
};

/**   The type of an NFT pool event. */
export enum NftPoolEventType {
  AssetRecipientChange = 'ASSET_RECIPIENT_CHANGE',
  DeltaUpdate = 'DELTA_UPDATE',
  FeeUpdate = 'FEE_UPDATE',
  NewPool = 'NEW_POOL',
  NewPoolV2 = 'NEW_POOL_V2',
  NftDeposit = 'NFT_DEPOSIT',
  NftDepositV2 = 'NFT_DEPOSIT_V2',
  NftWithdrawal = 'NFT_WITHDRAWAL',
  NftWithdrawalV2 = 'NFT_WITHDRAWAL_V2',
  SpotPriceUpdate = 'SPOT_PRICE_UPDATE',
  SpotPriceUpdateV2 = 'SPOT_PRICE_UPDATE_V2',
  SwapNftInPool = 'SWAP_NFT_IN_POOL',
  SwapNftInPoolV2 = 'SWAP_NFT_IN_POOL_V2',
  SwapNftOutPool = 'SWAP_NFT_OUT_POOL',
  SwapNftOutPoolV2 = 'SWAP_NFT_OUT_POOL_V2',
  TokenDeposit = 'TOKEN_DEPOSIT',
  TokenDepositV2 = 'TOKEN_DEPOSIT_V2',
  TokenWithdrawal = 'TOKEN_WITHDRAWAL',
  TokenWithdrawalV2 = 'TOKEN_WITHDRAWAL_V2'
}

/**   Response returned by `getNftPoolEvents`. */
export type NftPoolEventsResponse = {
  __typename?: 'NftPoolEventsResponse';
  /**   A cursor for use in pagination. */
  cursor?: Maybe<Scalars['String']>;
  /**   A list of transactions for an NFT pool. */
  items?: Maybe<Array<Maybe<NftPoolEvent>>>;
};

/**   Event data for updating the fee of a pool. */
export type NftPoolFeeUpdateEventData = {
  __typename?: 'NftPoolFeeUpdateEventData';
  /**   The ratio of the transaction token to the network's base token. */
  nbtRatio: Scalars['String'];
  /**   The updated pool fee in the pool's liquidity token. */
  newFeeT: Scalars['String'];
  /**   The type of NFT pool event, `FEE_UPDATE`. */
  type: NftPoolEventType;
  /**   The ratio of the transaction token to USD. */
  usdRatio: Scalars['String'];
};

/**   undocumented */
export type NftPoolFeeUpdateEventDataInput = {
  nbtRatio: Scalars['String'];
  newFeeT: Scalars['String'];
  type: NftPoolEventType;
  usdRatio: Scalars['String'];
};

/**   Response returned by `filterNftPools`. */
export type NftPoolFilterConnection = {
  __typename?: 'NftPoolFilterConnection';
  /**   The number of NFT pools returned. */
  count?: Maybe<Scalars['Int']>;
  /**   Where in the list the server started when returning items. */
  page?: Maybe<Scalars['Int']>;
  /**   The list of NFT pools matching the filter parameters. */
  results?: Maybe<Array<Maybe<NftPoolFilterResult>>>;
};

/**   An NFT pool matching a set of filter parameters. */
export type NftPoolFilterResult = {
  __typename?: 'NftPoolFilterResult';
  /**   For ERC1155 pools, the list of NFT token IDs that are accepted by the pool. */
  acceptedNftTokenIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**   The current pool liquidity in the network's base token. */
  balanceNBT?: Maybe<Scalars['String']>;
  /**   The current value of the collection in the pool's liquidity token. */
  balanceT?: Maybe<Scalars['String']>;
  /**   The current pool liquidity in USD. */
  balanceUSD?: Maybe<Scalars['String']>;
  /**   The contract address of the NFT collection. */
  collectionAddress: Scalars['String'];
  /**   The name of the NFT collection. */
  collectionName: Scalars['String'];
  /**   The symbol of the NFT collection. */
  collectionSymbol: Scalars['String'];
  /**   The contract address of the NFT AMM marketplace. */
  exchangeAddress: Scalars['String'];
  /**   The total sell volume of the pool in the network's base token over the past 24 hours. */
  expenseNBT24?: Maybe<Scalars['String']>;
  /**   The total sell volume of the pool in the network's base token over the pool's lifetime. */
  expenseNBTAll?: Maybe<Scalars['String']>;
  /**   The total sell volume of the pool in the pool's liquidity token over the past 24 hours. */
  expenseT24?: Maybe<Scalars['String']>;
  /**   The total sell volume of the pool in the pool's liquidity token over the pool's lifetime. */
  expenseTAll?: Maybe<Scalars['String']>;
  /**   The total sell volume of the pool in USD over the past 24 hours. */
  expenseUSD24?: Maybe<Scalars['String']>;
  /**   The total sell volume of the pool in USD over the pool's lifetime. */
  expenseUSDAll?: Maybe<Scalars['String']>;
  /**   The ID of the NFT pool (`poolAddress`:`networkId`). */
  id: Scalars['String'];
  /**   The network ID the NFT collection is deployed on. */
  networkId: Scalars['Int'];
  /**
   *   The current number of NFTs in the pool.
   * @deprecated nftBalance is changing from Int to String - use nftBalanceV2 instead.
   */
  nftBalance?: Maybe<Scalars['Int']>;
  /**   The current number of NFTs in the pool. */
  nftBalanceV2?: Maybe<Scalars['String']>;
  /**
   *   The total number of NFTs bought and sold over the past 24 hours.
   * @deprecated nftVolume24 is changing from Int to String - use nftVolume24V2 instead.
   */
  nftVolume24?: Maybe<Scalars['Int']>;
  /**   The total number of NFTs bought and sold over the past 24 hours. */
  nftVolume24V2?: Maybe<Scalars['String']>;
  /**
   *   The total number of NFTs bought and sold over the pool's lifetime.
   * @deprecated nftVolumeAll is changing from Int to String - use nftVolumeAllV2 instead.
   */
  nftVolumeAll?: Maybe<Scalars['Int']>;
  /**   The total number of NFTs bought and sold over the pool's lifetime. */
  nftVolumeAllV2?: Maybe<Scalars['String']>;
  /**
   *   The total number of NFTs bought by the pool over the past 24 hours.
   * @deprecated nftsBought24 is changing from Int to String - use nftsBought24V2 instead.
   */
  nftsBought24?: Maybe<Scalars['Int']>;
  /**   The total number of NFTs bought by the pool over the past 24 hours. */
  nftsBought24V2?: Maybe<Scalars['String']>;
  /**
   *   The total number of NFTs bought over the pool's lifetime.
   * @deprecated nftsBoughtAll is changing from Int to String - use nftsBoughtAllV2 instead.
   */
  nftsBoughtAll?: Maybe<Scalars['Int']>;
  /**   The total number of NFTs bought over the pool's lifetime. */
  nftsBoughtAllV2?: Maybe<Scalars['String']>;
  /**
   *   The total number of NFTs sold by the pool over the past 24 hours.
   * @deprecated nftsSold24 is changing from Int to String - use nftsSold24V2 instead.
   */
  nftsSold24?: Maybe<Scalars['Int']>;
  /**   The total number of NFTs sold by the pool over the past 24 hours. */
  nftsSold24V2?: Maybe<Scalars['String']>;
  /**
   *   The total number of NFTs sold over the pool's lifetime.
   * @deprecated nftsSoldAll is changing from Int to String - use nftsSoldAllV2 instead.
   */
  nftsSoldAll?: Maybe<Scalars['Int']>;
  /**   The total number of NFTs sold over the pool's lifetime. */
  nftsSoldAllV2?: Maybe<Scalars['String']>;
  /**   The current price at which the pool is willing to buy an NFT in the network's base token. */
  offerNBT?: Maybe<Scalars['String']>;
  /**   The current price at which the pool is willing to buy an NFT in the pool's liquidity token. */
  offerT?: Maybe<Scalars['String']>;
  /**   The current price at which the pool is willing to buy an NFT in USD. */
  offerUSD?: Maybe<Scalars['String']>;
  /**   The contract address of the NFT pool. */
  poolAddress: Scalars['String'];
  /**   The sum of pool fees generated by the pool in the network's base token over the past 24 hours. */
  poolFeesNBT24?: Maybe<Scalars['String']>;
  /**   The sum of pool fees generated by the pool in the network's base token over the pool's lifetime. */
  poolFeesNBTAll?: Maybe<Scalars['String']>;
  /**   The sum of pool fees generated by the pool in the pool's liquidity token over the past 24 hours. */
  poolFeesT24?: Maybe<Scalars['String']>;
  /**   The sum of pool fees generated by the pool in the pool's liquidity token over the pool's lifetime. */
  poolFeesTAll?: Maybe<Scalars['String']>;
  /**   The sum of pool fees generated by the pool in USD over the past 24 hours. */
  poolFeesUSD24?: Maybe<Scalars['String']>;
  /**   The sum of pool fees generated by the pool in USD over the pool's lifetime. */
  poolFeesUSDAll?: Maybe<Scalars['String']>;
  /**   The type of NFT in the pool. */
  poolNftType?: Maybe<PoolNftType>;
  /**   The pool variant. Can be `ERC20` or `NATIVE`. */
  poolVariant: GraphQlNftPoolVariant;
  /**   The property checker contract address for the pool. */
  propertyChecker?: Maybe<Scalars['String']>;
  /**   The sum of protocol fees generated by the pool in the network's base token over the past 24 hours. */
  protocolFeesNBT24?: Maybe<Scalars['String']>;
  /**   The sum of protocol fees generated by the pool in the network's base token over the pool's lifetime. */
  protocolFeesNBTAll?: Maybe<Scalars['String']>;
  /**   The sum of protocol fees generated by the pool in the pool's liquidity token over the past 24 hours. */
  protocolFeesT24?: Maybe<Scalars['String']>;
  /**   The sum of protocol fees generated by the pool in the pool's liquidity token over the pool's lifetime. */
  protocolFeesTAll?: Maybe<Scalars['String']>;
  /**   The sum of protocol fees generated by the pool in USD over the past 24 hours. */
  protocolFeesUSD24?: Maybe<Scalars['String']>;
  /**   The sum of protocol fees generated by the pool in USD over the pool's lifetime. */
  protocolFeesUSDAll?: Maybe<Scalars['String']>;
  /**   The total buy volume of the pool in the network's base token over the past 24 hours. */
  revenueNBT24?: Maybe<Scalars['String']>;
  /**   The total buy volume of the pool in the network's base token over the pool's lifetime. */
  revenueNBTAll?: Maybe<Scalars['String']>;
  /**   The total buy volume of the pool in the pool's liquidity token over the past 24 hours. */
  revenueT24?: Maybe<Scalars['String']>;
  /**   The total buy volume of the pool in the pool's liquidity token over the pool's lifetime. */
  revenueTAll?: Maybe<Scalars['String']>;
  /**   The total buy volume of the pool in USD over the past 24 hours. */
  revenueUSD24?: Maybe<Scalars['String']>;
  /**   The total buy volume of the pool in USD over the pool's lifetime. */
  revenueUSDAll?: Maybe<Scalars['String']>;
  /**   The list of royalties for the pool. Only applicable for `SUDOSWAP_V2` pools. */
  royalties?: Maybe<Array<Maybe<NftPoolRoyalty>>>;
  /**   The current price at which the pool is willing to sell an NFT. */
  sellNBT?: Maybe<Scalars['String']>;
  /**   The current price at which the pool is willing to sell an NFT in the network's base token. */
  sellT?: Maybe<Scalars['String']>;
  /**   The current price at which the pool is willing to sell an NFT in USD. */
  sellUSD?: Maybe<Scalars['String']>;
  /**   The current spot price for the pool in the network's base token. */
  spotNBT?: Maybe<Scalars['String']>;
  /**   The current spot price for the pool in the pool's liquidity token. */
  spotT?: Maybe<Scalars['String']>;
  /**   The unix timestamp indicating the last time the data was updated. Updates every 2 hours. */
  timestamp: Scalars['Int'];
  /**   The NFT pool contract version. Can be `SUDOSWAP_V1` or `SUDOSWAP_V2`. */
  version?: Maybe<NftPoolContractVersion>;
  /**   The total volume of the pool in the network's base token over the past 24 hours. */
  volumeNBT24?: Maybe<Scalars['String']>;
  /**   The total volume of the pool in the network's base token over the pool's lifetime. */
  volumeNBTAll?: Maybe<Scalars['String']>;
  /**   The total volume of the pool in the pool's liquidity token over the past 24 hours. */
  volumeT24?: Maybe<Scalars['String']>;
  /**   The total volume of the pool in the pool's liquidity token over the pool's lifetime. */
  volumeTAll?: Maybe<Scalars['String']>;
  /**   The total volume of the pool in USD over the past 24 hours. */
  volumeUSD24?: Maybe<Scalars['String']>;
  /**   The total volume of the pool in USD over the pool's lifetime. */
  volumeUSDAll?: Maybe<Scalars['String']>;
};

/**   Input type of `NftPoolFilters`. */
export type NftPoolFilters = {
  /**   For ERC1155 pools, the list of NFT token IDs that are accepted by the pool. */
  acceptedNftTokenIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /**   The pool liquidity in the network's base token. */
  balanceNBT?: InputMaybe<NftPoolNumberFilter>;
  /**   The pool liquidity in USD. */
  balanceUSD?: InputMaybe<NftPoolNumberFilter>;
  /**   The contract address of the NFT collection. */
  collectionAddress?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /**   The list of NFT AMM marketplace addresses to filter by. */
  exchangeAddress?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /**   The total sell volume of the pool in the network's base token over the past 24 hours. */
  expenseNBT24?: InputMaybe<NftPoolNumberFilter>;
  /**   The total sell volume of the pool in the network's base token over the pool's lifetime. */
  expenseNBTAll?: InputMaybe<NftPoolNumberFilter>;
  /**   The total sell volume of the pool in USD over the past 24 hours. */
  expenseUSD24?: InputMaybe<NftPoolNumberFilter>;
  /**   The total sell volume of the pool in USD over the pool's lifetime. */
  expenseUSDAll?: InputMaybe<NftPoolNumberFilter>;
  /**   The list of network IDs to filter by. */
  network?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /**   The number of NFTs in the pool. */
  nftBalance?: InputMaybe<NftPoolNumberFilter>;
  /**   The number of NFTs bought or sold over the past 24 hours. */
  nftVolume24?: InputMaybe<NftPoolNumberFilter>;
  /**   The number of NFTs bought or sold over the pool's lifetime. */
  nftVolumeAll?: InputMaybe<NftPoolNumberFilter>;
  /**   The number of NFTs bought over the past 24 hours. */
  nftsBought24?: InputMaybe<NftPoolNumberFilter>;
  /**   The number of NFTs bought over the pool's lifetime. */
  nftsBoughtAll?: InputMaybe<NftPoolNumberFilter>;
  /**   The number of NFTs sold over the past 24 hours. */
  nftsSold24?: InputMaybe<NftPoolNumberFilter>;
  /**   The number of NFTs sold over the pool's lifetime. */
  nftsSoldAll?: InputMaybe<NftPoolNumberFilter>;
  /**   The price at which the pool is willing to buy an NFT in the network's base token. */
  offerNBT?: InputMaybe<NftPoolNumberFilter>;
  /**   The price at which the pool is willing to buy an NFT in USD. */
  offerUSD?: InputMaybe<NftPoolNumberFilter>;
  /**   The sum of fees generated by the pool in the network's base token in the past 24 hours. */
  poolFeesNBT24?: InputMaybe<NftPoolNumberFilter>;
  /**   The sum of fees generated by the pool in the network's base token over the pool's lifetime. */
  poolFeesNBTAll?: InputMaybe<NftPoolNumberFilter>;
  /**   The sum of fees generated by the pool in USD in the past 24 hours. */
  poolFeesUSD24?: InputMaybe<NftPoolNumberFilter>;
  /**   The sum of fees generated by the pool in USD over the pool's lifetime. */
  poolFeesUSDAll?: InputMaybe<NftPoolNumberFilter>;
  /**   The sum of protocol fees generated by the pool in the network's base token over the past 24 hours. */
  protocolFeesNBT24?: InputMaybe<NftPoolNumberFilter>;
  /**   The sum of protocol fees generated by the pool in the network's base token over the pool's lifetime. */
  protocolFeesNBTAll?: InputMaybe<NftPoolNumberFilter>;
  /**   The sum of protocol fees generated by the pool in USD over the past 24 hours. */
  protocolFeesUSD24?: InputMaybe<NftPoolNumberFilter>;
  /**   The sum of protocol fees generated by the pool in USD over the pool's lifetime. */
  protocolFeesUSDAll?: InputMaybe<NftPoolNumberFilter>;
  /**   The total buy volume of the pool in the network's base token over the past 24 hours. */
  revenueNBT24?: InputMaybe<NftPoolNumberFilter>;
  /**   The total buy volume of the pool in the network's base token over the pool's lifetime. */
  revenueNBTAll?: InputMaybe<NftPoolNumberFilter>;
  /**   The total buy volume of the pool in USD over the past 24 hours. */
  revenueUSD24?: InputMaybe<NftPoolNumberFilter>;
  /**   The total buy volume of the pool in USD over the pool's lifetime. */
  revenueUSDAll?: InputMaybe<NftPoolNumberFilter>;
  /**   The current sell price of the pool in the network's base token. */
  sellNBT?: InputMaybe<NftPoolNumberFilter>;
  /**   The current sell price of the pool in USD. */
  sellUSD?: InputMaybe<NftPoolNumberFilter>;
  /**   The total volume of the pool in the network's base token over the past 24 hours. */
  volumeNBT24?: InputMaybe<NftPoolNumberFilter>;
  /**   The total volume of the pool in the network's base token over the pool's lifetime. */
  volumeNBTAll?: InputMaybe<NftPoolNumberFilter>;
  /**   The total volume of the pool in USD over the past 24 hours. */
  volumeUSD24?: InputMaybe<NftPoolNumberFilter>;
  /**   The total volume of the pool in USD over the pool's lifetime. */
  volumeUSDAll?: InputMaybe<NftPoolNumberFilter>;
};

/**   Event data for depositing an NFT into a pool. */
export type NftPoolNftDepositEventData = {
  __typename?: 'NftPoolNftDepositEventData';
  /**   The number of NFTs in the contract after the block has processed. */
  nftTokenBalance: Scalars['String'];
  /**   The list of NFT token IDs deposited. */
  nftTokenIds?: Maybe<Array<Scalars['String']>>;
  /**   The amount of token in the contract after the block has processed in the pool's liquidity token. */
  tokenBalanceT: Scalars['String'];
  /**   The type of NFT pool event, `NFT_DEPOSIT`. */
  type: NftPoolEventType;
};

/**   undocumented */
export type NftPoolNftDepositEventDataInput = {
  nftTokenBalance: Scalars['String'];
  nftTokenIds?: InputMaybe<Array<Scalars['String']>>;
  tokenBalanceT: Scalars['String'];
  type: NftPoolEventType;
};

/**   Event data for depositing an NFT into a pool. */
export type NftPoolNftDepositEventDataV2 = {
  __typename?: 'NftPoolNftDepositEventDataV2';
  /**   The amount of each NFT token deposited. */
  nftTokenAmounts: Array<Scalars['String']>;
  /**   The list of NFT token IDs deposited. */
  nftTokenIds: Array<Scalars['String']>;
  /**   The amount of token in the contract after the block has processed in the pool's liquidity token. */
  tokenBalanceT: Scalars['String'];
  /**   The type of NFT pool event, `NFT_DEPOSIT`. */
  type: NftPoolEventType;
};

/**   undocumented */
export type NftPoolNftDepositEventDataV2Input = {
  nftTokenAmounts: Array<Scalars['String']>;
  nftTokenIds: Array<Scalars['String']>;
  tokenBalanceT: Scalars['String'];
  type: NftPoolEventType;
};

/**   Event data for withdrawing an NFT from a pool. */
export type NftPoolNftWithdrawalEventData = {
  __typename?: 'NftPoolNftWithdrawalEventData';
  /**   The number of NFTs in the contract after the block has processed. */
  nftTokenBalance: Scalars['String'];
  /**   The NFT token IDs withdrawn. */
  nftTokenIds?: Maybe<Array<Scalars['String']>>;
  /**   The amount of token in the contract after the block has processed in the pool's liquidity token. */
  tokenBalanceT: Scalars['String'];
  /**   The type of NFT pool event, `NFT_WITHDRAWAL`. */
  type: NftPoolEventType;
};

/**   undocumented */
export type NftPoolNftWithdrawalEventDataInput = {
  nftTokenBalance: Scalars['String'];
  nftTokenIds?: InputMaybe<Array<Scalars['String']>>;
  tokenBalanceT: Scalars['String'];
  type: NftPoolEventType;
};

/**   Event data for withdrawing an NFT from a pool. */
export type NftPoolNftWithdrawalEventDataV2 = {
  __typename?: 'NftPoolNftWithdrawalEventDataV2';
  /**   The amount of each NFT token withdrawn. */
  nftTokenAmounts: Array<Scalars['String']>;
  /**   The list of NFT token IDs withdrawn. */
  nftTokenIds?: Maybe<Array<Scalars['String']>>;
  /**   The amount of token in the contract after the block has processed in the pool's liquidity token. */
  tokenBalanceT: Scalars['String'];
  /**   The type of NFT pool event, `NFT_WITHDRAWAL`. */
  type: NftPoolEventType;
};

/**   undocumented */
export type NftPoolNftWithdrawalEventDataV2Input = {
  nftTokenAmounts: Array<Scalars['String']>;
  nftTokenIds: Array<Scalars['String']>;
  tokenBalanceT: Scalars['String'];
  type: NftPoolEventType;
};

/**   Input type of `NftPoolNumberFilter`. */
export type NftPoolNumberFilter = {
  /**   Greater than. */
  gt?: InputMaybe<Scalars['String']>;
  /**   Greater than or equal to. */
  gte?: InputMaybe<Scalars['String']>;
  /**   Less than. */
  lt?: InputMaybe<Scalars['String']>;
  /**   Less than or equal to. */
  lte?: InputMaybe<Scalars['String']>;
};

/**   Input type of `NftPoolRanking`. */
export type NftPoolRanking = {
  /**   The attribute to rank NFT pools by. */
  attribute?: InputMaybe<NftPoolRankingAttribute>;
  /**   The direction to apply to the ranking attribute. */
  direction?: InputMaybe<RankingDirection>;
};

/**   The attribute used to rank NFT pools. */
export enum NftPoolRankingAttribute {
  BalanceNbt = 'balanceNBT',
  BalanceUsd = 'balanceUSD',
  ExpenseNbt24 = 'expenseNBT24',
  ExpenseNbtAll = 'expenseNBTAll',
  ExpenseUsd24 = 'expenseUSD24',
  ExpenseUsdAll = 'expenseUSDAll',
  NftBalance = 'nftBalance',
  NftVolume24 = 'nftVolume24',
  NftVolumeAll = 'nftVolumeAll',
  NftsBought24 = 'nftsBought24',
  NftsBoughtAll = 'nftsBoughtAll',
  NftsSold24 = 'nftsSold24',
  NftsSoldAll = 'nftsSoldAll',
  OfferNbt = 'offerNBT',
  OfferUsd = 'offerUSD',
  PoolFeesNbt24 = 'poolFeesNBT24',
  PoolFeesNbtAll = 'poolFeesNBTAll',
  PoolFeesUsd24 = 'poolFeesUSD24',
  PoolFeesUsdAll = 'poolFeesUSDAll',
  ProtocolFeesNbt24 = 'protocolFeesNBT24',
  ProtocolFeesNbtAll = 'protocolFeesNBTAll',
  ProtocolFeesUsd24 = 'protocolFeesUSD24',
  ProtocolFeesUsdAll = 'protocolFeesUSDAll',
  RevenueNbt24 = 'revenueNBT24',
  RevenueNbtAll = 'revenueNBTAll',
  RevenueUsd24 = 'revenueUSD24',
  RevenueUsdAll = 'revenueUSDAll',
  SellNbt = 'sellNBT',
  SellUsd = 'sellUSD',
  VolumeNbt24 = 'volumeNBT24',
  VolumeNbtAll = 'volumeNBTAll',
  VolumeUsd24 = 'volumeUSD24',
  VolumeUsdAll = 'volumeUSDAll'
}

/**   An NFT pool. */
export type NftPoolResponse = {
  __typename?: 'NftPoolResponse';
  /**   For ERC1155 pools, the list of NFT token IDs that are accepted by the pool. */
  acceptedNftTokenIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**   The wallet address that will receive the tokens or NFT sent to the pair during swaps. */
  assetRecipientAddress: Scalars['String'];
  /**   The current pool liquidity in the network's base token. */
  balanceNBT: Scalars['String'];
  /**   The current pool liquidity in the pool's liquidity token. */
  balanceT: Scalars['String'];
  /**   The contract address of the bonding curve. */
  bondingCurveAddress: Scalars['String'];
  /**   The bonding curve type that defines how the prices of NFTs change after each buy or sell within a pool. */
  bondingCurveType: BondingCurveType;
  /**   The contract address of the NFT collection. */
  collectionAddress: Scalars['String'];
  /**   The name of the nft collection that this pool is associated with. */
  collectionName: Scalars['String'];
  /**   The current delta used in the bonding curve. */
  delta: Scalars['String'];
  /**   The contract address of the NFT AMM marketplace. */
  exchangeAddress: Scalars['String'];
  /**   The current fee for pool. */
  fee: Scalars['String'];
  /**   The current price at which the pool is willing to sell an NFT in the network's base token. Only applicable for `SELL` and `BUY_AND_SELL` pool types. */
  floorNBT?: Maybe<Scalars['String']>;
  /**   The current price at which the pool is willing to sell an NFT in the pool's liquidity token. Only applicable for `SELL` and `BUY_AND_SELL` pool types. */
  floorT?: Maybe<Scalars['String']>;
  /**   The network ID the NFT collection is deployed on. */
  networkId: Scalars['Int'];
  /**   The list of NFT assets in the pool. */
  nftAssets?: Maybe<Array<Maybe<NftAsset>>>;
  /**
   *   The current number of NFTs in the pool.
   * @deprecated nftBalance is changing from Int to String - use nftBalanceV2 instead.
   */
  nftBalance?: Maybe<Scalars['Int']>;
  /**   The current number of NFTs in the pool. */
  nftBalanceV2: Scalars['String'];
  /**
   *   The total number of NFTs bought and sold over the pool's lifetime.
   * @deprecated nftVolumeAllTime is changing from Int to String - use nftVolumeAllTimeV2 instead.
   */
  nftVolumeAllTime: Scalars['Int'];
  /**   The total number of NFTs bought and sold over the pool's lifetime. */
  nftVolumeAllTimeV2: Scalars['String'];
  /**   The current price at which the pool is willing to buy an NFT in the network's base token. Only applicable for `BUY` and `BUY_AND_SELL` pool types. */
  offerNBT?: Maybe<Scalars['String']>;
  /**   The current price at which the pool is willing to buy an NFT in the pool's liquidity token. Only applicable for `BUY` and `BUY_AND_SELL` pool types. */
  offerT?: Maybe<Scalars['String']>;
  /**   The wallet address of the pool owner. */
  owner: Scalars['String'];
  /**   The contract address of the NFT pool. */
  poolAddress: Scalars['String'];
  /**   The ID of the NFT pool (`poolAddress`:`networkId`). For example, `0xdbea289dcc10eed8431e78753414a3d81b8e7201:1`. */
  poolId: Scalars['String'];
  /**   The type of NFT in the pool. */
  poolNftType?: Maybe<PoolNftType>;
  /**   The type of liquidity pool. */
  poolType: NftPoolType;
  /**   The pool variant. Can be `ERC20` or `NATIVE`. */
  poolVariant: GraphQlNftPoolVariant;
  /**   The property checker contract address for the pool. */
  propertyChecker?: Maybe<Scalars['String']>;
  /**   The list of royalties for the pool. Only applicable for `SUDOSWAP_V2` pools. */
  royalties?: Maybe<Array<Maybe<NftPoolRoyalty>>>;
  /**   The spot price in the network's base token. */
  spotPriceNBT: Scalars['String'];
  /**   The instantaneous price for selling 1 NFT to the pool in the pool's liquidity token. */
  spotPriceT: Scalars['String'];
  /**   The contract address of the liquidity token of the pool (usually WETH). */
  tokenAddress: Scalars['String'];
  /**   The NFT pool contract version. Can be `SUDOSWAP_V1` or `SUDOSWAP_V2`. */
  version?: Maybe<NftPoolContractVersion>;
  /**   The total volume of the pool in the network's base token over the pool's lifetime. */
  volumeAllTimeNBT: Scalars['String'];
  /**   The total volume of the pool in the pool's liquidity token over the pool's lifetime. */
  volumeAllTimeT: Scalars['String'];
};

/**   The royalty for a SUDOSWAP_V2 pool. */
export type NftPoolRoyalty = {
  __typename?: 'NftPoolRoyalty';
  /**   The royalty percent. */
  percent?: Maybe<Scalars['String']>;
  /**   The wallet address of recipient. */
  recipient?: Maybe<Scalars['String']>;
};

export type NftPoolRoyaltyInput = {
  percent?: InputMaybe<Scalars['String']>;
  recipient?: InputMaybe<Scalars['String']>;
};

/**   Event data for updating the spot price of a pool. */
export type NftPoolSpotPriceUpdateEventData = {
  __typename?: 'NftPoolSpotPriceUpdateEventData';
  /**   The ratio of the transaction token to the network's base token. */
  nbtRatio: Scalars['String'];
  /**   The updated price at which the pool is willing to buy an NFT in the pool's liquidity token. */
  newBuyPriceT: Scalars['String'];
  /**   The updated price at which the pool is willing to sell an NFT in the pool's liquidity token. */
  newSellPriceT: Scalars['String'];
  /**   The updated spot price in the pool's liquidity token. */
  newSpotPriceT: Scalars['String'];
  /**   The type of NFT pool event, `SPOT_PRICE_UPDATE`. */
  type: NftPoolEventType;
  /**   The ratio of the transaction token to USD. */
  usdRatio: Scalars['String'];
};

/**   undocumented */
export type NftPoolSpotPriceUpdateEventDataInput = {
  nbtRatio: Scalars['String'];
  newBuyPriceT: Scalars['String'];
  newSellPriceT: Scalars['String'];
  newSpotPriceT: Scalars['String'];
  type: NftPoolEventType;
  usdRatio: Scalars['String'];
};

/**   Event data for updating the spot price of a pool. */
export type NftPoolSpotPriceUpdateEventDataV2 = {
  __typename?: 'NftPoolSpotPriceUpdateEventDataV2';
  /**   The ratio of the transaction token to the network's base token. */
  nbtRatio: Scalars['String'];
  /**   The updated price at which the pool is willing to buy an NFT in the pool's liquidity token. */
  newBuyPriceT: Scalars['String'];
  /**   The updated price at which the pool is willing to sell an NFT in the pool's liquidity token. */
  newSellPriceT: Scalars['String'];
  /**   The updated spot price in the pool's liquidity token. */
  newSpotPriceT: Scalars['String'];
  /**   The type of NFT pool event, `SPOT_PRICE_UPDATE`. */
  type: NftPoolEventType;
  /**   The ratio of the transaction token to USD. */
  usdRatio: Scalars['String'];
};

/**   undocumented */
export type NftPoolSpotPriceUpdateEventDataV2Input = {
  nbtRatio: Scalars['String'];
  newBuyPriceT: Scalars['String'];
  newSellPriceT: Scalars['String'];
  newSpotPriceT: Scalars['String'];
  type: NftPoolEventType;
  usdRatio: Scalars['String'];
};

/**   Stats for an NFT pool. */
export type NftPoolStatsResponse = {
  __typename?: 'NftPoolStatsResponse';
  /**   The pool liquidity in the network's base token at the end of the time frame. */
  closeBalanceNBT?: Maybe<Scalars['String']>;
  /**
   *   The number of NFTs in the pool at the end of the time frame.
   * @deprecated closeNftBalance is changing from Int to String - use closeNftBalanceV2 instead.
   */
  closeNftBalance?: Maybe<Scalars['Int']>;
  /**   The number of NFTs in the pool at the end of the time frame. */
  closeNftBalanceV2?: Maybe<Scalars['String']>;
  /**   The contract address of the NFT collection. */
  collectionAddress: Scalars['String'];
  /**   The unix timestamp for the end of the time frame. */
  endTime: Scalars['Int'];
  /**   The contract address of the NFT AMM marketplace. */
  exchangeAddress: Scalars['String'];
  /**   The total sell volume of the pool in the network's base token over the time frame. */
  expenseNBT?: Maybe<Scalars['String']>;
  /**   The highest price at which the pool was willing to sell an NFT in the network's base token over the time frame. */
  highFloorNBT?: Maybe<Scalars['String']>;
  /**   The highest price at which the pool was willing to buy an NFT in the network's base token over the time frame. */
  highOfferNBT?: Maybe<Scalars['String']>;
  /**   The lowest price at which the pool was willing to sell an NFT in the network's base token over the time frame. */
  lowFloorNBT?: Maybe<Scalars['String']>;
  /**   The lowest price at which the pool was willing to buy an NFT in the network's base token over the time frame. */
  lowOfferNBT?: Maybe<Scalars['String']>;
  /**   The network ID the NFT collection is deployed on. */
  networkId: Scalars['Int'];
  /**
   *   The total number of NFTs bought and sold over the time frame.
   * @deprecated nftVolume is changing from Int to String - use nftVolumeV2 instead.
   */
  nftVolume?: Maybe<Scalars['Int']>;
  nftVolumeV2?: Maybe<Scalars['String']>;
  /**
   *   The total number of NFTs bought over the time frame.
   * @deprecated nftsBought is changing from Int to String - use nftsBoughtV2 instead.
   */
  nftsBought?: Maybe<Scalars['Int']>;
  /**   The total number of NFTs bought over the time frame. */
  nftsBoughtV2?: Maybe<Scalars['String']>;
  /**
   *   The total number of NFTs sold over the time frame.
   * @deprecated nftsSold is changing from Int to String - use nftsSoldV2 instead.
   */
  nftsSold?: Maybe<Scalars['Int']>;
  /**   The total number of NFTs sold over the time frame. */
  nftsSoldV2?: Maybe<Scalars['String']>;
  /**   The pool liquidity in the network's base token at the start of the time frame. */
  openBalanceNBT?: Maybe<Scalars['String']>;
  /**
   *   The number of NFTs in the pool at the start of the time frame.
   * @deprecated openNftBalance is changing from Int to String - use openNftBalanceV2 instead.
   */
  openNftBalance?: Maybe<Scalars['Int']>;
  /**   The number of NFTs in the pool at the start of the time frame. */
  openNftBalanceV2?: Maybe<Scalars['String']>;
  /**   The contract address of the NFT pool. */
  poolAddress?: Maybe<Scalars['String']>;
  /**   The sum of pool fees generated by the pool in the network's base token over the time frame. */
  poolFeesNBT?: Maybe<Scalars['String']>;
  /**   The sum of protocol fees generated by the pool in the network's base token over the time frame. */
  protocolFeesNBT?: Maybe<Scalars['String']>;
  /**   The total buy volume of the pool in the network's base token over the time frame. */
  revenueNBT?: Maybe<Scalars['String']>;
  /**   The unix timestamp for the start of the time frame. */
  startTime: Scalars['Int'];
  /**   The total volume of the pool in the network's base token over the time frame. */
  volumeNBT?: Maybe<Scalars['String']>;
};

/**   undocumented */
export type NftPoolStatsResponseV2 = {
  __typename?: 'NftPoolStatsResponseV2';
  closeBalanceNBT?: Maybe<Scalars['String']>;
  closeNftBalance?: Maybe<Scalars['String']>;
  collectionAddress: Scalars['String'];
  endTime: Scalars['Int'];
  exchangeAddress: Scalars['String'];
  expenseNBT?: Maybe<Scalars['String']>;
  highFloorNBT?: Maybe<Scalars['String']>;
  highOfferNBT?: Maybe<Scalars['String']>;
  lowFloorNBT?: Maybe<Scalars['String']>;
  lowOfferNBT?: Maybe<Scalars['String']>;
  networkId: Scalars['Int'];
  nftVolume?: Maybe<Scalars['String']>;
  nftsBought?: Maybe<Scalars['String']>;
  nftsSold?: Maybe<Scalars['String']>;
  openBalanceNBT?: Maybe<Scalars['String']>;
  openNftBalance?: Maybe<Scalars['String']>;
  poolAddress?: Maybe<Scalars['String']>;
  poolFeesNBT?: Maybe<Scalars['String']>;
  protocolFeesNBT?: Maybe<Scalars['String']>;
  revenueNBT?: Maybe<Scalars['String']>;
  startTime: Scalars['Int'];
  volumeNBT?: Maybe<Scalars['String']>;
};

/**   Event data for depositing a token into a pool. */
export type NftPoolTokenDepositEventData = {
  __typename?: 'NftPoolTokenDepositEventData';
  /**   The total value of token deposited in the pool's liquidity token. */
  amountT: Scalars['String'];
  /**   The ratio of the transaction token to the network's base token. */
  nbtRatio: Scalars['String'];
  /**   The number of NFTs in the contract after the block has processed. */
  nftTokenBalance: Scalars['String'];
  /**   The amount of token in the contract after the block has processed in the pool's liquidity token. */
  tokenBalanceT: Scalars['String'];
  /**   The type of NFT pool event, `TOKEN_DEPOSIT`. */
  type: NftPoolEventType;
  /**   The ratio of the transaction token to USD. */
  usdRatio: Scalars['String'];
};

/**   undocumented */
export type NftPoolTokenDepositEventDataInput = {
  amountT: Scalars['String'];
  nbtRatio: Scalars['String'];
  nftTokenBalance: Scalars['String'];
  tokenBalanceT: Scalars['String'];
  type: NftPoolEventType;
  usdRatio: Scalars['String'];
};

/**   Event data for depositing a token into a pool. */
export type NftPoolTokenDepositEventDataV2 = {
  __typename?: 'NftPoolTokenDepositEventDataV2';
  /**   The total value of token deposited in the pool's liquidity token. */
  amountT: Scalars['String'];
  /**   The ratio of the transaction token to the network's base token. */
  nbtRatio: Scalars['String'];
  /**   The amount of token in the contract after the block has processed in the pool's liquidity token. */
  tokenBalanceT: Scalars['String'];
  /**   The type of NFT pool event, `TOKEN_DEPOSIT`. */
  type: NftPoolEventType;
  /**   The ratio of the transaction token to USD. */
  usdRatio: Scalars['String'];
};

/**   undocumented */
export type NftPoolTokenDepositEventDataV2Input = {
  amountT: Scalars['String'];
  nbtRatio: Scalars['String'];
  tokenBalanceT: Scalars['String'];
  type: NftPoolEventType;
  usdRatio: Scalars['String'];
};

/**   Event data for withdrawing a token from a pool. */
export type NftPoolTokenWithdrawalEventData = {
  __typename?: 'NftPoolTokenWithdrawalEventData';
  /**   The total value of token withdrawn in the pool's liquidity token. */
  amountT: Scalars['String'];
  /**   The ratio of the transaction token to the network's base token. */
  nbtRatio: Scalars['String'];
  /**   The number of NFTs in the contract after the block has processed. */
  nftTokenBalance: Scalars['String'];
  /**   The amount of token in the contract after the block has processed in the pool's liquidity token. */
  tokenBalanceT: Scalars['String'];
  /**   The type of NFT pool event, `TOKEN_WITHDRAWAL`. */
  type: NftPoolEventType;
  /**   The ratio of the transaction token to USD. */
  usdRatio: Scalars['String'];
};

/**   undocumented */
export type NftPoolTokenWithdrawalEventDataInput = {
  amountT: Scalars['String'];
  nbtRatio: Scalars['String'];
  nftTokenBalance: Scalars['String'];
  tokenBalanceT: Scalars['String'];
  type: NftPoolEventType;
  usdRatio: Scalars['String'];
};

/**   Event data for withdrawing a token from a pool. */
export type NftPoolTokenWithdrawalEventDataV2 = {
  __typename?: 'NftPoolTokenWithdrawalEventDataV2';
  /**   The total value of token withdrawn in the pool's liquidity token. */
  amountT: Scalars['String'];
  /**   The ratio of the transaction token to the network's base token. */
  nbtRatio: Scalars['String'];
  /**   The amount of token in the contract after the block has processed in the pool's liquidity token. */
  tokenBalanceT: Scalars['String'];
  /**   The type of NFT pool event, `TOKEN_WITHDRAWAL`. */
  type: NftPoolEventType;
  /**   The ratio of the transaction token to USD. */
  usdRatio: Scalars['String'];
};

/**   undocumented */
export type NftPoolTokenWithdrawalEventDataV2Input = {
  amountT: Scalars['String'];
  nbtRatio: Scalars['String'];
  tokenBalanceT: Scalars['String'];
  type: NftPoolEventType;
  usdRatio: Scalars['String'];
};

/**   The pool type selected by the pool creator. */
export enum NftPoolType {
  Buy = 'BUY',
  BuyAndSell = 'BUY_AND_SELL',
  Sell = 'SELL'
}

/**   Response returned by `searchNfts`. */
export type NftSearchResponse = {
  __typename?: 'NftSearchResponse';
  /**   The number of additional results found. */
  hasMore: Scalars['Int'];
  /**   A list of NFT collections matching a given query string. */
  items?: Maybe<Array<Maybe<NftSearchResponseCollection>>>;
};

/**   An NFT collection matching a given query string. */
export type NftSearchResponseCollection = {
  __typename?: 'NftSearchResponseCollection';
  /**   The contract address of the NFT collection. */
  address: Scalars['String'];
  /**   The average sale price over the `window`. */
  average: Scalars['String'];
  /**   The highest sale price over the `window`. */
  ceiling: Scalars['String'];
  /**   The lowest sale price over the `window`. */
  floor: Scalars['String'];
  /**   The ID of the NFT collection (`address`:`networkId`). */
  id: Scalars['String'];
  /**   The image URL for the collection or one of the assets within the collection. */
  imageUrl?: Maybe<Scalars['String']>;
  /**   The name of the NFT collection. For example, `Bored Ape Yacht Club`. */
  name?: Maybe<Scalars['String']>;
  /**   The network ID the NFT collection is deployed on. */
  networkId: Scalars['Int'];
  /**   The symbol of the NFT collection. For example, `BAYC`. */
  symbol?: Maybe<Scalars['String']>;
  /**   The trade count over the `window`. */
  tradeCount: Scalars['String'];
  /**   The change in trade count between the previous and current `window`. */
  tradeCountChange: Scalars['Float'];
  /**   The trade volume in USD over the `window`. */
  volume: Scalars['String'];
  /**   The change in volume between the previous and current `window`. */
  volumeChange: Scalars['Float'];
  /**   The time frame used for calculating stats. */
  window: Scalars['String'];
};

/**   Number metrics for NFT stats. */
export type NftStatsNumberMetrics = {
  __typename?: 'NftStatsNumberMetrics';
  /**   The percent change between the `current` and `previous`. */
  change?: Maybe<Scalars['Float']>;
  /**   The total value for the current window. */
  current?: Maybe<Scalars['Float']>;
  /**   The total value for the previous window. */
  previous?: Maybe<Scalars['Float']>;
};

/**   String metrics for NFT stats. */
export type NftStatsStringMetrics = {
  __typename?: 'NftStatsStringMetrics';
  /**   The percent change between the `current` and `previous`. */
  change?: Maybe<Scalars['Float']>;
  /**   The total value for the current window. */
  current?: Maybe<Scalars['String']>;
  /**   The total value for the previous window. */
  previous?: Maybe<Scalars['String']>;
};

/**   NFT stats for a given time frame. */
export type NftStatsWindowFilter = {
  /**   The currency stats in the network's base token, such as volume. */
  networkBaseToken?: InputMaybe<StatsCurrency>;
  /**   The numerical stats, such as number of sales. */
  nonCurrency?: InputMaybe<StatsNonCurrency>;
  /**   The currency stats in USD, such as volume. */
  usd?: InputMaybe<StatsCurrency>;
};

/**   NFT stats over a time frame. */
export type NftStatsWindowWithChange = {
  __typename?: 'NftStatsWindowWithChange';
  /**   The unix timestamp for the end of the window. */
  endTime?: Maybe<Scalars['Int']>;
  /**   The currency stats in the network's base token, such as volume. */
  networkBaseToken?: Maybe<NftCollectionCurrencyStats>;
  /**   The numerical stats, such as number of sales. */
  nonCurrency?: Maybe<NftCollectionNonCurrencyStats>;
  /**   The unix timestamp for the start of the window. */
  startTime?: Maybe<Scalars['Int']>;
  /**   The currency stats in USD, such as volume. */
  usd?: Maybe<NftCollectionCurrencyStats>;
};

/**   Input type of `NumberFilter`. */
export type NumberFilter = {
  /**   Greater than. */
  gt?: InputMaybe<Scalars['Float']>;
  /**   Greater than or equal to. */
  gte?: InputMaybe<Scalars['Float']>;
  /**   Less than. */
  lt?: InputMaybe<Scalars['Float']>;
  /**   Less than or equal to. */
  lte?: InputMaybe<Scalars['Float']>;
};

/**   Integer list condition. */
export type OneOfNumberCondition = {
  __typename?: 'OneOfNumberCondition';
  /**   The list of integers. */
  oneOf: Array<Scalars['Int']>;
};

/**   Input for integer list condition. */
export type OneOfNumberConditionInput = {
  /**   The list of integers. */
  oneOf: Array<Scalars['Int']>;
};

/**   Metadata for a token pair. */
export type Pair = {
  __typename?: 'Pair';
  /**   The contract address of the pair. */
  address: Scalars['String'];
  /**   The address for the exchange factory contract. */
  exchangeHash: Scalars['String'];
  /**   The exchange fee for swaps. */
  fee?: Maybe<Scalars['Int']>;
  /**   The ID for the pair (`address`:`networkId`). */
  id: Scalars['String'];
  /**   The network ID the pair is deployed on. */
  networkId: Scalars['Int'];
  /**   The amount of required tick separation. Only applicable for pairs on UniswapV3. */
  tickSpacing?: Maybe<Scalars['Int']>;
  /**   The contract address of `token0`. */
  token0: Scalars['String'];
  /**   The contract address of `token1`. */
  token1: Scalars['String'];
};

/**   Input type of `PairChartInput`. */
export type PairChartInput = {
  /**   The unix timestamp for the start of the requested range. */
  from?: InputMaybe<Scalars['Int']>;
  /**   The network ID the pair is deployed on. */
  networkId: Scalars['Int'];
  /**   The contract address of the pair. */
  pairAddress: Scalars['String'];
  /**   The token of interest within the token's top pair. Can be `token0` or `token1`. */
  quoteToken?: InputMaybe<QuoteToken>;
  /**   The time frame for each candle. Available options are `1`, `5`, `15`, `30`, `60`, `240`, `720`, `1D`, `7D` */
  resolution?: InputMaybe<Scalars['String']>;
  /**   The colour theme of the chart */
  theme?: InputMaybe<ChartTheme>;
  /**   The unix timestamp for the end of the requested range. */
  to?: InputMaybe<Scalars['Int']>;
};

/**   Response returned by `filterPairs`. */
export type PairFilterConnection = {
  __typename?: 'PairFilterConnection';
  /**   The number of pairs returned. */
  count?: Maybe<Scalars['Int']>;
  /**   Where in the list the server started when returning items. */
  offset?: Maybe<Scalars['Int']>;
  /**   The list of pairs matching the filter parameters. */
  results?: Maybe<Array<Maybe<PairFilterResult>>>;
};

/**   Input type of `PairFilterMatchTokens`. */
export type PairFilterMatchTokens = {
  /**   The contract address of `token0` to filter by. */
  token0?: InputMaybe<Scalars['String']>;
  /**   The contract address of `token1` to filter by. */
  token1?: InputMaybe<Scalars['String']>;
};

/**   A pair matching a set of filter parameters. */
export type PairFilterResult = {
  __typename?: 'PairFilterResult';
  /**   The number of buys in the past hour. */
  buyCount1?: Maybe<Scalars['Int']>;
  /**   The number of buys in the past 4 hours. */
  buyCount4?: Maybe<Scalars['Int']>;
  /**   The number of buys in the past 12 hours. */
  buyCount12?: Maybe<Scalars['Int']>;
  /**   The number of buys in the past 24 hours. */
  buyCount24?: Maybe<Scalars['Int']>;
  /**   The unix timestamp for the first transaction to happen on the pair. */
  createdAt?: Maybe<Scalars['Int']>;
  /**   Exchange metadata for the pair. */
  exchange?: Maybe<FilterExchange>;
  /**   The highest price in USD in the past hour. */
  highPrice1?: Maybe<Scalars['String']>;
  /**   The highest price in USD in the past 4 hours. */
  highPrice4?: Maybe<Scalars['String']>;
  /**   The highest price in USD in the past 12 hours. */
  highPrice12?: Maybe<Scalars['String']>;
  /**   The highest price in USD in the past 24 hours. */
  highPrice24?: Maybe<Scalars['String']>;
  /**   The unix timestamp for the last transaction to happen on the pair. */
  lastTransaction?: Maybe<Scalars['Int']>;
  /**   Amount of liquidity in the pair. */
  liquidity?: Maybe<Scalars['String']>;
  /**   The token with higher liquidity in the pair. Can be `token0` or `token1`. */
  liquidityToken?: Maybe<Scalars['String']>;
  /**   The lowest price in USD in the past hour. */
  lowPrice1?: Maybe<Scalars['String']>;
  /**   The lowest price in USD in the past 4 hours. */
  lowPrice4?: Maybe<Scalars['String']>;
  /**   The lowest price in USD in the past 12 hours. */
  lowPrice12?: Maybe<Scalars['String']>;
  /**   The lowest price in USD in the past 24 hours. */
  lowPrice24?: Maybe<Scalars['String']>;
  /**   The fully diluted market cap. */
  marketCap?: Maybe<Scalars['String']>;
  /**   Metadata for the pair. */
  pair?: Maybe<Pair>;
  /**   The token price in USD. */
  price?: Maybe<Scalars['String']>;
  /**   The percent price change in the past hour. */
  priceChange1?: Maybe<Scalars['String']>;
  /**   The percent price change in the past 4 hours. */
  priceChange4?: Maybe<Scalars['String']>;
  /**   The percent price change in the past 12 hours. */
  priceChange12?: Maybe<Scalars['String']>;
  /**   The percent price change in the past 24 hours. */
  priceChange24?: Maybe<Scalars['String']>;
  /**   10^n, where n is the number of decimal places the price has. Max 16. Used for charting. */
  priceScale?: Maybe<Scalars['String']>;
  /**   The token of interest. Can be `token0` or `token1`. */
  quoteToken?: Maybe<Scalars['String']>;
  /**   The number of sells in the past hour. */
  sellCount1?: Maybe<Scalars['Int']>;
  /**   The number of sells in the past 4 hours. */
  sellCount4?: Maybe<Scalars['Int']>;
  /**   The number of sells in the past 12 hours. */
  sellCount12?: Maybe<Scalars['Int']>;
  /**   The number of sells in the past 24 hours. */
  sellCount24?: Maybe<Scalars['Int']>;
  /**   Metadata for the first token in the pair. */
  token0?: Maybe<EnhancedToken>;
  /**   Metadata for the second token in the pair. */
  token1?: Maybe<EnhancedToken>;
  /**   The number of transactions in the past hour. */
  txnCount1?: Maybe<Scalars['Int']>;
  /**   The number of transactions in the past 4 hours. */
  txnCount4?: Maybe<Scalars['Int']>;
  /**   The number of transactions in the past 12 hours. */
  txnCount12?: Maybe<Scalars['Int']>;
  /**   The number of transactions in the past 24 hours. */
  txnCount24?: Maybe<Scalars['Int']>;
  /**   The unique number of buys in the past hour. */
  uniqueBuys1?: Maybe<Scalars['Int']>;
  /**   The unique number of buys in the past 4 hours. */
  uniqueBuys4?: Maybe<Scalars['Int']>;
  /**   The unique number of buys in the past 12 hours. */
  uniqueBuys12?: Maybe<Scalars['Int']>;
  /**   The unique number of buys in the past 24 hours. */
  uniqueBuys24?: Maybe<Scalars['Int']>;
  /**   The unique number of sells in the past hour. */
  uniqueSells1?: Maybe<Scalars['Int']>;
  /**   The unique number of sells in the past 4 hours. */
  uniqueSells4?: Maybe<Scalars['Int']>;
  /**   The unique number of sells in the past 12 hours. */
  uniqueSells12?: Maybe<Scalars['Int']>;
  /**   The unique number of sells in the past 24 hours. */
  uniqueSells24?: Maybe<Scalars['Int']>;
  /**   The unique number of transactions in the past hour. */
  uniqueTransactions1?: Maybe<Scalars['Int']>;
  /**   The unique number of transactions in the past 4 hours. */
  uniqueTransactions4?: Maybe<Scalars['Int']>;
  /**   The unique number of transactions in the past 12 hours. */
  uniqueTransactions12?: Maybe<Scalars['Int']>;
  /**   The unique number of transactions in the past 24 hours. */
  uniqueTransactions24?: Maybe<Scalars['Int']>;
  /**   The trade volume in USD in the past hour. */
  volumeUSD1?: Maybe<Scalars['String']>;
  /**   The trade volume in USD in the past 4 hours. */
  volumeUSD4?: Maybe<Scalars['String']>;
  /**   The trade volume in USD in the past 12 hours. */
  volumeUSD12?: Maybe<Scalars['String']>;
  /**   The trade volume in USD in the past 24 hours. */
  volumeUSD24?: Maybe<Scalars['String']>;
};

/**   Input type of `PairFilters`. */
export type PairFilters = {
  /**   The number of buys in the past hour. */
  buyCount1?: InputMaybe<NumberFilter>;
  /**   The number of buys in the past 4 hours. */
  buyCount4?: InputMaybe<NumberFilter>;
  /**   The number of buys in the past 12 hours. */
  buyCount12?: InputMaybe<NumberFilter>;
  /**   The number of buys in the past 24 hours. */
  buyCount24?: InputMaybe<NumberFilter>;
  /**   The unix timestamp for the first transaction to happen on the pair. */
  createdAt?: InputMaybe<NumberFilter>;
  /**   The list of exchange contract addresses to filter by. */
  exchangeAddress?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /**   The highest price in USD in the past hour. */
  highPrice1?: InputMaybe<NumberFilter>;
  /**   The highest price in USD in the past 4 hours. */
  highPrice4?: InputMaybe<NumberFilter>;
  /**   The highest price in USD in the past 12 hours. */
  highPrice12?: InputMaybe<NumberFilter>;
  /**   The highest price in USD in the past 24 hours. */
  highPrice24?: InputMaybe<NumberFilter>;
  /**   The unix timestamp for the last transaction to happen on the pair. */
  lastTransaction?: InputMaybe<NumberFilter>;
  /**   The amount of liquidity in the pair. */
  liquidity?: InputMaybe<NumberFilter>;
  /**   The lowest price in USD in the past hour. */
  lowPrice1?: InputMaybe<NumberFilter>;
  /**   The lowest price in USD in the past 4 hours. */
  lowPrice4?: InputMaybe<NumberFilter>;
  /**   The lowest price in USD in the past 12 hours. */
  lowPrice12?: InputMaybe<NumberFilter>;
  /**   The lowest price in USD in the past 24 hours. */
  lowPrice24?: InputMaybe<NumberFilter>;
  /**   The list of network IDs to filter by. */
  network?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /**   The token price in USD. */
  price?: InputMaybe<NumberFilter>;
  /**   The percent price change in the past hour. */
  priceChange1?: InputMaybe<NumberFilter>;
  /**   The percent price change in the past 4 hours. */
  priceChange4?: InputMaybe<NumberFilter>;
  /**   The percent price change in the past 12 hours. */
  priceChange12?: InputMaybe<NumberFilter>;
  /**   The percent price change in the past 24 hours. */
  priceChange24?: InputMaybe<NumberFilter>;
  /**   The number of sells in the past hour. */
  sellCount1?: InputMaybe<NumberFilter>;
  /**   The number of sells in the past 4 hours. */
  sellCount4?: InputMaybe<NumberFilter>;
  /**   The number of sells in the past 12 hours. */
  sellCount12?: InputMaybe<NumberFilter>;
  /**   The number of sells in the past 24 hours. */
  sellCount24?: InputMaybe<NumberFilter>;
  /**   The list of token contract addresses to filter by. */
  tokenAddress?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /**   The number of transactions in the past hour. */
  txnCount1?: InputMaybe<NumberFilter>;
  /**   The number of transactions in the past 4 hours. */
  txnCount4?: InputMaybe<NumberFilter>;
  /**   The number of transactions in the past 12 hours. */
  txnCount12?: InputMaybe<NumberFilter>;
  /**   The number of transactions in the past 24 hours. */
  txnCount24?: InputMaybe<NumberFilter>;
  /**   The unique number of buys in the past hour. */
  uniqueBuys1?: InputMaybe<NumberFilter>;
  /**   The unique number of buys in the past 4 hours. */
  uniqueBuys4?: InputMaybe<NumberFilter>;
  /**   The unique number of buys in the past 12 hours. */
  uniqueBuys12?: InputMaybe<NumberFilter>;
  /**   The unique number of buys in the past 24 hours. */
  uniqueBuys24?: InputMaybe<NumberFilter>;
  /**   The unique number of sells in the past hour. */
  uniqueSells1?: InputMaybe<NumberFilter>;
  /**   The unique number of sells in the past 4 hours. */
  uniqueSells4?: InputMaybe<NumberFilter>;
  /**   The unique number of sells in the past 12 hours. */
  uniqueSells12?: InputMaybe<NumberFilter>;
  /**   The unique number of sells in the past 24 hours. */
  uniqueSells24?: InputMaybe<NumberFilter>;
  /**   The unique number of transactions in the past hour. */
  uniqueTransactions1?: InputMaybe<NumberFilter>;
  /**   The unique number of transactions in the past 4 hours. */
  uniqueTransactions4?: InputMaybe<NumberFilter>;
  /**   The unique number of transactions in the past 12 hours. */
  uniqueTransactions12?: InputMaybe<NumberFilter>;
  /**   The unique number of transactions in the past 24 hours. */
  uniqueTransactions24?: InputMaybe<NumberFilter>;
  /**   The trade volume in USD in the past hour. */
  volumeUSD1?: InputMaybe<NumberFilter>;
  /**   The trade volume in USD in the past 4 hours. */
  volumeUSD4?: InputMaybe<NumberFilter>;
  /**   The trade volume in USD in the past 12 hours. */
  volumeUSD12?: InputMaybe<NumberFilter>;
  /**   The trade volume in USD in the past 24 hours. */
  volumeUSD24?: InputMaybe<NumberFilter>;
};

/**   Input type of `PairRanking`. */
export type PairRanking = {
  /**   The attribute to rank pairs by. */
  attribute?: InputMaybe<PairRankingAttribute>;
  /**   The direction to apply to the ranking attribute. */
  direction?: InputMaybe<RankingDirection>;
};

/**   The attribute used to rank tokens. */
export enum PairRankingAttribute {
  BuyCount1 = 'buyCount1',
  BuyCount4 = 'buyCount4',
  BuyCount12 = 'buyCount12',
  BuyCount24 = 'buyCount24',
  CreatedAt = 'createdAt',
  HighPrice1 = 'highPrice1',
  HighPrice4 = 'highPrice4',
  HighPrice12 = 'highPrice12',
  HighPrice24 = 'highPrice24',
  LastTransaction = 'lastTransaction',
  Liquidity = 'liquidity',
  LowPrice1 = 'lowPrice1',
  LowPrice4 = 'lowPrice4',
  LowPrice12 = 'lowPrice12',
  LowPrice24 = 'lowPrice24',
  MarketCap = 'marketCap',
  Price = 'price',
  PriceChange1 = 'priceChange1',
  PriceChange4 = 'priceChange4',
  PriceChange12 = 'priceChange12',
  PriceChange24 = 'priceChange24',
  SellCount1 = 'sellCount1',
  SellCount4 = 'sellCount4',
  SellCount12 = 'sellCount12',
  SellCount24 = 'sellCount24',
  TxnCount1 = 'txnCount1',
  TxnCount4 = 'txnCount4',
  TxnCount12 = 'txnCount12',
  TxnCount24 = 'txnCount24',
  UniqueBuys1 = 'uniqueBuys1',
  UniqueBuys4 = 'uniqueBuys4',
  UniqueBuys12 = 'uniqueBuys12',
  UniqueBuys24 = 'uniqueBuys24',
  UniqueSells1 = 'uniqueSells1',
  UniqueSells4 = 'uniqueSells4',
  UniqueSells12 = 'uniqueSells12',
  UniqueSells24 = 'uniqueSells24',
  UniqueTransactions1 = 'uniqueTransactions1',
  UniqueTransactions4 = 'uniqueTransactions4',
  UniqueTransactions12 = 'uniqueTransactions12',
  UniqueTransactions24 = 'uniqueTransactions24',
  VolumeUsd1 = 'volumeUSD1',
  VolumeUsd4 = 'volumeUSD4',
  VolumeUsd12 = 'volumeUSD12',
  VolumeUsd24 = 'volumeUSD24'
}

/**   A Parallel asset. */
export type ParallelAsset = {
  __typename?: 'ParallelAsset';
  /**   The contract address of the NFT collection. */
  address: Scalars['String'];
  /**   The attributes for the NFT asset. */
  attributes?: Maybe<Array<NftAssetAttribute>>;
  /**   The description of the NFT asset. */
  description?: Maybe<Scalars['String']>;
  /**   The game data for the NFT asset. */
  gameData?: Maybe<ParallelAssetGameData>;
  /**   The ID of the NFT asset (`address`:`tokenId`). */
  id: Scalars['String'];
  /**   The NFT asset media. */
  media?: Maybe<NftAssetMedia>;
  /**   Metadata for the NFT asset. */
  metadata?: Maybe<ParallelAssetMetadata>;
  /**   The name of the NFT asset. */
  name?: Maybe<Scalars['String']>;
  /**   The network ID the NFT collection is deployed on. */
  networkId: Scalars['Int'];
  /**   The source image URI linked by smart contract metadata. */
  originalImage?: Maybe<Scalars['String']>;
  /**   The token ID of the NFT asset. */
  tokenId: Scalars['String'];
  /**   The URI provided by the smart contract. Typically JSON that contains metadata. */
  uri?: Maybe<Scalars['String']>;
};

/**   Response returned by `filterNftParallelAssets`. */
export type ParallelAssetFilterConnection = {
  __typename?: 'ParallelAssetFilterConnection';
  /**   The number of Parallel assets returned. */
  count?: Maybe<Scalars['Int']>;
  /**   Where in the list the server started when returning items. */
  offset?: Maybe<Scalars['Int']>;
  /**   The list of Parallel assets matching the filter parameters. */
  results?: Maybe<Array<Maybe<ParallelAssetFilterResult>>>;
};

/**   A Parallel asset matching a set of filter parameters. */
export type ParallelAssetFilterResult = {
  __typename?: 'ParallelAssetFilterResult';
  /**   The contract address of the NFT collection. */
  address: Scalars['String'];
  /**   The description of the NFT asset. */
  description?: Maybe<Scalars['String']>;
  /**   The game data for the NFT asset. */
  gameData?: Maybe<ParallelAssetGameData>;
  /**   The ID of the NFT asset (`address`:`tokenId`). */
  id: Scalars['String'];
  /**   The last sale price in the network's base token. */
  lastPriceNetworkBaseToken?: Maybe<Scalars['String']>;
  /**   The last sale price in USD. */
  lastPriceUsd?: Maybe<Scalars['String']>;
  /**   The NFT asset media. */
  media?: Maybe<NftAssetMedia>;
  /**   Metadata for the NFT asset. */
  metadata?: Maybe<ParallelAssetMetadata>;
  /**   The name of the NFT asset. */
  name?: Maybe<Scalars['String']>;
  /**   The network ID the NFT collection is deployed on. */
  networkId: Scalars['Int'];
  /**   The source image URI linked by smart contract metadata. */
  originalImage?: Maybe<Scalars['String']>;
  /**   The unix timestamp for the last trade. */
  timestamp?: Maybe<Scalars['Int']>;
  /**   The token ID of the NFT asset. */
  tokenId: Scalars['String'];
  /**   The URI provided by the smart contract. Typically JSON that contains metadata. */
  uri?: Maybe<Scalars['String']>;
};

/**   Input type of `ParallelAssetFilters`. */
export type ParallelAssetFilters = {
  /**   The damage dealt when engaged in combat. */
  attack?: InputMaybe<NumberFilter>;
  /**   The energy used to play in-game. */
  cost?: InputMaybe<NumberFilter>;
  /**   The possible damage received before being destroyed. */
  health?: InputMaybe<NumberFilter>;
  /**   The last sale price in the network's base token. */
  lastPriceNetworkBaseToken?: InputMaybe<NumberFilter>;
  /**   The last sale price in USD. */
  lastPriceUsd?: InputMaybe<NumberFilter>;
  /**   The total supply of this individual asset. */
  supply?: InputMaybe<NumberFilter>;
};

/**   Game data for a Parallel asset. */
export type ParallelAssetGameData = {
  __typename?: 'ParallelAssetGameData';
  /**   The damage dealt when engaged in combat. */
  attack?: Maybe<Scalars['String']>;
  /**   The card type. Can be `Effect`, `Relic`, `Unit`, `Upgrade` or `Paragon`. */
  cardType?: Maybe<Scalars['String']>;
  /**   The energy used to play in-game. */
  cost?: Maybe<Scalars['String']>;
  /**   The description of the card's in-game abilities. */
  functionText?: Maybe<Scalars['String']>;
  /**   The possible damage received before being destroyed. */
  health?: Maybe<Scalars['String']>;
  /**   The Parallel the asset belongs to. */
  parallel?: Maybe<Scalars['String']>;
  /**   The description of the card's passive ability. */
  passiveAbility?: Maybe<Scalars['String']>;
  /**   The rarity of the asset. Can be `Common`, `Uncommon`, `Rare`, `Legendary`, or `Prime`. */
  rarity?: Maybe<Scalars['String']>;
  /**   The card subtype. Can be `Pirate`, `Vehicle` or `Clone`. */
  subtype?: Maybe<Scalars['String']>;
};

/**   The Parallel asset card type. */
export enum ParallelAssetMatcherCardType {
  Effect = 'Effect',
  Paragon = 'Paragon',
  Relic = 'Relic',
  Unit = 'Unit',
  Upgrade = 'Upgrade'
}

/**   The Parallel asset class. */
export enum ParallelAssetMatcherClass {
  ArtCard = 'ArtCard',
  Asset = 'Asset',
  CardBack = 'CardBack',
  Fe = 'FE',
  Masterpiece = 'Masterpiece',
  Pl = 'PL',
  Se = 'SE'
}

/**   The Parallel stream of evolution. */
export enum ParallelAssetMatcherParallel {
  Augencore = 'Augencore',
  Earthen = 'Earthen',
  Kathari = 'Kathari',
  Marcolian = 'Marcolian',
  Shroud = 'Shroud',
  Universal = 'Universal',
  UnknownOrigins = 'UnknownOrigins'
}

/**   The Parallel asset rarity. */
export enum ParallelAssetMatcherRarity {
  Common = 'Common',
  Legendary = 'Legendary',
  Prime = 'Prime',
  Rare = 'Rare',
  Uncommon = 'Uncommon'
}

/**   The Parallel asset subtype. */
export enum ParallelAssetMatcherSubtype {
  Clone = 'Clone',
  Pirate = 'Pirate',
  Vehicle = 'Vehicle'
}

/**   Input type of `ParallelAssetMatchers`. */
export type ParallelAssetMatchers = {
  /**   The card type. Can be `Effect`, `Relic`, `Unit`, `Upgrade` or `Paragon`. */
  cardType?: InputMaybe<Array<InputMaybe<ParallelAssetMatcherCardType>>>;
  /**   The card class. Can be `Art Card`, `Asset`, `Card Back`, `FE`, `Masterpiece`, `PL`, or `SE`. */
  class?: InputMaybe<Array<InputMaybe<ParallelAssetMatcherClass>>>;
  /**   The expansion used for naming base and expansion sets. */
  expansion?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /**   The Parallel the asset belongs to. */
  parallel?: InputMaybe<Array<InputMaybe<ParallelAssetMatcherParallel>>>;
  /**   The paraset the asset belongs to. */
  paraset?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /**   The list of rarities. Can be `Common`, `Uncommon`, `Rare`, `Legendary`, or `Prime`. */
  rarity?: InputMaybe<Array<InputMaybe<ParallelAssetMatcherRarity>>>;
  /**   The card subtype. Can be `Pirate`, `Vehicle` or `Clone`. */
  subtype?: InputMaybe<Array<InputMaybe<ParallelAssetMatcherSubtype>>>;
  tokenId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ParallelAssetMetadata = {
  __typename?: 'ParallelAssetMetadata';
  /**   The artist name. */
  artist?: Maybe<Scalars['String']>;
  /**   The card class. Can be `Art Card`, `Asset`, `Card Back`, `FE`, `Masterpiece`, `PL`, or `SE`. */
  class?: Maybe<Scalars['String']>;
  /**   The expansion used for naming base and expansion sets. */
  expansion?: Maybe<Scalars['String']>;
  /**   The asset description, sourced off-chain. Usually equal to the asset's on-chain `description`. */
  flavourText?: Maybe<Scalars['String']>;
  /**   The ID used to match other cards with the same name but different class. */
  parallelId?: Maybe<Scalars['String']>;
  /**   The paraset the asset belongs to. */
  paraset?: Maybe<Scalars['String']>;
  /**   The total supply of this individual asset. */
  supply?: Maybe<Scalars['String']>;
};

/**   Input type of `ParallelAssetRanking`. */
export type ParallelAssetRanking = {
  /**   The attribute to rank Parallel assets by. */
  attribute?: InputMaybe<ParallelAssetRankingAttribute>;
  /**   The direction to apply to the ranking attribute. */
  direction?: InputMaybe<RankingDirection>;
};

/**   Attribute used to rank Parallel assets. */
export enum ParallelAssetRankingAttribute {
  Attack = 'attack',
  Cost = 'cost',
  Health = 'health',
  LastPriceNetworkBaseToken = 'lastPriceNetworkBaseToken',
  LastPriceUsd = 'lastPriceUsd',
  Supply = 'supply'
}

/**   Response returned by `getParallelAssets`. */
export type ParallelAssetsConnection = {
  __typename?: 'ParallelAssetsConnection';
  /**   A cursor for use in pagination. */
  cursor?: Maybe<Scalars['String']>;
  /**   A list of Parallel assets. */
  items?: Maybe<Array<Maybe<ParallelAsset>>>;
};

/**   Tracked changes made to a Parallel card. */
export type ParallelCardChange = {
  __typename?: 'ParallelCardChange';
  /**   The Parallel card metadata before and after the card change. */
  diff: ParallelCardChangeDiff;
  /**   The unix timestamp for the card change. */
  timestamp: Scalars['Int'];
  /**   The token ID of the Parallel asset. */
  tokenId: Scalars['String'];
};

/**   Parallel card metadata before and after a card change. */
export type ParallelCardChangeDiff = {
  __typename?: 'ParallelCardChangeDiff';
  /**   Metadata for a Parallel card after the card change. */
  new: ParallelCardChangeFields;
  /**   Metadata for a Parallel card before the card change. */
  old: ParallelCardChangeFields;
};

/**   Metadata for a Parallel card. */
export type ParallelCardChangeFields = {
  __typename?: 'ParallelCardChangeFields';
  /**   The artist name. */
  artist?: Maybe<Scalars['String']>;
  /**   The damage dealt when engaged in combat. */
  attack?: Maybe<Scalars['String']>;
  /**   The card type. Can be `Effect`, `Relic`, `Unit`, `Upgrade` or `Paragon`. */
  cardType?: Maybe<Scalars['String']>;
  /**   The card class. Can be `Art Card`, `Asset`, `Card Back`, `FE`, `Masterpiece`, `PL`, or `SE`. */
  class?: Maybe<Scalars['String']>;
  /**   The energy used to play in-game. */
  cost?: Maybe<Scalars['String']>;
  /**   The expansion used for naming base and expansion sets. */
  expansion?: Maybe<Scalars['String']>;
  /**   The asset description, sourced off-chain. Usually equal to the asset's on-chain `description`. */
  flavourText?: Maybe<Scalars['String']>;
  /**   The description of the card's in-game abilities. */
  functionText?: Maybe<Scalars['String']>;
  /**   The possible damage received before being destroyed. */
  health?: Maybe<Scalars['String']>;
  /**   The Parallel the asset belongs to. */
  parallel?: Maybe<Scalars['String']>;
  /**   The ID used to match other cards with the same name but different class. */
  parallelId?: Maybe<Scalars['String']>;
  /**   The paraset the asset belongs to. */
  paraset?: Maybe<Scalars['String']>;
  /**   The description of the card's passive ability. */
  passiveAbility?: Maybe<Scalars['String']>;
  /**   The rarity of the asset. Can be `Common`, `Uncommon`, `Rare`, `Legendary`, or `Prime`. */
  rarity?: Maybe<Scalars['String']>;
  /**   The card subtype. Can be `Pirate`, `Vehicle` or `Clone`. */
  subtype?: Maybe<Scalars['String']>;
  /**   The total supply of this individual asset. */
  supply?: Maybe<Scalars['String']>;
};

/**   Input type of `ParallelCardChangeQueryTimestamp. */
export type ParallelCardChangeQueryTimestampInput = {
  /**   The unix timestamp for the start of the requested range. */
  from?: InputMaybe<Scalars['Int']>;
  /**   The unix timestamp for the end of the requested range. */
  to?: InputMaybe<Scalars['Int']>;
};

/**   Response returned by `getParallelCardChanges`. */
export type ParallelCardChangesConnection = {
  __typename?: 'ParallelCardChangesConnection';
  /**   A cursor for use in pagination. */
  cursor?: Maybe<Scalars['String']>;
  /**   A list of tracked changes made to a Parallel card. */
  items?: Maybe<Array<Maybe<ParallelCardChange>>>;
};

/**   The type of NFT in the pool. */
export enum PoolNftType {
  Erc721Erc20 = 'ERC721ERC20',
  Erc721Eth = 'ERC721ETH',
  Erc1155Erc20 = 'ERC1155ERC20',
  Erc1155Eth = 'ERC1155ETH'
}

/**   Current or historical prices for a token. */
export type Price = {
  __typename?: 'Price';
  /**   The contract address of the token. */
  address: Scalars['String'];
  /**   The network ID the token is deployed on. */
  networkId: Scalars['Int'];
  /**   The token price in USD. */
  priceUsd: Scalars['Float'];
  /**   The unix timestamp for the price. */
  timestamp: Scalars['Int'];
};

/**   Webhook conditions for a price event. */
export type PriceEventWebhookCondition = {
  __typename?: 'PriceEventWebhookCondition';
  /**   The network ID the webhook is listening on. */
  networkId: IntEqualsCondition;
  /**   The price condition that must be met in order for the webhook to send. */
  priceUsd: ComparisonOperator;
  /**   The token contract address the webhook is listening for. */
  tokenAddress: StringEqualsCondition;
};

/**   Input conditions for a price event webhook. */
export type PriceEventWebhookConditionInput = {
  /**   The network ID to listen on. */
  networkId: IntEqualsConditionInput;
  /**   The price conditions to listen for. */
  priceUsd: ComparisonOperatorInput;
  /**   The contract address of the token to listen for. */
  tokenAddress: StringEqualsConditionInput;
};

/**   An Echelon Prime Pool. */
export type PrimePool = {
  __typename?: 'PrimePool';
  /**   Values calculated by Defined using on-chain data. */
  calcData?: Maybe<PrimePoolCalcData>;
  /**   Values obtained directly from the chain. */
  chainData?: Maybe<PrimePoolChainData>;
  /**   When the pool was created by Defined. */
  createdAt?: Maybe<Scalars['Int']>;
  /**   The block number for when Defined discovered this pool. */
  discoveryBlockNumber?: Maybe<Scalars['Int']>;
  /**   The transaction hash of when Defined discovered this pool. */
  discoveryTransactionHash?: Maybe<Scalars['String']>;
  /**   The ID of the contract-level Prime Pool (poolContractAddress:networkId). For example, `0x89bb49d06610b4b18e355504551809be5177f3d0:1`. */
  id?: Maybe<Scalars['String']>;
  /**   The network ID the Prime Pool is deployed on. */
  networkId?: Maybe<Scalars['Int']>;
  /**   The contract address for the tokens cached ib the pool. */
  nftContractAddress?: Maybe<Scalars['String']>;
  /**   The contract address for the Prime Pool. */
  poolContractAddress?: Maybe<Scalars['String']>;
  /**   The ID of the pool within the contract. */
  poolId?: Maybe<Scalars['String']>;
  /**   The type of pool for this Prime Pool. */
  poolType?: Maybe<Scalars['String']>;
  /**   The Parallel tokenIds required to cache in the pool. */
  tokenIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**   The # of cached sets in the pool. */
  totalSupply?: Maybe<Scalars['String']>;
};

export type PrimePoolCalcData = {
  __typename?: 'PrimePoolCalcData';
  /**   The amount of accumulated ETH rewards in total for the pool. */
  poolAccumulatedEth?: Maybe<Scalars['String']>;
  /**   The amount of accumulated PRIME rewards in total for the pool. */
  poolAccumulatedPrime?: Maybe<Scalars['String']>;
  /**   The amount of ETH for the pool to pay out as caching rewards. */
  poolEthAmount?: Maybe<Scalars['String']>;
  /**   The amount of ETH paid out daily by the pool as caching rewards. */
  poolEthPerDay?: Maybe<Scalars['String']>;
  /**   The amount of ETH paid out per second by the pool as caching rewards. */
  poolEthPerSecond?: Maybe<Scalars['String']>;
  /**   The amount of PRIME for the pool to pay out as caching rewards. */
  poolPrimeAmount?: Maybe<Scalars['String']>;
  /**   The amount of PRIME paid out daily by the pool as caching rewards. */
  poolPrimePerDay?: Maybe<Scalars['String']>;
  /**   The amount of PRIME paid out per second by the pool as caching rewards. */
  poolPrimePerSecond?: Maybe<Scalars['String']>;
  /**   The amount of accumulated ETH rewards per share for the pool. */
  shareAccumulatedEth?: Maybe<Scalars['String']>;
  /**   The amount of accumulated PRIME rewards per share for the pool. */
  shareAccumulatedPrime?: Maybe<Scalars['String']>;
  /**   The amount of ETH paid out daily by the pool, per share of the pool's total cached supply. */
  shareEthPerDay?: Maybe<Scalars['String']>;
  /**   The amount of ETH paid out per second by the pool, per share of the pool's total cached supply. */
  shareEthPerSecond?: Maybe<Scalars['String']>;
  /**   The amount of PRIME paid out daily by the pool, per share of the pool's total cached supply. */
  sharePrimePerDay?: Maybe<Scalars['String']>;
  /**   The amount of PRIME paid out per second by the pool, per share of the pool's total cached supply. */
  sharePrimePerSecond?: Maybe<Scalars['String']>;
};

/**   Values obtained directly from the chain. */
export type PrimePoolChainData = {
  __typename?: 'PrimePoolChainData';
  /**   Whether caching is paused for this pool. */
  cachingPaused?: Maybe<Scalars['Boolean']>;
  /**   The pool's allocation of the contract's per-second ETH rewards. */
  ethAllocPoint?: Maybe<Scalars['String']>;
  /**   How much ETH has been claimed for this pool. */
  ethClaimed?: Maybe<Scalars['String']>;
  /**   Caching ETH rewards period end timestamp. */
  ethEndTimestamp?: Maybe<Scalars['Int']>;
  /**   Last timestamp at which ETH rewards were assigned. */
  ethLastRewardTimestamp?: Maybe<Scalars['Int']>;
  /**   How much ETH reward has been accrued for this pool. */
  ethReward?: Maybe<Scalars['String']>;
  /**   Caching ETH rewards period start timestamp. */
  ethStartTimestamp?: Maybe<Scalars['Int']>;
  /**   Minimum number of timed cache seconds per ETH. */
  ethTimedCachePeriod?: Maybe<Scalars['String']>;
  /**   Total share points of the contract's per-second ETH rewards to the pool. */
  ethTotalAllocPoint?: Maybe<Scalars['String']>;
  /**   The pool's allocation of the contract's per second PRIME rewards. */
  primeAllocPoint?: Maybe<Scalars['String']>;
  /**   Caching rewards period end timestamp. */
  primeEndTimestamp?: Maybe<Scalars['Int']>;
  /**   Last timestamp at which PRIME rewards were assigned. */
  primeLastRewardTimestamp?: Maybe<Scalars['Int']>;
  /**   Caching rewards period start timestamp. */
  primeStartTimestamp?: Maybe<Scalars['String']>;
  /**   Total share points of the contract's per second PRIME rewards to the pool. */
  primeTotalAllocPoint?: Maybe<Scalars['String']>;
};

/**   Response returned by `getPrimePools`. */
export type PrimePoolConnection = {
  __typename?: 'PrimePoolConnection';
  /**   A cursor for use in pagination. */
  cursor?: Maybe<Scalars['String']>;
  /**   A list of prime pools. */
  items?: Maybe<Array<Maybe<PrimePool>>>;
};

/**   Input Type of `PrimePoolQuery` */
export type PrimePoolInput = {
  /**   The address of the pool contract. */
  address: Scalars['String'];
  /**   The network that the pool is deployed on. */
  networkId: Scalars['Int'];
  /**   Optional list of pool ids to fetch. */
  poolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Query = {
  __typename?: 'Query';
  /**   Returns urls for different chart types */
  chartUrls?: Maybe<ChartUrlsResponse>;
  /**
   *   Returns a list of exchanges based on a variety of filters.
   *  filters: A set of filters to apply.
   *  phrase: A phrase to search for. Can match an exchange address or ID (`address`:`networkId`), or partially match an exchange name.
   *  rankings: A list of ranking attributes to apply.
   *  limit: The maximum number of exchanges to return.
   *  offset: Where in the list the server should start when returning items. Use `count`+`offset` from the previous query to request the next page of results.
   */
  filterExchanges?: Maybe<ExchangeFilterConnection>;
  /**
   *   Returns a list of NFT collection based on a variety of filters.
   *  filters: A set of filters to apply.
   *  phrase: A phrase to search for. Can match a collection contract address or ID (`address`:`networkId`).
   *  collections: A list of collection contract addresses or IDs (`address`:`networkId`) to filter by.
   *  rankings: A list of ranking attributes to apply.
   *  limit: The maximum number of NFT collections to return.
   *  offset: Where in the list the server should start when returning items. Use `count`+`offset` from the previous query to request the next page of results.
   */
  filterNftCollections?: Maybe<NftCollectionFilterConnection>;
  /**
   *   Returns a list of Parallel assets based on a variety of filters.
   *  filters: A set of filters to apply.
   *  phrase: A phrase to search for. Can match a Parallel asset ID (`address`:`tokenId`) or partially match a name.
   *  match: A set of parameters to match.
   *  rankings: A list of ranking attributes to apply.
   *  limit: The maximum number of Parallel assets to return.
   *  offset: Where in the list the server should start when returning items. Use `count`+`offset` from the previous query to request the next page of results.
   */
  filterNftParallelAssets?: Maybe<ParallelAssetFilterConnection>;
  /**
   *   Returns a list of NFT collections based on a variety of filters.
   *  filters: A set of filters to apply.
   *  phrase: A phrase to search for. Can partially match an NFT collection name.
   *  rankings: A list of ranking attributes to apply.
   *  limit: The maximum number of NFT collections to return.
   *  offset: Where in the list the server should start when returning items. Use `count`+`page` from the previous query to request the next page of results.
   */
  filterNftPoolCollections?: Maybe<NftPoolCollectionFilterConnection>;
  /**
   *   Returns a list of NFT pools based on a variety of filters.
   *  filters: A set of filters to apply.
   *  rankings: A list of ranking attributes to apply.
   *  limit: The maximum number of NFT pools to return.
   *  offset: Where in the list the server should start when returning items. Use `count`+`page` from the previous query to request the next page of results.
   */
  filterNftPools?: Maybe<NftPoolFilterConnection>;
  /**
   *   Returns a list of pairs based on a variety of filters.
   *  filters: A set of filters to apply.
   *  phrase: A phrase to search for. Can match a token or pair contract address or ID (`address`:`networkId`), or partially match a token name or symbol.
   *  pairs: A list of pair or token contract addresses or IDs (`address`:`networkId`) to filter by.
   *  matchTokens: A set of token contract addresses that make up a pair. Can be used in place of a pair contract address.
   *  rankings: A list of ranking attributes to apply.
   *  limit: The maximum number of pairs to return.
   *  offset: Where in the list the server should start when returning items. Use `count`+`offset` from the previous query to request the next page of results.
   */
  filterPairs?: Maybe<PairFilterConnection>;
  /**
   *   Returns a list of tokens based on a variety of filters.
   *  filters: A set of filters to apply.
   *  phrase: A phrase to search for. Can match a token contract address or partially match a token's name or symbol.
   *  tokens: A list of token IDs (`address`:`networkId`). Can be left blank to discover new tokens.
   *  rankings: A list of ranking attributes to apply.
   *  limit: The maximum number of tokens to return.
   *  offset: Where in the list the server should start when returning items. Use `count`+`page` from the previous query to request the next page of results.
   */
  filterTokens?: Maybe<TokenFilterConnection>;
  /**
   *   Returns bar chart data to track token price changes over time.
   *  symbol: The ID of the pair or token (`pairAddress`:`networkId` or `tokenAddress`:`networkId`). If a token contract address is provided, the token's top pair is used. For example, `0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2:1` returns WETH's top pair on Ethereum.
   *  from: The unix timestamp for the start of the requested range.
   *  to: The unix timestamp for the end of the requested range.
   *  resolution: The time frame for each candle. Available options are `1`, `5`, `15`, `30`, `60`, `240`, `720`, and `1D`.
   *  currencyCode: The currency to use for the response. Can be `USD` (default) or `TOKEN`.
   *  removeLeadingNullValues: Whether to remove leading null values from the response. Only valid with `1D` resolution. To fetch a token's entire history, set the `from` value to `0` and set `removeLeadingNullValues` to `true. Default is `false`.
   *  quoteToken: The token of interest within the token's top pair. Can be `token0` or `token1`.
   */
  getBars?: Maybe<BarsResponse>;
  /**   undocumented */
  getDecodedCalls?: Maybe<DecodedCallsConnection>;
  /**   undocumented */
  getDecodedLogs?: Maybe<DecodedLogsConnection>;
  /**   undocumented */
  getDecodings?: Maybe<DecodingsConnection>;
  /**
   *   Returns bucketed stats for a given NFT collection.
   *  collectionAddress: The contract address of the NFT collection.
   *  networkId: The network ID the NFT collection is deployed on.
   *  grouping: The marketplace address to filter by. Can be used to get marketplace-specific metrics, otherwise uses all.
   *  timestamp: The unix timestamp for the stats. Defaults to current.
   *  durations: The list of durations to get detailed pair stats for.
   *  bucketCount: The number of aggregated values to receive.<br> Note: Each duration has predetermined bucket sizes.<br> The first n-1 buckets are historical. The last bucket is a snapshot of current data.<br> duration `day1`: 6 buckets (4 hours each) plus 1 partial bucket<br> duration `hour12`: 12 buckets (1 hour each) plus 1 partial bucket<br> duration `hour4`: 8 buckets (30 min each) plus 1 partial bucket<br> duration `hour1`: 12 buckets (5 min each) plus 1 partial bucket<br> duration `min5`: 5 buckets (1 min each) plus 1 partial bucket<br> For example, requesting 11 buckets for a `min5` duration will return the last 10 minutes worth of data plus a snapshot for the current minute.
   */
  getDetailedNftStats?: Maybe<DetailedNftStats>;
  /**
   *   Returns bucketed stats for a given token within a pair.
   *  pairAddress: The contract address of the pair.
   *  networkId: The network ID the pair is deployed on.
   *  tokenOfInterest: The token of interest used to calculate token-specific stats for the pair. Can be `token0` or `token1`.
   *  timestamp: The unix timestamp for the stats. Defaults to current.
   *  durations: The list of durations to get detailed pair stats for.
   *  bucketCount: The number of aggregated values to receive. Note: Each duration has predetermined bucket sizes.<br>  The first n-1 buckets are historical. The last bucket is a snapshot of current data.<br> duration `day1`: 6 buckets (4 hours each) plus 1 partial bucket<br> duration `hour12`: 12 buckets (1 hour each) plus 1 partial bucket<br> duration `hour4`: 8 buckets (30 min each) plus 1 partial bucket<br> duration `hour1`: 12 buckets (5 min each) plus 1 partial bucket<br> duration `min5`: 5 buckets (1 min each) plus 1 partial bucket<br> For example, requesting 11 buckets for a `min5` duration will return the last 10 minutes worth of data plus a snapshot for the current minute.
   */
  getDetailedPairStats?: Maybe<DetailedPairStats>;
  /**   undocumented */
  getDetailedStats?: Maybe<DetailedStats>;
  /**
   *   Returns the status of a list of networks supported on Defined.
   *  networkIds: The list of network IDs.
   */
  getNetworkStatus?: Maybe<Array<MetadataResponse>>;
  /**   Returns a list of all networks supported on Defined. */
  getNetworks?: Maybe<Array<Network>>;
  /**
   *   Returns a list of NFT assets in a given collection.
   *  address: The contract address of the NFT collection.
   *  networkId: The network ID the NFT collection is deployed on.
   *  tokenIds: An optional list of token IDs to filter by.
   *  cursor: The cursor to use for pagination.
   *  limit: The maximum number of NFT assets to return.
   */
  getNftAssets?: Maybe<NftAssetsConnection>;
  /**
   *   Returns stats for an NFT collection across different time frames.
   *  collectionId: The ID of the NFT collection (`address`:`networkId`). For example, `0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d:1`.
   *  address: The contract address of the NFT collection.
   *  networkId: The network ID the NFT collection is deployed on.
   */
  getNftCollectionMetadata?: Maybe<NftCollectionMetadataResponse>;
  /**
   *   Returns transactions for an NFT collection across any marketplace(s).
   *  address: The contract address of the NFT collection.
   *  exchangeAddress: The NFT marketplace address to filter by.
   *  poolAddress: The NFT pool address to filter by.
   *  networkId: The network ID the NFT collection is deployed on.
   *  tokenId: The token ID to filter by.
   *  cursor: The cursor to use for pagination.
   *  timestamp: The time range to filter by.
   *  limit: The maximum number of NFT events to return.
   */
  getNftEvents?: Maybe<NftEventsConnection>;
  /**
   *   Returns an NFT pool.
   *  address: The contract address of the NFT pool.
   *  networkId: The network ID the NFT collection is deployed on.
   */
  getNftPool?: Maybe<NftPoolResponse>;
  /**
   *   Returns an NFT pool collection with pool stats for a given AMM NFT marketplace.
   *  collectionAddress: The contract address of the NFT collection.
   *  exchangeAddress: The NFT AMM marketplace address to filter by.
   *  networkId: The network ID the NFT collection is deployed on.
   */
  getNftPoolCollection?: Maybe<NftPoolCollectionResponse>;
  /**
   *   Returns an NFT collection with pool stats for a given AMM NFT marketplace.
   *  exchangeAddress: The NFT AMM marketplace address to filter by.
   *  networkId: The network ID the NFT collection is deployed on.
   *  cursor: The cursor to use for pagination.
   *  limit: The maximum number of NFT collections to return.
   */
  getNftPoolCollectionsByExchange?: Maybe<GetNftPoolCollectionsResponse>;
  /**
   *   Returns transactions for an NFT collection across all NFT pools or within a given pool.
   *  collectionAddress: The contract address of the NFT collection.
   *  networkId: The network ID the NFT collection is deployed on.
   *  poolAddress: The NFT pool address to filter by.
   *  eventTypes: The event types to filter by.
   *  cursor: The cursor to use for pagination.
   *  limit: The maximum number of NFT pool events to return.
   */
  getNftPoolEvents?: Maybe<NftPoolEventsResponse>;
  /**
   *   Returns aggregated NFT pool/collection stats for a given time frame.
   *  collectionAddress: The contract address of the NFT collection.
   *  exchangeAddress: The NFT AMM marketplace address to filter by.
   *  networkId: The network ID the NFT collection is deployed on.
   *  startTime: The unix timestamp for the start of the requested range.
   *  endTime: The unix timestamp for the end of the requested range.
   *  poolAddress: The NFT pool address to filter by.
   */
  getNftPoolStats?: Maybe<NftPoolStatsResponse>;
  /**
   *   Returns a list of NFT pools for a given collection and exchange.
   *  collectionAddress: The contract address of the NFT collection.
   *  exchangeAddress: The NFT AMM marketplace address to filter by.
   *  networkId: The network ID the NFT collection is deployed on.
   *  cursor: The cursor to use for pagination.
   *  limit: The maximum number of NFT pools to return.
   */
  getNftPoolsByCollectionAndExchange?: Maybe<GetNftPoolsResponse>;
  /**
   *   Returns a list of NFT pools for a given owner.
   *  ownerAddress: The contract address of the pool owner.
   *  networkId: The network ID the NFT collection is deployed on.
   *  exchangeAddress: The NFT AMM marketplace address to filter by.
   *  cursor: The cursor to use for pagination.
   *  limit: The maximum number of NFT pools to return.
   */
  getNftPoolsByOwner?: Maybe<GetNftPoolsResponse>;
  /**
   *   Returns a list of Parallel assets.
   *  tokenIds; An optional list of token IDs to filter by.
   *  cursor: The cursor to use for pagination.
   *  limit: The maximum number of Parallel assets to return.
   * @deprecated Use filterNftParallelAssets instead because it is more full-featured.
   */
  getParallelAssets?: Maybe<ParallelAssetsConnection>;
  /**
   *   Returns changes made to Parallel card metadata over time.
   *  tokenId: The token ID for a Parallel asset.
   *  timestamp: The time frame to request card changes.
   *  cursor: The cursor to use for pagination.
   *  limit: The maximum number of Parallel card changes to return.
   */
  getParallelCardChanges?: Maybe<ParallelCardChangesConnection>;
  /**
   *   Returns real-time or historical prices for a token.
   *  address: The contract address of the token.
   *  networkId: The network ID the token is deployed on.
   *  timestamp: The unix timestamp for the price.
   *  maxDeviations: The maximum number of deviations from the token's Liquidity-Weighted Mean Price. This is used to mitigate low liquidity pairs producing prices that are not representative of reality. Default is `1`.
   * @deprecated Use getTokenPrices instead because it has built in stability using standard deviations.
   */
  getPrice?: Maybe<Price>;
  /**
   *   Returns real-time or historical prices for a list of tokens, fetched in batches.
   *  inputs: A list of `GetPriceInput`s. Accepts a maximum of 25 inputs (anything over will be truncated).
   * @deprecated Use getTokenPrices instead because it has built in stability using standard deviations.
   */
  getPrices?: Maybe<Array<Maybe<Price>>>;
  /**
   *   Returns a list of Prime pools.
   *  address: The contract address for the prime pool.
   *  networkId: The network ID the prime pool is deployed on.
   *  poolIds: An optional list of pool IDs to filter by.
   */
  getPrimePools?: Maybe<PrimePoolConnection>;
  /**
   *   Returns charting metadata for a given pair.
   *  symbol: The ID of the pair (`address`:`networkId`).
   *  currencyCode: The currency to use for the response. Can be `USD` (default) or `TOKEN`.
   */
  getSymbol?: Maybe<SymbolResponse>;
  /**
   *   Returns transactions for a pair.
   *  limit: The maximum number of transactions to return.
   *  query: The query filters to apply to the results.
   *  cursor: The cursor to use for pagination.
   *  direction: The order to receive the token events. Can be `DESC` (default) or `ASC`.
   */
  getTokenEvents?: Maybe<EventConnection>;
  /**
   *   Returns metadata for a given token.
   *  address: The contract address of the token.
   *  networkId: The network ID the token is deployed on.
   */
  getTokenInfo?: Maybe<TokenInfo>;
  /**
   *   Returns real-time or historical prices for a list of tokens, fetched in batches.
   *  inputs: A list of `GetPriceInput`s. Accepts a maximum of 25 inputs (anything over will be truncated).
   */
  getTokenPrices?: Maybe<Array<Maybe<Price>>>;
  /**
   *   Returns a user's list of webhooks
   *  cursor: The cursor to use for pagination.
   *  limit: The maximum number of webhooks to return.
   */
  getWebhooks?: Maybe<GetWebhooksResponse>;
  /**
   *   Returns a list of trending tokens across any given network(s).
   *  limit: The maximum number of tokens to return. Max is `50`.
   *  networkFilter: The list of network IDs to filter by.
   *  resolution: The time frame for trending results. Can be `1`, `5`, `15`, `30`, `60`, `240`, `720`, or `1D`.
   */
  listTopTokens?: Maybe<Array<TokenWithMetadata>>;
  /**
   *   Returns a list of NFT collections matching a given query string.
   *  limit: The maximum number of NFTs to return.
   *  networkFilter: The list of network IDs to filter by.
   *  window: The time frame to use for calculating stats. Can be `1h`, `4h`, `12h`, or `1d` (default).
   *  search: The query string to search for. Can match an NFT collection contract address or partially match a collection's name or symbol.
   */
  searchNfts?: Maybe<NftSearchResponse>;
  /**
   *   Returns a list of tokens matching a given query string.
   *  limit: The maximum number of tokens to return.
   *  lowVolumeFilter: Whether to filter out low volume results from the response. Low volume is less than $500 USD. This setting has no effect when only low volume results are found. Default is `true`.
   *  networkFilter: The list of network IDs to filter by.
   *  resolution: The time frame to use for token metadata. Can be `60`, `240`, `720`, or `1D`.
   *  search: The query string to search for. Can match a token or pair contract address or partially match a token's name or symbol. Automatically filters out tokens marked as scams.
   */
  searchTokens?: Maybe<TokenSearchResponse>;
};


export type QueryChartUrlsArgs = {
  input: ChartInput;
};


export type QueryFilterExchangesArgs = {
  filters?: InputMaybe<ExchangeFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  phrase?: InputMaybe<Scalars['String']>;
  rankings?: InputMaybe<Array<InputMaybe<ExchangeRanking>>>;
};


export type QueryFilterNftCollectionsArgs = {
  collections?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filters?: InputMaybe<NftCollectionFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  phrase?: InputMaybe<Scalars['String']>;
  rankings?: InputMaybe<Array<InputMaybe<NftCollectionRanking>>>;
};


export type QueryFilterNftParallelAssetsArgs = {
  filters?: InputMaybe<ParallelAssetFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  match?: InputMaybe<ParallelAssetMatchers>;
  offset?: InputMaybe<Scalars['Int']>;
  phrase?: InputMaybe<Scalars['String']>;
  rankings?: InputMaybe<Array<InputMaybe<ParallelAssetRanking>>>;
};


export type QueryFilterNftPoolCollectionsArgs = {
  filters?: InputMaybe<NftPoolCollectionFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  phrase?: InputMaybe<Scalars['String']>;
  rankings?: InputMaybe<Array<InputMaybe<NftPoolCollectionRanking>>>;
};


export type QueryFilterNftPoolsArgs = {
  filters?: InputMaybe<NftPoolFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  rankings?: InputMaybe<Array<InputMaybe<NftPoolRanking>>>;
};


export type QueryFilterPairsArgs = {
  filters?: InputMaybe<PairFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  matchTokens?: InputMaybe<PairFilterMatchTokens>;
  offset?: InputMaybe<Scalars['Int']>;
  pairs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  phrase?: InputMaybe<Scalars['String']>;
  rankings?: InputMaybe<Array<InputMaybe<PairRanking>>>;
};


export type QueryFilterTokensArgs = {
  filters?: InputMaybe<TokenFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  phrase?: InputMaybe<Scalars['String']>;
  rankings?: InputMaybe<Array<InputMaybe<TokenRanking>>>;
  tokens?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryGetBarsArgs = {
  currencyCode?: InputMaybe<Scalars['String']>;
  from: Scalars['Int'];
  quoteToken?: InputMaybe<QuoteToken>;
  removeLeadingNullValues?: InputMaybe<Scalars['Boolean']>;
  resolution: Scalars['String'];
  symbol: Scalars['String'];
  to: Scalars['Int'];
};


export type QueryGetDecodedCallsArgs = {
  input?: InputMaybe<GetDecodedCallsInput>;
};


export type QueryGetDecodedLogsArgs = {
  input?: InputMaybe<GetDecodedLogsInput>;
};


export type QueryGetDecodingsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  networkId?: InputMaybe<Scalars['Int']>;
};


export type QueryGetDetailedNftStatsArgs = {
  bucketCount?: InputMaybe<Scalars['Int']>;
  collectionAddress: Scalars['String'];
  durations?: InputMaybe<Array<InputMaybe<DetailedNftStatsDuration>>>;
  grouping?: InputMaybe<Scalars['String']>;
  networkId: Scalars['Int'];
  timestamp?: InputMaybe<Scalars['Int']>;
};


export type QueryGetDetailedPairStatsArgs = {
  bucketCount?: InputMaybe<Scalars['Int']>;
  durations?: InputMaybe<Array<InputMaybe<DetailedPairStatsDuration>>>;
  networkId: Scalars['Int'];
  pairAddress: Scalars['String'];
  timestamp?: InputMaybe<Scalars['Int']>;
  tokenOfInterest?: InputMaybe<TokenOfInterest>;
};


export type QueryGetDetailedStatsArgs = {
  bucketCount?: InputMaybe<Scalars['Int']>;
  pairId: Scalars['String'];
  timestamp?: InputMaybe<Scalars['Int']>;
  tokenOfInterest?: InputMaybe<TokenOfInterest>;
  windowSizes?: InputMaybe<Array<InputMaybe<DetailedStatsWindowSize>>>;
};


export type QueryGetNetworkStatusArgs = {
  networkIds: Array<Scalars['Int']>;
};


export type QueryGetNftAssetsArgs = {
  address: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  networkId: Scalars['Int'];
  tokenIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryGetNftCollectionMetadataArgs = {
  address?: InputMaybe<Scalars['String']>;
  collectionId?: InputMaybe<Scalars['String']>;
  networkId?: InputMaybe<Scalars['Int']>;
};


export type QueryGetNftEventsArgs = {
  address?: InputMaybe<Scalars['String']>;
  cursor?: InputMaybe<Scalars['String']>;
  exchangeAddress?: InputMaybe<Scalars['String']>;
  includeTransfers?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  networkId: Scalars['Int'];
  poolAddress?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<EventQueryTimestampInput>;
  tokenId?: InputMaybe<Scalars['String']>;
};


export type QueryGetNftPoolArgs = {
  address: Scalars['String'];
  networkId: Scalars['Int'];
};


export type QueryGetNftPoolCollectionArgs = {
  collectionAddress: Scalars['String'];
  exchangeAddress: Scalars['String'];
  networkId: Scalars['Int'];
};


export type QueryGetNftPoolCollectionsByExchangeArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  exchangeAddress: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  networkId: Scalars['Int'];
};


export type QueryGetNftPoolEventsArgs = {
  collectionAddress: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
  eventTypes?: InputMaybe<Array<NftPoolEventType>>;
  limit?: InputMaybe<Scalars['Int']>;
  networkId: Scalars['Int'];
  poolAddress?: InputMaybe<Scalars['String']>;
};


export type QueryGetNftPoolStatsArgs = {
  collectionAddress: Scalars['String'];
  endTime: Scalars['Int'];
  exchangeAddress: Scalars['String'];
  networkId: Scalars['Int'];
  poolAddress?: InputMaybe<Scalars['String']>;
  startTime: Scalars['Int'];
};


export type QueryGetNftPoolsByCollectionAndExchangeArgs = {
  collectionAddress: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
  exchangeAddress: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  networkId: Scalars['Int'];
};


export type QueryGetNftPoolsByOwnerArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  exchangeAddress?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  networkId: Scalars['Int'];
  ownerAddress: Scalars['String'];
};


export type QueryGetParallelAssetsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  tokenIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryGetParallelCardChangesArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  timestamp?: InputMaybe<ParallelCardChangeQueryTimestampInput>;
  tokenId?: InputMaybe<Scalars['String']>;
};


export type QueryGetPriceArgs = {
  address: Scalars['String'];
  maxDeviations?: InputMaybe<Scalars['Float']>;
  networkId: Scalars['Int'];
  timestamp?: InputMaybe<Scalars['Int']>;
};


export type QueryGetPricesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<GetPriceInput>>>;
};


export type QueryGetPrimePoolsArgs = {
  address: Scalars['String'];
  networkId: Scalars['Int'];
  poolIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryGetSymbolArgs = {
  currencyCode?: InputMaybe<Scalars['String']>;
  symbol: Scalars['String'];
};


export type QueryGetTokenEventsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<RankingDirection>;
  limit?: InputMaybe<Scalars['Int']>;
  query: EventsQueryInput;
};


export type QueryGetTokenInfoArgs = {
  address: Scalars['String'];
  networkId: Scalars['Int'];
};


export type QueryGetTokenPricesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<GetPriceInput>>>;
};


export type QueryGetWebhooksArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryListTopTokensArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  networkFilter?: InputMaybe<Array<Scalars['Int']>>;
  resolution?: InputMaybe<Scalars['String']>;
};


export type QuerySearchNftsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  networkFilter?: InputMaybe<Array<Scalars['Int']>>;
  search?: InputMaybe<Scalars['String']>;
  window?: InputMaybe<Scalars['String']>;
};


export type QuerySearchTokensArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  lowVolumeFilter?: InputMaybe<Scalars['Boolean']>;
  networkFilter?: InputMaybe<Array<Scalars['Int']>>;
  resolution?: InputMaybe<Scalars['String']>;
  search: Scalars['String'];
};

/**   The token of interest within a pair. */
export enum QuoteToken {
  Token0 = 'token0',
  Token1 = 'token1'
}

/**   The order of ranking. */
export enum RankingDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/**   Raw call trace type. */
export enum RawCallTraceType {
  Create = 'create'
}

/**   Input condition for a raw call trace type. */
export type RawCallTraceTypeConditionInput = {
  /**   The raw call trace type to equal. */
  eq: RawCallTraceType;
};

/**   Webhook condition for trace type. */
export type RawCallTraceTypeCondtion = {
  __typename?: 'RawCallTraceTypeCondtion';
  /**   The raw call trace type. */
  eq: RawCallTraceType;
};

/**   Webhook conditions for a raw call trace event. */
export type RawCallTraceWebhookCondition = {
  __typename?: 'RawCallTraceWebhookCondition';
  /**   A list of network IDs the webhook is listening on. */
  networkId?: Maybe<OneOfNumberCondition>;
  /**   The result conitions the webhook is listening for. */
  result?: Maybe<RawCallTraceWebhookConditionResult>;
  /**   The type of trace the webhook is listening for. */
  traceType: RawCallTraceTypeCondtion;
};

/**   Input conditions for a raw call trace webhook. */
export type RawCallTraceWebhookConditionInput = {
  /**   A list of network IDs to listen on. */
  networkId?: InputMaybe<OneOfNumberConditionInput>;
  /**   The result to listen for. */
  result?: InputMaybe<RawCallTraceWebhookConditionResultInput>;
  /**   The type of trace to listen for. */
  traceType: RawCallTraceTypeConditionInput;
};

/**   The result for a raw call trace webhook to listen for. */
export type RawCallTraceWebhookConditionResult = {
  __typename?: 'RawCallTraceWebhookConditionResult';
  /**   String conditions within the raw call trace code. */
  code: StringContainsCondition;
};

/**   The result for a raw call trace webhook to listen for. */
export type RawCallTraceWebhookConditionResultInput = {
  /**   String conditions to listen for within the raw call trace code. */
  code?: InputMaybe<StringContainsConditionInput>;
};

/**   Price data for each supported resolution. */
export type ResolutionBarData = {
  __typename?: 'ResolutionBarData';
  r1?: Maybe<CurrencyBarData>;
  r1D?: Maybe<CurrencyBarData>;
  r1S?: Maybe<CurrencyBarData>;
  r5?: Maybe<CurrencyBarData>;
  r5S?: Maybe<CurrencyBarData>;
  r7D?: Maybe<CurrencyBarData>;
  r15?: Maybe<CurrencyBarData>;
  r15S?: Maybe<CurrencyBarData>;
  r30?: Maybe<CurrencyBarData>;
  r60?: Maybe<CurrencyBarData>;
  r240?: Maybe<CurrencyBarData>;
  r720?: Maybe<CurrencyBarData>;
};

/**   undocumented */
export type ResolutionBarInput = {
  r1?: InputMaybe<CurrencyBarInput>;
  r1D?: InputMaybe<CurrencyBarInput>;
  r1S?: InputMaybe<CurrencyBarInput>;
  r5?: InputMaybe<CurrencyBarInput>;
  r5S?: InputMaybe<CurrencyBarInput>;
  r7D?: InputMaybe<CurrencyBarInput>;
  r15?: InputMaybe<CurrencyBarInput>;
  r15S?: InputMaybe<CurrencyBarInput>;
  r30?: InputMaybe<CurrencyBarInput>;
  r60?: InputMaybe<CurrencyBarInput>;
  r240?: InputMaybe<CurrencyBarInput>;
  r720?: InputMaybe<CurrencyBarInput>;
};

/**   Config for retrying failed webhook messages */
export type RetrySettings = {
  __typename?: 'RetrySettings';
  /**   The maximum number of times the webhook will retry sending a message */
  maxRetries?: Maybe<Scalars['Int']>;
  /**   The maximum time in seconds that the webhook will wait before retrying a failed message */
  maxRetryDelay?: Maybe<Scalars['Int']>;
  /**   The maximum time in seconds that the webhook will retry sending a message */
  maxTimeElapsed?: Maybe<Scalars['Int']>;
  /**   The minimum time in seconds that the webhook will wait before retrying a failed message */
  minRetryDelay?: Maybe<Scalars['Int']>;
};

/**   Config input for retrying failed webhook messages */
export type RetrySettingsInput = {
  /**   The maximum number of times the webhook will retry sending a message */
  maxRetries?: InputMaybe<Scalars['Int']>;
  /**   The maximum time in seconds that the webhook will wait before retrying a failed message */
  maxRetryDelay?: InputMaybe<Scalars['Int']>;
  /**   The maximum time in seconds that the webhook will retry sending a message */
  maxTimeElapsed?: InputMaybe<Scalars['Int']>;
  /**   The minimum time in seconds that the webhook will wait before retrying a failed message */
  minRetryDelay?: InputMaybe<Scalars['Int']>;
};

export type SocialLinks = {
  __typename?: 'SocialLinks';
  bitcointalk?: Maybe<Scalars['String']>;
  blog?: Maybe<Scalars['String']>;
  coingecko?: Maybe<Scalars['String']>;
  coinmarketcap?: Maybe<Scalars['String']>;
  discord?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  reddit?: Maybe<Scalars['String']>;
  slack?: Maybe<Scalars['String']>;
  telegram?: Maybe<Scalars['String']>;
  twitch?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  wechat?: Maybe<Scalars['String']>;
  whitepaper?: Maybe<Scalars['String']>;
  youtube?: Maybe<Scalars['String']>;
};

/**   undocumented */
export type SortkeyInput = {
  blockNumber: Scalars['Int'];
  logIndex?: InputMaybe<Scalars['Int']>;
  transactionIndex?: InputMaybe<Scalars['Int']>;
};

/**   Filter for NFT stats. */
export type StatsFilter = {
  /**   The percent change between the `current` and `previous`. */
  change?: InputMaybe<NumberFilter>;
  /**   The total value for the current window. */
  current?: InputMaybe<NumberFilter>;
  /**   The total value for the previous window. */
  previous?: InputMaybe<NumberFilter>;
};

/**   String contains condition. */
export type StringContainsCondition = {
  __typename?: 'StringContainsCondition';
  /**   A list of substrings included within the string. */
  contains?: Maybe<Array<Scalars['String']>>;
  /**   A list of substrings not included within the string. */
  notContains?: Maybe<Array<Scalars['String']>>;
};

/**   Input for string contains condition. */
export type StringContainsConditionInput = {
  /**   A list of substrings to be included within the string. */
  contains?: InputMaybe<Array<Scalars['String']>>;
  /**   A list of substrings not to be included within the string. */
  notContains?: InputMaybe<Array<Scalars['String']>>;
};

/**   String equals condition. */
export type StringEqualsCondition = {
  __typename?: 'StringEqualsCondition';
  /**   The string to equal. */
  eq: Scalars['String'];
};

/**   Input for string equals condition. */
export type StringEqualsConditionInput = {
  /**   The string to equal. */
  eq: Scalars['String'];
};

/**   Input type of `StringFilter`. */
export type StringFilter = {
  /**   Greater than. */
  gt?: InputMaybe<Scalars['String']>;
  /**   Greater than or equal to. */
  gte?: InputMaybe<Scalars['String']>;
  /**   Less than. */
  lt?: InputMaybe<Scalars['String']>;
  /**   Less than or equal to. */
  lte?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  /**
   *   Live-streamed bar chart data to track price changes over time.
   *  pairId: The ID of the pair (`address`:`networkId`).
   *  quoteToken: The token of interest within the pair. Can be `token0` or `token1`.
   */
  OnBarsUpdated?: Maybe<UpdateAggregateBatchResponse>;
  /**
   *   Live-streamed transactions for a token.
   *  address: The pair contract address.
   *  id: The ID of the pair (`address`:`networkId`).
   *  quoteToken: The token of interest within the pair. Can be `token0` or `token1`.
   */
  OnEventsCreated?: Maybe<AddEventsOutput>;
  /**
   *   Live-streamed transactions for a token.
   *  address: The pair contract address.
   *  id: The ID of the pair (`address`:`networkId`).
   */
  onCreateEvents?: Maybe<AddEventsOutput>;
  /**
   *   Live-streamed price updates for an NFT collection.
   *  address: The contract address for the NFT collection.
   *  networkId: The network ID the NFT collection is deployed on.
   */
  onCreateNftEvents?: Maybe<AddNftEventsOutput>;
  /**
   *   Live-streamed transactions for an NFT pool.
   *  poolAddress: The contract address for the NFT pool.
   *  networkId: The network ID the NFT collection is deployed on.
   *  collectionAddress: The contract address for the NFT collection.
   *  exchangeAddress: The contract address for the NFT AMM marketplace.
   */
  onCreateNftPoolEvents?: Maybe<AddNftPoolEventsOutput>;
  /**
   *   Live-streamed bar chart data to track price changes over time.
   *  pairId: The ID of the pair (`address`:`networkId`).
   *  quoteToken: The token of interest within the pair. Can be `token0` or `token1`.
   */
  onUpdateAggregateBatch?: Maybe<UpdateAggregateBatchResponse>;
  /**
   *   Live-streamed price updates for a token.
   *  address: The token contract address.
   *  networkId: The network ID the token is deployed on.
   */
  onUpdatePrice?: Maybe<Price>;
};


export type SubscriptionOnBarsUpdatedArgs = {
  pairId?: InputMaybe<Scalars['String']>;
  quoteToken?: InputMaybe<QuoteToken>;
};


export type SubscriptionOnEventsCreatedArgs = {
  address?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  quoteToken?: InputMaybe<QuoteToken>;
};


export type SubscriptionOnCreateEventsArgs = {
  address?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnCreateNftEventsArgs = {
  address?: InputMaybe<Scalars['String']>;
  networkId?: InputMaybe<Scalars['Int']>;
};


export type SubscriptionOnCreateNftPoolEventsArgs = {
  collectionAddress?: InputMaybe<Scalars['String']>;
  exchangeAddress?: InputMaybe<Scalars['String']>;
  networkId?: InputMaybe<Scalars['Int']>;
  poolAddress?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpdateAggregateBatchArgs = {
  pairId?: InputMaybe<Scalars['String']>;
  quoteToken?: InputMaybe<QuoteToken>;
};


export type SubscriptionOnUpdatePriceArgs = {
  address?: InputMaybe<Scalars['String']>;
  networkId?: InputMaybe<Scalars['Int']>;
};

/**   Event data for a token swap event. */
export type SwapEventData = {
  __typename?: 'SwapEventData';
  /**   The amount of `token0` involved in the swap. Only applicable for UniswapV3 events. */
  amount0?: Maybe<Scalars['String']>;
  /**   The amount of `token0` that was sold. Only applicable for UniswapV2 events. */
  amount0In?: Maybe<Scalars['String']>;
  /**   The amount of `token0` that was bought. Only applicable for UniswapV2 events. */
  amount0Out?: Maybe<Scalars['String']>;
  /**   The amount of `token1` involved in the swap. Only applicable for UniswapV3 events. */
  amount1?: Maybe<Scalars['String']>;
  /**   The amount of `token1` that was sold. Only applicable for UniswapV2 events. */
  amount1In?: Maybe<Scalars['String']>;
  /**   The amount of `token1` that was bought. Only applicable for UniswapV2 events. */
  amount1Out?: Maybe<Scalars['String']>;
  /**   The amount of `quoteToken` involved in the swap. For example, if `quoteToken` is USDC for a USDC/WETH pair, `amountNonLiquidityToken` would be the amount of USDC involved in the swap. */
  amountNonLiquidityToken?: Maybe<Scalars['String']>;
  /**   The price per `quoteToken` at the time of the swap in the network's base token. For example, if `quoteToken` is USDC for a USDC/WETH pair on ETH network, `priceBaseToken` would the price of USDC in ETH. */
  priceBaseToken?: Maybe<Scalars['String']>;
  /**   The total amount of `quoteToken` involved in the swap in the network's base token (`amountNonLiquidityToken` x `priceBaseToken`). */
  priceBaseTokenTotal?: Maybe<Scalars['String']>;
  /**   The price per `quoteToken` at the time of the swap in USD. For example, if `quoteToken` is USDC for a USDC/WETH pair on ETH network, `priceBaseToken` would the price of USDC in USD ($1.00). */
  priceUsd?: Maybe<Scalars['String']>;
  /**   The total amount of `quoteToken` involved in the swap in USD (`amountNonLiquidityToken` x `priceUsd`). */
  priceUsdTotal?: Maybe<Scalars['String']>;
  /**   The tick index that the swap occurred in. Only applicable for UniswapV3 events. */
  tick?: Maybe<Scalars['String']>;
  /**   The type of token event, `Swap`. */
  type: EventType;
};

/**   undocumented */
export type SwapEventDataInput = {
  amount0?: InputMaybe<Scalars['String']>;
  amount0In?: InputMaybe<Scalars['String']>;
  amount0Out?: InputMaybe<Scalars['String']>;
  amount1?: InputMaybe<Scalars['String']>;
  amount1In?: InputMaybe<Scalars['String']>;
  amount1Out?: InputMaybe<Scalars['String']>;
  amountNonLiquidityToken?: InputMaybe<Scalars['String']>;
  priceBaseToken?: InputMaybe<Scalars['String']>;
  priceBaseTokenTotal?: InputMaybe<Scalars['String']>;
  priceUsd?: InputMaybe<Scalars['String']>;
  priceUsdTotal?: InputMaybe<Scalars['String']>;
  tick?: InputMaybe<Scalars['String']>;
  type: EventType;
};

/**   undocumented */
export type SwapEventInput = {
  address: Scalars['String'];
  baseTokenPrice?: InputMaybe<Scalars['String']>;
  blockHash: Scalars['String'];
  blockNumber: Scalars['Int'];
  data?: InputMaybe<SwapEventDataInput>;
  eventDisplayType?: InputMaybe<EventDisplayType>;
  id: Scalars['String'];
  liquidityToken?: InputMaybe<Scalars['String']>;
  logIndex: Scalars['Int'];
  maker?: InputMaybe<Scalars['String']>;
  networkId: Scalars['Int'];
  quoteToken?: InputMaybe<QuoteToken>;
  timestamp: Scalars['Int'];
  token0PoolValueUsd?: InputMaybe<Scalars['String']>;
  token0SwapValueUsd?: InputMaybe<Scalars['String']>;
  token0ValueBase?: InputMaybe<Scalars['String']>;
  token1PoolValueUsd?: InputMaybe<Scalars['String']>;
  token1SwapValueUsd?: InputMaybe<Scalars['String']>;
  token1ValueBase?: InputMaybe<Scalars['String']>;
  transactionHash: Scalars['String'];
  transactionIndex: Scalars['Int'];
};

/**   Event data for swapping an NFT into a pool. */
export type SwapNftInPoolEventData = {
  __typename?: 'SwapNftInPoolEventData';
  /**   The total value of all NFTs involved in the swap in the pool's liquidity token. */
  amountT: Scalars['String'];
  /**   The ratio of the transaction token to the network's base token. */
  nbtRatio: Scalars['String'];
  /**   The updated price at which the pool is willing to buy an NFT in the pool's liquidity token. */
  newBuyPriceT: Scalars['String'];
  /**   The updated delta used in the bonding curve. */
  newDelta: Scalars['String'];
  /**   The updated price at which the pool is willing to sell an NFT in the pool's liquidity token. */
  newSellPriceT: Scalars['String'];
  /**   The updated spot price in the pool's liquidity token. */
  newSpotPriceT: Scalars['String'];
  /**   The number of NFTs in the contract after the block has processed. */
  nftTokenBalance: Scalars['String'];
  /**   Metadata for each of the NFTs involved in the swap. */
  nftsTransfered?: Maybe<Array<Maybe<NftPoolEventNftTransfer>>>;
  /**   The fee for the pool in the pool's liquidity token. */
  poolFeeT: Scalars['String'];
  /**   The protocol fee in the pool's liquidity token. */
  protocolFeeT: Scalars['String'];
  /**   The amount of token in the contract after the block has processed in the pool's liquidity token. */
  tokenBalanceT: Scalars['String'];
  /**   The ID of the token involved in the swap (`address`:`networkId`). */
  tokenId: Scalars['String'];
  /**   The type of NFT pool event, `SWAP_NFT_IN_POOL`. */
  type: NftPoolEventType;
  /**   The ratio of the transaction token to USD. */
  usdRatio: Scalars['String'];
};

/**   undocumented */
export type SwapNftInPoolEventDataInput = {
  amountT: Scalars['String'];
  nbtRatio: Scalars['String'];
  newBuyPriceT: Scalars['String'];
  newDelta: Scalars['String'];
  newSellPriceT: Scalars['String'];
  newSpotPriceT: Scalars['String'];
  nftTokenBalance: Scalars['String'];
  nftsTransfered?: InputMaybe<Array<InputMaybe<NftPoolEventNftTransferInput>>>;
  poolFeeT: Scalars['String'];
  protocolFeeT: Scalars['String'];
  tokenBalanceT: Scalars['String'];
  tokenId: Scalars['String'];
  type: NftPoolEventType;
  usdRatio: Scalars['String'];
};

export type SwapNftInPoolEventDataV2 = {
  __typename?: 'SwapNftInPoolEventDataV2';
  /**   The total value of all NFTs involved in the swap in the pool's liquidity token. */
  amountT: Scalars['String'];
  /**   The ratio of the transaction token to the network's base token. */
  nbtRatio: Scalars['String'];
  /**   The updated price at which the pool is willing to buy an NFT in the pool's liquidity token. */
  newBuyPriceT: Scalars['String'];
  /**   The updated delta used in the bonding curve. */
  newDelta: Scalars['String'];
  /**   The updated price at which the pool is willing to sell an NFT in the pool's liquidity token. */
  newSellPriceT: Scalars['String'];
  /**   The updated spot price in the pool's liquidity token. */
  newSpotPriceT: Scalars['String'];
  /**   Metadata for each of the NFTs involved in the swap. */
  nftsTransfered?: Maybe<Array<Maybe<NftPoolEventNftTransferV2>>>;
  /**   The fee for the pool in the pool's liquidity token. */
  poolFeeT: Scalars['String'];
  /**   The protocol fee in the pool's liquidity token. */
  protocolFeeT: Scalars['String'];
  /**   The amount of token in the contract after the block has processed in the pool's liquidity token. */
  tokenBalanceT: Scalars['String'];
  /**   The ID of the token involved in the swap (`address`:`networkId`). */
  tokenId: Scalars['String'];
  /**   The type of NFT pool event, `SWAP_NFT_IN_POOL`. */
  type: NftPoolEventType;
  /**   The ratio of the transaction token to USD. */
  usdRatio: Scalars['String'];
};

/**   undocumented */
export type SwapNftInPoolEventDataV2Input = {
  amountT: Scalars['String'];
  nbtRatio: Scalars['String'];
  newBuyPriceT: Scalars['String'];
  newDelta: Scalars['String'];
  newSellPriceT: Scalars['String'];
  newSpotPriceT: Scalars['String'];
  nftsTransfered: Array<NftPoolEventNftTransferV2Input>;
  poolFeeT: Scalars['String'];
  protocolFeeT: Scalars['String'];
  tokenBalanceT: Scalars['String'];
  tokenId: Scalars['String'];
  type: NftPoolEventType;
  usdRatio: Scalars['String'];
};

/**   Event data for swapping an NFT out of a pool. */
export type SwapNftOutPoolEventData = {
  __typename?: 'SwapNftOutPoolEventData';
  /**   The total value of all NFTs involved in the swap in the pool's liquidity token. */
  amountT: Scalars['String'];
  /**   The ratio of the transaction token to the network's base token. */
  nbtRatio: Scalars['String'];
  /**   The updated price at which the pool is willing to buy an NFT in the pool's liquidity token. */
  newBuyPriceT: Scalars['String'];
  /**   The updated delta used in the bonding curve. */
  newDelta: Scalars['String'];
  /**   The updated price at which the pool is willing to sell an NFT in the pool's liquidity token. */
  newSellPriceT: Scalars['String'];
  /**   The updated spot price in the pool's liquidity token. */
  newSpotPriceT: Scalars['String'];
  /**   The number of NFTs in the contract after the block has processed. */
  nftTokenBalance: Scalars['String'];
  /**   Metadata for each of the NFTs involved in the swap. */
  nftsTransfered?: Maybe<Array<Maybe<NftPoolEventNftTransfer>>>;
  /**   The fee for the pool in the pool's liquidity token. */
  poolFeeT: Scalars['String'];
  /**   The protocol fee in the pool's liquidity token. */
  protocolFeeT: Scalars['String'];
  /**   The amount of token in the contract after the block has processed in the pool's liquidity token. */
  tokenBalanceT: Scalars['String'];
  /**   The ID of the token involved in the swap (`address`:`networkId`). */
  tokenId: Scalars['String'];
  /**   The type of NFT pool event, `SWAP_NFT_OUT_POOL`. */
  type: NftPoolEventType;
  /**   The ratio of the transaction token to USD. */
  usdRatio: Scalars['String'];
};

/**   undocumented */
export type SwapNftOutPoolEventDataInput = {
  amountT: Scalars['String'];
  nbtRatio: Scalars['String'];
  newBuyPriceT: Scalars['String'];
  newDelta: Scalars['String'];
  newSellPriceT: Scalars['String'];
  newSpotPriceT: Scalars['String'];
  nftTokenBalance: Scalars['String'];
  nftsTransfered?: InputMaybe<Array<InputMaybe<NftPoolEventNftTransferInput>>>;
  poolFeeT: Scalars['String'];
  protocolFeeT: Scalars['String'];
  tokenBalanceT: Scalars['String'];
  tokenId: Scalars['String'];
  type: NftPoolEventType;
  usdRatio: Scalars['String'];
};

/**   Event data for swapping an NFT out of a pool. */
export type SwapNftOutPoolEventDataV2 = {
  __typename?: 'SwapNftOutPoolEventDataV2';
  /**   The total value of all NFTs involved in the swap in the pool's liquidity token. */
  amountT: Scalars['String'];
  /**   The ratio of the transaction token to the network's base token. */
  nbtRatio: Scalars['String'];
  /**   The updated price at which the pool is willing to buy an NFT in the pool's liquidity token. */
  newBuyPriceT: Scalars['String'];
  /**   The updated delta used in the bonding curve. */
  newDelta: Scalars['String'];
  /**   The updated price at which the pool is willing to sell an NFT in the pool's liquidity token. */
  newSellPriceT: Scalars['String'];
  /**   The updated spot price in the pool's liquidity token. */
  newSpotPriceT: Scalars['String'];
  /**   Metadata for each of the NFTs involved in the swap. */
  nftsTransfered?: Maybe<Array<Maybe<NftPoolEventNftTransferV2>>>;
  /**   The fee for the pool in the pool's liquidity token. */
  poolFeeT: Scalars['String'];
  /**   The protocol fee in the pool's liquidity token. */
  protocolFeeT: Scalars['String'];
  /**   The amount of token in the contract after the block has processed in the pool's liquidity token. */
  tokenBalanceT: Scalars['String'];
  /**   The ID of the token involved in the swap (`address`:`networkId`). */
  tokenId: Scalars['String'];
  /**   The type of NFT pool event, `SWAP_NFT_OUT_POOL`. */
  type: NftPoolEventType;
  /**   The ratio of the transaction token to USD. */
  usdRatio: Scalars['String'];
};

/**   undocumented */
export type SwapNftOutPoolEventDataV2Input = {
  amountT: Scalars['String'];
  nbtRatio: Scalars['String'];
  newBuyPriceT: Scalars['String'];
  newDelta: Scalars['String'];
  newSellPriceT: Scalars['String'];
  newSpotPriceT: Scalars['String'];
  nftsTransfered: Array<NftPoolEventNftTransferV2Input>;
  poolFeeT: Scalars['String'];
  protocolFeeT: Scalars['String'];
  tokenBalanceT: Scalars['String'];
  tokenId: Scalars['String'];
  type: NftPoolEventType;
  usdRatio: Scalars['String'];
};

/**   Response returned by `getSymbol`. */
export type SymbolResponse = {
  __typename?: 'SymbolResponse';
  /**   The `currencyCode` argument passed in (`TOKEN` or `USD`). */
  currency_code: Scalars['String'];
  /**   The trading pair. If `currencyCode` is `TOKEN`, the base token will be used, otherwise `USD`. */
  description: Scalars['String'];
  /**   The symbols of the pair. */
  name: Scalars['String'];
  /**   The base token symbol. */
  original_currency_code: Scalars['String'];
  /**   10^n, where n is the number of decimal places the price has. Max 16. Used for charting. */
  pricescale: Scalars['Float'];
  /**   The list of time frames supported for the symbol in other charting endpoints, eg. `getBars`. */
  supported_resolutions: Array<Scalars['String']>;
  /**   The ID of the pair (`address`:`networkId`). */
  ticker: Scalars['String'];
};

/**   undocumented */
export type Token = {
  __typename?: 'Token';
  address: Scalars['String'];
  decimals: Scalars['Int'];
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  networkId: Scalars['Int'];
  symbol?: Maybe<Scalars['String']>;
};

/**   Response returned by `filterTokens`. */
export type TokenFilterConnection = {
  __typename?: 'TokenFilterConnection';
  /**   The number of tokens returned. */
  count?: Maybe<Scalars['Int']>;
  /**   Where in the list the server started when returning items. */
  page?: Maybe<Scalars['Int']>;
  /**   The list of tokens matching the filter parameters. */
  results?: Maybe<Array<Maybe<TokenFilterResult>>>;
};

/**   A token matching a set of filter parameters. */
export type TokenFilterResult = {
  __typename?: 'TokenFilterResult';
  /**
   *   undocumented
   * @deprecated Use createdAt instead
   */
  age?: Maybe<Scalars['Int']>;
  /**   The number of buys in the past hour. */
  buyCount1?: Maybe<Scalars['Int']>;
  /**   The number of buys in the past 4 hours. */
  buyCount4?: Maybe<Scalars['Int']>;
  /**   The number of buys in the past 12 hours. */
  buyCount12?: Maybe<Scalars['Int']>;
  /**   The number of buys in the past 24 hours. */
  buyCount24?: Maybe<Scalars['Int']>;
  /**   The percent price change in the past hour. */
  change1?: Maybe<Scalars['String']>;
  /**   The percent price change in the past 4 hours. */
  change4?: Maybe<Scalars['String']>;
  /**   The percent price change in the past 12 hours. */
  change12?: Maybe<Scalars['String']>;
  /**   The percent price change in the past 24 hours. */
  change24?: Maybe<Scalars['String']>;
  /**   The unix timestamp for the first transaction to happen on the pair. */
  createdAt?: Maybe<Scalars['Int']>;
  /**   The exchanges the token is listed on. */
  exchanges?: Maybe<Array<Maybe<Exchange>>>;
  /** @deprecated FDV isn't supported yet - use marketCap instead */
  fdv?: Maybe<Scalars['String']>;
  /**   The highest price in USD in the past hour. */
  high1?: Maybe<Scalars['String']>;
  /**   The highest price in USD in the past 4 hours. */
  high4?: Maybe<Scalars['String']>;
  /**   The highest price in USD in the past 12 hours. */
  high12?: Maybe<Scalars['String']>;
  /**   The highest price in USD in the past 24 hours. */
  high24?: Maybe<Scalars['String']>;
  /**   The unix timestamp for the last transaction to happen on the pair that the token is a part of. */
  lastTransaction?: Maybe<Scalars['Int']>;
  /**   Amount of liquidity in the token's top pair. */
  liquidity?: Maybe<Scalars['String']>;
  /**   The lowest price in USD in the past hour. */
  low1?: Maybe<Scalars['String']>;
  /**   The lowest price in USD in the past 4 hours. */
  low4?: Maybe<Scalars['String']>;
  /**   The lowest price in USD in the past 12 hours. */
  low12?: Maybe<Scalars['String']>;
  /**   The lowest price in USD in the past 24 hours. */
  low24?: Maybe<Scalars['String']>;
  /**   The market cap of circulating supply. */
  marketCap?: Maybe<Scalars['String']>;
  /**   Metadata for the token's top pair. */
  pair?: Maybe<Pair>;
  /**   The token price in USD. */
  priceUSD?: Maybe<Scalars['String']>;
  /**   The token of interest. Can be `token0` or `token1`. */
  quoteToken?: Maybe<Scalars['String']>;
  /**   The number of sells in the past hour. */
  sellCount1?: Maybe<Scalars['Int']>;
  /**   The number of sells in the past 4 hours. */
  sellCount4?: Maybe<Scalars['Int']>;
  /**   The number of sells in the past 12 hours. */
  sellCount12?: Maybe<Scalars['Int']>;
  /**   The number of sells in the past 24 hours. */
  sellCount24?: Maybe<Scalars['Int']>;
  /**   Metadata for the token. */
  token?: Maybe<EnhancedToken>;
  /**   The number of transactions in the past hour. */
  txnCount1?: Maybe<Scalars['Int']>;
  /**   The number of transactions in the past 4 hours. */
  txnCount4?: Maybe<Scalars['Int']>;
  /**   The number of transactions in the past 12 hours. */
  txnCount12?: Maybe<Scalars['Int']>;
  /**   The number of transactions in the past 24 hours. */
  txnCount24?: Maybe<Scalars['Int']>;
  /**   The unique number of buys in the past hour. */
  uniqueBuys1?: Maybe<Scalars['Int']>;
  /**   The unique number of buys in the past 4 hours. */
  uniqueBuys4?: Maybe<Scalars['Int']>;
  /**   The unique number of buys in the past 12 hours. */
  uniqueBuys12?: Maybe<Scalars['Int']>;
  /**   The unique number of buys in the past 24 hours. */
  uniqueBuys24?: Maybe<Scalars['Int']>;
  /**   The unique number of sells in the past hour. */
  uniqueSells1?: Maybe<Scalars['Int']>;
  /**   The unique number of sells in the past 4 hours. */
  uniqueSells4?: Maybe<Scalars['Int']>;
  /**   The unique number of sells in the past 12 hours. */
  uniqueSells12?: Maybe<Scalars['Int']>;
  /**   The unique number of sells in the past 24 hours. */
  uniqueSells24?: Maybe<Scalars['Int']>;
  /**   The unique number of transactions in the past hour. */
  uniqueTransactions1?: Maybe<Scalars['Int']>;
  /**   The unique number of transactions in the past 4 hours. */
  uniqueTransactions4?: Maybe<Scalars['Int']>;
  /**   The unique number of transactions in the past 12 hours. */
  uniqueTransactions12?: Maybe<Scalars['Int']>;
  /**   The unique number of transactions in the past 24 hours. */
  uniqueTransactions24?: Maybe<Scalars['Int']>;
  /**   The trade volume in USD in the past hour. */
  volume1?: Maybe<Scalars['String']>;
  /**   The trade volume in USD in the past 4 hours. */
  volume4?: Maybe<Scalars['String']>;
  /**   The trade volume in USD in the past 12 hours. */
  volume12?: Maybe<Scalars['String']>;
  /**   The trade volume in USD in the past 24 hours. */
  volume24?: Maybe<Scalars['String']>;
};

/**   Input type of `TokenFilters`. */
export type TokenFilters = {
  /**   undocumented */
  age?: InputMaybe<NumberFilter>;
  /**   The number of buys in the past hour. */
  buyCount1?: InputMaybe<NumberFilter>;
  /**   The number of buys in the past 4 hours. */
  buyCount4?: InputMaybe<NumberFilter>;
  /**   The number of buys in the past 12 hours. */
  buyCount12?: InputMaybe<NumberFilter>;
  /**   The number of buys in the past 24 hours. */
  buyCount24?: InputMaybe<NumberFilter>;
  /**   The percent price change in the past hour. */
  change1?: InputMaybe<NumberFilter>;
  /**   The percent price change in the past 4 hours. */
  change4?: InputMaybe<NumberFilter>;
  /**   The percent price change in the past 12 hours. */
  change12?: InputMaybe<NumberFilter>;
  /**   The percent price change in the past 24 hours. */
  change24?: InputMaybe<NumberFilter>;
  /**   The unix timestamp for the first transaction of the first pair created with this token. */
  createdAt?: InputMaybe<NumberFilter>;
  /**   undocumented */
  fdv?: InputMaybe<NumberFilter>;
  /**   The highest price in USD in the past hour. */
  high1?: InputMaybe<NumberFilter>;
  /**   The highest price in USD in the past 4 hours. */
  high4?: InputMaybe<NumberFilter>;
  /**   The highest price in USD in the past 12 hours. */
  high12?: InputMaybe<NumberFilter>;
  /**   The highest price in USD in the past 24 hours. */
  high24?: InputMaybe<NumberFilter>;
  /**   The unix timestamp for the last transaction to happen on the pair that the token is a part of. */
  lastTransaction?: InputMaybe<NumberFilter>;
  /**   The amount of liquidity in the token's top pair. */
  liquidity?: InputMaybe<NumberFilter>;
  /**   The lowest price in USD in the past hour. */
  low1?: InputMaybe<NumberFilter>;
  /**   The lowest price in USD in the past 4 hours. */
  low4?: InputMaybe<NumberFilter>;
  /**   The lowest price in USD in the past 12 hours. */
  low12?: InputMaybe<NumberFilter>;
  /**   The lowest price in USD in the past 24 hours. */
  low24?: InputMaybe<NumberFilter>;
  /**   The market cap of circulating supply. */
  marketCap?: InputMaybe<NumberFilter>;
  /**   The network ID. */
  network?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /**   The token price in USD. */
  priceUSD?: InputMaybe<NumberFilter>;
  /**   The number of sells in the past hour. */
  sellCount1?: InputMaybe<NumberFilter>;
  /**   The number of sells in the past 4 hours. */
  sellCount4?: InputMaybe<NumberFilter>;
  /**   The number of sells in the past 12 hours. */
  sellCount12?: InputMaybe<NumberFilter>;
  /**   The number of sells in the past 24 hours. */
  sellCount24?: InputMaybe<NumberFilter>;
  /**   The number of transactions in the past hour. */
  txnCount1?: InputMaybe<NumberFilter>;
  /**   The number of transactions in the past 4 hours. */
  txnCount4?: InputMaybe<NumberFilter>;
  /**   The number of transactions in the past 12 hours. */
  txnCount12?: InputMaybe<NumberFilter>;
  /**   The number of transactions in the past 24 hours. */
  txnCount24?: InputMaybe<NumberFilter>;
  /**   The unique number of buys in the past hour. */
  uniqueBuys1?: InputMaybe<NumberFilter>;
  /**   The unique number of buys in the past 4 hours. */
  uniqueBuys4?: InputMaybe<NumberFilter>;
  /**   The unique number of buys in the past 12 hours. */
  uniqueBuys12?: InputMaybe<NumberFilter>;
  /**   The unique number of buys in the past 24 hours. */
  uniqueBuys24?: InputMaybe<NumberFilter>;
  /**   The unique number of sells in the past hour. */
  uniqueSells1?: InputMaybe<NumberFilter>;
  /**   The unique number of sells in the past 4 hours. */
  uniqueSells4?: InputMaybe<NumberFilter>;
  /**   The unique number of sells in the past 12 hours. */
  uniqueSells12?: InputMaybe<NumberFilter>;
  /**   The unique number of sells in the past 24 hours. */
  uniqueSells24?: InputMaybe<NumberFilter>;
  /**   The unique number of transactions in the past hour. */
  uniqueTransactions1?: InputMaybe<NumberFilter>;
  /**   The unique number of transactions in the past 4 hours. */
  uniqueTransactions4?: InputMaybe<NumberFilter>;
  /**   The unique number of transactions in the past 12 hours. */
  uniqueTransactions12?: InputMaybe<NumberFilter>;
  /**   The unique number of transactions in the past 24 hours. */
  uniqueTransactions24?: InputMaybe<NumberFilter>;
  /**   The trade volume in USD in the past hour. */
  volume1?: InputMaybe<NumberFilter>;
  /**   The trade volume in USD in the past 4 hours. */
  volume4?: InputMaybe<NumberFilter>;
  /**   The trade volume in USD in the past 12 hours. */
  volume12?: InputMaybe<NumberFilter>;
  /**   The trade volume in USD in the past 24 hours. */
  volume24?: InputMaybe<NumberFilter>;
};

/**   Metadata for a token. */
export type TokenInfo = {
  __typename?: 'TokenInfo';
  /**   The contract address of the token. */
  address: Scalars['String'];
  /**   The circulating supply of the token. */
  circulatingSupply?: Maybe<Scalars['String']>;
  /**   The token ID on CoinMarketCap. */
  cmcId?: Maybe<Scalars['Int']>;
  /**   The url to the token's logo. */
  imageLargeUrl?: Maybe<Scalars['String']>;
  /**   Whether the token has been flagged as a scam. */
  isScam?: Maybe<Scalars['Boolean']>;
  /**   External social links for the token. */
  links?: Maybe<SocialLinks>;
  /**   The token name. For example, `ApeCoin`. */
  name?: Maybe<Scalars['String']>;
  /**   The network ID the token is deployed on. */
  networkId: Scalars['Int'];
  /**   The token symbol. For example, `APE`. */
  symbol: Scalars['String'];
  /**   The total supply of the token. */
  totalSupply?: Maybe<Scalars['String']>;
};

/**   The token of interest within a pair. Can be `token0` or `token1`. */
export enum TokenOfInterest {
  Token0 = 'token0',
  Token1 = 'token1'
}

/**   Token pair event types. */
export enum TokenPairEventType {
  Burn = 'BURN',
  Buy = 'BUY',
  Collect = 'COLLECT',
  CollectProtocol = 'COLLECT_PROTOCOL',
  Mint = 'MINT',
  Sell = 'SELL',
  Swap = 'SWAP',
  Sync = 'SYNC'
}

/**   Webhook condition for token pair event type. */
export type TokenPairEventTypeCondition = {
  __typename?: 'TokenPairEventTypeCondition';
  /**   The list of token pair event types. */
  oneOf: Array<TokenPairEventType>;
};

/**   Input for token pair event type condition. */
export type TokenPairEventTypeConditionInput = {
  /**   The list of token event types to equal. */
  oneOf: Array<TokenPairEventType>;
};

/**   Webhook conditions for a token pair event. */
export type TokenPairEventWebhookCondition = {
  __typename?: 'TokenPairEventWebhookCondition';
  /**   The event type the webhook is listening for. */
  eventType?: Maybe<TokenPairEventTypeCondition>;
  /**   The exchange contract address the webhook is listening for. */
  exchangeAddress?: Maybe<StringEqualsCondition>;
  /**   The maker wallet address the webhook is listening for. */
  maker?: Maybe<StringEqualsCondition>;
  /**   The list of network IDs the webhook is listening on. */
  networkId?: Maybe<OneOfNumberCondition>;
  /**   The pair contract address the webhook is listening for. */
  pairAddress?: Maybe<StringEqualsCondition>;
  /**   The swap values the webhook is listening for. */
  swapValue?: Maybe<ComparisonOperator>;
  /**   The token contract address the webhook is listening for. */
  tokenAddress?: Maybe<StringEqualsCondition>;
};

/**   Input conditions for a token pair event webhook. */
export type TokenPairEventWebhookConditionInput = {
  /**   The token event type to listen for. */
  eventType?: InputMaybe<TokenPairEventTypeConditionInput>;
  /**   The exchange contract address to listen for. */
  exchangeAddress?: InputMaybe<StringEqualsConditionInput>;
  /**   The maker wallet address to listen for. */
  maker?: InputMaybe<StringEqualsConditionInput>;
  /**   A list of network IDs to listen on. */
  networkId?: InputMaybe<OneOfNumberConditionInput>;
  /**   The pair contract address to listen for. */
  pairAddress?: InputMaybe<StringEqualsConditionInput>;
  /**   The swap values to listen for. */
  swapValue?: InputMaybe<ComparisonOperatorInput>;
  /**   The token contract address to listen for. */
  tokenAddress?: InputMaybe<StringEqualsConditionInput>;
};

/**   Input type of `TokenRanking`. */
export type TokenRanking = {
  /**   The attribute to rank tokens by. */
  attribute?: InputMaybe<TokenRankingAttribute>;
  /**   The direction to apply to the ranking attribute. */
  direction?: InputMaybe<RankingDirection>;
};

/**   The attribute used to rank tokens. */
export enum TokenRankingAttribute {
  Age = 'age',
  BuyCount1 = 'buyCount1',
  BuyCount4 = 'buyCount4',
  BuyCount12 = 'buyCount12',
  BuyCount24 = 'buyCount24',
  Change1 = 'change1',
  Change4 = 'change4',
  Change12 = 'change12',
  Change24 = 'change24',
  CreatedAt = 'createdAt',
  High1 = 'high1',
  High4 = 'high4',
  High12 = 'high12',
  High24 = 'high24',
  LastTransaction = 'lastTransaction',
  Liquidity = 'liquidity',
  Low1 = 'low1',
  Low4 = 'low4',
  Low12 = 'low12',
  Low24 = 'low24',
  MarketCap = 'marketCap',
  PriceUsd = 'priceUSD',
  SellCount1 = 'sellCount1',
  SellCount4 = 'sellCount4',
  SellCount12 = 'sellCount12',
  SellCount24 = 'sellCount24',
  TxnCount1 = 'txnCount1',
  TxnCount4 = 'txnCount4',
  TxnCount12 = 'txnCount12',
  TxnCount24 = 'txnCount24',
  UniqueBuys1 = 'uniqueBuys1',
  UniqueBuys4 = 'uniqueBuys4',
  UniqueBuys12 = 'uniqueBuys12',
  UniqueBuys24 = 'uniqueBuys24',
  UniqueSells1 = 'uniqueSells1',
  UniqueSells4 = 'uniqueSells4',
  UniqueSells12 = 'uniqueSells12',
  UniqueSells24 = 'uniqueSells24',
  UniqueTransactions1 = 'uniqueTransactions1',
  UniqueTransactions4 = 'uniqueTransactions4',
  UniqueTransactions12 = 'uniqueTransactions12',
  UniqueTransactions24 = 'uniqueTransactions24',
  Volume1 = 'volume1',
  Volume4 = 'volume4',
  Volume12 = 'volume12',
  Volume24 = 'volume24'
}

/**  Response returned by `searchTokens`. */
export type TokenSearchResponse = {
  __typename?: 'TokenSearchResponse';
  /**   The number of additional high volume results found. Only used if `lowVolumeFilter` is set to `true`. */
  hasMore?: Maybe<Scalars['Int']>;
  /**  If `lowVolumeFilter` is set to `true`, the number of additional low volume results found.  <br>If `lowVolumeFilter` is set to `false`, the number of additional high and low volume results found. */
  hasMoreLowVolume?: Maybe<Scalars['Int']>;
  /**   A list of tokens. */
  tokens?: Maybe<Array<TokenWithMetadata>>;
};

/**   A token with metadata. */
export type TokenWithMetadata = {
  __typename?: 'TokenWithMetadata';
  /**   The contract address of the token. */
  address: Scalars['String'];
  /**   The precision to which the token can be divided. For example, the smallest unit for USDC is 0.000001 (6 decimals). */
  decimals?: Maybe<Scalars['Int']>;
  /**   The exchanges the token is listed on. */
  exchanges: Array<Exchange>;
  /**   The ID of the token (`address`:`networkId`). For example, `0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2:1`. */
  id: Scalars['String'];
  /**   The total liquidity of the token's top pair in USD. */
  liquidity: Scalars['String'];
  /**   The name of the token. */
  name: Scalars['String'];
  /**   The network ID the token is deployed on. */
  networkId: Scalars['Int'];
  /**   The token price in USD. */
  price: Scalars['Float'];
  /**   The percent price change for the time frame requested. */
  priceChange: Scalars['Float'];
  /**   The time frame for the results. */
  resolution: Scalars['String'];
  /**   The symbol for the token. */
  symbol: Scalars['String'];
  /**   The ID of the token's top pair (`pairAddress`:`networkId`). */
  topPairId: Scalars['String'];
  /**   The volume over the time frame requested in USD. */
  volume: Scalars['String'];
};

/**   undocumented */
export type UpdateAggregateBatchInput = {
  aggregates: ResolutionBarInput;
  eventSortKey: Scalars['String'];
  networkId: Scalars['Int'];
  pairAddress: Scalars['String'];
  pairId: Scalars['String'];
  quoteToken?: InputMaybe<QuoteToken>;
  timestamp: Scalars['Int'];
};

/**   Response returned by `onUpdateAggregateBatch`. */
export type UpdateAggregateBatchResponse = {
  __typename?: 'UpdateAggregateBatchResponse';
  /**   Price data broken down by resolution. */
  aggregates: ResolutionBarData;
  /**   The sortKey for the bar (`blockNumber`#`transactionIndex`#`logIndex`, zero padded). For example, `0000000016414564#00000224#00000413`. */
  eventSortKey: Scalars['String'];
  /**   The network ID the pair is deployed on. */
  networkId: Scalars['Int'];
  /**   The contract address for the pair. */
  pairAddress: Scalars['String'];
  /**   The ID for the pair (`pairAddress`:`networkId`). */
  pairId: Scalars['String'];
  /**   The quote token within the pair. */
  quoteToken?: Maybe<QuoteToken>;
  /**   The unix timestamp for the new bar. */
  timestamp: Scalars['Int'];
};

/**   undocumented */
export type UpdatePriceInput = {
  address: Scalars['String'];
  networkId: Scalars['Int'];
  priceUsd: Scalars['Float'];
  timestamp: Scalars['Int'];
};

/**   Metadata for a webhook. */
export type Webhook = {
  __typename?: 'Webhook';
  /**   The recurrence of the webhook. Can be `INDEFINITE` or `ONCE`. */
  alertRecurrence: AlertRecurrence;
  /**   The url to which the webhook message should be sent. */
  callbackUrl: Scalars['String'];
  /**   The conditions which must be met in order for the webhook to send a message. */
  conditions: WebhookCondition;
  /**   The unix timestamp for the time the webhook was created. */
  created: Scalars['Int'];
  /**   The webhook group ID used to group webhooks together for ordered message sending. */
  groupId?: Maybe<Scalars['String']>;
  /**   The ID of the webhook. */
  id: Scalars['String'];
  /**   The given name of the webhook. */
  name: Scalars['String'];
  /**   The settings for retrying failed webhook messages. */
  retrySettings?: Maybe<RetrySettings>;
  /**   The status of the webhook. Can be `ACTIVE` or `INACTIVE`. */
  status: Scalars['String'];
  /**   The type of webhook. Can be `PRICE_EVENT`, `DECODED_CALL`, `DECODED_LOG`, `RAW_CALL_TRACE`, `NFT_EVENT`, or `TOKEN_PAIR_EVENT`. */
  webhookType: WebhookType;
};

/**   Webhook conditions that must be met for each webhook type. */
export type WebhookCondition = DecodedCallWebhookCondition | DecodedLogWebhookCondition | NftEventWebhookCondition | PriceEventWebhookCondition | RawCallTraceWebhookCondition | TokenPairEventWebhookCondition;

/**   NFT marketplace names. */
export enum WebhookNftEventFillSource {
  Blur = 'BLUR',
  Coinbase = 'COINBASE',
  Echelon = 'ECHELON',
  Element = 'ELEMENT',
  Ensvision = 'ENSVISION',
  Flipxyz = 'FLIPXYZ',
  Gem = 'GEM',
  Genie = 'GENIE',
  Kodex = 'KODEX',
  Magiceden = 'MAGICEDEN',
  Nftnerds = 'NFTNERDS',
  Opensea = 'OPENSEA',
  Rarible = 'RARIBLE',
  Reservoirtools = 'RESERVOIRTOOLS',
  Soundxyz = 'SOUNDXYZ'
}

/**   NFT event types. */
export enum WebhookNftEventType {
  Mint = 'MINT',
  Sale = 'SALE',
  Transfer = 'TRANSFER'
}

/**   The type of webhook. */
export enum WebhookType {
  DecodedCall = 'DECODED_CALL',
  DecodedLog = 'DECODED_LOG',
  NftEvent = 'NFT_EVENT',
  PriceEvent = 'PRICE_EVENT',
  RawCallTrace = 'RAW_CALL_TRACE',
  TokenPairEvent = 'TOKEN_PAIR_EVENT'
}

/**   Price stats for a pair over a time frame. */
export type WindowedDetailedCurrencyPairStats = {
  __typename?: 'WindowedDetailedCurrencyPairStats';
  /**   The buy volume over the time frame. */
  buyVolume?: Maybe<DetailedPairStatsStringMetrics>;
  /**   The closing price for the time frame. */
  close?: Maybe<DetailedPairStatsStringMetrics>;
  /**   The highest price in USD in the time frame. */
  highest?: Maybe<DetailedPairStatsStringMetrics>;
  /**   The lowest price in USD in the time frame. */
  lowest?: Maybe<DetailedPairStatsStringMetrics>;
  /**   The opening price for the time frame. */
  open?: Maybe<DetailedPairStatsStringMetrics>;
  /**   The sell volume over the time frame. */
  sellVolume?: Maybe<DetailedPairStatsStringMetrics>;
  /**   The volume over the time frame. */
  volume?: Maybe<DetailedPairStatsStringMetrics>;
};

/**   Price stats for an NFT collection over a time frame. Either in USD or the network's base token. */
export type WindowedDetailedNftCurrencyStats = {
  __typename?: 'WindowedDetailedNftCurrencyStats';
  /**   The average sale price in the time frame. */
  average?: Maybe<DetailedNftStatsStringMetrics>;
  /**   The closing price for the time frame. */
  close?: Maybe<DetailedNftStatsStringMetrics>;
  /**   The closing floor listing price for the time frame. */
  closeListingFloor?: Maybe<DetailedNftStatsStringMetrics>;
  /**   The highest listing price in the time frame. */
  highestListingFloor?: Maybe<DetailedNftStatsStringMetrics>;
  /**   The highest sale price in the time frame. */
  highestSale?: Maybe<DetailedNftStatsStringMetrics>;
  /**   The lowest listing price for the time frame. */
  listingFloor?: Maybe<DetailedNftStatsStringMetrics>;
  /**   The lowest sale price in the time frame. */
  lowestSale?: Maybe<DetailedNftStatsStringMetrics>;
  /**   The opening price for the time frame. */
  open?: Maybe<DetailedNftStatsStringMetrics>;
  /**   The opening floor listing price for the time frame. */
  openListingFloor?: Maybe<DetailedNftStatsStringMetrics>;
  /**   The volume over the time frame. */
  volume?: Maybe<DetailedNftStatsStringMetrics>;
};

/**   Numerical stats for an NFT collection over a time frame. */
export type WindowedDetailedNftNonCurrencyStats = {
  __typename?: 'WindowedDetailedNftNonCurrencyStats';
  /**   The number of mints over the time frame. */
  mints?: Maybe<DetailedNftStatsNumberMetrics>;
  /**   The number of sales over the time frame. */
  sales?: Maybe<DetailedNftStatsNumberMetrics>;
  /**   The number of transfers over the time frame. */
  transfers?: Maybe<DetailedNftStatsNumberMetrics>;
  /**   The number of unique buyers over the time frame. */
  uniqueBuyers?: Maybe<DetailedNftStatsNumberMetrics>;
  /**   The number of unique minters over the time frame. */
  uniqueMinters?: Maybe<DetailedNftStatsNumberMetrics>;
  /**   The number of unique wallets (buyers or sellers) over the time frame. */
  uniqueSalesWallets?: Maybe<DetailedNftStatsNumberMetrics>;
  /**   The number of unique sellers over the time frame. */
  uniqueSellers?: Maybe<DetailedNftStatsNumberMetrics>;
};

/**   Detailed NFT stats over a time frame. */
export type WindowedDetailedNftStats = {
  __typename?: 'WindowedDetailedNftStats';
  /**   The duration used to request detailed NFT stats. */
  duration: DetailedNftStatsDuration;
  /**   The unix timestamp for the end of the window. */
  end: Scalars['Int'];
  /**   The unix timestamp for the start of the window. */
  start: Scalars['Int'];
  /**   The currency stats in the network's base token, such as volume. */
  statsNetworkBaseToken: WindowedDetailedNftCurrencyStats;
  /**   The numerical stats, such as number of buyers. */
  statsNonCurrency: WindowedDetailedNftNonCurrencyStats;
  /**   The currency stats in USD, such as volume. */
  statsUsd: WindowedDetailedNftCurrencyStats;
  /**   The list of start/end timestamps broken down for each bucket within the window. */
  timestamps: Array<Maybe<DetailedNftStatsBucketTimestamp>>;
};

/**   Numerical stats for a pair over a time frame. */
export type WindowedDetailedNonCurrencyPairStats = {
  __typename?: 'WindowedDetailedNonCurrencyPairStats';
  /**   The number of unique buyers over the time frame. */
  buyers?: Maybe<DetailedPairStatsNumberMetrics>;
  /**   The number of buys over the time frame. */
  buys?: Maybe<DetailedPairStatsNumberMetrics>;
  /**   The number of unique sellers over the time frame. */
  sellers?: Maybe<DetailedPairStatsNumberMetrics>;
  /**   The number of sells over the time frame. */
  sells?: Maybe<DetailedPairStatsNumberMetrics>;
  /**   The number of unique traders over the time frame. */
  traders?: Maybe<DetailedPairStatsNumberMetrics>;
  /**   The transaction count over the time frame. */
  transactions?: Maybe<DetailedPairStatsNumberMetrics>;
};

/**   Detailed pair stats over a time frame. */
export type WindowedDetailedPairStats = {
  __typename?: 'WindowedDetailedPairStats';
  /**   The duration used to request detailed pair stats. */
  duration: DetailedPairStatsDuration;
  /**   The unix timestamp for the end of the window. */
  end: Scalars['Int'];
  /**   The unix timestamp for the start of the window. */
  start: Scalars['Int'];
  /**   The numerical stats, such as number of buyers. */
  statsNonCurrency: WindowedDetailedNonCurrencyPairStats;
  /**   The currency stats in USD, such as volume. */
  statsUsd: WindowedDetailedCurrencyPairStats;
  /**   The list of start/end timestamps broken down for each bucket within the window. */
  timestamps: Array<Maybe<DetailedPairStatsBucketTimestamp>>;
};

/**   undocumented */
export type WindowedDetailedStats = {
  __typename?: 'WindowedDetailedStats';
  buckets: Array<Maybe<DetailedStatsBucketTimestamp>>;
  buyVolume?: Maybe<DetailedStatsStringMetrics>;
  buyers: DetailedStatsNumberMetrics;
  buys: DetailedStatsNumberMetrics;
  endTimestamp: Scalars['Int'];
  sellVolume?: Maybe<DetailedStatsStringMetrics>;
  sellers: DetailedStatsNumberMetrics;
  sells: DetailedStatsNumberMetrics;
  timestamp: Scalars['Int'];
  traders?: Maybe<DetailedStatsNumberMetrics>;
  transactions: DetailedStatsNumberMetrics;
  volume: DetailedStatsStringMetrics;
  windowSize: DetailedStatsWindowSize;
};

/**   Currency stats. */
export type StatsCurrency = {
  /**   The average sale price in the time frame. */
  average?: InputMaybe<StatsFilter>;
  /**   The closing price for the time frame. */
  close?: InputMaybe<StatsFilter>;
  /**   The closing floor listing price for the time frame. */
  closeListingFloor?: InputMaybe<StatsFilter>;
  /**   The highest listing price in the time frame. */
  highestListingFloor?: InputMaybe<StatsFilter>;
  /**   The highest sale price in the time frame. */
  highestSale?: InputMaybe<StatsFilter>;
  /**   The lowest listing price for the time frame. */
  listingFloor?: InputMaybe<StatsFilter>;
  /**   The lowest sale price in the time frame. */
  lowestSale?: InputMaybe<StatsFilter>;
  /**   The opening price for the time frame. */
  open?: InputMaybe<StatsFilter>;
  /**   The opening floor listing price for the time frame. */
  openListingFloor?: InputMaybe<StatsFilter>;
  /**   The volume over the time frame. */
  volume?: InputMaybe<StatsFilter>;
};

/**   Numerical stats for an NFT collection over a time frame. */
export type StatsNonCurrency = {
  /**   The number of mints over the time frame. */
  mints?: InputMaybe<StatsFilter>;
  /**   The number of sales over the time frame. */
  sales?: InputMaybe<StatsFilter>;
  /**   The number of tokens sold over the time frame. */
  tokensSold?: InputMaybe<StatsFilter>;
  /**   The number of transfers over the time frame. */
  transfers?: InputMaybe<StatsFilter>;
  /**   The number of unique buyers over the time frame. */
  uniqueBuyers?: InputMaybe<StatsFilter>;
  /**   The number of unique minters over the time frame. */
  uniqueMinters?: InputMaybe<StatsFilter>;
  /**   The number of unique wallets (buyers or sellers) over the time frame. */
  uniqueSalesWallets?: InputMaybe<StatsFilter>;
  /**   The number of unique sellers over the time frame. */
  uniqueSellers?: InputMaybe<StatsFilter>;
};

export type BaseTokenWithMetadataFragment = { __typename?: 'TokenWithMetadata', address: string, decimals?: number | null, id: string, liquidity: string, name: string, networkId: number, price: number, priceChange: number, resolution: string, symbol: string, volume: string, topPairId: string, exchanges: Array<{ __typename?: 'Exchange', address: string, color?: string | null, exchangeVersion?: string | null, id: string, name?: string | null, networkId: number, tradeUrl?: string | null, iconUrl?: string | null }> };

export type ExchangeModelFragment = { __typename?: 'Exchange', address: string, color?: string | null, exchangeVersion?: string | null, id: string, name?: string | null, networkId: number, tradeUrl?: string | null, iconUrl?: string | null };

export type PairModelFragment = { __typename?: 'Pair', address: string, exchangeHash: string, fee?: number | null, id: string, networkId: number, tickSpacing?: number | null, token0: string, token1: string };

export type FilterTokensQueryVariables = Exact<{
  filters?: InputMaybe<TokenFilters>;
  phrase?: InputMaybe<Scalars['String']>;
  rankings?: InputMaybe<Array<InputMaybe<TokenRanking>> | InputMaybe<TokenRanking>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type FilterTokensQuery = { __typename?: 'Query', filterTokens?: { __typename?: 'TokenFilterConnection', count?: number | null, page?: number | null, results?: Array<{ __typename?: 'TokenFilterResult', buyCount1?: number | null, buyCount12?: number | null, buyCount24?: number | null, buyCount4?: number | null, change1?: string | null, change12?: string | null, change24?: string | null, change4?: string | null, fdv?: string | null, high1?: string | null, high12?: string | null, high24?: string | null, high4?: string | null, liquidity?: string | null, low1?: string | null, low12?: string | null, low24?: string | null, low4?: string | null, marketCap?: string | null, priceUSD?: string | null, quoteToken?: string | null, sellCount1?: number | null, sellCount12?: number | null, sellCount24?: number | null, sellCount4?: number | null, txnCount1?: number | null, txnCount12?: number | null, txnCount24?: number | null, txnCount4?: number | null, volume1?: string | null, volume12?: string | null, volume24?: string | null, volume4?: string | null, exchanges?: Array<{ __typename?: 'Exchange', address: string, color?: string | null, exchangeVersion?: string | null, id: string, name?: string | null, networkId: number, tradeUrl?: string | null, iconUrl?: string | null } | null> | null, pair?: { __typename?: 'Pair', address: string, exchangeHash: string, fee?: number | null, id: string, networkId: number, tickSpacing?: number | null, token0: string, token1: string } | null, token?: { __typename?: 'EnhancedToken', address: string, decimals: number, id: string, name?: string | null, networkId: number, symbol?: string | null, isScam?: boolean | null } | null } | null> | null } | null };

export type GetBarsQueryVariables = Exact<{
  resolution: Scalars['String'];
  symbol: Scalars['String'];
  to: Scalars['Int'];
  from: Scalars['Int'];
}>;


export type GetBarsQuery = { __typename?: 'Query', getBars?: { __typename?: 'BarsResponse', c: Array<number | null>, h: Array<number | null>, l: Array<number | null>, o: Array<number | null>, s: string, t: Array<number>, volume?: Array<string | null> | null } | null };

export type GetNftAssetsQueryVariables = Exact<{
  address: Scalars['String'];
  networkId: Scalars['Int'];
  limit?: InputMaybe<Scalars['Int']>;
  tokenIds?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type GetNftAssetsQuery = { __typename?: 'Query', getNftAssets?: { __typename?: 'NftAssetsConnection', cursor?: string | null, items?: Array<{ __typename?: 'NftAsset', address: string, description?: string | null, id: string, name?: string | null, networkId: number, originalImage?: string | null, tokenId: string, uri?: string | null, media?: { __typename?: 'NftAssetMedia', image: string, thumbLg: string, thumbSm: string } | null } | null> | null } | null };

export type GetTokenPricesQueryVariables = Exact<{
  inputs?: InputMaybe<Array<InputMaybe<GetPriceInput>> | InputMaybe<GetPriceInput>>;
}>;


export type GetTokenPricesQuery = { __typename?: 'Query', getTokenPrices?: Array<{ __typename?: 'Price', address: string, networkId: number, priceUsd: number, timestamp: number } | null> | null };

export type SearchNftsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
  networkFilter?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  window?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type SearchNftsQuery = { __typename?: 'Query', searchNfts?: { __typename?: 'NftSearchResponse', hasMore: number, items?: Array<{ __typename?: 'NftSearchResponseCollection', address: string, average: string, ceiling: string, floor: string, id: string, imageUrl?: string | null, name?: string | null, networkId: number, symbol?: string | null, tradeCount: string, tradeCountChange: number, volume: string, volumeChange: number, window: string } | null> | null } | null };

export const ExchangeModelFragmentDoc = gql`
    fragment ExchangeModel on Exchange {
  address
  color
  exchangeVersion
  id
  name
  networkId
  tradeUrl
  iconUrl
}
    `;
export const BaseTokenWithMetadataFragmentDoc = gql`
    fragment BaseTokenWithMetadata on TokenWithMetadata {
  address
  decimals
  id
  liquidity
  name
  networkId
  price
  priceChange
  resolution
  symbol
  volume
  topPairId
  exchanges {
    ...ExchangeModel
  }
}
    ${ExchangeModelFragmentDoc}`;
export const PairModelFragmentDoc = gql`
    fragment PairModel on Pair {
  address
  exchangeHash
  fee
  id
  networkId
  tickSpacing
  token0
  token1
}
    `;
export const FilterTokensDocument = gql`
    query FilterTokens($filters: TokenFilters, $phrase: String, $rankings: [TokenRanking], $limit: Int, $offset: Int) {
  filterTokens(
    filters: $filters
    phrase: $phrase
    rankings: $rankings
    limit: $limit
    offset: $offset
  ) {
    results {
      buyCount1
      buyCount12
      buyCount24
      buyCount4
      change1
      change12
      change24
      change4
      exchanges {
        ...ExchangeModel
      }
      fdv
      high1
      high12
      high24
      high4
      liquidity
      low1
      low12
      low24
      low4
      marketCap
      pair {
        ...PairModel
      }
      priceUSD
      quoteToken
      sellCount1
      sellCount12
      sellCount24
      sellCount4
      token {
        address
        decimals
        id
        name
        networkId
        symbol
        isScam
      }
      txnCount1
      txnCount12
      txnCount24
      txnCount4
      volume1
      volume12
      volume24
      volume4
    }
    count
    page
  }
}
    ${ExchangeModelFragmentDoc}
${PairModelFragmentDoc}`;

/**
 * __useFilterTokensQuery__
 *
 * To run a query within a React component, call `useFilterTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterTokensQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      phrase: // value for 'phrase'
 *      rankings: // value for 'rankings'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useFilterTokensQuery(baseOptions?: Apollo.QueryHookOptions<FilterTokensQuery, FilterTokensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilterTokensQuery, FilterTokensQueryVariables>(FilterTokensDocument, options);
      }
export function useFilterTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilterTokensQuery, FilterTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilterTokensQuery, FilterTokensQueryVariables>(FilterTokensDocument, options);
        }
export type FilterTokensQueryHookResult = ReturnType<typeof useFilterTokensQuery>;
export type FilterTokensLazyQueryHookResult = ReturnType<typeof useFilterTokensLazyQuery>;
export type FilterTokensQueryResult = Apollo.QueryResult<FilterTokensQuery, FilterTokensQueryVariables>;
export const GetBarsDocument = gql`
    query GetBars($resolution: String!, $symbol: String!, $to: Int!, $from: Int!) {
  getBars(resolution: $resolution, symbol: $symbol, to: $to, from: $from) {
    c
    h
    l
    o
    s
    t
    volume
  }
}
    `;

/**
 * __useGetBarsQuery__
 *
 * To run a query within a React component, call `useGetBarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBarsQuery({
 *   variables: {
 *      resolution: // value for 'resolution'
 *      symbol: // value for 'symbol'
 *      to: // value for 'to'
 *      from: // value for 'from'
 *   },
 * });
 */
export function useGetBarsQuery(baseOptions: Apollo.QueryHookOptions<GetBarsQuery, GetBarsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBarsQuery, GetBarsQueryVariables>(GetBarsDocument, options);
      }
export function useGetBarsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBarsQuery, GetBarsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBarsQuery, GetBarsQueryVariables>(GetBarsDocument, options);
        }
export type GetBarsQueryHookResult = ReturnType<typeof useGetBarsQuery>;
export type GetBarsLazyQueryHookResult = ReturnType<typeof useGetBarsLazyQuery>;
export type GetBarsQueryResult = Apollo.QueryResult<GetBarsQuery, GetBarsQueryVariables>;
export const GetNftAssetsDocument = gql`
    query GetNftAssets($address: String!, $networkId: Int!, $limit: Int, $tokenIds: [String]) {
  getNftAssets(
    address: $address
    networkId: $networkId
    limit: $limit
    tokenIds: $tokenIds
  ) {
    cursor
    items {
      address
      description
      id
      media {
        image
        thumbLg
        thumbSm
      }
      name
      networkId
      originalImage
      tokenId
      uri
    }
  }
}
    `;

/**
 * __useGetNftAssetsQuery__
 *
 * To run a query within a React component, call `useGetNftAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNftAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNftAssetsQuery({
 *   variables: {
 *      address: // value for 'address'
 *      networkId: // value for 'networkId'
 *      limit: // value for 'limit'
 *      tokenIds: // value for 'tokenIds'
 *   },
 * });
 */
export function useGetNftAssetsQuery(baseOptions: Apollo.QueryHookOptions<GetNftAssetsQuery, GetNftAssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNftAssetsQuery, GetNftAssetsQueryVariables>(GetNftAssetsDocument, options);
      }
export function useGetNftAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNftAssetsQuery, GetNftAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNftAssetsQuery, GetNftAssetsQueryVariables>(GetNftAssetsDocument, options);
        }
export type GetNftAssetsQueryHookResult = ReturnType<typeof useGetNftAssetsQuery>;
export type GetNftAssetsLazyQueryHookResult = ReturnType<typeof useGetNftAssetsLazyQuery>;
export type GetNftAssetsQueryResult = Apollo.QueryResult<GetNftAssetsQuery, GetNftAssetsQueryVariables>;
export const GetTokenPricesDocument = gql`
    query GetTokenPrices($inputs: [GetPriceInput]) {
  getTokenPrices(inputs: $inputs) {
    address
    networkId
    priceUsd
    timestamp
  }
}
    `;

/**
 * __useGetTokenPricesQuery__
 *
 * To run a query within a React component, call `useGetTokenPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTokenPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTokenPricesQuery({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useGetTokenPricesQuery(baseOptions?: Apollo.QueryHookOptions<GetTokenPricesQuery, GetTokenPricesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTokenPricesQuery, GetTokenPricesQueryVariables>(GetTokenPricesDocument, options);
      }
export function useGetTokenPricesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTokenPricesQuery, GetTokenPricesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTokenPricesQuery, GetTokenPricesQueryVariables>(GetTokenPricesDocument, options);
        }
export type GetTokenPricesQueryHookResult = ReturnType<typeof useGetTokenPricesQuery>;
export type GetTokenPricesLazyQueryHookResult = ReturnType<typeof useGetTokenPricesLazyQuery>;
export type GetTokenPricesQueryResult = Apollo.QueryResult<GetTokenPricesQuery, GetTokenPricesQueryVariables>;
export const SearchNftsDocument = gql`
    query SearchNfts($search: String, $networkFilter: [Int!], $window: String, $limit: Int) {
  searchNfts(
    search: $search
    networkFilter: $networkFilter
    window: $window
    limit: $limit
  ) {
    items {
      address
      average
      ceiling
      floor
      id
      imageUrl
      name
      networkId
      symbol
      tradeCount
      tradeCountChange
      volume
      volumeChange
      window
    }
    hasMore
  }
}
    `;

/**
 * __useSearchNftsQuery__
 *
 * To run a query within a React component, call `useSearchNftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchNftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchNftsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      networkFilter: // value for 'networkFilter'
 *      window: // value for 'window'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchNftsQuery(baseOptions?: Apollo.QueryHookOptions<SearchNftsQuery, SearchNftsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchNftsQuery, SearchNftsQueryVariables>(SearchNftsDocument, options);
      }
export function useSearchNftsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchNftsQuery, SearchNftsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchNftsQuery, SearchNftsQueryVariables>(SearchNftsDocument, options);
        }
export type SearchNftsQueryHookResult = ReturnType<typeof useSearchNftsQuery>;
export type SearchNftsLazyQueryHookResult = ReturnType<typeof useSearchNftsLazyQuery>;
export type SearchNftsQueryResult = Apollo.QueryResult<SearchNftsQuery, SearchNftsQueryVariables>;