import { Theme } from "@emotion/react";
import {
  blackA,
  blue,
  blueDark,
  gray,
  grayA,
  grayDark,
  grayDarkA,
  green,
  greenDark,
  red,
  redDark,
  whiteA,
} from "@radix-ui/colors";

const commonTheme = {
  spacing: (amount: number) => {
    return `${amount * 8}px`;
  },
  fontSize: {
    h1: "56px",
    h2: "48px",
    h3: "36px",
    h4: "32px",
    h5: "28px",
    h6: "24px",
    subtitle1: "20px",
    subtitle2: "18px",
    body1: "15px",
    body2: "14px",
    caption: "13px",
  },
  fontWeight: {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
  },
  borderRadius: "8px",
};

const lightTheme: Theme = {
  ...commonTheme,
  mode: "light",
  colors: {
    blue: { ...blue },
    green: { ...green },
    red: { ...red },
    gray: { ...gray },
    grayA: { ...grayA },
    white: { ...whiteA },
    black: { ...blackA },
    primary: "#389d77",
    secondary: "#625ab4",
    secondaryAlt: "#302888",
    success: "#00c393",
    info: "#18a7f8",
    warning: "#f4c820",
    error: "#ef425b",
    disabled: "#716c8b",
    text: "#2f284e",
  },
};

const darkTheme: Theme = {
  ...commonTheme,
  mode: "dark",
  colors: {
    blue: { ...blueDark },
    green: { ...greenDark },
    red: { ...redDark },
    gray: { ...grayDark },
    grayA: { ...grayDarkA },
    white: { ...whiteA },
    black: { ...blackA },
    primary: "#81f2c8",
    secondary: "#8b80f8",
    secondaryAlt: "#e1deff",
    success: "#00c393",
    info: "#18a7f8",
    warning: "#f4c820",
    error: "#ef425b",
    disabled: "#716c8b",
    text: "#d7f9ec",
  },
};

const nivoDarkTheme = {
  textColor: darkTheme.colors.text,
  fontSize: 13,
  axis: {
    domain: {
      line: {
        stroke: darkTheme.colors.secondary + "22",
        strokeWidth: 1,
      },
    },
    legend: {
      text: {
        fontSize: 15,
        fill: darkTheme.colors.text,
      },
    },
    ticks: {
      line: {
        stroke: darkTheme.colors.secondary + "22",
        strokeWidth: 1,
      },
      text: {
        fontSize: 13,
        fill: darkTheme.colors.text,
      },
    },
  },
  crosshair: {
    line: {
      stroke: darkTheme.colors.text + "aa",
      strokeWidth: 1,
    },
  },
  grid: {
    line: {
      stroke: darkTheme.colors.secondary + "11",
      strokeWidth: 1,
    },
  },
  legends: {
    text: {
      fontSize: 12,
      fill: darkTheme.colors.text,
    },
  },
  tooltip: {
    container: {
      background: "black",
      color: darkTheme.colors.text,
      fontSize: 14,
    },
  },
};

const nivoLightTheme = {
  textColor: lightTheme.colors.text,
  fontSize: 13,
  axis: {
    domain: {
      line: {
        stroke: lightTheme.colors.secondary + "22",
        strokeWidth: 1,
      },
    },
    legend: {
      text: {
        fontSize: 15,
        fill: lightTheme.colors.text,
      },
    },
    ticks: {
      line: {
        stroke: lightTheme.colors.secondary + "22",
        strokeWidth: 1,
      },
      text: {
        fontSize: 13,
        fill: lightTheme.colors.text,
      },
    },
  },
  crosshair: {
    line: {
      stroke: lightTheme.colors.text + "aa",
      strokeWidth: 1,
    },
  },
  grid: {
    line: {
      stroke: lightTheme.colors.secondary + "11",
      strokeWidth: 1,
    },
  },
  legends: {
    text: {
      fontSize: 12,
      fill: lightTheme.colors.text,
    },
  },
  tooltip: {
    container: {
      background: "white",
      color: lightTheme.colors.text,
      fontSize: 14,
    },
  },
};

export { lightTheme, darkTheme, nivoLightTheme, nivoDarkTheme };
