const breakpoints = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};

enum zIndex {
  pageContent = 1,
  header,
  overlay,
  search,
  tooltip,
}

const greaterThan = (sizeInPx: number) => {
  return `(min-width: ${sizeInPx}px)`;
};

const lessThan = (sizeInPx: number) => {
  return `(max-width: ${sizeInPx}px)`;
};

export { zIndex, breakpoints, greaterThan, lessThan };
