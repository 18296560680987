import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import { borderRadius } from "../../styles/Mixins";
import { NetworkId } from "../../types/Network";
import { NetworkIcon } from "./NetworkIcon";

export interface NetworkIconStackProps {
  networkIds: NetworkId[];
  maxShown?: number;
}

const NetworkIconStackContainer = styled("div")`
  display: flex;
  align-items: center;
`;

const HiddenNetworks = styled("div")(
  ({ theme }) => css`
    align-items: center;
    background-color: ${theme.colors.gray.gray6};
    display: flex;
    font-size: 12px;
    padding: ${theme.spacing(0.25)} ${theme.spacing(0.75)};
    ${borderRadius}
  `
);

const NetworkIconStack: React.FC<NetworkIconStackProps> = ({
  networkIds,
  maxShown = 8,
}) => {
  let shownNetworkIds = networkIds;
  let hiddenNetworkIds = [];
  if (maxShown && networkIds.length > maxShown) {
    shownNetworkIds = networkIds.slice(0, maxShown);
    hiddenNetworkIds = networkIds.slice(maxShown);
  }

  return (
    <NetworkIconStackContainer>
      {shownNetworkIds.map((networkId) => (
        <NetworkIcon
          key={networkId}
          networkId={networkId as NetworkId}
          stacked
        />
      ))}

      {hiddenNetworkIds.length > 0 && (
        <HiddenNetworks>+{hiddenNetworkIds.length}</HiddenNetworks>
      )}
    </NetworkIconStackContainer>
  );
};

export { NetworkIconStack };
