import React, { useContext } from "react";
import toast from "react-hot-toast";
import { BiReset } from "react-icons/bi";

import { DurationFormattedShort } from "../../../constants/Duration";
import { EnabledNetworks } from "../../../constants/Network";
import {
  FiltersActionType,
  FiltersContext,
} from "../../../context/FiltersContext";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { breakpoints, lessThan } from "../../../styles/Layout";
import { Duration } from "../../../types/Duration";
import { FlexCenteredGap } from "../../basic/Flex";
import { DurationSelect } from "../../durationSelect/DurationSelect";
import { TextButton } from "../../inputs/Button";
import { Input } from "../../inputs/Input";
import { ToggleGroupItem, ToggleGroupRoot } from "../../inputs/ToggleGroup";
import { FilterNetworksModal } from "../../networks/FilterNetworkModal";
import { NetworkIconStack } from "../../networks/NetworkIconStack";

const windows = [
  Duration.Hour1,
  Duration.Hour4,
  Duration.Hour12,
  Duration.Day1,
];

const DEFAULT_WINDOW = Duration.Day1;

const ResetFilterButton: React.FC<{
  isDefault: boolean;
  setToDefault: () => void;
}> = ({ isDefault, setToDefault }) => {
  const lessThanMd = useMediaQuery(lessThan(breakpoints.md));

  return (
    <TextButton
      onClick={setToDefault}
      name="reset-filters"
      style={{
        opacity: isDefault ? 0.3 : 1,
        minWidth: lessThanMd ? 20 : 34,
        padding: lessThanMd ? 0 : 8,
      }}
    >
      <BiReset style={{ fontSize: "18px" }} />
    </TextButton>
  );
};

const NetworkFilter: React.FC = () => {
  const enabledNetworks = EnabledNetworks;
  const lessThanMd = useMediaQuery(lessThan(breakpoints.md));

  const {
    state: { visibleNetworks },
    dispatch: filtersDispatch,
  } = useContext(FiltersContext);

  const isDefault = visibleNetworks.length === enabledNetworks.length;

  const handleSetToDefault = () => {
    filtersDispatch({
      type: FiltersActionType.ShowAllNetworks,
    });
    toast.success(`Set networks to default`, {
      id: "filter-update",
    });
  };

  return (
    <FlexCenteredGap>
      <NetworkIconStack
        networkIds={visibleNetworks}
        maxShown={lessThanMd ? 7 : 10}
      />
      <FilterNetworksModal />

      <ResetFilterButton
        isDefault={isDefault}
        setToDefault={handleSetToDefault}
      />
    </FlexCenteredGap>
  );
};

const MinMaxInputs: React.FC<{
  min?: string;
  max?: string;
  setMin: (min: string) => void;
  setMax: (max: string) => void;
  smallSize?: boolean;
}> = ({ min, max, setMin, setMax, smallSize }) => {
  const [minError, setMinError] = React.useState(false);
  const [maxError, setMaxError] = React.useState(false);

  // Validate
  React.useEffect(() => {
    setMinError(false);
    setMaxError(false);
    if (min && max) {
      if (Number(min) > Number(max)) {
        setMinError(true);
      }
      if (Number(max) < Number(min)) {
        setMaxError(true);
      }
    }
  }, [max, min]);

  return (
    <div style={{ display: "flex", gap: "8px" }}>
      <Input
        type="number"
        placeholder="Min"
        onChange={(e) => {
          setMin(e.target.value);
        }}
        value={min}
        $error={minError}
        $highlightIfFilled
        style={{
          width: "100%",
          maxWidth: smallSize ? 50 : 80,
        }}
      />
      <Input
        type="number"
        placeholder="Max"
        onChange={(e) => {
          setMax(e.target.value);
        }}
        value={max}
        $error={maxError}
        $highlightIfFilled
        style={{
          width: "100%",
          maxWidth: smallSize ? 50 : 80,
        }}
      />
    </div>
  );
};

const MinMaxFilter: React.FC<{
  min?: string;
  max?: string;
  handleMinChange: (newMin: string) => void;
  handleMaxChange: (newMax: string) => void;
}> = ({ min, max, handleMinChange, handleMaxChange }) => {
  const [isDefault, setIsDefault] = React.useState(false);

  const handleSetToDefault = () => {
    handleMinChange("");
    handleMaxChange("");
    setIsDefault(true);
  };

  React.useEffect(() => {
    setIsDefault(min === "" && max === "");
  }, [max, min]);

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <MinMaxInputs
        min={min?.toString()}
        max={max?.toString()}
        setMin={handleMinChange}
        setMax={handleMaxChange}
      />
      <ResetFilterButton
        isDefault={isDefault}
        setToDefault={handleSetToDefault}
      />
    </div>
  );
};

const WindowMinMaxFilter: React.FC<{
  min?: string;
  max?: string;
  window: Duration;
  handleWindowChange: (newWindow: Duration) => void;
  handleMinChange: (newMin: string) => void;
  handleMaxChange: (newMax: string) => void;
}> = ({
  min,
  max,
  window,
  handleWindowChange,
  handleMinChange,
  handleMaxChange,
}) => {
  const lessThanMd = useMediaQuery(lessThan(breakpoints.md));
  const [isDefault, setIsDefault] = React.useState(false);

  const handleSetToDefault = () => {
    handleWindowChange(DEFAULT_WINDOW);
    handleMinChange("");
    handleMaxChange("");
    setIsDefault(true);
  };

  React.useEffect(() => {
    setIsDefault(
      (window as Duration) === DEFAULT_WINDOW && min === "" && max === ""
    );
  }, [max, min, window]);

  const handleToggleWindow = React.useCallback(
    (newWindow: Duration) => {
      if (!newWindow) return;
      handleWindowChange(newWindow);
    },
    [handleWindowChange]
  );

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      {lessThanMd ? (
        <DurationSelect handleChange={handleToggleWindow} value={window} />
      ) : (
        <ToggleGroupRoot
          type="single"
          value={window}
          onValueChange={handleToggleWindow}
        >
          {windows.map((window) => (
            <ToggleGroupItem key={window} value={window}>
              {DurationFormattedShort[window]}
            </ToggleGroupItem>
          ))}
        </ToggleGroupRoot>
      )}
      <MinMaxInputs
        min={min?.toString()}
        max={max?.toString()}
        setMin={handleMinChange}
        setMax={handleMaxChange}
        smallSize={lessThanMd}
      />
      <ResetFilterButton
        isDefault={isDefault}
        setToDefault={handleSetToDefault}
      />
    </div>
  );
};

export {
  NetworkFilter,
  MinMaxInputs,
  MinMaxFilter,
  WindowMinMaxFilter,
  ResetFilterButton,
};
