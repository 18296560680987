import { css } from "@emotion/react";
import styled from "@emotion/styled";
import * as SelectPrimitive from "@radix-ui/react-select";
import React from "react";
import { BiChevronDown } from "react-icons/bi";
import { IoMdCheckmark } from "react-icons/io";

import { borderRadius } from "../../styles/Mixins";

export interface SelectProps {
  value: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (e: any) => void;
  children?: React.ReactNode;
}

const SelectTrigger = styled(SelectPrimitive.Trigger)(
  ({ theme }) => css`
    align-items: center;
    background-color: ${theme.colors.secondary}44;
    box-shadow: 0 0 0 1px ${theme.colors.gray.gray7};
    color: ${theme.colors.secondaryAlt};
    cursor: pointer;
    display: flex;
    font-size: ${theme.fontSize.body2};
    gap: ${theme.spacing(0.75)};
    height: 35px;
    justify-content: space-between;
    line-height: 1;
    min-height: 34px;
    min-width: 34px;
    padding: ${theme.spacing(0.75)} ${theme.spacing(1)};
    transition: all 0.1s;
    user-select: none;
    white-space: nowrap;
    ${borderRadius}
    &:hover {
      background-color: ${theme.colors.secondary}55;
    }
    &:focus {
      box-shadow: 0 0 0 2px ${theme.colors.secondary}aa;
      color: ${theme.colors.gray.gray12};
    }
  `
);

const SelectPortal = styled(SelectPrimitive.Portal)(
  ({ theme }) => css`
    background-color: ${theme.colors.gray.gray1};
    color: ${theme.colors.gray.gray11};
    ${borderRadius}
  `
);

const SelectItem = styled(SelectPrimitive.Item)(
  ({ theme }) => css`
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: ${theme.spacing(0.5)};
    user-select: none;
    &:hover {
      background-color: ${theme.colors.secondary}11;
    }
  `
);

const SelectOption: React.FC<{ text: string; value: string }> = ({
  text,
  value,
}) => (
  <SelectItem value={value}>
    <SelectPrimitive.ItemText>{text}</SelectPrimitive.ItemText>
    <SelectPrimitive.ItemIndicator>
      <IoMdCheckmark />
    </SelectPrimitive.ItemIndicator>
  </SelectItem>
);

const Select: React.FC<SelectProps> = ({ value, onChange, children }) => (
  <SelectPrimitive.Root value={value} onValueChange={onChange}>
    <SelectTrigger>
      <SelectPrimitive.Value />
      <SelectPrimitive.Icon>
        <BiChevronDown />
      </SelectPrimitive.Icon>
    </SelectTrigger>
    <SelectPortal>
      <SelectPrimitive.Content>
        <SelectPrimitive.Viewport>{children}</SelectPrimitive.Viewport>
      </SelectPrimitive.Content>
    </SelectPortal>
  </SelectPrimitive.Root>
);

export { Select, SelectOption };
