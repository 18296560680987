import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { blackA, grayA, mauve } from "@radix-ui/colors";
import * as RadixToggleGroup from "@radix-ui/react-toggle-group";

import { borderRadius } from "../../styles/Mixins";

const ToggleGroupRoot = styled(RadixToggleGroup.Root)`
  display: inline-flex;
  background-color: ${grayA.grayA6};
  box-shadow: 0 1px 4px ${blackA.blackA7};
  ${borderRadius}
`;

const ToggleGroupItem = styled(RadixToggleGroup.Item)(
  ({ theme }) => css`
    align-items: center;
    background-color: ${theme.colors.gray.gray3};
    color: ${mauve.mauve11};
    cursor: pointer;
    display: flex;
    font-size: ${theme.fontSize.body2};
    height: 35px;
    min-width: 35px;
    justify-content: center;
    line-height: 1;
    margin-left: 1px;
    gap: ${theme.spacing(0.75)};
    padding: 0 ${theme.spacing(1)};
    &:first-of-type {
      margin-left: 0;
      border-top-left-radius: ${theme.spacing(1)};
      border-bottom-left-radius: ${theme.spacing(1)};
    }
    &:last-of-type {
      border-top-right-radius: ${theme.spacing(1)};
      border-bottom-right-radius: ${theme.spacing(1)};
    }
    &:hover {
      background-color: ${theme.colors.secondary}22;
      color: ${theme.colors.secondaryAlt};
    }
    &[data-state="on"] {
      background-color: ${theme.colors.secondary}55;
      color: ${theme.colors.secondaryAlt};
    }
    &:focus {
      position: relative;
      box-shadow: 0 0 0 2px ${theme.colors.secondary}aa;
    }
  `
);

export { ToggleGroupRoot, ToggleGroupItem };
