import { Duration } from "../types/Duration";

const DurationFormattedShort: Record<Duration, string> = {
  [Duration.Sec1]: "1s",
  [Duration.Sec5]: "5s",
  [Duration.Sec15]: "15s",
  [Duration.Min1]: "1m",
  [Duration.Min5]: "5m",
  [Duration.Min15]: "15m",
  [Duration.Min30]: "30m",
  [Duration.Hour1]: "1h",
  [Duration.Hour4]: "4h",
  [Duration.Hour12]: "12h",
  [Duration.Day1]: "24h",
  [Duration.Week1]: "1w",
  [Duration.Day30]: "30d",
  [Duration.Day90]: "90d",
} as const;

const DurationFormattedLong: Record<Duration, string> = {
  [Duration.Sec1]: "1 sec",
  [Duration.Sec5]: "5 secs",
  [Duration.Sec15]: "15 secs",
  [Duration.Min1]: "1 min",
  [Duration.Min5]: "5 mins",
  [Duration.Min15]: "15 mins",
  [Duration.Min30]: "30 mins",
  [Duration.Hour1]: "1 hour",
  [Duration.Hour4]: "4 hours",
  [Duration.Hour12]: "12 hours",
  [Duration.Day1]: "24 hours",
  [Duration.Week1]: "1 week",
  [Duration.Day30]: "30 days",
  [Duration.Day90]: "90 days",
} as const;

const DurationInSeconds: Record<Duration, number> = {
  [Duration.Sec1]: 1,
  [Duration.Sec5]: 5,
  [Duration.Sec15]: 15,
  [Duration.Min1]: 60,
  [Duration.Min5]: 60 * 5,
  [Duration.Min15]: 60 * 15,
  [Duration.Min30]: 60 * 30,
  [Duration.Hour1]: 60 * 60,
  [Duration.Hour4]: 60 * 60 * 4,
  [Duration.Hour12]: 60 * 60 * 12,
  [Duration.Day1]: 60 * 60 * 24,
  [Duration.Week1]: 60 * 60 * 24 * 7,
  [Duration.Day30]: 60 * 60 * 24 * 30,
  [Duration.Day90]: 60 * 60 * 24 * 90,
} as const;

// This is how Trading View formats their durations.
// Since the format is ambiguous and we were overloading
// the term resolution, we've since decided to more broadly
// use the Duration type.
enum Resolution {
  day1 = "1D",
  hour1 = "60",
  hour12 = "720",
  hour4 = "240",
  min1 = "1",
  min15 = "15",
  min30 = "30",
  min5 = "5",
  week1 = "7D",
  sec15 = "15S",
  sec5 = "5S",
  sec1 = "1S",
}

const DurationToTVResolution: Partial<Record<Duration, Resolution>> = {
  [Duration.Sec1]: Resolution.sec1,
  [Duration.Sec5]: Resolution.sec5,
  [Duration.Sec15]: Resolution.sec15,
  [Duration.Min1]: Resolution.min1,
  [Duration.Min5]: Resolution.min5,
  [Duration.Min15]: Resolution.min15,
  [Duration.Min30]: Resolution.min30,
  [Duration.Hour1]: Resolution.hour1,
  [Duration.Hour4]: Resolution.hour4,
  [Duration.Hour12]: Resolution.hour12,
  [Duration.Day1]: Resolution.day1,
  [Duration.Week1]: Resolution.week1,
};

enum NftAnalyticWindow {
  day30 = "30d",
  week1 = "7d",
  day1 = "1d",
  hour12 = "12h",
  hour4 = "4h",
  hour1 = "1h",
  min15 = "15m",
}

const DurationToNftAnalyticWindow: Partial<
  Record<Duration, NftAnalyticWindow>
> = {
  [Duration.Min15]: NftAnalyticWindow.min15,
  [Duration.Hour1]: NftAnalyticWindow.hour1,
  [Duration.Hour4]: NftAnalyticWindow.hour4,
  [Duration.Hour12]: NftAnalyticWindow.hour12,
  [Duration.Day1]: NftAnalyticWindow.day1,
  [Duration.Week1]: NftAnalyticWindow.week1,
  [Duration.Day30]: NftAnalyticWindow.day30,
} as const;

enum FilterSuffix {
  Hour1 = "1",
  Hour4 = "4",
  Hour12 = "12",
  Day1 = "24",
}

const DurationToFilterSuffix: Partial<Record<Duration, FilterSuffix>> = {
  [Duration.Hour1]: FilterSuffix.Hour1,
  [Duration.Hour4]: FilterSuffix.Hour4,
  [Duration.Hour12]: FilterSuffix.Hour12,
  [Duration.Day1]: FilterSuffix.Day1,
} as const;

export {
  DurationFormattedLong,
  DurationFormattedShort,
  DurationInSeconds,
  DurationToFilterSuffix,
  DurationToNftAnalyticWindow,
  DurationToTVResolution,
  NftAnalyticWindow,
  Resolution,
};
