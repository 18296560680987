import "./styles/global.scss";

import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "@emotion/react";
import React from "react";
import { Toaster } from "react-hot-toast";

import { initializeApolloClient } from "./ApolloClient";
import { FiltersContextProvider } from "./context/FiltersContext";
import { HistoryContextProvider } from "./context/HistoryContext";
import {
  SettingsContext,
  SettingsContextProvider,
} from "./context/SettingsContext";
import { HomePage } from "./pages/HomePage";
import { darkTheme, lightTheme } from "./styles/Theme";

const ThemeWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const {
    settingsState: { darkMode },
  } = React.useContext(SettingsContext);

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      {children}
    </ThemeProvider>
  );
};

const App: React.FC = () => {
  const client = initializeApolloClient();

  return (
    <>
      <ApolloProvider client={client}>
        <Toaster />
        <SettingsContextProvider>
          <HistoryContextProvider>
            <FiltersContextProvider>
              <ThemeWrapper>
                <HomePage />
              </ThemeWrapper>
            </FiltersContextProvider>
          </HistoryContextProvider>
        </SettingsContextProvider>
      </ApolloProvider>
    </>
  );
};

export { App };
