import { css } from "@emotion/react/macro";
import styled from "@emotion/styled";
import { Command } from "cmdk";
import React from "react";
import { HiArrowLeft } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";

import { borderRadius } from "../../styles/Mixins";
import { FlexCentered, FlexCenteredGap } from "../basic/Flex";
import { SearchPage, searchPageAttributes } from "./Search";

export interface SearchInputProps {
  backToMainPage: () => void;
  clearSearchText: () => void;
  inputRef: React.RefObject<HTMLInputElement>;
  page?: SearchPage | null;
  searchText?: string;
  searchTextForPages?: string;
  setSearchText: (text: string) => void;
  setSearchTextForPages: (text: string) => void;
}

const InputContainer = styled(FlexCenteredGap)(
  ({ theme }) => css`
    padding: 0 ${theme.spacing(2)};
    input {
      padding: ${theme.spacing(2)} 0;
    }
  `
);

const InputIconContainer = styled(FlexCentered)`
  cursor: pointer;
  height: 100%;
`;

const InputIcon = styled(FlexCentered)`
  background: #88888822;
  color: #888;
  cursor: pointer;
  height: 24px;
  padding: 4px;
  width: 24px;
  ${borderRadius}
`;

const SearchInput: React.FC<SearchInputProps> = ({
  backToMainPage,
  clearSearchText,
  inputRef,
  page,
  searchText,
  searchTextForPages,
  setSearchText,
  setSearchTextForPages,
}) => {
  return (
    <InputContainer>
      {page && (
        <InputIconContainer onClick={backToMainPage}>
          <InputIcon>
            <HiArrowLeft />
          </InputIcon>
        </InputIconContainer>
      )}
      <Command.Input
        ref={inputRef}
        autoFocus
        placeholder={
          page
            ? searchPageAttributes[page].searchPlaceholder
            : "Search by name, symbol or address"
        }
        value={page ? searchTextForPages : searchText}
        onValueChange={page ? setSearchTextForPages : setSearchText}
      />
      {((!page && searchText) || searchTextForPages) && (
        <InputIconContainer onClick={clearSearchText}>
          <InputIcon>
            <IoMdClose />
          </InputIcon>
        </InputIconContainer>
      )}
    </InputContainer>
  );
};

export { SearchInput };
