import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useCallback, useContext, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { BiSelectMultiple } from "react-icons/bi";
import { BsCheckCircleFill, BsCircle } from "react-icons/bs";

import {
  EnabledNetworks,
  NetworkNames,
  NetworkShortName,
} from "../../constants/Network";
import {
  FiltersActionType,
  FiltersContext,
} from "../../context/FiltersContext";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { breakpoints, lessThan } from "../../styles/Layout";
import { NetworkId } from "../../types/Network";
import {
  Flex,
  FlexCenteredGap,
  FlexColumn,
  FlexColumnGap,
} from "../basic/Flex";
import { Dialog } from "../feedback/Dialog";
import { Button } from "../inputs/Button";
import { Input } from "../inputs/Input";
import { NetworkIcon } from "./NetworkIcon";

const NetworkSymbol = styled("p")(
  ({ theme }) => css`
    font-size: ${theme.fontSize.body1};
    font-weight: 600;
  `
);

const NetworkName = styled("p")(
  ({ theme }) => css`
    font-size: ${theme.fontSize.caption};
    font-weight: 500;
    opacity: 0.7;
    line-height: 1;
  `
);

const FilterNetworksModal: React.FC = () => {
  const lessThanMd = useMediaQuery(lessThan(breakpoints.md));

  const [searchText, setSearchText] = useState("");

  const {
    state: { visibleNetworks, visibleNetworksModalOpen },
    dispatch: filtersDispatch,
    isHidden,
  } = useContext(FiltersContext);

  const toggleModal = (open: boolean) => {
    filtersDispatch({
      type: FiltersActionType.ToggleFilterNetworksModal,
      open: open,
    });
    setSearchText("");
  };

  const networkNames = useMemo(
    () =>
      EnabledNetworks.map((nw) => {
        return {
          id: nw,
          name: NetworkNames[nw].toLowerCase(),
          shortName: NetworkShortName[nw].toLowerCase(),
        };
      }),
    []
  );

  const filteredNetworks = useMemo(
    () =>
      networkNames.filter(
        (nw) =>
          nw.name.includes(searchText.toLowerCase()) ||
          nw.shortName.includes(searchText.toLowerCase())
      ),
    [networkNames, searchText]
  );

  const toggleHidden = useCallback(
    (network: NetworkId, hide: boolean) => {
      if (visibleNetworks.length > 1 || !hide) {
        const type = hide
          ? FiltersActionType.HideNetwork
          : FiltersActionType.ShowNetwork;

        filtersDispatch({ type, network: network });
      } else {
        toast("At least one network must be visible", {
          id: "visibleNetworkWarning",
        });
      }
    },
    [visibleNetworks.length, filtersDispatch]
  );

  const handleSelectAllOrNone = useCallback(() => {
    const type =
      visibleNetworks.length === EnabledNetworks.length
        ? FiltersActionType.HideAllButOneNetwork
        : FiltersActionType.ShowAllNetworks;
    filtersDispatch({ type });
  }, [visibleNetworks.length, filtersDispatch]);

  return (
    <div style={{ position: "relative" }}>
      <Button onClick={() => toggleModal(true)}>
        {lessThanMd ? "Edit" : "Edit networks"}
      </Button>
      <Dialog
        title="Filter Networks"
        open={visibleNetworksModalOpen}
        handleOpenChange={toggleModal}
      >
        <FlexColumnGap>
          {!lessThanMd && (
            <Input
              onChange={(event) => setSearchText(event.target.value)}
              placeholder="Search by name or symbol"
              value={searchText}
              style={{
                padding: "8px 16px",
                width: "calc(100% - 32px)",
                marginBottom: 8,
              }}
            />
          )}
          <Flex style={{ gap: "8px", flexWrap: "wrap" }}>
            {EnabledNetworks.map((nw, i) => {
              const isNetworkHidden = isHidden(nw);
              const isFiltered = filteredNetworks.find((fnw) => fnw.id === nw);
              return (
                <Button
                  key={i}
                  onClick={() => toggleHidden(nw, !isNetworkHidden)}
                  style={{
                    transition: "0.1s all",
                    opacity:
                      (isNetworkHidden ? 0.5 : 1) / (isFiltered ? 1 : 10),
                    boxShadow:
                      searchText && isFiltered
                        ? `0px 4px 8px #00000088`
                        : "none",
                  }}
                >
                  <FlexCenteredGap>
                    <NetworkIcon
                      sizeInPx={lessThanMd ? 20 : 30}
                      networkId={nw}
                    />
                    <FlexColumn style={{ alignItems: "flex-start" }}>
                      <NetworkSymbol>
                        {NetworkShortName[nw].toUpperCase()}
                      </NetworkSymbol>
                      {!lessThanMd && (
                        <NetworkName>{NetworkNames[nw]}</NetworkName>
                      )}
                    </FlexColumn>
                    <Flex style={{ opacity: 0.6, fontSize: 18 }}>
                      {isNetworkHidden ? <BsCircle /> : <BsCheckCircleFill />}
                    </Flex>
                  </FlexCenteredGap>
                </Button>
              );
            })}
          </Flex>
          <Flex style={{ justifyContent: "flex-end" }}>
            <Button color="secondary" onClick={handleSelectAllOrNone}>
              <BiSelectMultiple />
              {visibleNetworks.length === EnabledNetworks.length
                ? "Hide All"
                : "Show All"}
            </Button>
          </Flex>
        </FlexColumnGap>
      </Dialog>
    </div>
  );
};

export { FilterNetworksModal };
