import React, { useCallback } from "react";

import { DurationFormattedShort } from "../../constants/Duration";
import { Duration } from "../../types/Duration";
import { Select, SelectOption } from "../inputs/Select";

type DurationSelectProps = {
  disableWrap?: boolean;
  durations?: Duration[];
  handleChange: (newDuration: Duration) => void;
  value: string;
};

const defaultDurations = [
  Duration.Hour1,
  Duration.Hour4,
  Duration.Hour12,
  Duration.Day1,
];

const DurationSelect: React.FC<
  DurationSelectProps &
    React.DetailedHTMLProps<
      React.SelectHTMLAttributes<HTMLSelectElement>,
      HTMLSelectElement
    >
> = ({ durations = defaultDurations, handleChange, value, ...props }) => {
  const handleDurationChange = useCallback(
    (newDuration: Duration) => {
      // The same resolution has been selected, don't deselect it.
      if (newDuration === null) return;

      handleChange(newDuration);
    },
    [handleChange]
  );

  return (
    <Select value={value} onChange={handleDurationChange} {...props}>
      {durations.map((duration) => (
        <SelectOption
          key={duration}
          value={duration}
          text={DurationFormattedShort[duration]}
        />
      ))}
    </Select>
  );
};

export { DurationSelect };
