import { CreateStyled } from "@emotion/styled";

/**
 * Do not forward props to the DOM if they start with $.
 * This follows the styled-component "transient prop" pattern to prevent
 * errors due to treating react props as HTML attributes.
 */
export const withTransientProps: Parameters<CreateStyled>[1] = {
  shouldForwardProp: (propName: string) => !propName.startsWith("$"),
};
