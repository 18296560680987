import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { borderRadius } from "../../styles/Mixins";
import { withTransientProps } from "../../utils/styles";

const Input = styled(
  "input",
  withTransientProps
)<{ $error?: boolean; $highlightIfFilled?: boolean }>(
  ({ theme, $error, $highlightIfFilled }) => css`
    all: unset;

    align-items: center;
    background-color: ${theme.colors.gray.gray1};
    box-shadow: 0 0 0 2px
      ${$error ? theme.colors.error : theme.colors.gray.gray7};
    cursor: text;
    color: ${theme.colors.gray.gray11};
    display: inline-flex;
    height: 35px;
    justify-content: center;
    line-height: 1;
    padding: 0 ${theme.spacing(1)};
    width: 200px;
    ${borderRadius}

    :not(:placeholder-shown) {
      ${$highlightIfFilled &&
      `box-shadow: 0 0 0 2px ${
        $error ? theme.colors.error : theme.colors.secondary
      }55;`}
      ${$highlightIfFilled &&
      `background-color: ${
        $error ? theme.colors.error : theme.colors.secondary
      }22;`}
      &:hover {
        ${$highlightIfFilled &&
        `box-shadow: 0 0 0 2px ${
          $error ? theme.colors.error : theme.colors.secondary
        }66;`}
      }
    }

    &:hover {
      box-shadow: 0 0 0 2px
        ${$error ? theme.colors.error : theme.colors.gray.gray9};
    }

    &:focus,
    &:hover:focus {
      box-shadow: 0 0 0 2px ${theme.colors.secondary};
      color: ${theme.colors.gray.gray12};
    }
  `
);

export { Input };
