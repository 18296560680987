import { css } from "@emotion/react";
import styled from "@emotion/styled";
import * as AvatarPrimitive from "@radix-ui/react-avatar";
import React from "react";

import { withTransientProps } from "../../utils/styles";

export interface TokenIconProps {
  address: string;
  name: string;
  networkId: number;
  sizeInPx?: number;
}

const AvatarRoot = styled(
  AvatarPrimitive.Root,
  withTransientProps
)<{ $sizeInPx: number }>(
  ({ $sizeInPx }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    overflow: hidden;
    user-select: none;
    width: ${$sizeInPx}px;
    height: ${$sizeInPx}px;
    border-radius: 100%;
  `
);

const AvatarImage = styled(AvatarPrimitive.Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
`;

const AvatarFallback = styled(AvatarPrimitive.Fallback)`
  align-items: center;
  display: flex;
  font-size: 15px;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  line-height: 1;
  width: 100%;
`;

const getTokenIconUrl = (address: string, networkId: number) => {
  const tokenImgBucketUrl = "https://d3kdei7cs9pk0w.cloudfront.net";

  return `${tokenImgBucketUrl}/${networkId}_${address}_small.png`;
};

const TokenIcon: React.FC<TokenIconProps> = ({
  address,
  networkId,
  name,
  sizeInPx = 20,
}) => (
  <AvatarRoot $sizeInPx={sizeInPx}>
    <AvatarImage src={getTokenIconUrl(address, networkId)} alt={name} />
    <AvatarFallback>{name?.[0]}</AvatarFallback>
  </AvatarRoot>
);

export { TokenIcon };
